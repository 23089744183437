import { useEffect, useState } from "react";
import { NameProccess } from "../../globle-component/globle";
import { ImageLightBox } from "./imageLightBox";
import { Triangle } from 'react-loader-spinner'
import ColorSelector from "../../../helper/colorSelector";

const CardForHourRow = ({ minuteSlot, minuteRange }) => {
    let noThumbImage = process.env.REACT_APP_S3_IMAGE_HOST + process.env.REACT_APP_S3_IMAGE_FORLDER_PATH + `no-image.` + process.env.REACT_APP_S3_IMAGE_TYPE;
    const [thumbnailValidity, setThumbnailValidity] = useState(false)
    const [imageChecked, setImageChecked] = useState(false)
    const [projectName, setProjectName] = useState(null)
    const [title, setTitle] = useState(null)

    var img = new Image();
    img.onload = function () {
        // console.log("image is loaded");
        setThumbnailValidity(true);
        setImageChecked(true)
    }
    img.onerror = () => {
        // console.log("image is Not loaded");
        setThumbnailValidity(false);
        setImageChecked(true)
    };
    if (minuteSlot !== null || minuteSlot !== undefined) {
        if ((minuteSlot.screens !== null || minuteSlot.screens !== undefined) && minuteSlot.screens.length > 0) {
            if (minuteSlot.screens[0].thumbnailUrl !== null || minuteSlot.screens[0].thumbnailUrl !== undefined) {
                img.src = minuteSlot?.screens[0]?.thumbnailUrl;
            }
        }
    }

    useEffect(() => {
        if (minuteSlot.projectNames.length > 1) {
            setProjectName('multipel projects')
            setTitle(minuteSlot.projectNames.toString().replace(",", ", "))
        } else {
            setProjectName(minuteSlot.projectNames)
            setTitle(minuteSlot.projectNames)
        }
    }, [])
    
    let projectColor = minuteSlot?.screens[0]?.projectId ? ColorSelector(minuteSlot?.screens[0]?.projectId) : '#2f3242';
    return (
        <div className="card w-full">
            {(minuteSlot.projectNames.length > 0 && minuteSlot.screens.length > 0) ? (
                <div className="card w-full">
                    <div className="rounded-full text-center mb-4 text-sm py-1 px-2 text-white font-bold capitalize" title={title} style={{ background: `${projectColor}` }}>{projectName}</div>
                </div>
            ) :
                <>
                    <div className="rounded-full text-center mb-4 text-sm py-1 px-2 text-white h-7" > </div>
                </>
            }
            <div className="border border-slate-300">
                {minuteSlot.screens.length > 0 ? (
                    <>
                        <div className="relative overflow-hidden screenshout-count-lable h-[136px]">
                            {
                                imageChecked === false && thumbnailValidity === false ? (
                                    <div className="flex justify-center">
                                        <Triangle
                                            height="40"
                                            width="40"
                                            color="#ffb30f"
                                            ariaLabel="triangle-loading"
                                            wrapperStyle={{ 'margin': '47px' }}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                ) : imageChecked === true && thumbnailValidity === false ? (
                                    <img className="object-cover w-full h-full rounded-sm  h-[136px]" src={noThumbImage} alt="" />
                                ) : imageChecked === true && thumbnailValidity === true ? (
                                    <img className="object-cover w-full h-full rounded-sm  h-[136px]" src={img.src} alt="" />
                                ) : null
                            }
                            <ImageLightBox
                                screens={minuteSlot.screens}
                            />
                        </div>

                        <div className="p-2">
                            <div className="flex text-xs">

                                <div className="w-11/12 font-blod test-xs">{minuteRange.start} - {minuteRange.end}</div>


                                <div className="w-1/12 text-right cursor-pointer text-gray-900 hover:text-yellow-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 m-auto item-end ">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                    </svg>
                                </div>
                            </div>
                            <div className="text-xs mt-2 text-gray-400 h-5">
                                {minuteSlot.taskName ? (<>{NameProccess(minuteSlot.taskName, 30)}</>) : null}
                            </div>
                        </div>

                    </>
                ) :
                    <>
                        <div className=" text-center bg-gray-300 text-sm py-1 h-7" > No activity</div>
                    </>
                }
            </div>
        </div>
    );
}

export default CardForHourRow;