import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filteredActiveMembers, filteredInvitedMembers, setActiveMembersSearchInputValue, setInvitedMembersSearchInputValue } from "../../../store/slices/members";

const MemberSearch = () => {
    const dispatch = useDispatch();
    const {
        selectedTab,
        activeMembersSearchInputValue,
        invitedMembersSearchInputValue
    } = useSelector((state) => state.members)

    const [searchInputValue, setSearchInputValue] = useState(null)
    function clearSearch() {
        let input = null;
        if (selectedTab === 0) {
            input = document.getElementById("active-members-search");
            dispatch(filteredActiveMembers(''))
            dispatch(setActiveMembersSearchInputValue(null))
        } else if (selectedTab === 1) {
            input = document.getElementById("invited-members-search");
            dispatch(filteredInvitedMembers(''))
            dispatch(setInvitedMembersSearchInputValue(null))
        }
        input.value = null;
    }
    return (
        <label className="project-search-lable">
            {selectedTab === 0 ? (
                <>
                    <input
                        placeholder={'Search active members'}
                        className="ht-input-rounded z-0"
                        id={'active-members-search'}
                        value={activeMembersSearchInputValue ?? ''}
                        onChange={(event) => {
                            dispatch(setActiveMembersSearchInputValue(event.target.value))
                            dispatch(filteredActiveMembers(event.target.value))
                        }}
                    />

                    {activeMembersSearchInputValue?.length > 0 ? (
                        <span className={'cursor-pointer z-50'} onClick={clearSearch}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 project-search-close">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                    ) : null}
                </>
            ) : selectedTab === 1 ? (
                <>
                    <input
                        placeholder={'Search invited members'}
                        className="ht-input-rounded z-0"
                        id={'invited-members-search'}
                        value={invitedMembersSearchInputValue ?? ''}
                        onChange={(event) => {
                            dispatch(setInvitedMembersSearchInputValue(event.target.value))
                            dispatch(filteredInvitedMembers(event.target.value))
                        }}
                    />

                    {invitedMembersSearchInputValue?.length > 0 ? (
                        <span className={'cursor-pointer z-50'} onClick={clearSearch}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 project-search-close">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                    ) : null}
                </>
            ) : null}
        </label>
    );
}

export default MemberSearch;