import { notifyError, notifySuccess } from "../../../helper/notification-helper";
import { addMembersToTask } from "../../../services/tasks";

export const AddMembers = (projectID, taskId, selectedAssignees) => {
    let users = []
    for (const user of selectedAssignees) {
        users.push(user.id)
    }
    let data = { "users": users }
    addMembersToTask(projectID, taskId, data)
        .then((res) => {
            notifySuccess("Members Added to task Successfully");
        })
        .catch((err) => {
            notifyError("Error on users adding!, Please try again");
        });
}

export const EditMembers = (projectID, taskId, selectedAssignees) => {
    let users = []
    for (const user of selectedAssignees) {
        users.push(user.id)
    }
    let data = { "users": users }
    addMembersToTask(projectID, taskId, data)
        .then((res) => {
            notifySuccess("Task members changed Successfully");
        })
        .catch((err) => {
            notifyError("Error on users adding!, Please try again");
        });
}