
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { userStatus } from '../../../constant/userState';
import { userRoleTextRender } from '../../globle-component/globle';


export default function Members({ numberOfMembers, memberList }) {

    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + numberOfMembers;
    const currentItems = memberList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(memberList.length / numberOfMembers);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * numberOfMembers) % memberList.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <div className="sm:  rounded w-full flex flex-col bg-white pb-1 min-h-[42vh]">
                <div className="flex flex-row justify-between py-3">
                    <div className="w-96 mx-4 order-first">
                        <h1 className="sm:  text-gray-500 font-normal tile-header uppercase px-4">
                            Members
                        </h1>
                    </div>
                </div>
                <div className="lg:overflow-x-hidden overflow-x-auto px-2">
                    <table className="table-auto relative lg:overflow-x-hidden overflow-x-auto w-full mx-2">
                        <thead>
                            <tr className="sm:  text-left text-gray-800 font-normal border-b border-1 ">
                                <th className="px-4 py-2 lg:w-[300px]">Member info</th>
                                <th className="px-2 text-center lg:w-[100px]">Status</th>
                                <th className="px-2 lg:w-[100px]">User role</th>
                                {/* <th className="px-2   mr-5">Joined at</th> */}
                            </tr>
                        </thead>
                        <tbody className="sm: ">
                            {/* <TableData props={currentItems} /> */}
                            {
                                currentItems.map((user, index) => {
                                    return (
                                        <tr className="text-left border-b border-1" key={index}>
                                            <td className="py-3 relative w-78 h-18 flex flex-row ">
                                                <div className="flex flex-row ml-4">
                                                    <button className="rounded-full sm:w-10 sm:h-10 w-[40px] h-[40px] members-avatar bg-yellow-300">
                                                        <svg
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="mx-auto my-2"
                                                        >
                                                            <path
                                                                d="M8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 12C10.7 12 13.8 13.29 14 14H2C2.23 13.28 5.31 12 8 12ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <span className="w-0.5 h-0.5 border border-white border-2 p-1.5 bg-green-400 rounded-full mt-7 -ml-3"></span>
                                                </div>
                                                <h5 className="px-4  text-slate-900 font-semibold my-2.5">
                                                    {user.full_name}
                                                </h5>
                                            </td>
                                            <td>
                                                <div className=" text-slate-900 text-center w-20 h-6 py-1 bg-yellow-300 rounded-full mx-auto leading-4">
                                                    {userStatus[user.status]}
                                                </div>
                                            </td>
                                            <td className="text-center  px-2 w-20  ">
                                                {userRoleTextRender(user.role)}
                                            </td>
                                            {/* <td className='text-center px-2 w-20  '>{new Date(data.user.verified_at).toLocaleDateString()}</td> */}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="flex flex-wrap relative">
                    {memberList.length > currentItems.length ? (
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            nextClassName="hover:bg-gray-700 hover:text-white sm:text-gray-800 px-1.5 font-bold rounded mr-7"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="<"
                            previousClassName="hover:bg-gray-700 hover:text-white sm:text-gray-800 px-1.5 font-bold rounded ml-7"
                            renderOnZeroPageCount={null}
                            containerClassName="flex flex-row justify-center items-center w-full mx-auto my-3 "
                            pageLinkClassName="hover:bg-gray-700 hover:text-white text-gray-800 px-1.5 rounded"
                            activeLinkClassName="text-gray-800 sm:font-extrabold rounded"
                        />
                    ) : null}

                </div>
            </div>
        </>
    );


}