import { createContext, useState } from "react";

const AuthContext = createContext();
const localTag = JSON.parse(localStorage.getItem('tag'));

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({ localTag });
    const [userRole, setUserRole] = useState(null)
    const [authUser, setAuthUser] = useState(null)
    return (
        <AuthContext.Provider value={{ auth, setAuth, userRole, setUserRole, authUser, setAuthUser }} >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContext;