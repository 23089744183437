import { useEffect, useState } from "react";
import Navbar from "../dashboard/component/navbar";
import SideBar from "../dashboard/component/sidebar";
import { getAccountData } from "../../services/account";
import { loadingHide, loadingShow } from "../../helper/loading";
import { useDispatch, useSelector } from "react-redux";
import { setScreenType, setSelectedUser } from "../../store/slices/worktimes";
import { DateRangeCalendar, WeekCalendar } from "../globle-component/calender";
import { addDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { fetchActiveMembers } from "../../store/slices/members";
import { getActiveProjects } from "../../store/slices/projects";
import AddManualTime from "./components/addManualTime";
import { Dropdown } from "../globle-component/dropdown";
import { setFilterData, setFilteredMember } from "../../store/slices/filters";
import { UserRole } from "../../constant/userRole";
import Filters from "../globle-component/filters";
import { getWeekDaysFromSingleDate } from "../../helper/worktimeHelper";
import moment from "moment";
import { getWorkTimes } from "../../services/worktimes";
import DailyView from "./views/daily";
import WeeklyView from "./views/weekly";
import CalendarWeekView from "./views/calendar";

const organizationId = JSON.parse(localStorage.getItem('organizationID'));
const authUserId = JSON.parse(localStorage.getItem('userId'));
const systemTimeZone = localStorage.getItem('systemTimeZone');
const organizationUserRole = JSON.parse(localStorage.getItem("orgUserRole"));

const today = new Date()
const initDateRangeObj = [
    {
        startDate: today,
        endDate: addDays(today, 0),
        key: "selection",
    },
]

let currentWeekDays = null;


const WorkTimes = () => {

    const dispatch = useDispatch();

    const { activeMemberList } = useSelector((state) => state.members);
    const { activeProjectList } = useSelector((state) => state.projects);
    const { screenType, selectedUser } = useSelector((state) => state.worktimes);
    const { filteredMember, filterParams, filterData } = useSelector((state) => state.filters);

    const [timeZoneStatus, setTimeZoneStatus] = useState(false);
    const [timerCount, setTimerCount] = useState(null)

    const [searchBoxVisible, setSearchBoxVisible] = useState(false)
    const [membersList, setMembersList] = useState([])
    const [filterUser, setFilterUser] = useState(null)

    const [datetimeRange, setDatetimeRange] = useState(initDateRangeObj)
    const [applyBtnClickStatus, setApplyBtnClickStatus] = useState(false)

    const [selectedDayForWeek, setSelectedDayForWeek] = useState(null)

    const [loading, setLoading] = useState(false);
    const [timeSlots, setTimeSlots] = useState([]);

    const [weekDays, setWeekDays] = useState([]);

    const [weekReportArray, setWeekReportArray] = useState([]);
    const [totalTimeForAllProjects, setTotalTimeForAllProjects] = useState([]);

    const [worktimeDataForCalendar, setWorktimeDataForCalendar] = useState([]);


    function checkSearchBoxVisibility() {
        if (organizationUserRole === UserRole.ORGANIZATION_OWNER || organizationUserRole === UserRole.ORGANIZATION_MANAGER) {
            setSearchBoxVisible(true);
        } else {
            setSearchBoxVisible(false);
        }
    }

    const checkTimeZone = () => {
        let timezoneRedirectStatus = false;

        try {
            getAccountData().then(({ data }) => {
                if (data.timezone == null || data.timezone == "") {
                    timezoneRedirectStatus = true;
                } else {
                    timezoneRedirectStatus = false;
                }

                if (timezoneRedirectStatus == true) {
                    setTimeZoneStatus(true)
                    setTimerCount(5)
                }
                loadingHide()
            });


        } catch (error) {
            console.log(error);
        }
    }

    const [addTimeFromCalendar, setAddTimeFromCalendar] = useState(false);
    const [addTimeToCalendar, setAddTimeToCalendar] = useState({
        start: null,
        end: null,
    });

    useEffect(() => {
        loadingShow();

        if (activeMemberList.length === 0) {
            dispatch(fetchActiveMembers(organizationId))
        } else {
            setUser()
        }

        if (activeProjectList.length === 0) {
            dispatch(getActiveProjects(organizationId))
        }

        checkTimeZone()
        checkSearchBoxVisibility()


    }, []);

    useEffect(() => {
        if (activeMemberList.length > 0) {
            setMembersList(activeMemberList)

            setUser()

            dispatch(setFilterData({
                members: activeMemberList,
            }))
        }
    }, [activeMemberList, filterUser])

    function setUser() {
        if ((selectedUser === null && filteredMember === null) || selectedUser === null && filteredMember !== null) {
            setAuthUser()
        } else if (filterUser !== null) {
            changeUser(filterUser)
        }
    }

    useEffect(() => {
        if (activeProjectList.length > 0) {

            dispatch(setFilterData({
                projects: activeProjectList,
            }))
        }
    }, [activeProjectList])


    function setAuthUser() {
        let user = null;
        if (activeMemberList.length > 0) {
            const currentUser = activeMemberList.filter(item => item.id === authUserId);
            if (currentUser.length > 0) {
                user = currentUser[0]
            }
        }

        setFilterUser(user)
        dispatch(setFilteredMember(user))
        dispatch(setSelectedUser(user))

        // console.log('set user- ', user);
    }

    function changeUser(user) {
        if (selectedUser !== user) {
            setFilterUser(user)
            dispatch(setFilteredMember(user))
            dispatch(setSelectedUser(user))

            // console.log('change user- ', user);
            // getWorktimeData()
        }
    }

    useEffect(() => {
        if (timerCount != null) {
            setTimeout(() => {
                if (timerCount > 0) {
                    setTimerCount(timerCount - 1)
                } else {
                    window.location.replace("/account");
                }
            }, 1000);
        }

    }, [timerCount])

    useEffect(() => {
        if (screenType > 0) {
            if (weekDays.length === 0) {
                setSelectedDayForWeek(new Date())
            }
        }
    }, [screenType])


    function getWorktimeData() {
        loadingShow();

        const params = {
            'time-zone': systemTimeZone,
        }

        if (datetimeRange[0].startDate !== null) {
            params['start_date_time'] = moment(datetimeRange[0].startDate).startOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
        }

        if (datetimeRange[0].endDate !== null) {
            params['end_date_time'] = moment(datetimeRange[0].endDate).endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
        }

        if (filterParams !== null) {

            if (filterParams.hasOwnProperty("user_id")) {
                params['user_id'] = filterParams.user_id
            }


            if (filterParams.hasOwnProperty("project_id")) {
                params['project_id'] = filterParams.project_id
            }

            if (filterParams.hasOwnProperty("tracking_type")) {
                params['tracking_type'] = filterParams.tracking_type
            }

            if (filterParams.hasOwnProperty("source")) {
                params['source'] = filterParams.source
            }

        }
        console.log('params - ', params);

        getWorkTimes(organizationId, params).then(({ data }) => {

            if (filterUser === null && selectedUser !== null) {
                setFilterUser(selectedUser)
            }

            if (screenType === 0) {
                dataProcessForDaily(data);
            } else if (screenType == 1) {
                dataProcessForWeekly(data);
            } else if (screenType == 2) {
                dataProcessForCalendar(data);
            }

            loadingHide();
        });


    }

    function dataProcessForDaily(data) {
        let dateList = new Set(
            data.map((item) => moment(item.startDateTime).format("YYYY-MM-DD"))
        );
        let workTimeSlotArray = [];

        for (const date of dateList) {
            let filteredWorkTimeList = data.filter(
                (worktime) =>
                    date == moment(worktime.startDateTime).format("YYYY-MM-DD")
            );

            filteredWorkTimeList.sort((a, b) => moment(a.startDateTime) - moment(b.startDateTime));

            let totalDurationInSeconds = 0;

            let totalDuration = "00:00:00";

            for (const filteredWorkTime of filteredWorkTimeList) {
                totalDurationInSeconds =
                    totalDurationInSeconds + filteredWorkTime.duration;

                var now = moment(new Date(filteredWorkTime.startDateTime)); //todays date
                var end = moment(new Date(filteredWorkTime.endDateTime)); // another date
                var duration = moment.duration(now.diff(end));

                var timeCal = moment
                    .duration(totalDuration)
                    .add(moment.duration(filteredWorkTime.durationFormatted));
                totalDuration = moment
                    .utc(timeCal.as("milliseconds"))
                    .format("HH:mm:ss");
            }

            workTimeSlotArray.push({
                date: date,
                totalDuration: totalDuration,
                timeslots: filteredWorkTimeList,
            });
        }
        let reverceTimeSlotsArray = workTimeSlotArray.sort(dynamicSort("-date"));
        setTimeSlots(reverceTimeSlotsArray);
    }

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result =
                a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
            return result * sortOrder;
        };
    }

    function dataProcessForWeekly(datas) {
        let taskArray = [];
        for (const slot of datas) {
            if (slot.taskName == undefined) {
                slot.taskName = null;
            }

            if (
                !taskArray.some(
                    (taskArray) =>
                        taskArray.taskId === slot.taskId &&
                        taskArray.projectId === slot.projectId
                )
            ) {
                let obj = {
                    projectId: slot.projectId,
                    projectName: slot.projectName,
                    taskId: slot.taskId,
                    taskName: slot.taskName,
                };
                taskArray.push(obj);
            }
        }

        let totalTimeForAllProjects = [];
        for (const days of currentWeekDays) {
            totalTimeForAllProjects.push({
                date: days,
                secondsCount: 0,
            });
        }

        for (const element of taskArray) {
            let weekDayTimeArray = [];
            let totalTimeForWeek = 0;

            for (const weekDay of currentWeekDays) {
                var filteredEvents = datas.filter(function (data) {
                    return (
                        data.projectId == element.projectId &&
                        data.taskId == element.taskId &&
                        moment(data.startDateTime).format("YYYY-MM-DD") == weekDay
                    );
                });

                var allCountDate = totalTimeForAllProjects.filter(function (object) {
                    return object.date == weekDay;
                });

                if (filteredEvents.length > 0) {
                    let totalSecondsCount = 0;
                    for (const event of filteredEvents) {
                        totalSecondsCount = totalSecondsCount + event.duration;
                    }
                    weekDayTimeArray.push(totalSecondsCount);
                    totalTimeForWeek = totalTimeForWeek + totalSecondsCount;

                    allCountDate[0].secondsCount =
                        allCountDate[0].secondsCount + totalSecondsCount;
                } else {
                    weekDayTimeArray.push(null);
                }
            }

            element.totalTimeForWeek = totalTimeForWeek;
            element.weekDayTimeArray = weekDayTimeArray;
        }

        setWeekReportArray(taskArray);
        setTotalTimeForAllProjects(totalTimeForAllProjects);
    }

    function dataProcessForCalendar(data) {
        setWorktimeDataForCalendar(data);
    }

    useEffect(() => {
        if (selectedDayForWeek !== null) {
            let weekDays = getWeekDaysFromSingleDate(selectedDayForWeek);
            const newObject = [
                {
                    startDate: new Date(weekDays[0]),
                    endDate: new Date(weekDays[weekDays.length - 1]),
                    key: "selection",
                },
            ];
            currentWeekDays = weekDays;
            setWeekDays(weekDays)
            setDatetimeRange(newObject);
        }
    }, [selectedDayForWeek])

    useEffect(() => {
        if (filterParams !== null && datetimeRange !== null) {
            if (screenType === 0) {
                if (applyBtnClickStatus) {
                    // console.log('ready - ', filterParams, datetimeRange);
                    getWorktimeData()
                } else {
                    getWorktimeData()
                }
            } else if (screenType === 1 || screenType === 2) {
                // console.log('ready - ', filterParams, datetimeRange);
                getWorktimeData()
            }
        }
    }, [filterParams, datetimeRange, applyBtnClickStatus])


    useEffect(() => {
        // console.log('worktimeDataForCalendar - ', worktimeDataForCalendar);
    }, [worktimeDataForCalendar])





    return (
        <>
            <div className="custom-body flex flex-row h-full w-full overflow-x-hidden">
                <SideBar />

                <div className="bg-gray-200 w-full h-full min-h-screen flex-1">
                    <Navbar />

                    <div className="flex flex-row w-full p-5 custom-body">
                        <div className="w-full bg-white p-2 rounded-md relative">


                            <div className="flex flex-row py-3">
                                <div className="flex w-full mx-4 order-first">
                                    <h1 className=" font-bold page-title absolute">View work-times</h1>
                                    <div
                                        className="m-auto py-1.5 lg:mt-0 mt-4 lg:mb-0 mb-4 rounded-full sm:text-sm text-xs timeframe"
                                        style={{ background: "#E6E7E7" }}
                                    >
                                        <a
                                            className={`sm:px-7 px-4 md:text-sm text-xs rounded-full py-2 ${screenType == 0 ? "active" : ""}`}
                                            onClick={() => { dispatch(setScreenType(0)) }}
                                        >
                                            Daily
                                        </a>
                                        <a
                                            className={`sm:px-7 px-4 md:text-sm text-xs rounded-full py-2 ${screenType == 1 ? "active" : ""}`}
                                            onClick={() => { dispatch(setScreenType(1)) }}
                                        >
                                            Weekly
                                        </a>
                                        <a
                                            className={`sm:px-7 px-4 md:text-sm text-xs rounded-full py-2 ${screenType == 2 ? "active" : ""}`}
                                            onClick={() => { dispatch(setScreenType(2)) }}
                                        >
                                            Calendar
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="flex px-3 pb-2 w-full justify-between item-center">
                                <div className="flex">
                                    <div className="w-full lg:w-auto ">
                                        <div>
                                            {screenType == 0 ? (
                                                <DateRangeCalendar
                                                    datetimeRange={datetimeRange}
                                                    setDatetimeRange={setDatetimeRange}
                                                    setApplyBtnClickStatus={setApplyBtnClickStatus}
                                                />
                                            ) : null}

                                            {screenType == 1 ? (
                                                <WeekCalendar
                                                    datetimeRange={datetimeRange}
                                                    setApplyBtnClickStatus={setApplyBtnClickStatus}
                                                    setSelectedDayForWeek={setSelectedDayForWeek}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className=" lg:w-40 w-3/12">
                                        {/* <AddManualTime
                                            projectList={activeProjectList}
                                        /> */}
                                        <AddManualTime
                                            projectList={activeProjectList}
                                            getWorktimeData={getWorktimeData}
                                            addTimeToCalendar={addTimeToCalendar}
                                            addTimeFromCalendar={addTimeFromCalendar}
                                            setAddTimeFromCalendar={setAddTimeFromCalendar}
                                        />
                                    </div>
                                </div>
                                <div className={`flex justify-end`}>

                                    {searchBoxVisible ? (
                                        <div style={{ maxWidth: '200px', width: '200px' }}>
                                            <Dropdown
                                                listData={membersList}
                                                multiselect={false}
                                                placeholder={'Search members'}
                                                selectedItems={filterUser}
                                                setSelectedItems={setFilterUser}
                                                descriptionShow={true}
                                            />
                                        </div>
                                    ) : null}
                                    <div className="ml-2" style={{ maxWidth: '150px', width: '150px' }}>
                                        <Filters
                                            selectedUser={filterUser}
                                            setSelectedUser={setFilterUser}
                                            searchBoxVisible={searchBoxVisible}
                                        />
                                    </div>


                                </div>
                            </div>

                            <div className="px-3">


                                {screenType == 0 ? (
                                    <DailyView timeSlots={timeSlots} loading={loading} />
                                ) : null}

                                {screenType == 1 ? (
                                    <WeeklyView
                                        weekDays={weekDays}
                                        setWeekDays={setWeekDays}
                                        weekReportArray={weekReportArray}
                                        totalTimeForAllProjects={totalTimeForAllProjects}
                                    />
                                ) : null}

                                {screenType == 2 ? (
                                    <CalendarWeekView
                                        worktimeDataForCalendar={worktimeDataForCalendar}
                                        setSelectedDayForWeek={setSelectedDayForWeek}
                                        setAddTimeToCalendar={setAddTimeToCalendar}
                                        setAddTimeFromCalendar={setAddTimeFromCalendar}
                                        selectedDayForWeek={selectedDayForWeek}
                                    />
                                ) : null}

                            </div>


                        </div>
                    </div>

                </div>

            </div>

            {timeZoneStatus
                ? (
                    <div className="bg-white w-full h-full min-h-screen z-50 fixed top-0 left-0 text-center">
                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center flex flex-col">
                            <div className=" text-[#F6932D] items-center mx-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                </svg>
                            </div>
                            <div className="text-center mt-2 text-base">
                                Your timezone details are empty ! <br />
                                We will redirect you to your profile in a moment !! Please update your timezone !
                            </div>

                            <span className="text-[#F6932D] items-center mt-5 text-base">{timerCount}</span>
                        </div>
                    </div>
                )
                : null
            }
        </>
    );
}

export default WorkTimes;