import { Dialog, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';
import AuthContext from '../../../context/authProvider';
import { loadingHide, loadingShow } from '../../../helper/loading';
import useAuth from '../../../hooks/useAuth';
import { archiveOrganization, leaveOrganization } from '../../../services/organizations';
import Select from 'react-select';
import { ArchiveReasons } from '../../../constant/archiveReasons';
import { UserRole } from '../../../constant/userRole';
import { useDispatch } from 'react-redux';
import { fetchOrganizations } from '../../../store/slices/organizations';

function Dropdown({ data, setModalData, notifyError, notifySuccess }) {

    const [leaveBtnStatus, setLeaveBtnStatus] = useState(true)
    const [leaveModalStatus, setLeaveModalStatus] = useState(false)
    const [archiveModalStatus, setArchiveModalStatus] = useState(false)

    const [selectedReason, setSelectedReason] = useState("")
    const [archiveDetails, setArchiveDetails] = useState("")
    const [archiveReasonStatus, setArchiveReasonStatus] = useState(false)

    const editModalObject = {
        formData: {
            id: data.organization.id,
            name: data.organization.name,
            industry: data.organization.industry,
            team_size: data.organization.team_size,
            members: ''
        },
        modals: {
            type: 'edit',
            show: true,
        }
    };

    function closeModal() {
        setLeaveModalStatus(false)
        setArchiveModalStatus(false)
    }

    const dispatch = useDispatch();

    return (
        <>
            <div className="container flex-row w-12/12 my-1">
                <div className="w-auto mx-0">
                    <div className="text-right">
                        <Menu as="div" className="relative inline-block text-right">
                            <div>
                                <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white border-[0.5px] border-[#ddd] px-4 py-1.5 font-medium text-[#171923] ">
                                    Options
                                    <ChevronDownIcon
                                        className="ml-2 -mr-1 h-5 w-5 text-[#171923]"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute z-50 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="px-1 py-1 text-[#171923]">
                                        {data.role === UserRole.ORGANIZATION_OWNER ? (
                                            <>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            className={`${active ? 'bg-[#FBE77F] ' : 'null'
                                                                } group flex w-full items-center rounded-md px-2 py-2`}
                                                            onClick={() => [setModalData(editModalObject)]}
                                                        >
                                                            Edit info
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            className={`${active ? 'bg-[#FBE77F]' : 'null'
                                                                } group flex w-full items-center rounded-md px-2 py-2`}
                                                            onClick={() => { window.location.href = `/members` }}
                                                        >
                                                            Edit members
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </>
                                        ) : null}

                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    className={`${active ? 'bg-[#FBE77F]' : 'null'
                                                        } group flex w-full items-center rounded-md px-2 py-2`}
                                                    onClick={() => { setLeaveModalStatus(true) }}
                                                >
                                                    Leave
                                                </button>
                                            )}
                                        </Menu.Item>
                                        {data.role === UserRole.ORGANIZATION_OWNER ? (
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        className={`${active ? 'bg-[#FBE77F]' : 'null'
                                                            } group flex w-full items-center rounded-md px-2 py-2`}
                                                        onClick={() => {
                                                            setArchiveModalStatus(true)
                                                            // setReloadOrganizationData(false)
                                                        }}
                                                    >
                                                        Archive
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        ) : null}
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </div>

            {leaveModalStatus ? (

                <Transition appear show={leaveModalStatus} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/60 modal-loading"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            <span
                                className="inline-block h-screen "
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="inline-block w-full max-w-md p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded">
                                    <Dialog.Title
                                        as="h3"
                                        className="ht-modal-header"
                                    >
                                        Leave the organization
                                    </Dialog.Title>
                                    <div className={"flex flex-col mt-1 mx-auto mb-2 w-full"}>
                                        <p className=" text-base font-small mt-1 text-left">Are you sure you'd like to leave this organization?</p>


                                        <div className="flex items-center justify-end pt-6">
                                            <button
                                                className="ht-btn-black"
                                                type="button"
                                                onClick={() => {
                                                    closeModal()
                                                }}
                                            >
                                                No
                                            </button>
                                            {leaveBtnStatus == true ? (
                                                <button
                                                    className="ht-btn-yellow"
                                                    type="submit"
                                                    onClick={async () => {
                                                        setLeaveBtnStatus(false)
                                                        loadingShow('.modal-loading');
                                                        await leaveOrganization(data.organization.id, localStorage.getItem('userId')).then((response) => {
                                                            if (response.code === 200) {
                                                                notifySuccess(`You are no longer attached to ${data.organization.name}`)
                                                            } else if (response.code === 400) {
                                                                notifyError(response.message);
                                                            }
                                                            loadingHide('.modal-loading');
                                                            closeModal()
                                                            // setReloadOrganizationData(true);
                                                            dispatch(fetchOrganizations())
                                                        });
                                                        setTimeout(() => {
                                                            setLeaveBtnStatus(true)
                                                        }, 7000);

                                                    }}
                                                >
                                                    Yes
                                                </button>
                                            ) : (
                                                <button
                                                    className="ht-btn-red"
                                                    type=""
                                                    disabled
                                                >
                                                    Yes
                                                </button>
                                            )}
                                        </div>

                                    </div>

                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            ) : null}

            {archiveModalStatus ? (

                <Transition appear show={archiveModalStatus} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/60 modal-loading"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            <span
                                className="inline-block h-screen "
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="inline-block  w-full max-w-md p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded">
                                    <Dialog.Title
                                        as="h3"
                                        className="ht-modal-header"
                                    >
                                        Archive confirmation
                                    </Dialog.Title>
                                    <div className={"flex flex-col mt-1 mx-auto mb-2 w-full"}>
                                        <p className=" text-base font-small mt-1 text-left">Are you sure you'd like to archive your organization?</p>
                                        <div className='my-2 notification-box '>Your subscription will be canceled, and your team will no longer be able to track time.</div>

                                        <div className='my-2'>
                                            <div className="lg:mt-4">
                                                <label className="text-gray-600 font-semibold mb-2 uppercase">
                                                    cancellation reason*
                                                </label>
                                                <div className="relative">
                                                    <Select
                                                        name="archiveReason"
                                                        options={ArchiveReasons}
                                                        value={selectedReason}
                                                        onChange={(item) => {
                                                            setSelectedReason(item)
                                                            setArchiveReasonStatus(true)
                                                        }}
                                                        className="mt-1 bg-white shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1"
                                                    />
                                                </div>
                                            </div>
                                            <div className="lg:mt-4">
                                                <label className="text-gray-600 font-semibold mb-2 uppercase">
                                                    details
                                                </label>
                                                <div className="relative">
                                                    <textarea
                                                        className='mt-1 border-[0.5px] shadow-sm border-slate-300 px-2 py-3 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1'
                                                        placeholder='Please tell us why you are leaving. We appreciate your feedback.'
                                                        onChange={(event) => {
                                                            setArchiveDetails(event.target.value)
                                                        }}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-end pt-6">
                                            <button
                                                className="ht-btn-black"
                                                type="button"
                                                onClick={() => {
                                                    closeModal()
                                                }}
                                            >
                                                No
                                            </button>
                                            {archiveReasonStatus ? (
                                                <button
                                                    className="ht-btn-red"
                                                    type="submit"
                                                    onClick={() => {
                                                        loadingShow('.modal-loading');
                                                        let formData = {
                                                            reason: selectedReason.value,
                                                            details: archiveDetails,
                                                        }

                                                        archiveOrganization(data.organization.id, formData).then((response) => {
                                                            console.log(response);

                                                            if (response.code === 200) {
                                                                notifySuccess(`Organization ${response.data.name} has been successfully archived!`)
                                                            } else if (response.code === 400) {
                                                                notifyError(response.message);
                                                            }

                                                            loadingHide('.modal-loading');
                                                            closeModal()
                                                            // setReloadOrganizationData(true)
                                                            dispatch(fetchOrganizations())
                                                        });
                                                    }}
                                                >
                                                    Archive
                                                </button>
                                            ) : (
                                                <button
                                                    className="ht-btn-red-disabled"
                                                    type="submit"
                                                >
                                                    Archive
                                                </button>
                                            )}

                                        </div>

                                    </div>

                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            ) : null}
        </>
    );

}

export default Dropdown;