import { useEffect } from "react";
import { UserRole } from "../../../constant/userRole";
import ColorSelector from "../../../helper/colorSelector";
import { NameProccess } from "../../globle-component/globle";
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from "./dropdown";
import { projectsAppendList } from "../../../store/slices/projects";

const userRoleOnOrganization = JSON.parse(localStorage.getItem("orgUserRole"));

const TableData = ({ projectList, actionDisplay }) => {

    const dispatch = useDispatch();
    const {
        selectedTab
    } = useSelector((state) => state.projects)
    
    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
            return;
        }

        dispatch(projectsAppendList())
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])

    return (
        <>
            {projectList.map((project, index) => {
                project = { ...project, displayName: NameProccess(project.name, 50) }
                let color = ColorSelector(project.id);
                let projectRole = project.projectUsers[0]?.role;
                return (
                    <tr className="ht-table-tr group" key={index}>
                        <td className="flex flex-row font-medium">
                            <button
                                className={"rounded-full w-10 h-10 my-3"}
                                style={{ backgroundColor: color }}
                            >
                                <p className="text-white font-bold">
                                    {project.displayName[0].toUpperCase()}
                                </p>
                            </button>
                            <div>
                                <h5 className="px-4 text-gray-800 font-medium static py-2 my-3">
                                    {project.displayName}
                                </h5>
                            </div>
                        </td>
                        <td className="text-gray-900 font-light whitespace-nowrap ">

                            {userRoleOnOrganization <= UserRole.USER ? (
                                <>
                                    <div className="flex flex-row">
                                        {project.projectUsers.length == 1 ? (
                                            <div className="relative z-[20]">
                                                <div
                                                    className="z-[18] w-9 h-9  rounded-full bg-gray-400 border-[3px] border-white"
                                                    style={{ borderColor: color }}
                                                >
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="mx-auto my-1.5"
                                                    >
                                                        <path
                                                            d="M8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 12C10.7 12 13.8 13.29 14 14H2C2.23 13.28 5.31 12 8 12ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </div>
                                                <div
                                                    className="z-[16] w-4 h-4 border-2  absolute rounded mx-5 -mt-2.5 "
                                                    style={{ borderColor: color, backgroundColor: color }}
                                                >
                                                    <span className="-mt-0.5 mx-[1px] absolute text-white  font-black">
                                                        {project.projectUsers[0].user.full_name[0].toUpperCase()}
                                                    </span>
                                                </div>
                                            </div>
                                        ) : null}
                                        {project.projectUsers.length > 1 ? (
                                            <>
                                                <div className="relative z-[20]">
                                                    <div
                                                        className="z-[18] w-9 h-9  rounded-full bg-gray-400 border-[3px] border-white"
                                                        style={{ borderColor: color }}
                                                    >
                                                        <svg
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="mx-auto my-1.5"
                                                        >
                                                            <path
                                                                d="M8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 12C10.7 12 13.8 13.29 14 14H2C2.23 13.28 5.31 12 8 12ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div
                                                        className="z-[16] w-4 h-4 border-2  absolute rounded mx-5 -mt-2.5 "
                                                        style={{
                                                            borderColor: color,
                                                            backgroundColor: color,
                                                        }}
                                                    >
                                                        <span className="-mt-0.5 mx-[1px] absolute text-white  font-black">
                                                            {project.projectUsers[0].user.full_name[0].toUpperCase()}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="z-0 w-9 h-9  rounded-full bg-gray-400 border-[3px] -ml-2 border-white px-auto  py-2 px-2 font-extrabold">
                                                    {project.projectUsers.length - 1} +
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                </>
                            ) : null}
                        </td>
                        <td className="text-gray-900 font-light text-center whitespace-nowrap mx-4">
                            {project.projectUsers.length}
                        </td>
                        <td className="text-gray-900 text-center font-light whitespace-nowrap mx-4">
                            -
                        </td>
                        <td className="text-right w-10">
                            {(actionDisplay === true && (userRoleOnOrganization === UserRole.ORGANIZATION_OWNER || userRoleOnOrganization === UserRole.ORGANIZATION_MANAGER || projectRole === UserRole.PROJECT_MANAGER)) ? (
                                <Dropdown
                                    projectId={project.id}
                                />
                            ) : null}
                        </td>
                    </tr>
                );

            })}

        </>
    );
}

export default TableData;