import moment from 'moment-timezone';
import { TableLoadingTriangle } from './reportData/table_loading';
import { NoRecordsAlert } from './reportData/no_records';
import GroupByMembers from './reportData/grp_by_members';
import GroupByProjects from './reportData/grp_by_projects';
import GrpByDate from './reportData/grp_by_date';
import { GroupByTasks } from './reportData/grp_by_tasks';
import { useEffect } from 'react';



const TableBody = ({ groupByValue, tabValue, fetchData, thCount, setTotalDurationToShow, loadingState }) => {
    const fetchReportData = fetchData.data?.reportData
    let value = groupByValue;
    let content;
    let totalDurationOfPeriod = 0;
    const systemTimeZone = localStorage.getItem("systemTimeZone");

    // Function to format duration in seconds to hh:mm:ss format
    function formatDuration(durationInSeconds) {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    function formatDate(dateString) {
        const formattedDate = moment(dateString, 'D-M-YYYY').format('dddd, MMMM D, YYYY');
        return formattedDate;
    }

    useEffect(() => {
        setTotalDurationToShow(totalDurationOfPeriod);
    }, [totalDurationOfPeriod])

    let color = "#ae322e";

    if (loadingState) {
        totalDurationOfPeriod = 0;
        content = (
            <TableLoadingTriangle
                thCount={thCount}
            />
        );
    } else {
        if (fetchData.data?.reportData.length == 0) {
            totalDurationOfPeriod = 0;
            content = (
                <NoRecordsAlert
                    thCount={thCount}
                />
            );
        }
        else {
            if (fetchData.data?.groupBy == "date") {
                totalDurationOfPeriod = 0;
                content =
                    <GrpByDate
                        fetchReportData={fetchReportData}
                        setTotalDurationToShow={setTotalDurationToShow}
                        tabValue={tabValue}
                        totalDurationOfPeriod={totalDurationOfPeriod}
                        content={content}
                        thCount={thCount}
                        formatDuration={formatDuration}
                        formatDate={formatDate}
                    />
            } else if (fetchData.data?.groupBy == "member") {
                totalDurationOfPeriod = 0;
                content =
                    <GroupByMembers
                        fetchReportData={fetchReportData}
                        setTotalDurationToShow={setTotalDurationToShow}
                        tabValue={tabValue}
                        totalDurationOfPeriod={totalDurationOfPeriod}
                        content={content}
                        thCount={thCount}
                        formatDuration={formatDuration}
                        formatDate={formatDate}
                    />
            } else if (fetchData.data?.groupBy == "project") {
                totalDurationOfPeriod = 0;
                content =
                    <GroupByProjects
                        fetchReportData={fetchReportData}
                        setTotalDurationToShow={setTotalDurationToShow}
                        tabValue={tabValue}
                        totalDurationOfPeriod={totalDurationOfPeriod}
                        content={content}
                        thCount={thCount}
                        formatDuration={formatDuration}
                        formatDate={formatDate}
                    />
            } else if (fetchData.data?.groupBy == "task") {
                totalDurationOfPeriod = 0;
                content =
                    <GroupByTasks
                        fetchReportData={fetchReportData}
                        setTotalDurationToShow={setTotalDurationToShow}
                        tabValue={tabValue}
                        totalDurationOfPeriod={totalDurationOfPeriod}
                        content={content}
                        thCount={thCount}
                        formatDuration={formatDuration}
                        formatDate={formatDate}
                    />
            }
        }
    }


    return (
        <>
            {content}
        </>
    )
}

export default TableBody