import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { TaskStatus } from "../../../constant/status";
import { markAsCompleted } from "../../../services/tasks";
import { useDispatch } from "react-redux";
import { getTaskData } from "../../../store/slices/tasks";

const organizationId = JSON.parse(localStorage.getItem('organizationID'));

function Dropdown({
  data,
  modalData,
  setModalData,
  editAllow,
  markAsCompletedAllow,
  deleteAllow,
}) {

  const dispatch = useDispatch();

  const markAsCompleteModalObject = {
    formData: {
      taskId: data.id,
      taskName: data.title,
      taskUsers: data.users,
      taskStatus: TaskStatus.ACTIVE,
    },
    modals: {
      type: "markAsComplete",
      show: true,
    },
  };
  const editModalObject = {
    formData: {
      taskId: data.id,
      taskName: data.title,
      taskUsers: data.users,
      taskStatus: TaskStatus.ACTIVE,
    },
    modals: {
      type: "edit",
      show: true,
    },
  };
  const deleteModalObject = {
    formData: {
      taskId: data.id,
      taskName: data.title,
      taskUsers: data.users,
      taskStatus: TaskStatus.ACTIVE,
    },
    modals: {
      type: "delete",
      show: true,
    },
  };

  let allowDropdown = true;

  if (editAllow === false && markAsCompletedAllow === false && deleteAllow === false) {
    allowDropdown = false;
  }

  return (
    <>
      {allowDropdown ? (
        <div className="flex justify-end pt-1 mx-[32px] ">
          <div className="">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white border-[0.5px] border-[#e0e0e0] px-4 py-1.5 font-medium text-[#171923] hover:bg-gray-100  ">
                  Action
                  <ChevronDownIcon
                    className="ml-4 -mr-1 h-5 w-5 text-[#171923]"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute z-50 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {editAllow ? (
                    <div className="px-1 py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${active
                              ? "bg-[#FBE77F] text-[#171923]"
                              : "text-[#171923]"
                              } group flex w-full items-center rounded-md px-2 py-2`}
                            onClick={() => {
                              setModalData(editModalObject);
                              // setAssignees(data.users);
                            }}
                          >
                            Edit
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  ) : null}

                  {markAsCompletedAllow ? (
                    <div className="px-1 py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${active
                              ? "bg-[#FBE77F] text-[#171923]"
                              : "text-[#171923]"
                              } group flex w-full items-center rounded-md px-2 py-2`}
                            onClick={() => {
                              setModalData(markAsCompleteModalObject);
                              // markAsCompleted(data.id).then((response) => {
                              //   if (response.code === 200) {
                              //     // window.location.reload();
                              //     dispatch(getTaskData(organizationId))

                              //   }
                              // });
                            }}
                          >
                            Mark as completed
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  ) : null}

                  {deleteAllow ? (
                    <div className="px-1 py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${active
                              ? "bg-[#FBE77F] text-[#171923]"
                              : "text-[#171923]"
                              } group flex w-full items-center rounded-md px-2 py-2`}
                            onClick={() => {
                              setModalData(deleteModalObject)
                            }}
                          >
                            Delete
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  ) : null}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Dropdown;
