import axios from "../api/axios";
import { logout } from "../hooks/logout";

let headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`
}

// Get account details
export const getAccountData = async (organizationId) => {
    const config = { headers: { ...headers, organization_id: organizationId } }
    try {
        const response = await axios.get('/users/accounts/data', config);
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
}

// New email update
export const newEmailUpdate = async (bodyData) => {
    const config = { headers: { ...headers } }
    try {
        const response = await axios.post('/email-update', bodyData, config);
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        } else {
            return { data: error };
        }
    }
}

// New email update verify
export const newEmailUpdateVerify = async (bodyData) => {
    const config = { headers: { ...headers } }
    try {
        const response = await axios.post('/email-update/verify', bodyData, config);
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        } else {
            return { data: error };
        }
    }
}

// Forgot password verify
export const forgotPasswordVerify = async (urlToken) => {
    const config  = { headers: {...headers}}
    try {
        const response = await axios.post('/forgot-password/verify', urlToken, config);
        return response.data;
    } catch (error) {
        return {data:error};
    }
}

// Password Change
export const passwordChange = async (bodyData) => {
    const config = { headers: { ...headers } }
    try {
        const response = await axios.patch('/users/accounts/change-password', bodyData, config);
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        } else {
            return { data: error };
        }
    }
}

// Delete profile image
export const deleteProfileImage = async (bodyData) => {
    const config = { headers: { ...headers } }
    try {
        const response = await axios.patch('/users/accounts/remove-profile-image', bodyData, config);
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        } else {
            return { data: error };
        }
    }
}