import { configureStore } from '@reduxjs/toolkit'
import membersReducer from '../store/slices/members'
import activitiesReducer from '../store/slices/activites'
import filtersReducer from '../store/slices/filters'
import projectsReducer from '../store/slices/projects'
import organizationsReducer from '../store/slices/organizations'
import tasksReducer from '../store/slices/tasks'
import worktimesReducer from '../store/slices/worktimes'

export const store = configureStore({
  reducer: {
    members: membersReducer,
    activities: activitiesReducer,
    filters: filtersReducer,
    projects: projectsReducer,
    organizations: organizationsReducer,
    tasks: tasksReducer,
    worktimes: worktimesReducer,
  },
})