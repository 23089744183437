import axios from "../../../api/axios";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { UserRole } from "../../../constant/userRole";

const logo = 'http://sanmark.lk/logo';

const formSchema = Yup.object().shape({
    userRole: Yup.number().required('User role is required'),
    inviteEmail: Yup.string().email('Please enter a valid email')
});

const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`
}

const organizationId = localStorage.getItem('organizationID');

function Modal({ setModalData, modalData }) {

    const notifyError = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.TOP_LEFT,
        });
    };

    let [parmsID, setParmsID] = useState();
    const [searchparams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (searchparams.get('_id') === null) {
            parmsID = organizationId;
        } else {
            parmsID = searchparams.get('_id');
        }
    })

    const [selectedMembers, setSelectedMembers] = useState([]);

    function requestBody(data) {
        const body = [];
        let i = 0;
        selectedMembers.map((member) => {
            body[i] = {
                "id": member.id,
                "role": parseInt(data.role)
            };
            i++;
        })
        return body;
    }

    function AddUsersIntoOrganization(data) {
        const body = requestBody(data);
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`,
                organization_id: parmsID
            }
        }
        axios.post('organizations/' + parmsID + '/', body, config)
            .then((response) => {
                setModalData({ ...modalData, modals: { show: false } })
                window.location.reload();
            })
            .catch((error) => {

            });
    }

    const [saveBtnState, setSaveBtnState] = useState(true)

    function EditUserRole(body) {

        var responce = {
            "id": modalData.formData.userID,
            "role": parseInt(body.role)
        }
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`,
                organization_id: parmsID
            }
        }
        axios.patch('/organizations/' + parmsID + '/changerole', responce, config)
            .then((response) => {
                setModalData({ ...modalData, modals: { show: false } })
                window.location.reload();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 400) {
                        notifyError('Cannot change the user-role on this user!');
                        setSaveBtnState(false);
                        setTimeout(() => {
                            setSaveBtnState(true);
                        }, 7000);
                    }
                }
            });
    }

    const CreateOrgRequest = async (data) => {
        try {
            window.location.replace(process.env.REACT_APP_FRONTEND_HOST + 'members');
        } catch (error) {
            notifyError('Something went wrong. Please try again later');
        }
    }

    function userRoleTextRender(data) {
        let ROLE = Object.keys(UserRole).find(key => UserRole[key] === data);
        let result = ROLE.replace("ORGANIZATION_", "").toLowerCase();
        return result;
    }

    return modalData.modals.type === 'delete' ? (
        <>
            <div className="ml-5 mt-4">
                <p>Are you sure you want to remove this?</p>
            </div>

            <div className="mt-4 flex justify-end mr-5">

                <button
                    type="button"
                    className="  px-4 py-2 mx-3 text-white bg-slate-800 border border-transparent rounded-md hover:bg-slate-900 duration-300 "
                    onClick={() => setModalData({ ...modalData, modals: { show: false } })}
                >
                    Close
                </button>
                <button
                    type="button"
                    className="  px-4 py-2 text-white bg-red-400 border border-transparent rounded-md hover:bg-red-600 duration-300 "

                >
                    Delete
                </button>

            </div>
        </>

    ) : (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className={"flex flex-col mt- mx-auto mb-1 w-full"}>


                <Formik
                    initialValues={{
                        userRole: ''
                    }}
                    validationSchema={formSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(data) => {
                        const formData = {
                            role: data.userRole,
                            status: 1,
                            start_date: new Date().toISOString().split('T')[0]
                        };

                        if (modalData.modals.type === 'update') {
                            EditUserRole(formData)
                        } else if (selectedMembers.length != 0) {
                            AddUsersIntoOrganization(formData)
                        }

                    }}
                >

                    {({ errors, touched }) => (
                        <Form className=" container mx-auto mt-6 align-center" >

                            <div className=" flex flex-col">
                                {modalData.modals.type === 'update' ? (<div className="py-1 w-full">
                                    <div>
                                        <label className="pl-1 font-bold text-[#171923]">
                                            Current membership
                                        </label>
                                        <Field
                                            className="mt-1 capitalize px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#FFC400] focus:ring-[#FFC400] block w-full rounded-md focus:ring-1"
                                            value={userRoleTextRender(modalData.formData.currentUserRole)}
                                            disabled
                                        />
                                    </div>
                                </div>) : (null)}
                                <div className="py-1 w-full">
                                    <div>
                                        <label className="pl-1 font-bold text-[#171923]">
                                            {modalData.modals.type === 'update' ? (<>New User Role</>) : (<>User Role</>)}
                                        </label>
                                        <Field
                                            className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#FFC400] focus:ring-[#FFC400] block w-full rounded-md focus:ring-1"
                                            name='userRole'
                                            as='select' >
                                            <option value="">Select a role</option>
                                            <option value="2">Manager</option>
                                            <option value="3">User</option>
                                        </Field>
                                        {errors.userRole && touched.userRole ? (
                                            <p className="text-red-500 italic mt-1">{errors.userRole}</p>
                                        ) : null}
                                    </div>
                                </div>

                            </div>
                            <div className="flex items-center justify-end pt-6">
                                <button
                                    className="ht-btn-black"
                                    type="button"
                                    onClick={() => setModalData({ ...modalData, modals: { show: false } })}
                                >
                                    Cancel
                                </button>
                                {saveBtnState == false ? (<>
                                    <button
                                        className="ht-btn-yellow-disabled"
                                        type="submit"
                                        disabled
                                    >
                                        Save
                                    </button>
                                </>) : <>
                                    <button
                                        className="ht-btn-yellow"
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </>}

                            </div>

                        </Form>
                    )}
                </Formik>

            </div>
        </>
    );
}

export default Modal;