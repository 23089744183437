import React, { useRef } from 'react'
import Navbar from '../../../dashboard/component/navbar';
import SideBar from '../../../dashboard/component/sidebar';
import { loadingHide, loadingShow } from '../../../../helper/loading';
import Select from "react-select";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { DateRangeCalendar } from '../../../worktime/components/calendarComponent';
import { addDays } from "date-fns";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import { setNewDateRangeForCalendar, setNewDateRangeForSingleDayCalendar } from '../../../globle-component/globle';
import { getOrganization } from '../../../../services/organizations';
import Filters from '../filter/filters';
import { getMembersForProjects } from '../../../../services/members';
import { getProjectByUser } from '../../../../services/projects';
import TableHead from './components/table_head';
import TableBody from './components/table_body';
import { getReports } from '../../../../services/reports';
import ExportReport from '../export_report/export_report';
import { useLayoutEffect } from 'react';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TimeAndActivity = () => {
  const [loading, setLoading] = useState(true);
  const [organizationName, setOrganizationName] = useState("");
  const [organizationTimeZone, setOrganizationTimeZone] = useState("");
  const currentOrganizationId = localStorage.getItem("organizationID");
  const currentOrgUserRole = localStorage.getItem("orgUserRole");
  const [totalDurationToShow, setTotalDurationToShow] = useState(0);
  const [pageState, setPageState] = useState(0);
  const [loadingState, setLoadingState] = useState(false);

  const [thCount, setThCount] = useState(0);
  const tableRef = useRef(null);
  const countHeaderCells = () => {
    if (tableRef.current) {
      const thead = tableRef.current.querySelector('thead');
      if (thead) {
        const headerRow = thead.querySelector('tr');
        if (headerRow) {
          setThCount(headerRow.querySelectorAll('th').length);
        }
      }
    }
  };

  // Function to format duration in seconds to hh:mm:ss format
  function formatDuration(durationInSeconds) {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  // Filters 
  const [selectedUser, setSelectedUser] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [memberValues, setMemberValues] = useState([])

  const [projectList, setProjectList] = useState([]);
  const [selectedProjectList, setSelectedProjectList] = useState([]);
  const [projectValues, setProjectValues] = useState([])

  const [tasksList, setTasksList] = useState([]);
  const [selectedTasksList, setSelectedTasksList] = useState([]);
  const [taskValues, setTaskValues] = useState([])

  const [filteredProject, setFilteredProject] = useState(null);
  const [filterData, setFilterData] = useState([]);

  const [fetchData, setFetchData] = useState([]);

  const [groupByValue, setGroupByValue] = useState("date");
  const [groupByOptions, setGroupByOptions] = useState([]);



  const setFilterValues = (fetchData) => {
    if (fetchData.code == 200) {
      setProjectList(fetchData.data.filters.projectsFilter);
      setMembersList(fetchData.data.filters.membersFilter);
      setTasksList(fetchData.data.filters.tasksFilter);
    }
  }

  useEffect(() => {
    setTaskValues(selectedTasksList.map(item => item.value));
    (pageState == 0) ? (setPageState(1)) : (setPageState(0));
  }, [selectedTasksList])

  useEffect(() => {
    setProjectValues(selectedProjectList.map(item => item.value));
    (pageState == 0) ? (setPageState(1)) : (setPageState(0));
  }, [selectedProjectList])

  useEffect(() => {
    setMemberValues(selectedUser.map(item => item.value));
    (pageState == 0) ? (setPageState(1)) : (setPageState(0));
  }, [selectedUser])

  useEffect(() => {
    (pageState == 0) ? (setPageState(1)) : (setPageState(0));
  }, [groupByValue])

  const checkOrg = () => {
    try {
      let orgCreate = false;
      if (
        localStorage.hasOwnProperty("organizationID") &&
        localStorage.hasOwnProperty("organizationName")
      ) {
        if (
          localStorage.getItem("organizationID") == "null" ||
          localStorage.getItem("organizationID") == "" ||
          localStorage.getItem("organizationName") == "null" ||
          localStorage.getItem("organizationName") == ""
        ) {
          orgCreate = true;
        }
      } else {
        orgCreate = true;
      }

      if (orgCreate) {
        window.location.replace("/createorg");
      } else {
        getOrganization(currentOrganizationId).then(({ data }) => {
          setOrganizationTimeZone(data.organization.timezone);
        });

        setOrganizationName(localStorage.getItem("organizationName"));
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkOrg();
    document.title = "HoneybeeTime - Time & Activity";
  }, []);

  const [value, setValue] = useState(1);


  useEffect(() => {
    if (currentOrgUserRole == 1 || currentOrgUserRole == 2) {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [currentOrgUserRole])



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    (pageState == 0) ? (setPageState(1)) : (setPageState(0));
    setGroupByValue('date');
    if (value === 1) {
      setGroupByOptions([
        { value: "date", label: "Date", icon: "../../../../img/icons/report/date.svg" },
        { value: "member", label: "Member", icon: "../../../../img/icons/report/member.svg" },
        { value: "project", label: "Project", icon: "../../../../img/icons/report/project.svg" },
        { value: "task", label: "Task", icon: "../../../../img/icons/report/todo.svg" },
      ]);
    } else {
      setGroupByOptions([
        { value: "date", label: "Date", icon: "../../../../img/icons/report/date.svg" },
        { value: "project", label: "Project", icon: "../../../../img/icons/report/project.svg" },
        { value: "task", label: "Task", icon: "../../../../img/icons/report/todo.svg" },
      ]);
    }
  }, [value])

  // Date Selector
  const [dateRangeObject, setDateRangeObject] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [startDate, setStartDate] = useState(moment(dateRangeObject[0].startDate).startOf('day').utc().format("YYYY-MM-DD HH:mm:ss"));
  const [endDate, setEndDate] = useState(moment(dateRangeObject[0].endDate).endOf('day').utc().format("YYYY-MM-DD HH:mm:ss"));
  const [differenceInDays, setDifferenceInDays] = useState(0);

  const calculateDateDifference = (endDate, startDate) => {
    const dateDiff = moment(endDate).diff(moment(startDate));
    setDifferenceInDays(dateDiff / (1000 * 60 * 60 * 24));
    return { differenceInDays };
  };
  const [newStartDate, setNewStartDate] = useState(null);

  const goBackDays = (dateRangeObject, differenceInDays) => {
    setNewStartDate(startDate);
  }

  const [applyDateRange, setApplyDateRange] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));


  useEffect(() => {
    setStartDate(moment(dateRangeObject[0].startDate).startOf('day').utc().format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(dateRangeObject[0].endDate).endOf('day').utc().format("YYYY-MM-DD HH:mm:ss"));
    calculateDateDifference(endDate, startDate);
  }, [dateRangeObject])

  let filteredOption;

  const groupByValueChange = (selectedValue) => {
    setGroupByValue(selectedValue.value);
  }

  function getReportData() {
    setLoadingState(true);
    getReports(
      currentOrganizationId,
      startDate,
      endDate,
      groupByValue,
      value,
      memberValues,
      projectValues,
      taskValues
    )
      .then((response) => {
        if (response.data.code === 200) {
          setFetchData(response.data)
          setLoadingState(false);
        }
      })
      .finally(() => setLoadingState(false));
  }

  useEffect(() => {
    if (applyDateRange) {
      setTimeout(() => {
        getReportData()
      }, 500);
      setApplyDateRange(false);
    }
  }, [applyDateRange]);

  useEffect(() => {
    getReportData()
  }, [pageState])

  useEffect(() => {
    countHeaderCells();
  })

  useEffect(() => {
    setFilterValues(fetchData)
  }, [fetchData])

  return (
    <>
      <div className="flex flex-row h-full w-full custom-body">
        <>
          <SideBar />
          <div className="bg-gray-200 w-full h-full min-h-screen flex-1">
            <Navbar />
            <div className="flex flex-row w-full p-5">
              <div className="flex flex-col w-full bg-white h-full min-h-[84vh] p-5 rounded-[20px]">
                <div className='go-back-section'>
                  <Link to={"/reports"}>
                    <div className="flex flex-row font-medium text-left my-auto">
                      <div className=" text-indigo-700 font-bold mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                          />
                        </svg>
                      </div>
                      <div className="text-md my-auto">
                        <h2>Reports</h2>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="md:text-2xl text-lg mt-5">
                  <h1>Time & activity report</h1>
                </div>
                <div className="tabbed-container mt-4">
                  <Box sx={{ width: "100%" }}>
                    <div className='relative'>
                      <Box >
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          className="!text-sm"
                        >
                          <Tab
                            label="Me"
                            className="sm:text-sm text-xs border-b-2"
                            {...a11yProps(0)}
                          />
                          {(currentOrgUserRole == 1 || currentOrgUserRole == 2) ? (
                            <Tab
                              label="All"
                              className="sm:text-sm text-xs border-b-2"
                              {...a11yProps(1)}
                            />
                          ) : (null)}

                        </Tabs>
                      </Box>
                      <div className='absolute flex flex-row top-0 right-0 my-auto'>
                        <div>
                          <DateRangeCalendar
                            dateRangeObject={dateRangeObject}
                            setDateRangeObject={setDateRangeObject}
                            applyDateRange={applyDateRange}
                            setApplyDateRange={setApplyDateRange}
                          />
                        </div>
                        <div className='ml-2'>
                          <Filters
                            value={value}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                            membersList={membersList}
                            projectList={projectList}
                            selectedProjectList={selectedProjectList}
                            setSelectedProjectList={setSelectedProjectList}
                            filteredProject={filteredProject}
                            setFilteredProject={setFilteredProject}
                            filterData={filterData}
                            tasksList={tasksList}
                            selectedTasksList={selectedTasksList}
                            setSelectedTasksList={setSelectedTasksList}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <TabPanel value={value} index={0}>
                        <div className="my-reports-container p-5">
                          <div className='flex bg-white p-3 rounded-2xl shadow-md border'>
                            {/* Use w-[45%] class if hope to use chart option  */}
                            <div className='w-[50%] border-r-2 py-1 px-5'>
                              <p className='text-[10px] pb-1'>TIME</p>
                              <h2 className='text-3xl text-[#f6921e]'>{formatDuration(totalDurationToShow)}</h2>
                            </div>

                            {/* Use w-[45%] and 'border-r-2' class if hope to use chart option  */}
                            <div className='w-[50%] py-1 px-5'>
                              <p className='text-[10px]'>AVG. ACTIVITY</p>
                              <h2 className='text-3xl text-[#f6921e]'>--<span>%</span></h2>
                            </div>
                            {/* <div className='w-[10%] py-3 px-5 relative text-[#F6932D] hover:text-[#df7f1f]'>
                                <span className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                                  </svg>
                                </span>
                              </div> */}
                          </div>

                          <div className='flex justify-between mt-5'>
                            <div className='order-first'>
                              <h2 className='text-2xl'>{organizationName} <span className='text-base text-gray-400'>{organizationTimeZone}</span></h2>
                            </div>
                            <div className='order-last'>
                              <ExportReport
                                dateRangeObject={dateRangeObject}
                                totalDurationToShow={totalDurationToShow}
                              />
                            </div>
                          </div>

                          <div className='flex justify-between mt-5'>
                            <div className='order-first'>
                              <div className='flex'>
                                <div className='flex mr-2 my-auto'>
                                  <div className='text-gray-500 mr-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                    </svg>
                                  </div>
                                  <div>
                                    <h3 className='text-lg text-gray-500'>Group by:</h3>
                                  </div>
                                </div>
                                <div className='text-base'>
                                  <Select
                                    defaultValue={groupByOptions[0]}
                                    value={filteredOption}
                                    options={groupByOptions}
                                    className='text-base w-[200px]'
                                    onChange={groupByValueChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='order-last'>
                              <div>
                                <button className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 border" type="button">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className='report-section'>
                            <table ref={tableRef} className='w-full table-fixed text-sm text-left text-gray-500 mt-3' id="report_table">
                              <thead>
                                <TableHead
                                  groupByValue={groupByValue}
                                  tabValue={value}
                                  loadingState={loadingState}
                                />
                              </thead>
                              <tbody>
                                <TableBody
                                  groupByValue={groupByValue}
                                  tabValue={value}
                                  fetchData={fetchData}
                                  thCount={thCount}
                                  setTotalDurationToShow={setTotalDurationToShow}
                                  loadingState={loadingState}
                                />
                              </tbody>
                            </table>
                          </div>

                        </div>
                      </TabPanel>
                      {(currentOrgUserRole == 1 || currentOrgUserRole == 2) ? (
                        <TabPanel value={value} index={1}>
                          <div className="all-reports-container p-5 overflow-x-hidden">
                            <div className='flex bg-white p-3 rounded-2xl shadow-md border'>
                              {/* Use w-[45%] class if hope to use chart option  */}
                              <div className='w-[50%] border-r-2 py-1 px-5'>
                                <p className='text-[10px] pb-1'>TIME</p>
                                <h2 className='text-3xl text-[#f6921e]'>{formatDuration(totalDurationToShow)}</h2>
                              </div>

                              {/* Use w-[45%] class if hope to use chart option  */}
                              {/* Use 'border-r-2' class if hope to use chart option  */}
                              <div className='w-[50%] py-1 px-5'>
                                <p className='text-[10px]'>AVG. ACTIVITY</p>
                                <h2 className='text-3xl text-[#f6921e]'>--<span>%</span></h2>
                              </div>

                              {/* <div className='w-[10%] py-3 px-5 relative text-[#F6932D] hover:text-[#df7f1f]'>
                                  <span className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                                    </svg>
                                  </span>
                                </div> */}
                            </div>

                            <div className='flex justify-between mt-5'>
                              <div className='order-first'>
                                <h2 className='text-2xl'>{organizationName} <span className='text-base text-gray-400'>{organizationTimeZone}</span></h2>
                              </div>
                              <div className='order-last'>
                                <ExportReport
                                  dateRangeObject={dateRangeObject}
                                  totalDurationToShow={totalDurationToShow}
                                />
                              </div>
                            </div>

                            <div className='flex justify-between mt-5'>
                              <div className='order-first'>
                                <div className='flex'>
                                  <div className='flex mr-2 my-auto'>
                                    <div className='text-gray-500 mr-2'>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                      </svg>

                                    </div>
                                    <div>
                                      <h3 className='text-lg text-gray-500'>Group by:</h3>
                                    </div>
                                  </div>
                                  <div className='text-base'>
                                    <Select
                                      defaultValue={groupByOptions[0]}
                                      value={filteredOption}
                                      options={groupByOptions}
                                      className='text-base w-[200px]'
                                      onChange={groupByValueChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='order-last'>
                                <div>
                                  <button className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 border" type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div>
                              <div className='report-section relative'>
                                <table ref={tableRef} className='w-full table-fixed text-sm text-left text-gray-500 mt-3' id="report_table">
                                  <thead>
                                    <TableHead
                                      groupByValue={groupByValue}
                                      tabValue={value}
                                      loadingState={loadingState}
                                    />
                                  </thead>
                                  <tbody>
                                    <TableBody
                                      groupByValue={groupByValue}
                                      tabValue={value}
                                      fetchData={fetchData}
                                      thCount={thCount}
                                      setTotalDurationToShow={setTotalDurationToShow}
                                      loadingState={loadingState}
                                    />
                                  </tbody>
                                </table>
                              </div>
                            </div>

                          </div>
                        </TabPanel>
                      ) : (null)}
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </>
      </div >
    </>
  )
}

export default TimeAndActivity