import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { UserRole } from "../../../constant/userRole";
import { setEditModal } from "../../../store/slices/projects";

const Dropdown = ({ projectId }) => {
    const userRoleOnOrganization = JSON.parse(localStorage.getItem("orgUserRole"));

    const dispatch = useDispatch();
    const {
        activeProjectList,
    } = useSelector((state) => state.projects)

    function getMembersID(idArray) {
        let managerID = idArray.map((item) => (
            item.user.id
        ))
        return managerID;
    }

    function editProject(type, tab) {
        const projectData = activeProjectList.filter(item => item.id === projectId);

        let selectedManagers = projectData[0].projectUsers.filter(obj => obj.role === UserRole.PROJECT_MANAGER)
        let selectUsers = projectData[0].projectUsers.filter(obj => obj.role === UserRole.USER)
        let selectViewers = projectData[0].projectUsers.filter(obj => obj.role === UserRole.VIEWER)


        const editModalObject = {
            formData: {
                id: projectData[0].id,
                name: projectData[0].name,
                description: projectData[0].description,
                managers: getMembersID(selectedManagers),
                users: getMembersID(selectUsers),
                viewers: getMembersID(selectViewers)
            },
            modals: {
                type: type,
                show: true,
                tab: tab
            }
        };

        dispatch(setEditModal(editModalObject));

    }

    return (
        <>
            <div className="my-4">
                <div className="">
                    <Menu as="div" className="relative inline-block text-left">
                        <div>
                            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white border-[0.5px] border-[#e0e0e0] px-4 py-1.5 font-medium text-[#171923] hover:bg-gray-100  ">
                                Action
                                <ChevronDownIcon
                                    className="ml-4 -mr-1 h-5 w-5 text-[#171923]"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute z-50 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="px-1 py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? 'bg-[#FBE77F]' : 'text-gray-700'
                                                    } group flex w-full items-center rounded-md px-2 py-2`}
                                                onClick={() => {
                                                    // setModalData(editModalObject)
                                                    // setSelectedTab(1)

                                                    editProject('edit', 1)
                                                }}
                                            >

                                                Edit
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? 'bg-[#FBE77F]' : 'text-gray-700'
                                                    } group flex w-full items-center rounded-md px-2 py-2`}
                                                onClick={() => {
                                                    // setModalData(editModalObject), setSelectedTab(2)
                                                    editProject('edit', 2)
                                                }}

                                            >

                                                Manage User
                                            </button>
                                        )}
                                    </Menu.Item>
                                    {(
                                        userRoleOnOrganization === UserRole.ORGANIZATION_OWNER ||
                                        userRoleOnOrganization === UserRole.ORGANIZATION_MANAGER
                                    )
                                        ? (
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        className={`${active ? 'bg-[#FBE77F]' : 'text-gray-700'
                                                            } group flex w-full items-center rounded-md px-2 py-2`}
                                                        // onClick={() => [setModalData(archiveModalObject)]}
                                                        onClick={() => {
                                                            editProject('archive', null)
                                                        }}
                                                    >
                                                        Archive
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        )
                                        : null
                                    }

                                    {/* <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    className={`${active ? 'bg-[#FBE77F]' : 'text-gray-700'
                                                        } group flex w-full items-center rounded-md px-2 py-2`}
                                                >

                                                    Delete
                                                </button>
                                            )}
                                        </Menu.Item> */}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </>
    );
}

export default Dropdown;