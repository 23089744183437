import { useLocation, Navigate, Outlet } from 'react-router-dom';

const localTag = JSON.parse(localStorage.getItem('tag'));

const RequireAuth = () => {
    const location = useLocation();

    if (!localTag) {
        return <Navigate to={'/login'} state={{ from: location }} replace />;
    } else {
        return <Outlet />;
    }

}
export default RequireAuth;