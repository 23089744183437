import { useDispatch, useSelector } from "react-redux";
import AuthContext from "../../../context/authProvider";
import useAuth from "../../../hooks/useAuth";
import { UserRole } from "../../../constant/userRole";
import { ScreenshotFrequency } from "../../../constant/screenshotFrequency";
import { loadingHide, loadingShow } from "../../../helper/loading";
import { updateOrganizationSettings } from "../../../services/organizations";
import { membersAppendList, fetchActiveMembers } from "../../../store/slices/members";
import { userStatus } from "../../../constant/userState";
import Select from "react-select";
import Dropdown from "./dropdown";
import { useEffect } from "react";
import { userRoleTextRender } from "../../globle-component/globle";


const authUserRole = JSON.parse(localStorage.getItem('orgUserRole'));
const organizationId = localStorage.getItem('organizationID');

function getScreenshotFrequencyObject(value) {
    if (value === null) {
        value = 0;
    }
    const objectArray = ScreenshotFrequency.filter(item => item.value === value);
    return objectArray[0]
}

const TableData = ({ tab, modalData, setModalData, setCancelInvitationModal = null, setCancelingUserId = null }) => {
    const { authUser, userRole } = useAuth(AuthContext);

    const dispatch = useDispatch();
    const {
        filteredActiveMemberList,
        filteredInvitedMemberList
    } = useSelector((state) => state.members)

    function screenshotCountUpdate(user, item) {
        loadingShow();
        let formData = { "client_screenshot_frequency": item.value }
        updateOrganizationSettings(organizationId, user.id, formData).then((response) => {
            loadingHide();
            dispatch(fetchActiveMembers(organizationId))
        });
    }

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
            return;
        }

        dispatch(membersAppendList())

    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])

    if (tab === 'members' && filteredActiveMemberList.length > 0) {
        return [...filteredActiveMemberList].map((user, index) => {
            let screenshotCaptureCountValue = 0;

            if (user.settings !== null) {
                if (user.settings.client_screenshot_frequency !== undefined || user.settings.client_screenshot_frequency !== null) {
                    screenshotCaptureCountValue = getScreenshotFrequencyObject(user.settings.client_screenshot_frequency);
                } else {
                    screenshotCaptureCountValue = getScreenshotFrequencyObject(0);
                }
            } else {
                screenshotCaptureCountValue = getScreenshotFrequencyObject(0);
            }

            return (
                < tr className={`ht-table-tr ${authUser.id === user.id && 'font-bold'}`} key={index} >
                    <td className="py-3 px-4 relative h-16 flex flex-row">
                        <button className={"rounded-full w-10 h-10 bg-yellow-300 "}>
                            <p className=" font-bold">{user.displayName[0].toUpperCase()}</p>
                        </button>
                        <span className="w-0.5 h-0.5 border border-white border-2 p-1.5 bg-green-400 rounded-full mt-[26px] -ml-3.5"></span>
                        <div>
                            <h5 className='px-4 text-gray-800 py-2'>
                                {user.displayName}
                                {authUser.id === user.id && (<span className="px-2 text-gray-400 font-light"> - current user</span>)}
                            </h5>
                        </div>
                    </td>

                    <td className="text-gray-900 text-center  px-6 py-4 whitespace-nowrap">
                        {user.email}
                    </td>

                    <td className="py-3 px-4 relative w-78 h-16 flex-row text-center ">
                        <div className=" w-20 bg-yellow-300 rounded-full mx-auto">
                            {userStatus[user.status]}
                        </div>
                    </td>
                    <td className="py-3 px-4 relative w-78 h-16 flex-row text-center">
                        <p className="capitalize">{userRoleTextRender(user.role)}</p>
                    </td>
                    {authUserRole === UserRole.ORGANIZATION_OWNER || authUserRole === UserRole.ORGANIZATION_MANAGER ? (
                        <td className="py-3 px-6 relative h-16 flex-row text-center">
                            <Select
                                name="screenCount"
                                options={ScreenshotFrequency}
                                value={screenshotCaptureCountValue}
                                onChange={(item) => {
                                    screenshotCountUpdate(user, item)
                                }}
                                menuPosition="fixed"
                                menuPlacement="auto"
                                className="mt-1 bg-white shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1"
                            />
                        </td>
                    ) : null}
                    {authUserRole === UserRole.ORGANIZATION_OWNER || authUserRole === UserRole.ORGANIZATION_MANAGER ? (
                        <td className="text-gray-900 font-light  px-6 py-1 whitespace-nowrap mr-5">
                            {userRole === UserRole.ORGANIZATION_OWNER ? (
                                <Dropdown
                                    user={user}
                                    modalData={modalData}
                                    setModalData={setModalData}
                                />
                            ) : null}
                        </td>
                    ) : null}
                </tr >
            );
        });
    } else if (tab === 'invited' && filteredInvitedMemberList.length > 0) {
        return [...filteredInvitedMemberList].map((user, index) => {

            return (
                < tr className={`ht-table-tr`} key={index} >
                    <td className="py-3 px-4 relative h-16 flex flex-row">
                        <button className={"rounded-full w-10 h-10 bg-yellow-300 "}>
                            {user.full_name != null ? (
                                <p className=" font-bold">{user.full_name[0].toUpperCase()}</p>
                            ) : (
                                <p className=" font-bold">{user.email[0].toUpperCase()}</p>
                            )}
                        </button>
                        <span className="w-0.5 h-0.5 border border-white border-2 p-1.5 bg-green-400 rounded-full mt-[26px] -ml-3.5"></span>
                        <div>
                            {user.full_name != null ? (
                                <h5 className='px-4 text-gray-800 py-2'>
                                    {user.full_name}
                                </h5>
                            ) : (
                                <h5 className='px-4 text-gray-400 font-light py-2'>
                                    Name Unkown
                                </h5>
                            )}
                        </div>
                    </td>

                    <td className="text-gray-900 text-center  px-6 py-4 whitespace-nowrap">
                        {user.email}
                    </td>

                    <td className="py-3 px-4 relative w-78 h-16 flex-row text-center ">
                        <div className={` w-20 ${userStatus[user.status] === 'Active' ? 'bg-yellow-300' : userStatus[user.status] === 'Pending' ? 'bg-blue-300' : null} rounded-full mx-auto`}>
                            {userStatus[user.status]}
                        </div>
                    </td>
                    <td className="py-3 px-4 relative w-78 h-16 flex-row text-center">
                        <p className="capitalize">{userRoleTextRender(user.role)}</p>

                    </td>
                    {authUserRole === UserRole.ORGANIZATION_OWNER || authUserRole === UserRole.ORGANIZATION_MANAGER ? (
                        <td className="text-gray-900 font-light  px-6 py-1 whitespace-nowrap mr-5">
                            <button className="bg-gray-300 hover:cursor hover:bg-red-600 hover:text-white  font-bold py-2 px-4 rounded" onClick={() => {
                                setCancelInvitationModal(true);
                                setCancelingUserId(user.id);
                                // cancelInvitationAsync(organizationId, user.id)
                            }} style={{ cursor: "auto !important" }}>Cancel the invitation</button>
                        </td>
                    ) : null}
                </tr >
            );
        });
    }




}

export default TableData;