import Navbar from "./component/navbar";
import Sidebar from "./component/sidebar";
import Members from "./tiles/members";
import Projects from "./tiles/projects";
import Task from "./tiles/task";

import { useEffect, useState } from "react";

import { Loader } from "../../helper/loading";
import { getAccountData } from "../../services/account";
import { useDispatch, useSelector } from 'react-redux';
import { UserRole } from "../../constant/userRole";
import { fetchActiveMembers } from "../../store/slices/members";

const authUserRole = JSON.parse(localStorage.getItem("orgUserRole"));
const organizationId = JSON.parse(localStorage.getItem("organizationID"));

function Dashboard() {
    const [loading, setLoading] = useState(true);
    const [timeZoneStatus, setTimeZoneStatus] = useState(false);
    const [timerCount, setTimerCount] = useState(null)
    const dispatch = useDispatch();
    const { isLoading, activeMemberList, filteredActiveMemberList } = useSelector((state) => state.members)

    useEffect(() => {
        (async () => {
            if ((authUserRole === UserRole.ORGANIZATION_OWNER || authUserRole === UserRole.ORGANIZATION_MANAGER || authUserRole === UserRole.PROJECT_MANAGER) && activeMemberList.length === 0) {
                dispatch(fetchActiveMembers(organizationId))
            }
        })();
    }, [])


    const checkOrg = () => {
        try {
            let orgCreate = false;
            if (
                localStorage.hasOwnProperty("organizationID") &&
                localStorage.hasOwnProperty("organizationName")
            ) {
                if (
                    localStorage.getItem("organizationID") == "null" ||
                    localStorage.getItem("organizationID") == "" ||
                    localStorage.getItem("organizationName") == "null" ||
                    localStorage.getItem("organizationName") == ""
                ) {
                    orgCreate = true;
                }
            } else {
                orgCreate = true;
            }

            if (orgCreate) {
                window.location.replace("/createorg");
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const checkTimeZone = () => {
        let timezoneRedirectStatus = false;
        try {
            // const loggedUserId = localStorage.getItem("userId");
            getAccountData().then(({ data }) => {
                if (data.timezone == null || data.timezone == "") {
                    timezoneRedirectStatus = true;
                } else {
                    timezoneRedirectStatus = false;
                }

                if (timezoneRedirectStatus == true) {
                    setTimeZoneStatus(true)
                    setTimerCount(5)
                    // window.location.replace("/account");
                }
            });


        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        checkOrg();
        checkTimeZone()
        document.title = "HoneybeeTime-Dashboard";
    }, []);

    useEffect(() => {
        if (timerCount != null) {
            setTimeout(() => {
                if (timerCount > 0) {
                    setTimerCount(timerCount - 1)
                } else {
                    window.location.replace("/account");
                }
            }, 1000);
        }

    }, [timerCount])

    return (
        <>
            <div className="flex flex-row ">
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <Sidebar />
                        <div className="flex-1 h-full min-h-screen bg-gray-200">
                            <Navbar />

                            <div className=" w-full p-5 custom-body">
                                {activeMemberList.length > 0 ? (
                                    <>
                                        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gaps-6">
                                            <div className="md:mx-[5px] ">
                                                <Members numberOfMembers={4} memberList={activeMemberList} />
                                            </div>
                                            <div className="md:mx-[5px] ">
                                                <Projects numberOfProjects={4} />
                                            </div>
                                        </div>
                                    </>
                                ) :<>
                                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gaps-6">
                                            <div className="md:mx-[5px] ">
                                                <Projects numberOfProjects={4} />
                                            </div>
                                        </div>
                                </>}

                                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 md:mx-[5px] mt-5">
                                    <Task numberOfTasks={4} />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {timeZoneStatus
                    ? (
                        <div className="bg-white w-full h-full min-h-screen z-50 fixed top-0 left-0 text-center">
                            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center flex flex-col">
                                <div className=" text-[#F6932D] items-center mx-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                    </svg>
                                </div>
                                <div className="text-center mt-2 text-base">
                                    Your timezone details are empty ! <br />
                                    We will redirect you to your profile in a moment !! Please update your timezone !
                                </div>

                                <span className="text-[#F6932D] items-center mt-5 text-base">{timerCount}</span>
                            </div>
                        </div>
                    )
                    : null
                }
            </div>
        </>
    );
}

export default Dashboard;
