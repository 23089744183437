import React from "react";
import Navbar from "../../../../dashboard/component/navbar";
import Sidebar from "../../../../dashboard/component/sidebar";
import { Loader } from "../../../../../helper/loading";
import { loadingHide, loadingShow } from "../../../../../helper/loading";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  notifyError,
  notifySuccess,
} from "../../../../../helper/notification-helper";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";

// Imports for Form
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import {
  checkOrgPermissions,
  getOrganization,
  updateOrganizationSettings,
} from "../../../../../services/organizations";
import { editOrganization } from "../../../../../services/organizations";
import Select from "react-select";

// Time Zone Selector
import timezones from "timezones-list";
import ImageUploader from "../../../../globle-component/imageUploader/imageUploader";

import SelectCurrency from "react-select-currency";
import ToggleSwich from "../../../../globle-component/toggleSwich";
import { Autocomplete, TextField } from "@mui/material";
import { ScreenshotFrequency } from "../../../../../constant/screenshotFrequency";

const modelFormSchema = Yup.object().shape({
  orgName: Yup.string().required("Organization name cannot be empty !"),
  orgIndustry: Yup.string().required("Organization Industry cannot be empty !"),
  orgAddress: Yup.string(),
  orgTaxId: Yup.string(),
  orgCurrency: Yup.string(),
  orgStartWeekOn: Yup.string(),
  orgTimeZone: Yup.string(),
});

const CustomTextfieldComponent = (props) => (
  <input
    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1"
    type="input"
    {...props}
  ></input>
);

const CustomTextAreaComponent = (props) => (
  <textarea
    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1"
    rows="4"
    {...props}
  ></textarea>
);

const formData = {
  id: "",
  orgName: "",
  orgIndustry: "",
  orgAddress: "",
  orgTaxId: "",
  orgCurrency: "",
  orgStartWeekOn: "",
  orgTimeZone: "",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const organizationId = localStorage.getItem("organizationID");

const orgLogoPath = process.env.REACT_APP_ORGANIZATION_LOGO_IMAGE_HOST;

function General() {
  const [orgId, setOrgId] = useState("");
  const [dataLoad, setDataLoad] = useState(false);
  const [saveBtnState, setSaveBtnState] = useState(true);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedWeekStart, setSelectedWeekStart] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [imageHash, setImageHash] = useState(new Date());
  const [timezoneValidation, setTimezoneValidation] = useState("")


  const [screenshotCaptureCountValue, setScreenshotCaptureCountValue] = useState();

  const weekStarts = [
    // { value: "", label: "" },
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
    { value: "sunday", label: "Sunday" },
  ];

  const [imageUploadModalStatus, setimageUploadModalStatus] = useState(false);
  const [imageUploadStatus, setImageUploadStatus] = useState(false);
  const [orgLogoThumb, setOrgLogoThumb] = useState("");

  function getScreenshotFrequencyObject(value) {
    const objectArray = ScreenshotFrequency.filter(item => item.value === value);
    return objectArray[0]
  }

  function loadData() {
    loadingShow();

    getOrganization(localStorage.getItem("organizationID")).then(({ data }) => {
      setOrgId(data.organization.id);
      if (data.organization.settings !== null) {
        if (data.organization.settings.client_screenshot_frequency !== null) {
          setScreenshotCaptureCountValue(getScreenshotFrequencyObject(data.organization.settings.client_screenshot_frequency))
        } else {
          setScreenshotCaptureCountValue(getScreenshotFrequencyObject(0))
        }
      } else {
        setScreenshotCaptureCountValue(getScreenshotFrequencyObject(0))
      }

      formData.id = data.organization.id;
      formData.orgName = data.organization.name;
      formData.orgIndustry = data.organization.industry;
      formData.orgAddress =
        data.organization.address != null ? data.organization.address : "";
      formData.orgTaxId =
        data.organization.tax_id != null ? data.organization.tax_id : "";
      formData.orgCurrency =
        data.organization.currency != null ? data.organization.currency : "";
      formData.orgStartWeekOn =
        data.organization.start_week_on != null
          ? data.organization.start_week_on
          : "";
      formData.orgTimeZone =
        data.organization.timezone != null ? data.organization.timezone : "";

      var savedTimezone = timezones.filter(function (timezone) {
        return timezone.tzCode === data.organization.timezone;
      });
      setSelectedTimeZone(savedTimezone[0]);

      var savedWeekStart = weekStarts.filter(function (date) {
        return date.value === data.organization.start_week_on;
      });
      setSelectedWeekStart(savedWeekStart[0]);

      setSelectedCurrency(formData.orgCurrency);

      setDataLoad(true);
      loadingHide();

      let logoImageThumb =
        orgLogoPath + `${organizationId}/thumbnail-${organizationId}-logo.jpg`;

      imageCheck(logoImageThumb).then((response) => {
        if (response) {
          setOrgLogoThumb(logoImageThumb);
        } else {
          setOrgLogoThumb(orgLogoPath + "avatar.jpg");
        }
      });
    });
  }

  function imageCheck(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.src = url;

      img.onload = function () {
        resolve(true);
      };

      img.onerror = function () {
        resolve(false);
      };
    });
  }

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setImageUploadStatus(false);
    loadData();
    setImageHash(new Date());
  }, [imageUploadStatus]);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [loading, setLoading] = useState(true);

  const checkOrg = () => {
    try {
      let orgCreate = false;
      if (
        localStorage.hasOwnProperty("organizationID") &&
        localStorage.hasOwnProperty("organizationName")
      ) {
        if (
          localStorage.getItem("organizationID") == "null" ||
          localStorage.getItem("organizationID") == "" ||
          localStorage.getItem("organizationName") == "null" ||
          localStorage.getItem("organizationName") == ""
        ) {
          orgCreate = true;
        }
      } else {
        orgCreate = true;
      }

      if (orgCreate) {
        window.location.replace("/createorg");
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkOrg();
    document.title = "HoneybeeTime-General Settings";
  }, []);

  const selectOnChange = (zone) => {
    setSelectedTimeZone(zone);
  };
  const selectDateOnChange = (date) => {
    setSelectedWeekStart(date);
  };
  const selectCurrencyOnChange = (currency) => {
    setSelectedCurrency(currency);
  };

  function screenshotCountUpdate(item) {
    console.log(item);
    let formData = { "client_screenshot_frequency": item.value };
    loadingShow();
    updateOrganizationSettings(organizationId, null, formData).then((response) => {
      loadingHide();
      loadData();
    });
  }

  return (
    <>
      {checkOrgPermissions() ? (
        <div className="flex flex-row custom-body">
          {loading ? (
            <Loader />
          ) : (
            <>
              <Sidebar />
              <div className="flex-1 bg-gray-200 h-full min-h-screen">
                <Navbar />

                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />

                <div className="w-full p-5">
                  <div className="bg-[#f9f9f9] rounded-lg p-5 min-h-screen ">
                    <div className="title_container md:text-2xl">
                      <h1>General Settings</h1>
                    </div>
                    <div className="tabbed-container mt-4">
                      {dataLoad ? (
                        <Box sx={{ width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                              className=""
                            >
                              <Tab
                                label="Organization"
                                className=""
                                {...a11yProps(0)}
                              />
                              {/* <Tab
                        label="Projects"
                        className=""
                        {...a11yProps(1)}
                      /> */}
                            </Tabs>
                          </Box>
                          <TabPanel value={value} index={0}>
                            <div className="organization-settings-container">
                              <div className="organization_details_form">
                                <Formik
                                  initialValues={{
                                    orgName: formData.orgName,
                                    orgIndustry: formData.orgIndustry,
                                    orgAddress: formData.orgAddress,
                                    orgTaxId: formData.orgTaxId,
                                    orgCurrency: formData.orgCurrency,
                                    orgStartWeekOn: formData.orgStartWeekOn,
                                    orgTimeZone: formData.orgTimeZone,
                                  }}
                                  enableReinitialize={true}
                                  validationSchema={modelFormSchema}
                                  onSubmit={(values) => {
                                    loadingShow();
                                    if (selectedTimeZone == undefined || selectedTimeZone == "") {
                                      loadingHide();
                                      setTimezoneValidation("TimeZone cannot be empty !");
                                    } else {
                                      let dataObject = {
                                        name: values.orgName,
                                        industry: values.orgIndustry,
                                        address: values.orgAddress,
                                        tax_id: values.orgTaxId,
                                        currency: selectedCurrency,
                                        timezone: selectedTimeZone.tzCode,
                                        start_week_on:
                                          selectedWeekStart != null
                                            ? selectedWeekStart.value
                                            : "",
                                      };

                                      editOrganization(orgId, dataObject).then(
                                        (response) => {
                                          if (response.status == 200) {
                                            setSaveBtnState(false);
                                            setTimeout(() => {
                                              setSaveBtnState(true);
                                            }, 7000);
                                            notifySuccess(
                                              "Organization updated successfully!"
                                            );
                                            loadingHide();

                                          } else {
                                            setSaveBtnState(false);
                                            setTimeout(() => {
                                              setSaveBtnState(true);
                                            }, 7000);
                                            notifyError(
                                              "Organization update fail!"
                                            );
                                            loadingHide();

                                          }
                                        }
                                      );
                                    }
                                  }}
                                >
                                  {({ errors, touched, setFieldValu }) => (
                                    <Form>
                                      <div className="genaral_details_container grid lg:grid-cols-2 md:grid-cols-1 gap-4 ">
                                        <div className="mt-4">
                                          <label className="text-gray-600 font-semibold mb-2 uppercase">
                                            Organization Name*
                                          </label>
                                          <div className="relative">
                                            <Field
                                              name="orgName"
                                              as={CustomTextfieldComponent}
                                              placeholder="Organization Name"
                                            />
                                          </div>
                                          {errors.orgName && touched.orgName ? (
                                            <p className="text-red-500 italic mt-1">
                                              {errors.orgName}
                                            </p>
                                          ) : null}
                                        </div>
                                        <div className="lg:mt-4">
                                          <label className="text-gray-600 font-semibold mb-2 uppercase">
                                            industry*
                                          </label>
                                          <div className="relative">
                                            <Field
                                              name="orgIndustry"
                                              as={CustomTextfieldComponent}
                                              placeholder="Industry"
                                            />
                                          </div>
                                          {errors.orgIndustry &&
                                            touched.orgIndustry ? (
                                            <p className="text-red-500 italic mt-1">
                                              {errors.orgIndustry}
                                            </p>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="my-4">
                                        <label className="text-gray-600 font-semibold mb-2 uppercase">
                                          Address
                                        </label>
                                        <div className="relative">
                                          <Field
                                            name="orgAddress"
                                            as={CustomTextAreaComponent}
                                            placeholder="Address"
                                          />
                                        </div>
                                      </div>
                                      <div className="my-4">
                                        <label className="text-gray-600 font-semibold mb-2 uppercase">
                                          Logo
                                        </label>
                                        <div className="general-settings-container md:mx-0 mx-auto">
                                          <div className="grid grid-cols-2 gap-4">
                                            <div className="py-2 sm:w-[200px] w-auto">
                                              <div className="mb-5 image-load">
                                                <img
                                                  alt="avatar"
                                                  id="user_avatar"
                                                  className="avatar avatar-full  rounded-full"
                                                  src={`${orgLogoThumb}?${imageHash}`}
                                                />
                                              </div>
                                              <ImageUploader
                                                setimageUploadModalStatus={
                                                  setimageUploadModalStatus
                                                }
                                                setImageUploadStatus={
                                                  setImageUploadStatus
                                                }
                                                type={"orgLogo"}
                                              />
                                            </div>
                                            <div className="py-2 w-auto">
                                              <label className="text-gray-600 font-semibold mb-2 uppercase">
                                                Screenshots count <span className="capitalize font-extralight">( within 10 minutes )</span>
                                              </label>
                                              <div className="relative">
                                                <Select
                                                  name="orgStartWeekOn"
                                                  options={ScreenshotFrequency}
                                                  value={screenshotCaptureCountValue}
                                                  onChange={(item) => {
                                                    screenshotCountUpdate(item)
                                                  }}
                                                  menuPosition="fixed"
                                                  menuPlacement="auto"
                                                  className="mt-1 bg-white shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1"
                                                />
                                              </div>

                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                      <div className="accounts_details_container grid lg:grid-cols-2 md:grid-cols-1 gap-4 ">
                                        <div className="lg:mt-4">
                                          <label className="text-gray-600 font-semibold mb-2 uppercase">
                                            Tax ID
                                          </label>
                                          <div className="relative">
                                            <Field
                                              name="orgTaxId"
                                              as={CustomTextfieldComponent}
                                              placeholder="Tax ID"
                                            />
                                          </div>
                                        </div>
                                        <div className="lg:mt-4">
                                          <label className="text-gray-600 font-semibold mb-2 uppercase">
                                            time zone
                                          </label>
                                          <div className="relative">
                                            {selectedTimeZone != "" ? (
                                              <Select
                                                name="orgTimeZone"
                                                options={timezones}
                                                value={selectedTimeZone}
                                                onChange={selectOnChange}
                                                menuPosition="fixed"
                                                menuPlacement="auto"
                                                className="mt-1 bg-white shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1" />
                                            ) : null}
                                            {timezoneValidation !== "" ? (
                                              <p className="text-red-500 text-xs italic mt-1">
                                                {timezoneValidation}
                                              </p>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="region_details_container grid lg:grid-cols-2 md:grid-cols-1 gap-4">
                                        <div className="lg:mt-4">
                                          <label className="text-gray-600 font-semibold mb-2 uppercase">
                                            Currency
                                          </label>
                                          <div className="relative">
                                            <SelectCurrency
                                              name="orgCurrency"
                                              value={selectedCurrency}
                                              menuposition="fixed"
                                              menuplacement="auto"
                                              onChange={(item) => {
                                                selectCurrencyOnChange(
                                                  item.target.value
                                                );
                                              }}
                                              className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1"
                                            />
                                          </div>
                                        </div>
                                        <div className="mt-4">
                                          <label className="text-gray-600 font-semibold mb-2 uppercase">
                                            Start Week On
                                          </label>
                                          <div className="relative">
                                            {selectedWeekStart != "" ? (
                                              <Select
                                                name="orgStartWeekOn"
                                                options={weekStarts}
                                                value={selectedWeekStart}
                                                onChange={selectDateOnChange}
                                                menuPosition="fixed"
                                                menuPlacement="auto"
                                                className="mt-1 bg-white shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1"
                                              />
                                            ) : null}
                                          </div>
                                        </div>

                                      </div>


                                      <div className="my-4 flex justify-end">
                                        <button
                                          type="button"
                                          className="  px-4 py-2 text-white hbt-bg-dark border border-transparent rounded duration-300 "
                                        >
                                          Close
                                        </button>
                                        {saveBtnState == false ? (
                                          <button
                                            type="button"
                                            className={
                                              "px-4 py-2 mx-3 text-black bg-yellow-300 border border-transparent rounded hover:bg-yellow-500 duration-300"
                                            }
                                            disabled
                                          >
                                            Save
                                          </button>
                                        ) : (
                                          <button
                                            type="submit"
                                            className={
                                              "px-4 py-2 mx-3 text-black bg-yellow-300 border border-transparent rounded hover:bg-yellow-500 duration-300"
                                            }
                                          >
                                            Save
                                          </button>
                                        )}
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </TabPanel>
                          {/* <TabPanel value={value} index={1}>
                    Prj
                  </TabPanel> */}
                        </Box>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : null}
    </>
  );
}

export default General;
