import { Dialog, Transition } from '@headlessui/react';
import { Field, Form, Formik } from 'formik';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveProjects, setAddModal, setSelectedManagers, setSelectedUsers, setSelectedViewers } from "../../../store/slices/projects";
import * as Yup from "yup";
import { loadingHide, loadingShow } from '../../../helper/loading';
import { addMemberToProject, addNewProject } from '../../../services/projects';
import { notifySuccess } from '../../../helper/notification-helper';
import { addUserBodyCreate } from '../../globle-component/globle';
import { UserRole } from '../../../constant/userRole';
import ListBox from './listBox';

const formSchema = Yup.object().shape({
    projectName: Yup.string().required("Required"),
});
const organizationId = localStorage.getItem('organizationID');
const orgUserRole = JSON.parse(localStorage.getItem('orgUserRole'));

const AddNewProjectModal = ({ CustomInputComponent, CustomTextareaComponent }) => {
    const dispatch = useDispatch();
    const {
        addModal,
        initialModal,
        selectedManagers,
        selectedUsers,
        selectedViewers
    } = useSelector((state) => state.projects)
    const { activeMemberList: activeMembersList } = useSelector((state) => state.members)

    useEffect(() => {
        // console.log(addModal);
    }, [addModal])

    function closeModal() {
        dispatch(setAddModal(initialModal))
    }

    useEffect(() => {
        for (const person of selectedManagers) {
            if (selectedUsers.some(e => e.id === person.id)) {
                // console.log(person.user.full_name, ' already in Users list');
                const newArray = selectedUsers.filter(item => item.id !== person.id);
                dispatch(setSelectedUsers(newArray));

            }
            if (selectedViewers.some(e => e.id === person.id)) {
                // console.log(person.user.full_name, ' already in Viewers list');
                const newArray = selectedViewers.filter(item => item.id !== person.id);
                dispatch(setSelectedViewers(newArray));
            }
        }
    }, [selectedManagers])

    useEffect(() => {
        for (const person of selectedUsers) {
            if (selectedManagers.some(e => e.id === person.id)) {
                // console.log(person.user.full_name, ' already in Manager list');
                const newArray = selectedManagers.filter(item => item.id !== person.id);
                dispatch(setSelectedManagers(newArray));
            }
            if (selectedViewers.some(e => e.id === person.id)) {
                // console.log(person.user.full_name, ' already in Viewers list');
                const newArray = selectedViewers.filter(item => item.id !== person.id);
                dispatch(setSelectedViewers(newArray));
            }
        }
    }, [selectedUsers])

    useEffect(() => {
        for (const person of selectedViewers) {
            if (selectedUsers.some(e => e.id === person.id)) {
                // console.log(person.user.full_name, ' already in Users list');
                const newArray = selectedUsers.filter(item => item.id !== person.id);
                dispatch(setSelectedUsers(newArray));
            }
            if (selectedManagers.some(e => e.id === person.id)) {
                // console.log(person.user.full_name, ' already in Manager list');
                const newArray = selectedManagers.filter(item => item.id !== person.id);
                dispatch(setSelectedManagers(newArray));
            }
        }
    }, [selectedViewers])

    const selectAllManagers = () => { dispatch(setSelectedManagers(activeMembersList)); dispatch(setSelectedUsers([])); dispatch(setSelectedViewers([])); }
    const selectAllUsers = () => { dispatch(setSelectedUsers(activeMembersList)); dispatch(setSelectedManagers([])); dispatch(setSelectedViewers([])); }
    const selectAllViewers = () => { dispatch(setSelectedViewers(activeMembersList)); dispatch(setSelectedManagers([])); dispatch(setSelectedUsers([])); }

    const clearAllManagers = () => { dispatch(setSelectedManagers([])); }
    const clearAllUsers = () => { dispatch(setSelectedUsers([])); }
    const clearAllViewers = () => { dispatch(setSelectedViewers([])); }


    function clearModalData() {
        dispatch(setAddModal(initialModal));
        dispatch(setSelectedManagers([]));
        dispatch(setSelectedUsers([]));
        dispatch(setSelectedViewers([]));
    }


    return (
        <>
            {orgUserRole === UserRole.ORGANIZATION_OWNER || orgUserRole === UserRole.ORGANIZATION_MANAGER ? (
                <div className="order-last text-center">
                    <button
                        className="ht-btn-yellow-lte"
                        type="button"
                        onClick={() => {
                            dispatch(setAddModal({
                                ...addModal,
                                modals: {
                                    type: "insert",
                                    show: true,
                                    tab: 1,
                                },
                            }))
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-3">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                        </svg>

                        <span>Add Project</span>
                    </button>
                </div>
            ) : null}


            {addModal.modals.show ? (
                <Transition appear show={addModal.modals.show} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed inset-0 z-[1000] overflow-y-auto bg-gray-500/40"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            <span className="inline-block h-screen" aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="modal-loading inline-block z-[1000] w-full max-w-md p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="ht-modal-header"
                                    >
                                        Add New Project
                                    </Dialog.Title>
                                    <Formik
                                        initialValues={{
                                            projectName: addModal.formData.name,
                                            projectDescription: addModal.formData.description,
                                        }}
                                        validationSchema={formSchema}
                                        onSubmit={(values) => {

                                            let managerIds = selectedManagers.map(a => a.id);
                                            let userIds = selectedUsers.map(a => a.id);
                                            let viewerIds = selectedViewers.map(a => a.id);

                                            var projectObject = {
                                                name: values.projectName,
                                                description: values.projectDescription,
                                                record_activity: true,
                                                start_date: new Date().toISOString().split("T")[0],
                                                status: 1,
                                            };

                                            let body = [
                                                ...addUserBodyCreate(managerIds, 100),
                                                ...addUserBodyCreate(userIds, 101),
                                                ...addUserBodyCreate(viewerIds, 102),
                                            ];

                                            loadingShow('.modal-loading');
                                            addNewProject(projectObject, organizationId).then(
                                                function (response) {
                                                    projectObject.id = response.data.project.id;

                                                    addMemberToProject(body, projectObject.id).then(
                                                        (res) => {
                                                            if (res.code === 201) {
                                                                notifySuccess("Project added successfully");
                                                                dispatch(getActiveProjects(organizationId))
                                                                loadingHide('.modal-loading');
                                                                clearModalData()
                                                            }
                                                        }
                                                    );

                                                }
                                            );

                                        }}
                                    >
                                        {({ errors, touched, setFieldValue }) => (
                                            <Form>
                                                <div className="mt-2">
                                                    <div className="relative flex-auto">
                                                        <div className="flex flex-wrap p-3">
                                                            <ul
                                                                className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4"
                                                                id="tabs-tab"
                                                                role="tablist"
                                                            >
                                                                <li
                                                                    className="nav-item"
                                                                    role="presentation"
                                                                >
                                                                    <a
                                                                        href="#add-new-general"
                                                                        className={
                                                                            "nav-link block leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent" +
                                                                            (addModal.modals.tab == 1 ? " active" : "")
                                                                        }
                                                                        id="add-new-general-tab"
                                                                        data-bs-toggle="pill"
                                                                        data-bs-target="#add-new-general"
                                                                        role="tab"
                                                                        aria-controls="add-new-general"
                                                                        aria-selected="true"
                                                                    >
                                                                        General
                                                                    </a>
                                                                </li>
                                                                <li
                                                                    className="nav-item"
                                                                    role="presentation"
                                                                >
                                                                    <a
                                                                        href="#add-new-member"
                                                                        className={
                                                                            "nav-link block leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent"
                                                                        }
                                                                        id="add-new-member-tab"
                                                                        data-bs-toggle="pill"
                                                                        data-bs-target="#add-new-member"
                                                                        role="tab"
                                                                        aria-controls="add-new-member"
                                                                        aria-selected="false"
                                                                    >
                                                                        Members
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                            <div
                                                                className="tab-content w-full"
                                                                id="tabs-tabContent"
                                                            >
                                                                <div
                                                                    className={
                                                                        "tab-pane fade show active"
                                                                    }
                                                                    id="add-new-general"
                                                                    role="tabpanel"
                                                                    aria-labelledby="add-new-general-tab"
                                                                >
                                                                    <div className="mb-3">
                                                                        <label className="block uppercase tracking-wide text-gray-700 mb-2">
                                                                            Name{" "}
                                                                            <label className="text-red-700 mb-2">
                                                                                *
                                                                            </label>
                                                                        </label>
                                                                        <Field
                                                                            name="projectName"
                                                                            placeholder="Project Name"
                                                                            as={CustomInputComponent}
                                                                            autoComplete='off'
                                                                        />
                                                                        {errors.projectName &&
                                                                            touched.projectName ? (
                                                                            <p className="text-red-500 italic mb-3">
                                                                                {errors.projectName}
                                                                            </p>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="block uppercase tracking-wide text-gray-700 mb-2">
                                                                            Description
                                                                        </label>
                                                                        <Field
                                                                            name="projectDescription"
                                                                            placeholder="Project Description"
                                                                            as={CustomTextareaComponent}
                                                                            autoComplete='off'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={
                                                                        "tab-pane fade"
                                                                    }
                                                                    id="add-new-member"
                                                                    role="tabpanel"
                                                                    aria-labelledby="add-new-member-tab"
                                                                >
                                                                    <div className="mb-7">

                                                                        <div className='right'>
                                                                            <span className='cursor-pointer hover:underline px-1' onClick={selectAllManagers}>Select All</span>
                                                                            <span className='text-gray-400 text-[10px] px-1'>|</span>
                                                                            <span className='cursor-pointer hover:underline px-1' onClick={clearAllManagers}>Clear</span>
                                                                        </div>

                                                                        <label className="block uppercase tracking-wide text-gray-700 ">
                                                                            Manager
                                                                        </label>
                                                                        <p className=" text-gray-500">
                                                                            Oversees and manages the project
                                                                        </p>
                                                                        <ListBox
                                                                            selectedPeople={selectedManagers}
                                                                            setSelectedPeople={setSelectedManagers}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-7">

                                                                        <div className='right'>
                                                                            <span className='cursor-pointer hover:underline px-1' onClick={selectAllUsers}>Select All</span>
                                                                            <span className='text-gray-400 text-[10px] px-1'>|</span>
                                                                            <span className='cursor-pointer hover:underline px-1' onClick={clearAllUsers}>Clear</span>
                                                                        </div>

                                                                        <label className="block uppercase tracking-wide text-gray-700">
                                                                            user
                                                                        </label>
                                                                        <p className=" text-gray-500">
                                                                            Works on the project, will not see
                                                                            other users (most common)
                                                                        </p>

                                                                        <ListBox
                                                                            selectedPeople={selectedUsers}
                                                                            setSelectedPeople={setSelectedUsers}
                                                                        />
                                                                    </div>
                                                                    <div className="mb-7">

                                                                        <div className='right'>
                                                                            <span className='cursor-pointer hover:underline px-1' onClick={selectAllViewers}>Select All</span>
                                                                            <span className='text-gray-400 text-[10px] px-1'>|</span>
                                                                            <span className='cursor-pointer hover:underline px-1' onClick={clearAllViewers}>Clear</span>
                                                                        </div>

                                                                        <label className="block uppercase tracking-wide text-gray-700 mb-0">
                                                                            Viewer
                                                                        </label>
                                                                        <p className=" text-gray-500">
                                                                            Can view the project, reports but
                                                                            not edit or comment
                                                                        </p>

                                                                        <ListBox
                                                                            selectedPeople={selectedViewers}
                                                                            setSelectedPeople={setSelectedViewers}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center justify-end pt-3">
                                                                    <button
                                                                        className="ht-btn-black"
                                                                        type="button"
                                                                        onClick={closeModal}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        className="ht-btn-yellow"
                                                                        type="submit"
                                                                    >
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            ) : null}
        </>
    );
}

export default AddNewProjectModal;