 const colorSchema = require('./colorSchema.json')

export default function ColorSelector(id){
    let sum =  calculateSum(id);
    while (sum > 30) {
        sum = calculateSum(sum);
    }
    let color = colorSchema[sum].color;
    return color;
 }
 
 function calculateSum (id) {
     let idString = JSON.stringify(id);
     let sum = 0;
     for (let i = 0; i < idString.length; i++) {
         sum = sum + parseInt(idString[i]);
     }
     return sum;
 }




