import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from "moment";
import { DATETIMES } from '../../../constant/datetimes';
import ColorSelector from '../../../helper/colorSelector';

let newSlotColor = null;

const Timeline = ({ worktimes, newTimeSlotArray, keyVal, overlap, isDateFuture }) => {

    const timeSlotes = [];
    const fullWidth = 1440
    processTimeSlots(worktimes, newTimeSlotArray)

    function processTimeSlots(worktimes, newTimeSlotArray) {
        
        let startTimeInMinutes = '';
        let endTimeInMinutes = '';
        let duration = '';
        let newObj = { duration: '', start: '' }

        if (worktimes.length > 0) {
            worktimes.forEach(worktime => {
                startTimeInMinutes = convertH2M(moment(worktime.startDateTime).format(DATETIMES.time24h));
                endTimeInMinutes = convertH2M(moment(worktime.endDateTime).format(DATETIMES.time24h));
                duration = endTimeInMinutes - startTimeInMinutes;

                newObj = { duration: '', start: '' }

                if(worktime.duration > 0 && duration === 0){
                    newObj.duration = JSON.stringify(1)
                } else {
                    newObj.duration = JSON.stringify(duration)
                }
                newObj.start = moment(worktime.startDateTime).format(DATETIMES.time24h)
                newObj.end = moment(worktime.endDateTime).format(DATETIMES.time24h)
                newObj.projectName = worktime.projectName;
                if (worktime.project.length > 0) {
                    newObj.projectId = worktime.project[0]?.id;
                } else if (worktime.projectId != null) {
                    newObj.projectId = worktime.projectId;
                } else {
                    newObj.projectId = 1;
                }
                newObj.status = 'exist'
                timeSlotes.push(newObj);
            });
        }

        if (newTimeSlotArray?.length > 0) {
            newTimeSlotArray.forEach(worktime => {
                startTimeInMinutes = convertH2M(moment(worktime.startDateTime).format(DATETIMES.time24h));
                endTimeInMinutes = convertH2M(moment(worktime.endDateTime).format(DATETIMES.time24h));
                duration = endTimeInMinutes - startTimeInMinutes;
                newObj = { duration: '', start: '' }
                newObj.duration = JSON.stringify(duration)
                newObj.start = moment(worktime.startDateTime).format(DATETIMES.time24h)
                newObj.end = moment((worktime.endDateTime)).format(DATETIMES.time24h)
                newObj.projectName = worktime.projectName;
                newObj.status = 'new'
                timeSlotes.push(newObj);
            });
        }
    }

    function lengthCalculate(derationInMinutes) {
        let value = (derationInMinutes / fullWidth) * 100;
        return value + '%';
    }

    function startTime(start) {
        let startInMinutes = convertH2M(padToTwoDigits(start));
        let startInPrecentage = (startInMinutes / fullWidth) * 100;
        return startInPrecentage + '%';
    }

    function endtimeCalculate(object) {
        let startTime = moment.duration(object.start).asSeconds()
        let endTime = moment.duration(object.end).asSeconds()
        let start = moment.utc(startTime*1000).format('hh:mm:ss a');
        let end = moment.utc(endTime*1000).format('hh:mm:ss a');
        return start + ' - ' + end;
    }

    function convertH2M(timeInHour) {
        var timeParts = timeInHour.split(":");
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    }

    function toHoursAndMinutes(totalMinutes) {
        const hours = padToTwoDigits(Math.floor(totalMinutes / 60));
        const minutes = padToTwoDigits(totalMinutes % 60);
        return { hours, minutes };
    }

    function padToTwoDigits(num) {
        return num.toString().padStart(2, '0');
    }

    function getTimeFromMins(mins) {
        if (mins >= 24 * 60 || mins < 0) {
            throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
        }
        var h = mins / 60 | 0,
            m = mins % 60 | 0;
        return 'Total time : ' + moment.utc().hours(h).minutes(m).format("hh:mm");
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} placement="top" />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'rgba(63,72,86,0.9)',
            color: 'rgba(255, 255, 255, 1)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    if(overlap || isDateFuture ){
        newSlotColor = '#ff480c';
    } else {
        newSlotColor = '#FDE047';
    }

    return (
        <>
            <div className={'timeline'} key={keyVal}>

                <svg focusable="false" tabIndex="-1" className="time-entries-timeline--chart" width="100%" height="50">
                    <g className="chart">
                        <g className="bg">
                            <rect y="6" x="0" height="16" width="100%" rx="8" ry="100" fill="#dfe6f1" tabIndex="-1"></rect>
                        </g>
                        <g className="time-entries">
                            {timeSlotes.map((timeSlote, index) => {
                                let color = null;
                                if(timeSlote.status !== 'new'){
                                    color = ColorSelector(timeSlote.projectId);
                                }
                                return (
                                    <HtmlTooltip
                                        key={index}
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">{timeSlote.projectName}</Typography>
                                                <em>{endtimeCalculate(timeSlote)}</em><br/>
                                                <em>{getTimeFromMins(timeSlote.duration)}</em>
                                            </React.Fragment>
                                        }
                                    >
                                        <rect y="6.5" height="15" rx="8" ry="100" width={lengthCalculate(timeSlote.duration)} x={startTime(timeSlote.start)} fill={timeSlote.status === 'new' ? newSlotColor : color}
                                            stroke={timeSlote.status === 'new' ? newSlotColor : color} strokeWidth="1" tabIndex="-1"></rect>
                                    </HtmlTooltip>
                                );

                            })}
                        </g>

                    </g>
                    <g className="x axis" transform="translate(0, 22)" fill="none" fontSize="10" fontFamily="sans-serif"
                        textAnchor="middle">
                        <g>
                            <text x="25%" y="20" fontFamily="Verdana" fontSize="14" fill="#8e9eb3">6:00 am</text>
                            <text x="50%" y="20" fontFamily="Verdana" fontSize="14" fill="#8e9eb3">12:00 pm</text>
                            <text x="75%" y="20" fontFamily="Verdana" fontSize="14" fill="#8e9eb3">6:00 pm</text>
                        </g>
                    </g>
                </svg>
            </div>
        </>

    );
}

export default Timeline;