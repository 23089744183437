import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import * as yup from "yup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Lottie from "lottie-react";
import animation from "../../helper/registration_animation.json";

import axios from "../../api/axios";
import AuthContext from "../../context/authProvider";
import useAuth from "../../hooks/useAuth";

import logo from "../../img/logo/Honey bee time tracker logo dark.png";

import GoogleLogin from "../login/google-login/login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect } from "react";

import ReCAPTCHA from "../login/ReCAPTCHA";


const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;

const validationSchema = yup.object({
  fullName: yup.string("Enter your name").required("Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  passwordConfirmation: yup
    .string("Please confirm your password")
    .required("Please confirm your password")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  checkbox: yup
    .bool("dasdsadas")
    .oneOf([true], "You need to accept the Terms and Conditions"),
});

function Signup() {
  React.useEffect(() => {}, []);
  const { setAuth } = useAuth(AuthContext);
  const [receivedToken, setReceivedToken] = useState("");

  const [signupStatus, setSignupStatus] = useState(false);
  const [verifiedUserSignup, setVerifiedUserSignup] = useState(false);
  const [signupEmail, setSignupEmail] = useState("");
  const [error, setError] = useState({
    message: {
      name: "",
      body: "",
    },
    show: false,
  });

  useEffect(() => {
    document.title = "HoneybeeTime - Signup";
    if (
      localStorage.hasOwnProperty("invited-user-email") &&
      localStorage.hasOwnProperty("invited-user-token")
    ) {
      setSignupEmail(localStorage.getItem("invited-user-email"));
    }
  }, []);

  const handleTokenUpdate = (token) => {
    setReceivedToken(token);
  };

  const DataSubmit = async (data) => {
    const formData = {
      full_name: data.fullName,
      email: data.email,
      password: data.password,
      status: 1,
      recaptcha: receivedToken,
    };
    if (!receivedToken) {
      setError({
        show: true,
        type: "custom2",
        message: "Please complete the reCAPTCHA first!",
      });
      return;
    }
    if((localStorage.hasOwnProperty('public-verification-tokan')) && (localStorage.getItem('public-verification-tokan') !== '' && localStorage.getItem('public-verification-tokan') !== 'null')){
      formData.organizationPublicInviteToken = localStorage.getItem('public-verification-tokan')
    }

    if (localStorage.hasOwnProperty("invited-user-token")) {
      formData.verifyToken = localStorage.getItem("invited-user-token");
    }
    setSignupEmail(data.email);

    try {
      var response = await axios.post("authentication/signup", formData);
      let origin = window.location.origin;
      if (
        localStorage.hasOwnProperty("invited-user-email") &&
        localStorage.hasOwnProperty("invited-user-token") &&
        response.data.code == 201
      ) {
        setVerifiedUserSignup(true);
        console.log(formData);
      } else {
        setSignupStatus(true);
        console.log(formData);
      }
    } catch (error) {
      console.log(error);
      setSignupStatus(false);

      if (error.response.status === 409) {
        console.log(error.response.data.error.code);
        console.log(formData);
        setError({
          message: {
            name: "email",
            body: "Email alredy exists",
          },
          show: true,
        });
      } else if (error.response.status === 400) {
        console.log(error.response.data.error.code);
        setError({
          message: {
            name: "all",
            body: "Something went wrong !!",
          },
          show: true,
        });
      }
    }
  };

  const logginDataSet = (res) => {
    try {
      const accessToken = res?.data?.accessToken;
      const organizationId = res?.data?.organizationId;
      const organizationName = res?.data?.organizationName;
      localStorage.setItem("tag", JSON.stringify(accessToken));
      localStorage.setItem("organizationID", organizationId);
      localStorage.setItem("organizationName", organizationName);

      const userId = res?.data?.user.id;
      localStorage.setItem("userId", userId);
      localStorage.setItem("orgUserRole", res?.data?.firstOrganizationRole);
      localStorage.setItem("user", JSON.stringify(res?.data?.user));

      setAuth(accessToken);

      window.location.replace(process.env.REACT_APP_FRONTEND_HOST);
    } catch (error) {
      console.log(error);
    }
  };

  let emailId = '';
  if(localStorage.hasOwnProperty("invited-user-email") && (localStorage.getItem("invited-user-email") !== undefined || localStorage.getItem("invited-user-email") !== null)){
    emailId = localStorage.getItem("invited-user-email");
  }

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: emailId,
      password: "",
      passwordConfirmation: "",
      checkbox: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      DataSubmit(values);
    },
  });

  const AccountIcon = () => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 12C10.7 12 13.8 13.29 14 14H2C2.23 13.28 5.31 12 8 12ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
          fill="#1C2130"
        />
      </svg>
    );
  };

  const EmailIcon = () => {
    return (
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.29408 18.4651H13.7647V16.6418H9.29408C5.41361 16.6418 2.14114 13.3053 2.14114 9.34882C2.14114 5.39236 5.41361 2.0558 9.29408 2.0558C13.1746 2.0558 16.447 5.39236 16.447 9.34882V10.6525C16.447 11.3726 15.8122 12.0837 15.1058 12.0837C14.3995 12.0837 13.7647 11.3726 13.7647 10.6525V9.34882C13.7647 6.83273 11.7618 4.79068 9.29408 4.79068C6.82632 4.79068 4.82349 6.83273 4.82349 9.34882C4.82349 11.8649 6.82632 13.907 9.29408 13.907C10.528 13.907 11.6546 13.3965 12.4593 12.5669C13.0404 13.3782 14.0418 13.907 15.1058 13.907C16.8673 13.907 18.2353 12.4484 18.2353 10.6525V9.34882C18.2353 4.31664 14.2296 0.232544 9.29408 0.232544C4.35855 0.232544 0.352905 4.31664 0.352905 9.34882C0.352905 14.381 4.35855 18.4651 9.29408 18.4651ZM9.29408 12.0837C7.80985 12.0837 6.61173 10.8621 6.61173 9.34882C6.61173 7.83552 7.80985 6.61394 9.29408 6.61394C10.7783 6.61394 11.9764 7.83552 11.9764 9.34882C11.9764 10.8621 10.7783 12.0837 9.29408 12.0837Z"
          fill="#1C2130"
        />
      </svg>
    );
  };

  const PasswordIcon = () => {
    return (
      <svg
        width="19"
        height="25"
        viewBox="0 0 19 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 8.79071H14.8823V6.51164C14.8823 3.36652 12.3788 0.813965 9.29408 0.813965C6.20938 0.813965 3.70585 3.36652 3.70585 6.51164V8.79071H2.5882C1.35879 8.79071 0.352905 9.81629 0.352905 11.0698V22.4651C0.352905 23.7186 1.35879 24.7442 2.5882 24.7442H16C17.2294 24.7442 18.2353 23.7186 18.2353 22.4651V11.0698C18.2353 9.81629 17.2294 8.79071 16 8.79071ZM5.94114 6.51164C5.94114 4.62001 7.43879 3.09303 9.29408 3.09303C11.1494 3.09303 12.647 4.62001 12.647 6.51164V8.79071H5.94114V6.51164ZM16 22.4651H2.5882V11.0698H16V22.4651ZM9.29408 19.0465C10.5235 19.0465 11.5294 18.0209 11.5294 16.7675C11.5294 15.514 10.5235 14.4884 9.29408 14.4884C8.06467 14.4884 7.05879 15.514 7.05879 16.7675C7.05879 18.0209 8.06467 19.0465 9.29408 19.0465Z"
          fill="#1C2130"
        />
      </svg>
    );
  };


  return (
    <>
      {verifiedUserSignup ? (
        <>
          <div className="relative h-full min-h-screen w-full md:text-[18px] text-[14px] p-1">
            <div className="lg:absolute lg:top-1/2 lg:left-1/2 lg:-translate-y-1/2 lg:-translate-x-1/2 items-center mt-20">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-14 h-14 p-2 rounded-full mx-auto bg-green-600 text-white my-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
              </div>
              <div>
                <div className="md:text-xl text-lg items-center text-center">
                  We've successfully registered you
                </div>
                <div>
                  <Link
                    className={"items-center text-center text-sm text-blue-600"}
                    to={"/login"}
                  >
                    Click here to go to the Login page
                  </Link>
                </div>
              </div>
              <div className="lg:absolute mb-3 lg:left-1/2 lg:-transform-x-1/2 justify-bottom items-center text-center mt-20">
                HoneybeeTime.com
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {signupStatus ? (
            <>
              <div className="relative h-screen w-full md:text-[18px] text-[14px] p-1">
                <div className="lg:absolute lg:top-1/2 lg:left-1/2 lg:-translate-y-1/2 lg:-translate-x-1/2 lg:mt-0 mt-20">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-14 h-14 p-2 rounded-full mx-auto bg-green-600 text-white my-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </div>
                  <div>
                    <div className="md:text-xl text-lg items-center text-center">
                      We've sent an email to <br />
                      <b>{signupEmail}</b>
                    </div>
                    <div className="text-center items-center">
                      Please check your mail box and confirm your email address.
                    </div>
                  </div>
                  <div className="lg:absolute mb-5 lg:left-1/2 lg:-translate-x-1/2 w-full justify-bottom items-center text-center mt-20">
                    HoneybeeTime.com
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="lg:grid lg:grid-cols-2 md:grid-cols-1 gap-4 h-full min-h-scrreen custom-body">
                <div className="relative lg:h-full h-0 lg:min-h-screen invisible lg:visible">
                  <Lottie
                    className="lg:absolute lg:h-full h-0 lg:min-h-screen lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 w-full bg-[#1C2130] m-0"
                    animationData={animation}
                  />
                </div>
                <div className="relative h-full min-h-screen overflow-y-scroll">
                  <div className="lg:w-[480px] w-full px-3 lg:px-0 items-center mx-auto lg:absolute lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:mt-0 mt-[40px]">
                    <div>
                      <h1 className="lg:text-[24px] text-[20px] leading-[29px] font-medium text-[#8F9196] text-center">
                        Welcome to
                      </h1>
                      <img
                        src={logo}
                        alt="logo"
                        className="lg:mt-[12px] mt-[10px] lg:mb-[8px] mb-[8px] mx-auto lg:w-full w-2/3"
                      />
                      <p className="font-medium lg:text-[16px] text-[14px] text-[#8F9196] text-center tracking-[0.03em]">
                        Streamline how you manage teams and your business<br/>with
                        HoneybeeTime
                      </p>
                    </div>
                    <div>
                      {/* form */}
                      <form onSubmit={formik.handleSubmit}>
                        <div className="flex flex-col lg:px-5 lg:py-2 p-10">
                          <div className="lg:mt-[18px] mt-[18px] w-full lg:w-[380px] mx-auto !h-[43.3px]">
                            <TextField
                              name="fullName"
                              className="lg:text-[18px] placeholder:text-[16px] text-[12px] mt-1 w-full lg:w-[380px] mx-1 md:h-[43px] h-[40px] bg-white "
                              id="fullName"
                              label="Full name"
                              value={formik.values.fullName}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.fullName &&
                                Boolean(formik.errors.fullName)
                              }
                              helperText={formik.errors.fullName}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <AccountIcon />
                                  </InputAdornment>
                                ),

                                sx: { height: 43.3, marginTop: "5px" },
                              }}
                              InputLabelProps={{
                                style: { color: "#000000", fontSize: "16px" },
                              }}
                              sx={{
                                "& label": {
                                  marginTop: "5",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #1c2130",
                                  borderRadius: "7px",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                  "& > fieldset": {
                                    borderColor: "#1C2130",
                                  },
                                },
                                "& .MuiOutlinedInput-root:hover": {
                                  "& > fieldset": {
                                    border: "2px solid #1c2130",
                                  },
                                },
                              }}
                            />
                          </div>

                          <div className="lg:mt-[25px] mt-[10px] w-full lg:w-[380px] mx-auto !h-[43.3px]">
                            <TextField
                              name="email"
                              className="lg:text-[18px] placeholder:text-[16px] text-[12px] mt-1 w-full lg:w-[380px] mx-1 md:h-[43px] h-[40px] px-3 py-2 bg-white border-slate-300 placeholder-slate-400 block rounded-md sm:text-sm "
                              id="email"
                              label="Email"
                              variant="outlined"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              error={
                                (formik.touched.email &&
                                  Boolean(formik.errors.email)) ||
                                Boolean(error.message.name === "email")
                              }
                              helperText={
                                formik.errors.email || error.message.body
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <EmailIcon />
                                  </InputAdornment>
                                ),
                                sx: { height: 43.3, marginTop: "5px" },
                              }}
                              InputLabelProps={{
                                style: { color: "#000000", fontSize: "16px" },
                              }}
                              sx={{
                                "& label": {
                                  marginTop: "5",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #1c2130",
                                  borderRadius: "7px",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                  "& > fieldset": {
                                    borderColor: "#1C2130",
                                  },
                                },
                                "& .MuiOutlinedInput-root:hover": {
                                  "& > fieldset": {
                                    border: "2px solid #1c2130",
                                  },
                                },
                              }}
                            />
                          </div>
                          <div className="lg:mt-[25px] mt-[10px] w-full lg:w-[380px] mx-auto !h-[43.3px]">
                            <TextField
                              name="password"
                              className="lg:text-[18px] placeholder:text-[16px] text-[12px] mt-1 w-full lg:w-[380px] md:h-[43px] h-[40px] px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 block rounded-md sm:text-sm "
                              id="password"
                              label="Password"
                              variant="outlined"
                              type="password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.password &&
                                Boolean(formik.errors.password)
                              }
                              helperText={formik.errors.password}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <PasswordIcon />
                                  </InputAdornment>
                                ),
                                sx: { height: 43.3, marginTop: "5px" },
                              }}
                              InputLabelProps={{
                                style: { color: "#000000", fontSize: "16px" },
                              }}
                              sx={{
                                "& label": {
                                  marginTop: "5",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #1c2130",
                                  borderRadius: "7px",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                  "& > fieldset": {
                                    borderColor: "#1C2130",
                                  },
                                },
                                "& .MuiOutlinedInput-root:hover": {
                                  "& > fieldset": {
                                    border: "2px solid #1c2130",
                                  },
                                },
                              }}
                            />
                          </div>
                          <div className="lg:mt-[25px] mt-[10px] mb-[15px] w-full lg:w-[380px] mx-auto !h-[43.3px]">
                            <TextField
                              name="passwordConfirmation"
                              className="lg:text-[18px] placeholder:text-[16px] text-[12px] mt-1 w-full lg:w-[380px] md:h-[43px] h-[40px] px-3 py-2 bg-white border border-slate-300 placeholder-slate-900 block rounded-md sm:text-sm "
                              id="confirmPassword"
                              label="Confirm Password"
                              variant="outlined"
                              type="password"
                              value={formik.values.passwordConfirmation}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.passwordConfirmation &&
                                Boolean(formik.errors.passwordConfirmation)
                              }
                              helperText={formik.errors.passwordConfirmation}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <PasswordIcon />
                                  </InputAdornment>
                                ),
                                sx: { height: 43.3, marginTop: "5px" },
                              }}
                              InputLabelProps={{
                                style: { color: "#000000", fontSize: "16px" },
                              }}
                              sx={{
                                "& label": {
                                  marginTop: "5",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #1c2130",
                                  borderRadius: "7px",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                  "& > fieldset": {
                                    borderColor: "#1C2130",
                                  },
                                },
                                "& .MuiOutlinedInput-root:hover": {
                                  "& > fieldset": {
                                    border: "2px solid #1c2130",
                                  },
                                },
                              }}
                            />
                          </div>

                          <div className="mt-[20px] w-full lg:w-[380px] mx-auto text-center items-center md:!text-[12px] !text-[10px]">
                            <FormGroup>
                              <FormControlLabel
                                className="label:text-[14px]"
                                control={
                                  <Checkbox
                                    className="md:text-[12px] text-[10px] text-yellow-400  h-4 bg-gray-100 border-gray-300 rounded "
                                    checked={formik.values.checkbox}
                                    name="checkbox"
                                    onChange={formik.handleChange}
                                    sx={{
                                      color: "#F6921E",
                                      "&.Mui-checked": {
                                        color: "#F6921E",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <span>
                                    I agree to accept the {" "} 
                                    <Link to={"/terms-conditions"}>
                                      <span className="text-[#F6921E] font-bold">
                                        Terms and Conditions.
                                      </span>
                                    </Link>
                                  </span>
                                  }
                              />
                            </FormGroup>
                            {formik.handleChange &&
                              Boolean(formik.errors.checkbox) && (
                                <p className="mb-1 mt-1 text-red-600 text-[12px] text-left mx-[15px]">
                                  {formik.errors.checkbox}
                                </p>
                              )}

                            {error.show && error.message.name === "all" ? (
                              <p className="mb-1 mt-1 text-red-600 text-[12px] mx-[25px]">
                                {error.message.body}
                              </p>
                            ) : null}
                          </div>
                          <div className="flex flex-row justify-center w-full mt-[25x] mr-[25px]">
                              {error.show && error.type === "custom2" ? (
                            <p className="mt-6 text-red-600 text-[12px] mx-14 ">
                             {error.message}
                            </p>
                              ) : null}
                          </div>
                          <div  className="flex flex-row justify-center mt-[11px] w-full lg:w-[380px] mx-auto items-center lg:text-[14px] text-[14px]">
                            <ReCAPTCHA onTokenReceived={handleTokenUpdate}/>
                          </div>
                          <button
                            className="mt-3 mx-auto w-full lg:w-[379px] text-white bg-[#F6921E] hover:bg-[#EE8000] lg:text-[16px] font-medium rounded-lg shadow-sm !h-[43px]"
                            type="submit"
                          >
                            <span className="font-semibold text-base lg:text-[16px]">
                              Sign up
                            </span>
                          </button>
                          <div className="items-center mx-auto mt-5 md:w-[380px] w-[250px]">
                            <div className="md:w-[380px] w-[250px]">
                              <GoogleOAuthProvider
                                className="md:w-[380px] w-[250px]"
                                clientId={clientId}
                              >
                                <GoogleLogin
                                  className="md:w-[380px] w-[250px]"
                                  logginDataSet={logginDataSet}
                                />
                              </GoogleOAuthProvider>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="visible lg:invisible lg:h-0 lg:absolute lg:left-1/2 lg:-translate-x-1/2 w-full lg:w-[235px] sign_in_text">
                      <p className="cursor-pointer font-medium lg:text-[14px] lg:w-[235px] text-[10px] text-[#8F9196] text-center tracking-[0.03em] sign_in_text">
                        <Link to={"/login"}>
                          Do you have an account?{" "}
                          <span className="font-bold lg:text-[18px] text-[12px] text-[#F6921E]">
                            Sign in
                          </span>{" "}
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="invisible lg:visible lg:absolute lg:mb-3 lg:bottom-0 lg:left-1/2 lg:-translate-x-1/2 w-full lg:w-[380px] lg:h-[22px]">
                      <p className="cursor-pointer font-medium lg:text-[18px] lg:h-[22px] text-[10px] text-[#8F9196] text-center lg:w-[380px]">
                        <Link to={"/login"}>
                          Do you have an account?{" "}
                          <span className="font-bold lg:text-[18px] text-[12px] text-[#F6921E]">
                            Sign in
                          </span>
                        </Link>
                      </p>
                    </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Signup;
