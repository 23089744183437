import axios from "../api/axios";
import { logout } from "../hooks/logout";

let headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`
}

// Profile image upload
export const profileImageUpload = async (fileToUpload) => {
    const data = new FormData()
    data.append('image', fileToUpload)

    const config = { headers: { ...headers } }
    try {
        const response = await axios.patch('/users/accounts/image-upload', data, config);
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        } else {
            return { data: error };
        }
    }
}

// Organization Logo image upload
export const orgLogoImageUpload = async (fileToUpload, organizationId) => {
    const data = new FormData()
    data.append('image', fileToUpload)

    const config = { headers: { ...headers } }
    try {
        const response = await axios.patch('/organizations/' + organizationId + '/logo-upload', data, config);
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        } else {
            return { data: error };
        }
    }
}