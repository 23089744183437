import logo from "../../img/logo/Honey bee time tracker logo dark.png";
  
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import * as yup from "yup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Lottie from "lottie-react";
import animation from "../../helper/registration_animation.json";

import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import AuthContext from "../../context/authProvider";
import React, { useState } from "react";

import GoogleLogin from "./google-login/login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getMyData } from "../../services/members";
import ReCAPTCHA from "./ReCAPTCHA";

const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

function Login() {
  React.useEffect(() => {
    document.title = "HoneybeeTime-Login";
  }, []);
  
  const { setAuth } = useAuth(AuthContext);
  const [error, setError] = React.useState({});
  const [receivedToken, setReceivedToken] = useState("");

  const onSubmit = async (data) => {
    try {
      const responce = await axios.post("authentication/signin/", data);
      logginDataSet(responce?.data);
    } catch (error) {
      if (error.response.status === 401) {
        setError({
          show: true,
          type: "custom",
          message: "Invalid email or password",
        });
      }
    }
  };

  const logginDataSet = (res) => {
    try {
      let accessToken = res?.data?.accessToken;
      localStorage.setItem("tag", JSON.stringify(accessToken));
      let organizationId = res?.data?.organizationId;
      let organizationName = res?.data?.organizationName;
      localStorage.setItem("organizationID", organizationId);
      localStorage.setItem("organizationName", organizationName);

      let userId = res?.data?.user.id;
      let orgUserRole = res?.data?.firstOrganizationRole;
      let userData = JSON.stringify(res?.data?.user);
      localStorage.setItem("userId", userId);
      localStorage.setItem("orgUserRole", orgUserRole);

      setAuth(accessToken);

      getMyData(accessToken).then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data.data.user));
        localStorage.setItem("systemTimeZone", res.data.data.user.timezone);
        window.location.replace(process.env.REACT_APP_FRONTEND_HOST);
      });

    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      recaptcha: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        email: values.email,
        password: values.password,
        recaptcha: receivedToken,
      };
      if (!receivedToken) {
        setError({
          show: true,
          type: "custom2",
          message: "Please complete the reCAPTCHA first!",
        });
        return;
      }
      if((localStorage.hasOwnProperty('public-verification-tokan')) && (localStorage.getItem('public-verification-tokan') !== '' && localStorage.getItem('public-verification-tokan') !== 'null')){
        data.organizationPublicInviteToken = localStorage.getItem('public-verification-tokan')
      }
      onSubmit(data);
    },
  });

  const EmailIcon = () => {
    return (
      <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
          d="M9.29408 18.4651H13.7647V16.6418H9.29408C5.41361 16.6418 2.14114 13.3053 2.14114 9.34882C2.14114 5.39236 5.41361 2.0558 9.29408 2.0558C13.1746 2.0558 16.447 5.39236 16.447 9.34882V10.6525C16.447 11.3726 15.8122 12.0837 15.1058 12.0837C14.3995 12.0837 13.7647 11.3726 13.7647 10.6525V9.34882C13.7647 6.83273 11.7618 4.79068 9.29408 4.79068C6.82632 4.79068 4.82349 6.83273 4.82349 9.34882C4.82349 11.8649 6.82632 13.907 9.29408 13.907C10.528 13.907 11.6546 13.3965 12.4593 12.5669C13.0404 13.3782 14.0418 13.907 15.1058 13.907C16.8673 13.907 18.2353 12.4484 18.2353 10.6525V9.34882C18.2353 4.31664 14.2296 0.232544 9.29408 0.232544C4.35855 0.232544 0.352905 4.31664 0.352905 9.34882C0.352905 14.381 4.35855 18.4651 9.29408 18.4651ZM9.29408 12.0837C7.80985 12.0837 6.61173 10.8621 6.61173 9.34882C6.61173 7.83552 7.80985 6.61394 9.29408 6.61394C10.7783 6.61394 11.9764 7.83552 11.9764 9.34882C11.9764 10.8621 10.7783 12.0837 9.29408 12.0837Z"
          fill="#1C2130"
        />
      </svg>
    );
  };

  const PasswordIcon = () => {
    return (
      <svg
        width="19"
        height="25"
        viewBox="0 0 19 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 8.79071H14.8823V6.51164C14.8823 3.36652 12.3788 0.813965 9.29408 0.813965C6.20938 0.813965 3.70585 3.36652 3.70585 6.51164V8.79071H2.5882C1.35879 8.79071 0.352905 9.81629 0.352905 11.0698V22.4651C0.352905 23.7186 1.35879 24.7442 2.5882 24.7442H16C17.2294 24.7442 18.2353 23.7186 18.2353 22.4651V11.0698C18.2353 9.81629 17.2294 8.79071 16 8.79071ZM5.94114 6.51164C5.94114 4.62001 7.43879 3.09303 9.29408 3.09303C11.1494 3.09303 12.647 4.62001 12.647 6.51164V8.79071H5.94114V6.51164ZM16 22.4651H2.5882V11.0698H16V22.4651ZM9.29408 19.0465C10.5235 19.0465 11.5294 18.0209 11.5294 16.7675C11.5294 15.514 10.5235 14.4884 9.29408 14.4884C8.06467 14.4884 7.05879 15.514 7.05879 16.7675C7.05879 18.0209 8.06467 19.0465 9.29408 19.0465Z"
          fill="#1C2130"
        />
      </svg>
    );
  };

  const handleTokenUpdate = (token) => {
    setReceivedToken(token);
  };

  return (
    <>
      <div className="lg:grid lg:grid-cols-2 md:grid-cols-1 gap-4 h-full w-full overflow-x-hidden min-h-screen custom-body">
        <div className="relative lg:h-full h-0 lg:min-h-screen invisible lg:visible">
          <Lottie
            className="lg:absolute lg:h-full h-0 lg:min-h-screen lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 w-full bg-[#1C2130] m-0"
            animationData={animation}
          />
        </div>
        <div className="relative h-full lg:min-h-screen">
          <div className="lg:w-[480px] w-full p-10 lg:px-0 items-center mx-auto lg:absolute lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:mt-0 mt-[50px]">
            <div className="text-center">
              <h1 className="lg:text-[24px] text-[20px] leading-[19px] font-medium text-[#8F9196] text-center">
                Hello Again!
              </h1>
              <img
                src={logo}
                alt="logo of HoneybeeTime"
                className=" mt-[26px] mb-[15px] mx-auto lg:w-full w-2/3"
              />
              <p className="font-medium lg:text-[16px] text-[14px] text-[#8F9196] text-center tracking-[0.03em]">
                Streamline how you manage teams and your business<br />with
                HoneybeeTime
              </p>
            </div>
            {/* form */}
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col">
                <div className="mt-[20px] w-full lg:w-[380px] mx-auto !h-[43.3px]">
                  <TextField
                    name="email"
                    className="lg:text-[18px] !placeholder:text-[16px] text-[14px] mt-1 w-full lg:w-[380px] lg:mx-auto !h-[43.3px] px-3 py-2 mx-1 bg-white border-slate-300 placeholder-slate-400  block rounded-md sm:text-sm sign-in-text-field"
                    label="Email"
                    placeholder="Please enter your email"
                    variant="outlined"
                    onChange={formik.handleChange}
                    error={
                      (formik.touched.email && Boolean(formik.errors.email)) ||
                      error.show
                    }
                    helperText={formik.errors.email}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                      sx: { height: 43.3, marginTop: "5px" },
                    }}
                    InputLabelProps={{
                      style: { color: "#000000", fontSize: "16px" },
                    }}
                    sx={{
                      "& placeholder": {
                        fontSize: "16",
                      },
                      "& label": {
                        marginTop: "5",
                        fontSize: "16",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #1c2130",
                        borderRadius: "7px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          borderColor: "#1C2130",
                        },
                      },
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          border: "2px solid #1c2130",
                        },
                      },
                    }}
                  />
                </div>
                <div className="flex flex-col w-full mt-[25x] mr-[25px]">
                  {error.show && error.type === "custom" ? (
                    <p className="mt-6 text-red-600 text-[12px] mx-14 ">
                      {error.message}
                    </p>
                  ) : null}
                </div>
                <div className="mt-[30px] w-full lg:w-[380px] mx-auto !h-[43.3px]">
                  <TextField
                    name="password"
                    className="lg:text-[18px] text-[14px] placeholder:text-[16px] mt-1 w-full lg:w-[380px] !h-[43.3px] px-3 py-2 mx-1 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md sm:text-sm focus:ring-1"
                    label="Password"
                    placeholder="Please enter your password"
                    variant="outlined"
                    type="password"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    error={
                      (formik.touched.password &&
                        Boolean(formik.errors.password)) ||
                      error.show
                    }
                    helperText={formik.errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PasswordIcon />
                        </InputAdornment>
                      ),
                      sx: {
                        height: 43.3,
                        marginTop: "5px",
                      },
                    }}
                    InputLabelProps={{
                      style: { color: "#000000", fontSize: "16px" },
                    }}
                    sx={{
                      "& label": {
                        marginTop: "5",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #1c2130",
                        borderRadius: "7px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          borderColor: "#1C2130",
                        },
                      },
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          border: "2px solid #1c2130",
                        },
                      },
                    }}
                  />
                </div>

                <div className="flex flex-col w-full mt-[25px] mr-[25px]">
                  {error.show && error.type === "custom" ? (
                    <p className="mt-0 text-red-600 text-[12px]  mx-14 ">
                      {error.message}
                    </p>
                  ) : null}
                </div>
                <div className="flex flex-row justify-between mt-[11px] w-full lg:w-[380px] mx-auto items-center lg:text-[14px] text-[14px]">
                  <div className='order-first'>
                    <FormGroup className="">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="text-yellow  h-4 bg-gray-100 border-gray-300 rounded "
                            name="checkbox"
                            sx={{
                              color: "#F6921E",
                              "&.Mui-checked": {
                                color: "#F6921E",
                              },
                              mr: 0,
                            }}
                          />
                        }
                        sx={{
                          fontSize: 14,
                        }}
                        label="Remember me"
                      />
                    </FormGroup>
                  </div>
                  <div className='order-last'>
                    <Link to={'/forgot-password'}>
                      <span className="w-auto text-right text-[#F6921E] hover:text-[#EE8000]">
                        Forgot Password
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="flex flex-row justify-center w-full mt-[25x] mr-[25px]">
                  {error.show && error.type === "custom2" ? (
                    <p className="mt-6 text-red-600 text-[12px] mx-14 ">
                      {error.message}
                    </p>
                  ) : null}
                </div>
                <div  className="flex flex-row justify-center mt-[11px] w-full lg:w-[380px] mx-auto items-center lg:text-[14px] text-[14px]">
                  <ReCAPTCHA onTokenReceived={handleTokenUpdate}/>
                </div>
                <button
                  className="mt-3 mx-auto w-full lg:w-[379px] lg:h-[43px] text-white bg-[#F6921E] hover:bg-[#EE8000] focus:ring-4 focus:ring-[EE8000] font-medium rounded-lg focus:outline-none btn-shadow lg:text-[16px]"
                  type="submit"
                >
                  <span className="font-semibold md:text-base lg:text-[16px] text-[14px]">
                    Sign In
                  </span>
                </button>

                <div className="items-center mx-auto mt-5 md:w-[380px] w-[250px]">
                  <div className="md:w-[380px] w-[250px]">
                    <GoogleOAuthProvider
                      className="md:w-[380px] w-[250px]"
                      clientId={clientId}
                    >
                      <GoogleLogin
                        className="md:w-[380px] w-[250px]"
                        logginDataSet={logginDataSet}
                        clientId={clientId}
                      />
                    </GoogleOAuthProvider>
                  </div>
                </div>
              </div>
            </form>

            <div className="lg:absolute lg:left-1/2 lg:-translate-x-1/2 w-full sign_up_text lg:w-[380px] visible lg:invisible">
              <p className="cursor-pointer font-medium w-full lg:w-[380px] lg:text-[14px] text-[10px] text-[#8F9196] text-center tracking-[0.03em]">
                <Link to={"/signup"}>
                  Don't have an account yet?{" "}
                  <span className=" font-bold lg:text-[16px] text-[12px] text-[#F6921E]">
                    Sign up
                  </span>{" "}
                </Link>
              </p>
            </div>
          </div>
          <div className="lg:absolute lg:left-1/2 lg:bottom-0 invisible lg:visible lg:-translate-x-1/2 sign_up_text lg:w-[380px] mb-5">
            <p className="cursor-pointer font-medium lg:w-[380px] lg:text-[18px] text-[10px] text-[#8F9196] text-center">
              <Link to={"/signup"}>
                Don't have an account yet?{" "}
                <span className=" font-bold lg:text-[18px] text-[12px] text-[#F6921E]">
                  Sign up
                </span>{" "}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
