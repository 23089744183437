import { Fragment, useEffect } from "react";
import { NameProccess } from "../../../../../globle-component/globle";

const GroupByMembers = ({
    fetchReportData,
    setTotalDurationToShow,
    tabValue,
    totalDurationOfPeriod,
    content,
    thCount,
    formatDuration,
    formatDate
}) => {

    useEffect(() => {
        setTotalDurationToShow(totalDurationOfPeriod);
    }, [totalDurationOfPeriod])

    let project_ids = [];
    let dates_array = [];
    let isHaveDate;
    let isHaveProject;
    totalDurationOfPeriod = 0;

    totalDurationOfPeriod = 0;
    if (tabValue == 1) {
        fetchReportData.map((row) => {
            totalDurationOfPeriod = totalDurationOfPeriod + row.totalDuration;
        })
        content = fetchReportData.map((row, rowIndex) => {
            return (
                <Fragment key={Math.random() + rowIndex}>
                    <tr key={row.userId} className="border-b bg-gray-200 mt-5">
                        <th colSpan={thCount} className="px-6 py-1 font-bold text-gray-900 whitespace-nowrap">
                            <div className="flex flex-row">
                                <button className="sm:w-10 sm:h-10 w-[10px] h-[10px]">
                                    <svg
                                        width="12"
                                        height="12"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mx-auto my-2"
                                    >
                                        <path
                                            d="M8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 12C10.7 12 13.8 13.29 14 14H2C2.23 13.28 5.31 12 8 12ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </button>
                                <div className="w-auto my-auto">
                                    <h6 className="px-4 text-gray-600 font-medium static capitalize">
                                        {row.fullName}
                                    </h6>
                                </div>
                            </div>
                        </th>
                    </tr>

                    <tr key={row.totalDuration} className="bg-white border-b font-bold hover:bg-gray-50">
                        <th colSpan='3' className="px-6 py-3 text-gray-900 font-bold whitespace-nowrap">
                            Total
                        </th>
                        <th className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
                            {formatDuration(row.totalDuration)}
                        </th>
                        <th className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
                            -
                        </th>
                        <th className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
                            -
                        </th>
                    </tr>

                    {row.dates.map((date, dateIndex) => {
                        dates_array = [];
                        project_ids = [];
                        return (
                            <Fragment key={Math.random() + dateIndex}>
                                {
                                    date.projects.map((project, projectIndex) => {
                                        return (
                                            <Fragment key={Math.random() + projectIndex}>
                                                {
                                                    project.tasks.map((task, taskIndex) => {
                                                        isHaveDate = dates_array.includes(date.date);
                                                        isHaveProject = project_ids.includes(project.projectId)
                                                        if (isHaveDate === false) {
                                                            dates_array.push(date.date);
                                                        }
                                                        if (isHaveProject === false) {
                                                            project_ids.push(project.projectId)
                                                        }
                                                        return (
                                                            <Fragment key={Math.random() + taskIndex}>
                                                                <tr className="bg-white border-b font-bold hover:bg-gray-50 text-[12px] custom-body" >
                                                                    {
                                                                        isHaveDate === false
                                                                            ? (
                                                                                <th className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                                    <h5 className="px-4 text-gray-600 font-bold static my-auto">
                                                                                        {formatDate(date?.date)}
                                                                                    </h5>
                                                                                </th>
                                                                            )
                                                                            : (
                                                                                <th className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                                    <h5 className="px-4 text-gray-600 font-bold static my-auto">
                                                                                        {" "}
                                                                                    </h5>
                                                                                </th>
                                                                            )
                                                                    }

                                                                    {
                                                                        isHaveProject === false
                                                                            ? (
                                                                                <td className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                                    <h5 className="px-4 text-gray-600 font-bold static my-auto">
                                                                                        {project?.projectName}
                                                                                    </h5>
                                                                                </td>
                                                                            )
                                                                            : (
                                                                                <td className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                                    <h5 className="px-4 text-gray-600 font-bold static my-auto">
                                                                                        {" "}
                                                                                    </h5>
                                                                                </td>
                                                                            )
                                                                    }

                                                                    <td className="px-6 py-4 text-gray-600 whitespace-nowrap">
                                                                        <h5 className="text-gray-600 font-medium my-auto" title={task?.taskName}>
                                                                            {
                                                                                NameProccess(task?.taskName, 35)
                                                                            }
                                                                        </h5>
                                                                    </td>
                                                                    <td className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                        <h5 className="px-4 text-gray-600 font-bold static my-auto text-center">
                                                                            {formatDuration(task?.duration)}
                                                                        </h5>
                                                                    </td>
                                                                    <td className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                        <h5 className="px-4 text-gray-600 font-bold static my-auto text-center">
                                                                            -
                                                                        </h5>
                                                                    </td>
                                                                    <td className="px-6 py-4 text-gray-600 whitespace-nowrap">
                                                                        <h5 className={"text-gray-600 font-bold my-auto" + (task?.reason === "" && " text-center")} title={task?.reason}>
                                                                            {
                                                                                task?.reason === ""
                                                                                    ? (
                                                                                        "-"
                                                                                    )
                                                                                    : (
                                                                                        NameProccess(task?.reason, 30)
                                                                                    )
                                                                            }
                                                                        </h5>
                                                                    </td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </Fragment>
                        )
                    })}
                </Fragment>
            )
        });
    }

    return (
        <>
            {content}
        </>
    )
}

export default GroupByMembers