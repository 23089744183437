import axios from "../api/axios";
import { notifyError } from "../helper/notification-helper";
import { logout } from "../hooks/logout";

let headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`
}

// Get Reports
export const getReports = async (organizationId, startDate, endDate, groupByValue, userType, memberIDs, projectIDs, taskIDs) => {
    let currentUserRole = localStorage.getItem("orgUserRole");
    let user = "me";
    if (userType === 0 && (currentUserRole != 1 && currentUserRole != 2)) {
        user = "me";
    } else if (userType === 1 && (currentUserRole == 1 || currentUserRole == 2)){
        user = "all";
    }

    const config = { headers: { ...headers } }

    try {
        const memberQueryString = (memberIDs.length !== 0) ? `&members=${memberIDs.join('&members=')}` : '';
        const projectQueryString = (projectIDs.length !== 0) ? `&projects=${projectIDs.join('&projects=')}` : '';
        const taskQueryString = (taskIDs.length !== 0) ? `&tasks=${taskIDs.join('&tasks=')}` : '';

        const response = await axios.get(
            `/reports/${organizationId}/time-and-activity?start-date=${startDate}&end-date=${endDate}&group-by=${groupByValue}&user-type=${user}`+
            `${memberQueryString}`+
            `${projectQueryString}`+
            `${taskQueryString}`, config)
        return response;
    } catch (error) {
        return error;
    }
};