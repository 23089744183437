import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filteredActiveProjects, filteredArchivedProjects } from "../../../store/slices/projects";

const ProjectSearch = () => {
    const dispatch = useDispatch();
    const {
        activeProjectList,
        archivedProjectList,
        selectedTab
    } = useSelector((state) => state.projects)

    const [searchInputValue, setSearchInputValue] = useState(null)


    function clearSearch() {
        let input = null;
        if (selectedTab === 0) {
            input = document.getElementById("active-project-search");
            
        } else if (selectedTab === 1) {
            input = document.getElementById("archived-project-search");
        }
        dispatch(filteredActiveProjects(''))
        dispatch(filteredArchivedProjects(''))
        setSearchInputValue(null);
        input.value = null;
    }

    return (
        <>
            {selectedTab === 0 ? (
                <>
                    {Object.keys(activeProjectList).length > 0 ? (
                        <label className="project-search-lable">

                            <input
                                placeholder={'Search active projects'}
                                className="ht-input-rounded z-0"
                                id={'active-project-search'}
                                onChange={(event) => {
                                    setSearchInputValue(event.target.value)
                                    dispatch(filteredActiveProjects(event.target.value))
                                }}
                            />

                            {searchInputValue?.length > 0 ? (
                                <span className={'cursor-pointer z-50'} onClick={clearSearch}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 project-search-close">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </span>
                            ) : null}

                        </label>
                    ) : <>
                        <label className="project-search-lable">
                            <input
                                placeholder={'Search active projects'}
                                className="ht-input-rounded disabled"
                                style={{ background: '#ededed' }}
                                disabled
                            />
                        </label>
                    </>
                    }
                </>
            ) : selectedTab === 1 ? (
                <>
                    {Object.keys(archivedProjectList).length > 0 ? (
                        <label className="project-search-lable">
                            <input
                                placeholder={'Search archived projects'}
                                className="ht-input-rounded"
                                id={'archived-project-search'}
                                onChange={(event) => {
                                    setSearchInputValue(event.target.value)
                                    dispatch(filteredArchivedProjects(event.target.value))
                                }}
                            />

                            {searchInputValue?.length > 0 ? (
                                <span className={'cursor-pointer z-50'} onClick={clearSearch}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 project-search-close">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </span>
                            ) : null}

                        </label>
                    ) : <>
                        <label className="project-search-lable">
                            <input
                                placeholder={'Search archived projects'}
                                className="ht-input-rounded disabled"
                                style={{ background: '#ededed' }}
                                disabled
                            />
                        </label>
                    </>
                    }
                </>
            ) : null}
        </>
    );

}

export default ProjectSearch;