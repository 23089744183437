import moment from "moment";
import { NameProccess, secondsToHours } from "../../../component/globle-component/globle";

const WeeklyView = ({
  weekDays,
  setWeekDays,
  weekReportArray,
  totalTimeForAllProjects,
}) => {
  let today = new Date();

  let dateList = weekDays.map((day, index) => (
    <th
      key={index}
      scope="col"
      className={
        moment(today).format("YYYY-MM-DD") === moment(day).format("YYYY-MM-DD")
          ? "lg:px-6 lg:py-5 px-3 py-2 text-center border-l bg-yellow-300"
          : "lg:px-6 lg:py-5 px-3 py-2 border-l text-center mx-auto"
      }
    >
      <div className="flex flex-row justify-center my-auto">
        <div className="xl:text-[30px] lg:text-[26px] md:text-[18px] text-[14px] md:py-2 py-1 font-medium text-center">
          {moment(day).format("DD")}
        </div>
        <div className="flex flex-col">
          <div className="xl:text-[12px] lg:text-[10px] md:text-[8px] text-[6px] font-semibold ">
            {moment(day).format("MM")}
          </div>
          <div className="xl:text-[11px] lg:text-[9px] md:text-[7px] text-[5px] font-light leading-none ">
            {moment(day).format("MMM")}
          </div>
        </div>
      </div>
    </th>
  ));

  let reportList = weekReportArray.map((report, index) => (
    <tr key={index} className="bg-white ">
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
      >
        {NameProccess(report.projectName, 30)}
        {report.taskName != null ? (
          <div className="text-[11px] md:text-xs text-gray-500" title={report.taskName}>
            {NameProccess(report.taskName, 25)}
          </div>
        ) : null}
      </th>
      {report.weekDayTimeArray.map((timeReport, trindex) => (
        <td key={trindex} className="px-6 py-4 text-center border ">
          {timeReport === null ? (
            <>-</>
          ) : (
            <>{moment.utc(timeReport * 1000).format("HH:mm:ss")}</>
          )}
        </td>
      ))}
      <td className="px-6 py-4 text-center border ">
        {report.totalTimeForWeek === 0 ? (
          <> - </>
        ) : (
          moment.utc(report.totalTimeForWeek * 1000).format("HH:mm:ss")
        )}
      </td>
    </tr>
  ));

  let todatotalTime = 0;
  for (const element of totalTimeForAllProjects) {
    todatotalTime = todatotalTime + element.secondsCount;
  }

  let allProjectTimes = totalTimeForAllProjects.map((timeObbject, index) => (
    <td key={index} className="px-6 py-4 text-center border ">
      {timeObbject.secondsCount === 0 ? (
        <>-</>
      ) : (
        secondsToHours(timeObbject.secondsCount)
      )}
    </td>
  ));

  return (
    <>
      <div className="overflow-x-auto py-3">
        <table className="w-full lg:text-sm text-xs text-left text-gray-500  border relative overflow-x-auto table-fixed">
          <thead className="lg:text-sm text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr className="border ">
              <th scope="col" className="lg:px-6 lg:py-3 px-3 py-1 w-[250px]">
                Project name
              </th>
              {dateList}
              <th
                scope="col"
                className="lg:px-6 lg:py-3 px-3 py-1 border-l text-center"
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {reportList}
            <tr className="bg-white  font-bold">
              <th
                scope="row"
                className="px-6 py-4 font-medium md:text-sm text-sm text-gray-900 whitespace-nowrap  "
              >
                {weekReportArray.length === 0 &&
                  totalTimeForAllProjects.length === 0 &&
                  todatotalTime === 0 ? (
                  <>No projects</>
                ) : (
                  <>All projects</>
                )}
              </th>
              {allProjectTimes}

              <td className="px-6 py-4 text-center border text-yellow-600">
                {secondsToHours(todatotalTime)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WeeklyView;
