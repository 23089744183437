import { Dialog, Transition } from "@headlessui/react";
import { Fragment} from "react";
import ImageUploader from "../../globle-component/imageUploader/imageUploader";

const ImageUpload = ({ imageUploadModalStatus, setimageUploadModalStatus, CustomTextfieldComponent, originImg, setImageUploadStatus }) => {

    return (
        <>
            {imageUploadModalStatus ? (
                <>
                    <Transition appear show={imageUploadModalStatus} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/60"
                            onClose={() => { }}

                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>
                                <span
                                    className=" h-screen  align-top"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="modal-loading inline-block w-full max-w-lg p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded ">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900 pb-2 border-b border-solid border-slate-200"
                                        >
                                            Image Upload
                                            <span className=' right cursor-pointer'
                                                onClick={() => {
                                                    setimageUploadModalStatus(false);
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </span>
                                        </Dialog.Title>
                                        <div className="">
                                            <img alt="avatar" id="user_avatar" className="avatar avatar-full" src={originImg} />
                                            <div className={'mt-3'}>
                                                <ImageUploader
                                                    setimageUploadModalStatus={setimageUploadModalStatus}
                                                    setImageUploadStatus={setImageUploadStatus}
                                                    type={'profile'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : null}
        </>
    );
}

export default ImageUpload;