import moment from "moment";
import { DATETIMES } from "../../../constant/datetimes";
import { ImageLightboxForSingle } from "./imageLightBox";
import ColorSelector from "../../../helper/colorSelector";
import { useState } from "react";
import { Triangle } from "react-loader-spinner";

const SingleCard = ({ screen }) => {

    let noThumbImage = process.env.REACT_APP_S3_IMAGE_HOST + process.env.REACT_APP_S3_IMAGE_FORLDER_PATH + `no-image.` + process.env.REACT_APP_S3_IMAGE_TYPE;
    const [thumbnailValidity, setThumbnailValidity] = useState(false)
    const [imageChecked, setImageChecked] = useState(false)

    var img = new Image();
    img.onload = function () {
        // console.log("image is loaded");
        setThumbnailValidity(true);
        setImageChecked(true)
    }
    img.onerror = () => {
        // console.log("image is Not loaded");
        setThumbnailValidity(false);
        setImageChecked(true)
    };
    img.src = screen?.thumbnailUrl;

    let projectColor = ColorSelector(screen.projectId);
    return (
        <>
            <div className="card w-full m-3 p-3">
                <div className="rounded-full text-center mb-4 text-sm py-1 text-white" style={{ background: `${projectColor}` }}>{screen.projectName}</div>
                <div className="border border-slate-300">
                    <div className="relative overflow-hidden screenshout-count-lable">
                        {
                            imageChecked === false && thumbnailValidity === false ? (
                                <div className="flex justify-center">
                                    <Triangle
                                        height="40"
                                        width="40"
                                        color="#ffb30f"
                                        ariaLabel="triangle-loading"
                                        wrapperStyle={{ 'margin': '47px' }}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                            ) : imageChecked === true && thumbnailValidity === false ? (
                                <img className="object-cover w-full h-full rounded-sm  h-[136px]" src={noThumbImage} alt="" />
                            ) : imageChecked === true && thumbnailValidity === true ? (
                                <img className="object-cover w-full h-full rounded-sm  h-[136px]" src={img.src} alt="" />
                            ) : null
                        }
                        <ImageLightboxForSingle
                            screen={screen}
                            visibility={thumbnailValidity}
                        />
                    </div>
                    <div className="p-2">
                        <div className="flex text-xs">

                            <div className="w-11/12 font-blod  test-xs">{moment.utc(screen.actualTime, "hh:mm:ss").local().format(DATETIMES.time)}</div>
                            <div className="w-1/12 text-right cursor-pointer hover:text-yellow-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 m-auto item-end ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                </svg>
                            </div>
                        </div>
                        {screen.taskName ? (
                            <div className="text-xs mt-2 text-gray-400">{screen.taskName}</div>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleCard;