import React from "react";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { logout } from "../../../hooks/logout";
import ReactPaginate from "react-paginate";
import Notiflix from "notiflix";
import { Line } from "rc-progress";
import ColorSelector from "../../../helper/colorSelector";

const localTag = JSON.parse(localStorage.getItem("tag"));

var isTaskActive = false;

let localOrganizationID = null;
function getOrgId() {
  localOrganizationID = JSON.parse(localStorage.getItem("organizationID"));
}

const TableData = ({ props, loading }) =>
  props.map((data) => {
    let time = data.duration.split(":");
    let seconds = +time[0] * 60 * 60 + +time[1] * 60 + +time[2];
    let precentage = (seconds / 3600) * 100;
    let color = ColorSelector(data.project.id);
    if (loading) {
      return (
        <tr key={data.id}>
          <td></td>
        </tr>
      );
    } else {
      return (
        <tr className="border-b border-1 " key={data.id} >
          <td className="py-3 px-3 flex flex-row">
            <button
              className={"rounded-full !w-[32px] !h-[32px] mx-1 my-auto "}
              style={{ backgroundColor: color }}
            >
              <p className="text-white font-bold">
                {data.title[0].toUpperCase()}
              </p>
            </button>
            <div className="sm:mx-3 mx-1 my-2">
              <h5 className="text-gray-800 font-normal w-fulltext-ellipsis overflow-hidden ...">
                {data.title}
              </h5>
            </div>
          </td>
          <td className="text-center w-10">
            {data.duration}
          </td>
          <td className="w-20 px-2">
            <Line
              percent={precentage}
              strokeWidth={5}
              strokeColor="black"
              trailWidth={5}
            />
          </td>
        </tr>
      );
    }
  });

function Task({ numberOfTasks }) {
  function loadingShow() {
    Notiflix.Block.pulse(".element");
    setLoading(true);
  }
  function loadingHide() {
    Notiflix.Block.remove(".element", 1000);
    setLoading(false);
  }
  let [loading, setLoading] = useState(true);
  let [taskData, setTaskData] = useState([]);
  useEffect(() => {
    loadingShow();
    axios
      .get("/tasks/all?organization_id=" + localOrganizationID, {
        headers: {
          Authorization: "Bearer " + localTag,
          organization_id: JSON.parse(localStorage.getItem("organizationID")),
        },
      })
      .then((res) => {
        setTaskData(Array.from(res.data.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          logout();
        }
      });
  }, []);
  useEffect(() => {
    if (taskData.length === 0) {
      loadingHide();
    }
  }, [taskData]);
  getOrgId();
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + numberOfTasks;
  const currentItems = taskData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(taskData.length / numberOfTasks);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * numberOfTasks) % taskData.length;
    setItemOffset(newOffset);
  };
  if (Object.keys(taskData).length > 0) {
    return (
      <>
        <div className="rounded bg-white min-h-[44vh]">
          <div className=" flex flex-col ">
            <div className="flex flex-row justify-between py-3">
              <div className="w-96 mx-4 order-first">
                <h1 className=" text-gray-500 tile-header px-4 my-1 uppercase">
                  Tasks
                </h1>
              </div>
            </div>
            <div className="lg:overflow-x-hidden overflow-x-auto px-2">
              <table className="table-auto relative lg:overflow-x-hidden overflow-x-auto w-full mx-2">
                <thead>
                  <tr className="text-left text-gray-900 font-semibold border-b border-1">
                    <th className="sm:px-4 px-2 py-2  lg:w-[300px]">
                      Tasks info
                    </th>
                    <th className="px-8  text-center lg:w-[100px]">
                      Time
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  <TableData props={currentItems} loading={loading} />
                </tbody>
              </table>
            </div>
            <div className="flex flex-wrap">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                nextClassName="hover:bg-gray-700 hover:text-white sm:text-gray-800 px-1.5 font-bold rounded mr-7"
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                previousClassName="hover:bg-gray-700 hover:text-white sm:text-gray-800 px-1.5 font-bold rounded ml-7"
                renderOnZeroPageCount={null}
                containerClassName="flex flex-row justify-center items-center w-full mx-auto my-3 "
                pageLinkClassName="hover:bg-gray-700 hover:text-white text-gray-800 px-1.5 rounded"
                activeLinkClassName="text-gray-800 sm:font-extrabold rounded"
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="rounded bg-white flex flex-col min-h-[42vh] ">
          <div className="flex flex-row   py-3">
            <div className="w-96 mx-4 order-first">
              <h1 className="text-gray-500 tile-header px-4 my-1 uppercase">
                Tasks
              </h1>
            </div>

          </div>
          <div className="lg:overflow-x-hidden overflow-x-auto px-2">
          <p className="text-gray-400 text-center mx-6 py-10">
                    {" "}
                    No task available yet.
                  </p>
          </div>
        </div>
      </>
    );
  }
}
export default Task;
