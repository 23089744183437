import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";

export function Dropdown({ listData, multiselect, placeholder, selectedItems, setSelectedItems, descriptionShow, validation = null, disabled = null }) {
    const [inputFocusStatus, setInputFocusStatus] = useState(false)
    const [query, setQuery] = useState('')
    const filteredData =
        query === ''
            ? listData
            : listData.filter((item) =>
                item.displayName
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )
    function onSelect(item) {
        setSelectedItems(item);
    }

    return (
        <>
            <Combobox
                value={selectedItems}
                multiple={multiselect}
                onChange={(item) => {
                    onSelect(item)
                }}
                disabled={disabled === true}
            >
                {multiselect ? (
                    <div className='my-0 flex flex-wrap -m-1'>
                        {selectedItems.map((item) => (
                            <span
                                className="ht-selected-label-orange"
                                key={item.id + Math.random()}
                                title={item.full_name ?? item.name}
                            >
                                {item.displayName}
                            </span>
                        ))}
                    </div>
                ) : null}

                <div className="relative">

                    <Combobox.Button className={`${inputFocusStatus === true ? 'dropdown-input-focus' : 'dropdown-input'} ${validation === false ? 'modal-input-danger':''}  ${disabled === true ? 'bg-gray-200 disabled':''}`}>
                        <Combobox.Input
                            className={`w-full focus:outline-none border-none py-1 ${disabled === true ? 'disabled':''}`}
                            autoComplete='off'
                            placeholder={placeholder}
                            displayValue={(item) => item?.displayName}
                            onChange={(event) => {
                                setQuery(event.target.value)
                            }}
                        />

                    </Combobox.Button>

                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => {
                            setInputFocusStatus(false)
                            setQuery('')
                        }}
                        afterEnter={() => {
                            setInputFocusStatus(true)
                        }}
                    >

                        <Combobox.Options className="absolute max-h-80 w-full overflow-auto rounded-md bg-white text-base shadow-lg dropdown-list z-50">
                            {filteredData.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                </div>
                            ) : (
                                filteredData.map((item) => (
                                    <Combobox.Option
                                        key={Math.random()}
                                        className={({ selected }) =>
                                            `relative cursor-default select-none py-2 pl-4 pr-10 hover:cursor-pointer hover:bg-[#FADE4B] hover:text-black ${selected ? 'bg-[#ffee96] text-black' : 'text-gray-900'
                                            }`
                                        }
                                        value={item}
                                        title={item.full_name}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <div>
                                                    <div className="text-sm">{item.displayName}</div>
                                                    {descriptionShow === true && item.description !== undefined ? (
                                                        <div className="text-xsm text-gray-600">{item.description}</div>
                                                    ) : null}
                                                </div>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 right-2 flex items-center pl-3 ${selected ? 'text-black' : ''
                                                            }`}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}

                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>

                    </Transition>
                </div>
            </Combobox>
        </>
    );
}
