
export const NoRecordsAlert = ({thCount}) => {
    return (
        <tr className="bg-white border-b text-center">
            <td colSpan={thCount} className="px-6 py-4 font-medium text-gray-400 whitespace-nowrap">
                No records found!
            </td>
        </tr>
    )
}
