import avatar from '../../../img/avatar/avatar.png';

import React from 'react';
import { Menu, Transition } from '@headlessui/react'
import axios from '../../../api/axios';
import { Fragment, useState, useEffect } from 'react';
import { getAllOrganizations } from '../../../services/organizations';
import { Link } from 'react-router-dom';
import { NameProccess } from '../../globle-component/globle';
import useAuth from '../../../hooks/useAuth';
import AuthContext from '../../../context/authProvider';

function Navbar() {

    const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
    const [isUserOpen, setIsUserOpen] = React.useState(false);
    const [organizationList, setOrganizationList] = useState([])
    const { setUserRole, setAuthUser } = useAuth(AuthContext);

    const signOut = () => {
        localStorage.clear();
        window.location.reload();
    };

    function changeOrgId(data) {
        localStorage.setItem('organizationID', data.organization.id);
        localStorage.setItem('organizationName', data.organization.name);
        localStorage.setItem('orgUserRole', data.role);
        setUserRole(JSON.parse(data.role))
        window.location.reload();
    }

    const [userData, setUserData] = React.useState();
    useEffect(() => {
        if (!localStorage.hasOwnProperty("user") || localStorage.getItem("user") === "[]" || localStorage.getItem("user") === "") {

            const headers = {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`
            }
            const GetUsersData = async () => {
                try {
                    const response = await axios.get('/users/' + localStorage.getItem('userId'), { headers })
                    return response.data;
                } catch (error) {
                    return { data: [] };
                }
            };

            GetUsersData().then(({ data }) => {
                if (data != []) {
                    localStorage.setItem('user', JSON.stringify(data));
                    setUserData(data)
                }
            });
        } else {
            const user = JSON.parse(localStorage.getItem('user'));
            setUserData(user)
        }

        if (
            !localStorage.hasOwnProperty("orgUserRole") ||
            (localStorage.hasOwnProperty("orgUserRole") && (localStorage.getItem("orgUserRole") === "[]" || localStorage.getItem("orgUserRole") === ""))
        ) {
            const headers = {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`,
                organization_id: localStorage.getItem('organizationID')
            }
            const GetUsersData = async () => {
                try {
                    const response = await axios.get('/organizations/' + localStorage.getItem('organizationID'), { headers })
                    return response.data;
                } catch (error) {
                    return { data: [] };
                }
            };

            GetUsersData().then(({ data }) => {
                if (data != []) {
                    localStorage.setItem('orgUserRole', JSON.stringify(data.user.role));
                    setUserData(data)
                }
            });
        } else {
            const user = JSON.parse(localStorage.getItem('user'));
            setUserData(user)
        }
        setUserRole(JSON.parse(localStorage.getItem('orgUserRole')))
        setAuthUser(JSON.parse(localStorage.getItem('user')))
    }, []);

    // Get Org Data
    function ReloadOrganizationData() {
        getAllOrganizations().then(({ data }) => {
            for (const orgObj of data) {
                orgObj.organization.displayName = NameProccess(orgObj.organization.name, 10)
            }
            setOrganizationList(data);
        });
    }


    function setCurrentOrgData() {
        const newArray = organizationList.filter(org => org.organizationId === JSON.parse(localStorage.getItem('organizationID')));
        localStorage.setItem('organizationPublicInviteToken', newArray[0].organization.organizationPublicInviteToken);
    }

    useEffect(() => {
      if(organizationList.length > 0){
        setCurrentOrgData();
      }
    }, [organizationList])
    
    useEffect(() => {
        ReloadOrganizationData()
    }, []);

    return (
        <nav className="fix mx-auto navbar w-full inset-x-0 top-0">
            <div className="flex justify-end py-3 mr-px bg-white z-50 pr-3">

                <Menu as="div" className="relative inline-block text-left mt-1 px-2 border-r-2 border-gray-100">
                    <div>
                        <Menu.Button className="flex rounded-full my-auto   ">
                            <img src={avatar} className='rounded-full mx-auto avatar my-auto w-[40px] h-[40px]' alt="user" />

                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-4 h-4 my-4 mx-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg> */}


                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute z-50 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-300 mx-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1 cursor-pointer navbar-menu">
                                <Link to='/account' target={'_blank'}>
                                    <button className={`group flex w-full items-center rounded-md px-2 py-2 pl-7`} >
                                        <h2 className=" font-medium my-auto">My Account</h2>
                                    </button>
                                </Link>
                            </div>
                            <div className="px-1 py-1">
                                <div className='group flex w-full items-center rounded-md px-2 py-0 text-bold ml-5'>{userData?.full_name}</div>
                                <div className='group flex w-full items-center rounded-md px-2 py-0 text-gray-600 ml-5'>{userData?.email}</div>
                            </div>
                            <div className="px-1 py-1 devider divide-y-4 cursor-pointer navbar-menu">
                                <div onClick={signOut} className={`group flex w-full items-center rounded-md px-2 py-2 pl-7`} >
                                    <h2 className=" font-medium my-auto">Sign Out</h2>
                                </div>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>

                <Menu as="div" className="relative inline-block text-left mt-1">
                    <div>
                        <Menu.Button className="flex rounded-full my-auto   ">
                            <div className={"border-2 rounded-full w-8 h-8 mx-2 py-0.5 my-auto"}>
                                <p className="font-bold">{localStorage.getItem('organizationName')[0].toUpperCase()}</p>

                            </div>
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute z-50 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-300 mx-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className='org-menu-container'>
                                {organizationList.map((organizationObject, organizationObjectIdx) => (
                                    <div className="p-3 border-none org-item" key={organizationObjectIdx} onClick={() => {
                                        changeOrgId(organizationObject);
                                    }}>
                                        <div className={`group flex w-full items-center rounded-md px-2 py-0 text-bold ml-5 ${organizationObject.organization?.name == localStorage.getItem('organizationName') ? 'text-blue-400 font-bold' : ''}`}>{organizationObject.organization?.displayName}</div>
                                    </div>
                                ))}
                            </div>
                            <div className="devider divide-y-4 p-3 cursor-pointer">
                                <Link to='/Organizations'>
                                    <div className='px-2 ml-5'>
                                        Organization Menu
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 right">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>

                                    </div>
                                </Link>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </nav >
    );
}

export default Navbar;