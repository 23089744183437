import { Fragment, useEffect } from 'react';
import ColorSelector from '../../../../../../helper/colorSelector';
import { NameProccess } from '../../../../../globle-component/globle';
import moment from 'moment-timezone';

const GrpByDate = ({
  fetchReportData,
  setTotalDurationToShow,
  tabValue,
  totalDurationOfPeriod,
  content,
  thCount,
  formatDuration,
  formatDate
}) => {

  useEffect(() => {
    setTotalDurationToShow(totalDurationOfPeriod);
  }, [totalDurationOfPeriod])

  const systemTimeZone = localStorage.getItem("systemTimeZone");
  let project_ids = [];
  let isHaveProject;
  totalDurationOfPeriod = 0;
  if (tabValue == 1) {
    fetchReportData.map((row) => {
      totalDurationOfPeriod = totalDurationOfPeriod + row.totalDuration;
    })
    content = fetchReportData.map((row, rowIndex) => {
      return (
        <Fragment key={Math.random() + rowIndex}>
          <tr className="border-b bg-gray-200 mt-5">
            <th colSpan={thCount} className="px-6 py-3 font-bold text-gray-900 whitespace-nowrap">
              {formatDate(row.date)}
            </th>
          </tr>

          <tr className="bg-white border-b font-bold hover:bg-gray-50">
            <th colSpan='4' className="px-6 py-3 text-gray-900 font-bold whitespace-nowrap">
              Total
            </th>
            <th className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
              {formatDuration(row.totalDuration)}
            </th>
            <th className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
              -
            </th>
            <th className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
              -
            </th>
          </tr>

          {row.projects.map((project, projectIndex) => {
            project_ids = [];
            return (
              <Fragment key={Math.random() + projectIndex} >
                {
                  project.users.map((user, userIndex) => (
                    <Fragment key={Math.random() + userIndex}>
                      {user.tasks.map((slot, slotIndex) => {
                        isHaveProject = project_ids.includes(project.projectId);
                        if (isHaveProject == false) {
                          project_ids.push(project.projectId);
                        }
                        return (
                          <tr key={Math.random() + slotIndex} className="bg-white border-b font-bold hover:bg-gray-50 text-[12px] custom-body" >

                            <th className="px-6 py-3 text-gray-600 whitespace-nowrap" >
                              {isHaveProject == false ? (
                                <div className='flex flex-row my-auto'>
                                  <>
                                    {/* <div
                                          className={"rounded-full w-10 h-10 bg-green-700 flex"}
                                          style={{ backgroundColor: ColorSelector(project.projectId) }}
                                      >
                                          <p className="text-white md:text-sm text-xs font-bold mx-auto my-auto">
                                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                                              </svg>

                                          </p>
                                      </div> */}
                                    <div className="w-auto my-auto">
                                      <h5 className="px-4 text-gray-600 font-bold static my-auto">
                                        {project?.projectName}
                                      </h5>
                                    </div>
                                  </>
                                </div>
                              )
                                : <div></div>}
                            </th>
                            <td className="px-3 py-3 text-gray-600 whitespace-nowrap text-left" >
                              {(slotIndex === 0) ?
                                <div className="flex flex-row ml-4">
                                  <button className="sm:w-10 sm:h-10 w-[10px] h-[10px]">
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="mx-auto my-2"
                                    >
                                      <path
                                        d="M8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 12C10.7 12 13.8 13.29 14 14H2C2.23 13.28 5.31 12 8 12ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </button>
                                  <div className="w-auto my-auto">
                                    <h6 className="px-4 text-gray-600 font-medium static" title={user.fullName}>
                                      {NameProccess(user.fullName, 15)}
                                    </h6>
                                  </div>
                                </div>
                                : <div></div>}
                            </td>
                            <td className="px-6 py-3 text-gray-600 whitespace-nowrap font-medium text-xs mr-2" >
                              <div title='user email | timezone | date crated'>
                                {user.info.email}<br />
                                {user.info.timeZone}<br />
                                {moment.utc(user.info.createdAt).tz(systemTimeZone).format('YYYY-MM-DD')}
                              </div>
                            </td>
                            <td className="px-6 py-4 text-gray-600 whitespace-nowrap font-medium w-[250px] " title={slot.taskName} >
                              {
                                slot.taskName === null
                                  ? (
                                    slot.taskName
                                  )
                                  : (
                                    NameProccess(slot.taskName, 30)
                                  )
                              }
                            </td>
                            <td className="px-6 py-3 text-gray-600 whitespace-nowrap font-medium text-center">
                              {formatDuration(slot.duration)}
                            </td>
                            <td className="px-6 py-3 text-gray-600 whitespace-nowrap font-medium text-center">
                              -
                            </td>
                            <td className={"px-6 py-3 text-gray-600 whitespace-nowrap font-medium w-[250px]" + (slot?.reason === "" && " text-center")}
                              title={slot.reason}
                            >
                              {
                                slot?.reason === ""
                                  ? (
                                    "-"
                                  )
                                  : (NameProccess(slot?.reason, 30))
                              }
                            </td>
                          </tr>
                        )
                      })}
                    </Fragment>
                  ))
                }
              </Fragment>
            )

          })}
        </Fragment >
      )
    });
  } else {
    fetchReportData.map((row) => {
      totalDurationOfPeriod = totalDurationOfPeriod + row.totalDuration;
    })
    content = fetchReportData.map((row, rowIndex) => (
      <Fragment key={Math.random() + rowIndex}>

        <tr className="border-b bg-gray-200">
          <td colSpan={thCount} className="px-6 py-3 font-bold text-gray-900 whitespace-nowrap">
            {formatDate(row.date)}
          </td>
        </tr>

        <tr className="bg-white border-b font-bold hover:bg-gray-50">
          <td colSpan='2' className="px-6 py-3 text-gray-900 font-bold whitespace-nowrap">
            Total
          </td>
          <td className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
            {formatDuration(row.totalDuration)}
          </td>
          <td className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
            -
          </td>
          <td className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
            -
          </td>
        </tr>

        {row.projects.map((project, projectIndex) => {
          project_ids = [];
          return (
            <Fragment key={Math.random() + projectIndex}>
              {project.users.map((user, userIndex) => (
                <Fragment key={Math.random() + userIndex}>
                  {user.tasks.map((slot, slotIndex) => {
                    isHaveProject = project_ids.includes(project.projectId);
                    if (isHaveProject == false) {
                      project_ids.push(project.projectId);
                    }
                    return (
                      <tr className="bg-white border-b hover:bg-gray-50 text-[12px]" key={Math.random() + slotIndex}>

                        <td key={project.projectId} className="px-6 py-3 text-gray-600 whitespace-nowrap">
                          {(isHaveProject == false) ?
                            <div className='flex flex-row my-auto'>
                              <>
                                <span
                                  className={"rounded-full w-10 h-10 bg-green-700 flex"}
                                  style={{ backgroundColor: ColorSelector(project.projectId) }}
                                >
                                  <p className="text-white md:text-sm text-xs font-bold mx-auto my-auto">
                                    {/* {project?.projectName[0].toUpperCase()} */}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                                    </svg>

                                  </p>
                                </span>
                                <div className="w-auto my-auto">
                                  <h5 className="px-4 text-gray-800 font-bold static my-auto">
                                    {project.projectName}
                                  </h5>
                                </div>
                              </>
                            </div>
                            : <div></div>}
                        </td>
                        <td className="px-6 py-4 text-gray-600 whitespace-nowrap font-medium break-words w-[250px]" title={slot.taskName}>
                          {
                            slot.taskName === null
                              ? (
                                slot.taskName
                              )
                              : (
                                NameProccess(slot.taskName, 30)
                              )
                          }
                        </td>
                        <td className="px-6 py-3 text-gray-600 whitespace-nowrap font-medium text-center">
                          {formatDuration(slot.duration)}
                        </td>
                        <td className="px-6 py-3 text-gray-600 whitespace-nowrap font-medium text-center">
                          -
                        </td>
                        <td className={"px-6 py-3 text-gray-600 whitespace-nowrap font-medium break-words w-[250px]" + (slot?.reason === "" && " text-center")} title={slot.reason}>
                          {
                            slot?.reason === ""
                              ? (
                                "-"
                              )
                              : (NameProccess(slot?.reason, 30))
                          }
                        </td>
                      </tr>
                    )
                  })}
                </Fragment>
              ))
              }
            </Fragment>
          )
        })}

      </Fragment>
    ));
  }

  return (
    <>
      {content}
    </>
  )
}

export default GrpByDate