import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWorkTimes } from "../../../services/worktimes";
import { addDays } from "date-fns";

const today = new Date()

const initialState = {
  screenType: 0,
  selectedUser: null,
};

export const fetchWorktimeData = createAsyncThunk(
  "worktimes/fetchWorktimeData",
  async (_, thunkApi) => {
    try {
      const { filters } = thunkApi.getState();
      const organizationId = JSON.parse(localStorage.getItem("organizationID"));

      const fetchedData = await getWorkTimes(
        organizationId,
        filters.filterParams
      );

      return fetchedData;
    } catch (error) {
      console.log("error - ", error);
    }
  }
);

export const worktimesSlice = createSlice({
  name: "worktime",
  initialState,
  reducers: {
    setScreenType: (state, action) => {
      state.screenType = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorktimeData.pending, (state, action) => {
      state.isLoading = true;
      state.worktimeData = [];
    });

    builder.addCase(fetchWorktimeData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.worktimeData = action.payload.data;
    });

    builder.addCase(fetchWorktimeData.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setScreenType, setSelectedUser } = worktimesSlice.actions;

export default worktimesSlice.reducer;
