import { useState, useEffect } from "react";
import axios from "../../../api/axios";
import { logout } from "../../../hooks/logout";
import ReactPaginate from "react-paginate";
import Notiflix from "notiflix";
import moment from "moment";
import { Line } from "rc-progress";
import ColorSelector from "../../../helper/colorSelector";
import { NameProccess } from "../../globle-component/globle";

const localTag = JSON.parse(localStorage.getItem("tag"));
let localOrganizationID = null;
function getOrgId() {
  localOrganizationID = JSON.parse(localStorage.getItem("organizationID"));
}

const TableData = ({ props }) =>
  props.map((data) => {
    let date = new Date(data.start_date).toString();
    let color = ColorSelector(data.id);
    return (
      <tr className="text-left border-b border-1" key={data.id}>
        <td className="py-[15px] relative mr-0 h-18 flex flex-row ">
          <div className="flex flex-row ml-4">
            <button
              className="rounded-full w-[32px] h-[32px]"
              style={{ backgroundColor: color }}
            >
              <p className="text-white font-bold  ">
                {data.displayName[0].toUpperCase()}
              </p>
            </button>
          </div>
          <h5 className="px-4 text-slate-900 font-semibold my-2.5 ">
            {data.displayName}
          </h5>
        </td>
        <td className="text-center ">
          {moment(date).format("YYYY-MM-DD ")}
        </td>
        <td className="py-3.5 text-center px-2 w-20">
          <Line
            percent={0}
            strokeWidth={5}
            strokeColor="black"
            trailWidth={5}
          />
        </td>
      </tr>
    );
  });

function Projects({ numberOfProjects }) {
  getOrgId();

  let [loading, setLoading] = useState(true);
  function loadingShow() {
    Notiflix.Block.pulse(".element");
    setLoading(true);
  }

  function loadingHide() {
    Notiflix.Block.remove(".element", 1000);
    setLoading(false);
  }

  let [projectData, setProjectData] = useState([]);

  const config = {
    headers: {
      Authorization: "Bearer " + localTag,
      organization_id: localOrganizationID,
    },
  };

  useEffect(() => {
    loadingShow();
    axios
      .get(
        "/projects/?organization_id=" + localOrganizationID + "&status=1",
        config
      )
      .then((res) => {
        var data = res?.data?.data?.projects;
        for (const project of data) {
          project.displayName = NameProccess(project.name, 15);
        }
        setProjectData(data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          logout();
        }
      });
  }, []);

  //set default project
  useEffect(() => {
    axios
      .get(
        "/projects/?organization_id=" + localOrganizationID + "&status=1",
        config
      )
      .then((res) => {
        if (res?.data?.data?.projects.length > 0) {
          localStorage.setItem(
            "defaultProjectID",
            res?.data?.data?.projects[0]?.id
          );
        } else {
          localStorage.setItem("defaultProjectID", "null");
        }
      })
      .catch((err) => { });
  }, []);

  useEffect(() => {
    if (projectData.length === 0) {
      loadingHide();
    }
  }, [projectData]);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + numberOfProjects;
  const currentItems = projectData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(projectData.length / numberOfProjects);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * numberOfProjects) % projectData.length;
    setItemOffset(newOffset);
  };

  if (Object.keys(projectData).length > 0) {
    return (
      <>
        <div className=" rounded flex flex-col bg-white min-h-[42vh]">
          <div className="flex flex-row justify-between pt-3.5 ">
            <div className="w-96 mx-4 order-first">
              <h1 className=" text-gray-500 tile-header uppercase px-4">
                Projects
              </h1>
            </div>
          </div>
          <div className="lg:overflow-x-hidden overflow-x-auto w-full px-2">
            <table className="relative lg:overflow-x-hidden overflow-x-auto w-full mx-2">
              <thead>
                <tr className="text-left text-gray-900 font-semibold border-b border-1">
                  <th className="pl-4 py-[5px] lg:w-[300px]">
                    Project
                  </th>
                  <th className=" text-center lg:w-[80px]">
                    Start date
                  </th>
                </tr>
              </thead>
              <tbody className="">
                <TableData props={currentItems} />
                <tr></tr>
              </tbody>
            </table>
          </div>
          <div className="flex flex-wrap">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              nextClassName="hover:bg-gray-700 hover:text-white sm:text-gray-800 px-1.5 font-bold rounded mr-7"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              previousClassName="hover:bg-gray-700 hover:text-white sm:text-gray-800 px-1.5 font-bold rounded ml-7"
              renderOnZeroPageCount={null}
              containerClassName="flex flex-row justify-center items-center w-full mx-auto my-3 "
              pageLinkClassName="hover:bg-gray-700 hover:text-white text-gray-800 px-1.5 rounded"
              activeLinkClassName="text-gray-800 sm:font-extrabold rounded"
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="sm:text-sm text-xs rounded w-full flex flex-col bg-white min-h-[40vh] ">
          <div className="flex flex-row justify-between py-3 ">
            <div className="w-96 mx-4 order-first">
              <h1 className="sm:text-sm text-xs text-gray-500 tile-header uppercase px-2">
                Projects
              </h1>
            </div>
            <div className="w-16 text-gray-400 order-last">
              <button className="text-gray-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.9}
                  stroke="currentColor"
                  className="w-6 h-6 mx-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="mx-auto">
            <p className="text-gray-400 font-semibold py-10">
              {" "}
              No project available yet.
            </p>
          </div>
        </div>
      </>
    );
  }
}
export default Projects;
