import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';


function Dropdown({ user, modalData, setModalData }) {

    return (
        <>
            <div className="container flex-row w-12/12 my-1">
                <div className="w-auto mx-0">
                    <div className="text-right">
                        <Menu as="div" className="relative inline-block text-right">
                            <div>
                                <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white border-[0.5px] border-[#e0e0e0] px-4 py-1.5 font-medium text-[#171923] hover:bg-gray-100">
                                    Options
                                    <ChevronDownIcon
                                        className="ml-2 -mr-1 h-5 w-5 text-[#171923]"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute z-50 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="px-1 py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    className={`${active ? 'bg-[#FBE77F]' : 'text-gray-900'
                                                        } group flex w-full items-center font-bold rounded-md px-2 py-2`}
                                                    onClick={
                                                        () => {
                                                            setModalData({
                                                                formData: {
                                                                    orgName: '',
                                                                    currentUserRole: user.role,
                                                                    userID: user.id,
                                                                    userName: user.full_name
                                                                },
                                                                modals: {
                                                                    type: 'update',
                                                                    show: true
                                                                }
                                                            })

                                                        }
                                                    }
                                                >
                                                    Edit user role
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Dropdown;