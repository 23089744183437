import React from 'react';
import { Loader } from '../../../helper/loading';
import { loadingHide, loadingShow } from "../../../helper/loading";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import * as yup from "yup";
import axios from '../../../api/axios';
import logo from "../../../img/logo/Honey bee time tracker logo dark.png";
import { notifySuccess, notifyError } from '../../../helper/notification-helper';
import { ToastContainer } from 'react-toastify';
import { forgotPasswordVerify } from '../../../services/account';

const validationSchema = yup.object({
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    passwordConfirmation: yup
      .string("Please confirm your password")
      .required("Please confirm your password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

const ResetPassword = () => {
    let params = useParams()
    const [verify, setVerify] = useState(false)

    useEffect(() => {
        loadingShow();

        if (params.token !== null || params.token !== undefined) {
            let formObject = {
                "verifyToken": params.token,
            }
            
            forgotPasswordVerify(formObject).then(function (response) {
                if (response.data.verfied === true) {
                    setVerify(true);
                    loadingHide();
                } else {
                    if(response.data.response.status === 404){
                        setVerify(false);
                        window.location.replace('/forgot-password');
                    }
                }
                
            })
        }

        document.title = "HoneybeeTime Account";
      
    }, [verify])
    

    const formik = useFormik({
        initialValues: {
          password: "",
          passwordConfirmation: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          DataSubmit(values);
        },
    });

    const DataSubmit = async (data) => {
        let formData = {
            "verifyToken": params.token,
            "new_password": data.password,
            "confirm_password": data.password,
        };
  
        try {
            var response = await axios.patch("/forgot-password/update-password", formData);
            if (
                response.data.code == 200
            ) {
                window.location.replace('/login');
            } else {
                notifyError("Password reset ERROR !");
            }
        } catch (error) {
        notifyError("Password Reset Error !");
        }
    };

    const PasswordIcon = () => {
        return (
          <svg
            width="19"
            height="25"
            viewBox="0 0 19 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 8.79071H14.8823V6.51164C14.8823 3.36652 12.3788 0.813965 9.29408 0.813965C6.20938 0.813965 3.70585 3.36652 3.70585 6.51164V8.79071H2.5882C1.35879 8.79071 0.352905 9.81629 0.352905 11.0698V22.4651C0.352905 23.7186 1.35879 24.7442 2.5882 24.7442H16C17.2294 24.7442 18.2353 23.7186 18.2353 22.4651V11.0698C18.2353 9.81629 17.2294 8.79071 16 8.79071ZM5.94114 6.51164C5.94114 4.62001 7.43879 3.09303 9.29408 3.09303C11.1494 3.09303 12.647 4.62001 12.647 6.51164V8.79071H5.94114V6.51164ZM16 22.4651H2.5882V11.0698H16V22.4651ZM9.29408 19.0465C10.5235 19.0465 11.5294 18.0209 11.5294 16.7675C11.5294 15.514 10.5235 14.4884 9.29408 14.4884C8.06467 14.4884 7.05879 15.514 7.05879 16.7675C7.05879 18.0209 8.06467 19.0465 9.29408 19.0465Z"
              fill="#1C2130"
            />
          </svg>
        );
    };
    
    return (
        <>
            {verify
                ? (
                    <>
                        <div className='custom-body flex h-full w-full min-h-screen bg-gray-200 relative'>
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="colored"
                            />
                            <div className='bg-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[650px] p-[60px] mx-auto rounded-[25px] shadow-lg'>
                                <div className='logo-container my-2 w-full'>
                                    <img 
                                        src={logo}
                                        alt="Logo of HoneybeeTime"
                                        className='w-2/3 mx-auto'
                                    />
                                </div>
                                <div className='title-container items-left mt-4'>
                                    <h1 className='text-[#3d3d3d] text-[35px] font-bold text-left'>
                                        Reset Password
                                    </h1>
                                </div>
                                <div className='w-full mx-auto text-left'>
                                    <p>
                                        <span className='text-[16px]'>
                                            Enter your new password !
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div>
                                            <div className="mt-5 w-full mx-auto !h-[50px]">
                                                <TextField
                                                    name="password"
                                                    className="text-[18px] !placeholder:text-[16px] mt-1 w-full mx-auto !h-[50px] px-3 py-2 bg-white border-slate-300 placeholder-slate-400 block rounded-md"
                                                    id="password"
                                                    label="Password"
                                                    variant="outlined"
                                                    type="password"
                                                    value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    error={
                                                        formik.touched.password &&
                                                        Boolean(formik.errors.password)
                                                    }
                                                    helperText={formik.errors.password}
                                                    InputProps={{
                                                        endAdornment: (
                                                        <InputAdornment position="end">
                                                            <PasswordIcon />
                                                        </InputAdornment>
                                                        ),
                                                        sx: { height: 43.3, marginTop: "5px" },
                                                    }}
                                                    InputLabelProps={{
                                                        style: { color: "#000000", fontSize: "16px" },
                                                    }}
                                                    sx={{
                                                        "& label": {
                                                        marginTop: "5",
                                                        },
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                        border: "1px solid #1c2130",
                                                        borderRadius: "7px",
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                                        "& > fieldset": {
                                                            borderColor: "#1C2130",
                                                        },
                                                        },
                                                        "& .MuiOutlinedInput-root:hover": {
                                                        "& > fieldset": {
                                                            border: "2px solid #1c2130",
                                                        },
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div className="mt-5 w-full mx-auto !h-[50px]">
                                                <TextField
                                                name="passwordConfirmation"
                                                className="text-[18px] !placeholder:text-[16px] mt-1 w-full mx-auto !h-[50px] px-3 py-2 bg-white border-slate-300 placeholder-slate-400 block rounded-md"
                                                id="confirmPassword"
                                                label="Confirm Password"
                                                variant="outlined"
                                                type="password"
                                                value={formik.values.passwordConfirmation}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.passwordConfirmation &&
                                                    Boolean(formik.errors.passwordConfirmation)
                                                }
                                                helperText={formik.errors.passwordConfirmation}
                                                InputProps={{
                                                    endAdornment: (
                                                    <InputAdornment position="end">
                                                        <PasswordIcon />
                                                    </InputAdornment>
                                                    ),
                                                    sx: { height: 43.3, marginTop: "5px" },
                                                }}
                                                InputLabelProps={{
                                                    style: { color: "#000000", fontSize: "16px" },
                                                }}
                                                sx={{
                                                    "& label": {
                                                    marginTop: "5",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "1px solid #1c2130",
                                                    borderRadius: "7px",
                                                    },
                                                    "& .MuiOutlinedInput-root.Mui-focused": {
                                                    "& > fieldset": {
                                                        borderColor: "#1C2130",
                                                    },
                                                    },
                                                    "& .MuiOutlinedInput-root:hover": {
                                                    "& > fieldset": {
                                                        border: "2px solid #1c2130",
                                                    },
                                                    },
                                                }}
                                                />
                                            </div>
                                        </div>
                                        <button
                                            className="mt-8 mx-auto w-full text-white bg-[#F6921E] hover:bg-[#EE8000] focus:ring-4 focus:ring-[EE8000] font-medium rounded-lg focus:outline-none h-[50px]"
                                            type="submit"
                                        >
                                            <span className="font-semibold text-[18px]">
                                                Reset Password
                                            </span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
                )
                : (null)
            }
        </>
    )
}

export default ResetPassword