import { Fragment, useEffect, useState } from "react";
import { loadingHide, loadingShow } from "../../../helper/loading";
import { Dialog, Transition } from "@headlessui/react";
import { Dropdown } from "../../globle-component/dropdown";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import { addManualTime, getWorkTimes } from "../../../services/worktimes";
import TimePicker from "./timePicker";
import { DATETIMES } from "../../../constant/datetimes";
import Timeline from "./timeline";
import { useDispatch, useSelector } from "react-redux";
import { getActiveTaskByProject } from "../../../store/slices/tasks";

const organizationId = JSON.parse(localStorage.getItem('organizationID'));
const authUserId = JSON.parse(localStorage.getItem('userId'));
const systemTimeZone = localStorage.getItem('systemTimeZone');
const organizationUserRole = JSON.parse(localStorage.getItem("orgUserRole"));


const addTimeModel = {
    formData: {
        projectId: "",
        taskId: "",
        date: "",
        start: "",
        end: "",
        reason: "",
    },
    display: false,
};

var now = new Date();
let startTime = now.setHours(8, 0, 0);
let endTime = now.setHours(9, 0, 0);

const initValidations = {
    project: true,
    date: true,
    start: true,
    end: true,
    reason: true,
}

const AddManualTime = ({
    projectList,
    getWorktimeData,
    setAddTimeFromCalendar
}) => {
    const dispatch = useDispatch();
    const { activeTaskList } = useSelector((state) => state.tasks);

    const [modalDisplay, setModalDisplay] = useState(false)

    const [selectedProject, setSelectedProject] = useState(null)
    const [selectedTask, setSelectedTask] = useState(null)
    const [manualaddingDate, setManualaddingDate] = useState(dayjs(now));
    const [manualAddingStratTime, setManualAddingStratTime] = useState(
        dayjs(startTime)
    );
    const [manualAddingEndTime, setManualAddingEndTime] = useState(
        dayjs(endTime)
    );
    const [manualAddingTimeSlots, setManualAddingTimeSlots] = useState([]);
    const [reason, setReason] = useState(null)


    const [isDateFuture, setIsDateFuture] = useState(false);
    const [isTimeOverlap, setIsTimeOverlap] = useState(false);

    const [newTimeSlotArray, setNewTimeSlotArray] = useState([]);

    const [validation, setValidation] = useState(initValidations)

    const [projectValidationMessage, setProjectValidationMessage] = useState(null)
    const [dateValidationMessage, setDateValidationMessage] = useState(null)
    const [startTimeValidationMessage, setStartTimeValidationMessage] = useState(null)
    const [endTimeValidationMessage, setEndTimeValidationMessage] = useState(null)
    const [reasonValidationMessage, setReasonValidationMessage] = useState(null)

    const [formSubmitStatus, setFormSubmitStatus] = useState(false)
    const [formSubmitResponce, setFormSubmitResponce] = useState(null)

    const [timeError, setTimeError] = useState(null);

    function closeAddModal() {
        setModalDisplay(false)
    }

    function getSelectedDateWorkTimes(dateValue = null) {
        let date = dateValue == null ? manualaddingDate : dateValue;

        let startTime = moment(moment(date.$d).startOf('day').utc().toISOString()).format("YYYY-MM-DD HH:mm:ss")
        let endTime = moment(moment(date.$d).endOf('day').utc().toISOString()).format("YYYY-MM-DD HH:mm:ss")

        let params = {
            start_date_time: startTime,
            end_date_time: endTime
        };

        console.log('params - ', params);

        getWorkTimes(organizationId, params).then(({ data }) => {
            setManualAddingTimeSlots(data);
            checkTimeSlot(data);
            setTimeout(() => {
                loadingHide(".modal-loading");
            }, 1000);
        });
    }


    function checkTimeSlot(worktimeArray) {
        setIsTimeOverlap(
            checkTimeOverlaping(worktimeArray, {
                start: manualAddingStratTime.$d,
                end: manualAddingEndTime.$d,
            })
        );
    }

    function datetimeValidation() {

        if (manualaddingDate !== null && manualaddingDate?.$d != 'Invalid Date') {
            if (new Date(manualaddingDate.$d).getDate() === new Date().getDate()) {
                if ((new Date(manualAddingStratTime.$d) > new Date()) || (new Date(manualAddingEndTime.$d) > new Date())) {
                    setIsDateFuture(true)
                } else {
                    setIsDateFuture(false)
                }
            } else if (new Date(manualaddingDate.$d).getDate() > new Date().getDate()) {
                setIsDateFuture(true)
            } else if (new Date(manualaddingDate.$d).getDate() < new Date().getDate()) {
                setIsDateFuture(false)
            }
        } else {
            setIsDateFuture(false)
        }

    }

    function datetimeToUtc(givenDate, givenTime) {
        return new moment(
            givenDate + " " + givenTime,
            DATETIMES.date + " " + DATETIMES.time24h
        )
            .utc()
            .toISOString();
    }

    useEffect(() => {


        setValidation({ ...validation, date: dateValidation(), start: startTimeValidation(), end: endTimeValidation() });

        // console.log('------------------------');
        // console.group("My message group");

        // console.log('manualaddingDate - ', manualaddingDate);
        // console.log('manualAddingStratTime - ', manualAddingStratTime);
        // console.log('manualAddingEndTime - ', manualAddingEndTime);

        // console.groupEnd()

        // console.log('------------------------');

        if (
            (manualaddingDate !== null && manualaddingDate?.$d != 'Invalid Date') &&
            (manualAddingStratTime !== null && manualAddingStratTime?.$d != 'Invalid Date') &&
            (manualAddingEndTime !== null && manualAddingEndTime?.$d != 'Invalid Date')
        ) {
            datetimeValidation()
            setNewWorktimeSlot()

            console.log("%cDatetimes is acceptable!", "color: green; font-weight: bold");
        } else {
            console.log("%cDatetimes is not acceptable!", "color: red; font-weight: bold");
        }

    }, [manualaddingDate, manualAddingStratTime, manualAddingEndTime])

    function setNewWorktimeSlot() {
        let startTime = datetimeToUtc(
            moment(manualaddingDate.toISOString()).format("YYYY-MM-DD"),
            moment(manualAddingStratTime.$d.toISOString()).format("HH:mm:ss")
        );

        let endTime = datetimeToUtc(
            moment(manualaddingDate.toISOString()).format("YYYY-MM-DD"),
            moment(manualAddingEndTime.$d.toISOString()).format("HH:mm:ss")
        );

        if (startTime < endTime) {
            let projectName = "";
            if (selectedProject === null) {
                projectName = "Unknown";
            } else {
                const results = projectList.filter((obj) => {
                    return obj.id === selectedProject.id;
                });
                projectName = results.name;
            }

            let newTimeSlot = {
                startDateTime: startTime,
                endDateTime: endTime,
                projectName: projectName,
            };

            setNewTimeSlotArray([newTimeSlot]);
        }

        checkTimeSlot(manualAddingTimeSlots);
    }

    function getTask(projectId) {
        console.log('Get task list on project')
        console.log('organizationId - ', organizationId);
        console.log('projectId - ', projectId);

        dispatch(getActiveTaskByProject({ organizationId: organizationId, projectID: projectId }))



        console.log('activeTaskList - ', activeTaskList);

    }

    useEffect(() => {
        if (activeTaskList.length > 0) {
            loadingHide(".modal-loading");
        } else {
            loadingHide(".modal-loading");
        }
    }, [activeTaskList])




    useEffect(() => {
        if (selectedProject !== null) {
            loadingShow(".modal-loading");
            getTask(selectedProject.id)
            setValidation({ ...validation, project: projectValidation() });
        }
    }, [selectedProject])

    useEffect(() => {
        if (reason !== null) {
            setValidation({ ...validation, reason: reasonValidation() });

        }
    }, [reason])

    function projectValidation() {
        if (selectedProject === null) {
            setProjectValidationMessage('Project field is required!')
            // setValidation({ ...validation, project: false });
            return false
        } else {
            setProjectValidationMessage(null)
            // setValidation({ ...validation, project: true });
            return true
        }
    }

    function dateValidation() {
        if (manualaddingDate === null) {
            setDateValidationMessage('Date is required!')
            // setValidation({ ...validation, date: false });
            return false

        } else if (manualaddingDate?.$d == 'Invalid Date') {
            setDateValidationMessage('Invalid date')
            // setValidation({ ...validation, date: false });
            return false

        } else {
            setDateValidationMessage(null)
            // setValidation({ ...validation, date: true });
            return true

        }

    }

    function startTimeValidation() {

        if (manualAddingStratTime === null) {
            setStartTimeValidationMessage('Start time is required!')
            return false
            // setValidation({ ...validation, start: false });
        } else if (manualAddingStratTime?.$d == 'Invalid Date') {
            setStartTimeValidationMessage('Invalid start time')
            return false
            // setValidation({ ...validation, start: false });
        } else {
            setStartTimeValidationMessage(null)
            return true
            // setValidation({ ...validation, start: true });
        }
    }

    function endTimeValidation() {
        if (manualAddingEndTime === null) {
            setEndTimeValidationMessage('End time is required!')
            return false
            // setValidation({ ...validation, end: false });
        } else if (manualAddingEndTime?.$d == 'Invalid Date') {
            setEndTimeValidationMessage('Invalid end time')
            return false
            // setValidation({ ...validation, end: false });
        } else {
            setEndTimeValidationMessage(null)
            return true
            // setValidation({ ...validation, end: true });
        }
    }

    function reasonValidation() {
        if (reason === null) {
            setReasonValidationMessage('Reason field is required!')
            return false
            // setValidation({ ...validation, reason: false });
        } else {
            setReasonValidationMessage(null)
            return true
            // setValidation({ ...validation, reason: true });
        }
    }


    function valicationCheck() {
        let object = validation;


        object.project = projectValidation();
        object.date = dateValidation();
        object.start = startTimeValidation();
        object.end = endTimeValidation();
        object.reason = reasonValidation();

        let valid = true;
        if (Object.values(object).includes(false)) {
            valid = false;
        }

        console.log('valid - ', valid);
        if (valid === true) {
            console.log("%cForm can submit!", "color: green; font-weight: bold");
        } else {
            console.log("%cForm cannot be submited!", "color: red; font-weight: bold");
        }

        return valid;

    }

    function dataSubmit() {
        loadingShow(".modal-loading");
        if (valicationCheck() === true) {
            console.log('validation passed');

            console.log('------------------------');
            console.group("My message group");

            console.log('selectedProject - ', selectedProject);
            console.log('selectedTask - ', selectedTask);
            console.log('manualaddingDate - ', manualaddingDate);
            console.log('manualAddingStratTime - ', manualAddingStratTime);
            console.log('manualAddingEndTime - ', manualAddingEndTime);
            console.log('reason - ', reason);

            console.groupEnd()

            console.group("My object");

            console.log('projectId - ', selectedProject !== null ? selectedProject?.id : selectedProject);
            console.log('taskId - ', selectedTask !== null ? selectedTask?.id : selectedTask);
            console.log('date - ', moment(manualaddingDate?.$d.toISOString()).format("YYYY-MM-DD"));
            console.log('start - ', moment(manualAddingStratTime?.$d.toISOString()).format("HH:mm:ss"));
            console.log('end - ', moment(manualAddingEndTime?.$d.toISOString()).format("HH:mm:ss"));
            console.log('reason - ', reason);

            console.groupEnd()

            console.log('------------------------');

            let object = {
                projectId: selectedProject !== null ? selectedProject?.id : selectedProject,
                taskId: selectedTask !== null ? selectedTask?.id : selectedTask,
                date: moment(manualaddingDate?.$d.toISOString()).format("YYYY-MM-DD"),
                start: moment(manualAddingStratTime?.$d.toISOString()).format("HH:mm:ss"),
                end: moment(manualAddingEndTime?.$d.toISOString()).format("HH:mm:ss"),
                reason: reason,
            };

            submitManualTime(object)

        } else {
            loadingHide(".modal-loading");
            console.log('validation fails - ', validation);
        }

    }

    function submitManualTime(params) {
        let formData = {
            projectId: params.projectId,
            organizationId: organizationId,
            date: moment(params.date, DATETIMES.date).utc().toISOString(),
            startDateTime: datetimeToUtc(params.date, params.start),
            endDateTime: datetimeToUtc(params.date, params.end),
            status: 1,
            reason: params.reason,
            note: "",
        };

        if (params.taskId != "") {
            formData.taskId = params.taskId;
        }

        addManualTime(organizationId, formData, setTimeError).then(function (
            response
        ) {
            if (response.code == 201) {
                closeAddModal();
                getWorktimeData();
                setAddTimeFromCalendar(false);
                loadingHide(".modal-loading");
            } else {
                loadingHide(".modal-loading");
                setFormSubmitResponce(response.data)
            }
        });
    }

    function clearTask() {
        setSelectedTask(null)
    }

    return (
        <>
            <button
                className="py-2 px-2 mt-5 ml-1 lg:mt-0 w-full font-bold text-sm transition-colors duration-150 bg-yellow-300 rounded focus:outline-none hover:shadow-lg active:bg-yellow-500 mb-2"
                type="button"
                onClick={() => {
                    getSelectedDateWorkTimes();
                    setModalDisplay(true)
                    setTimeout(() => {
                        // loadingShow(".modal-loading");
                    }, 100);
                }}
            >
                <span className={"mx-auto"}>Add Time</span>
            </button>
            {modalDisplay ? (
                <>
                    <Transition appear show={modalDisplay} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/60"
                            onClose={closeAddModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                <span className="h-screen align-top" aria-hidden="true">
                                    &#8203;
                                </span>

                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="modal-loading inline-block w-full max-w-lg p-6 my-8 text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded ">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900 pb-2 border-b border-solid border-slate-200"
                                        >
                                            Add time manually
                                        </Dialog.Title>

                                        <Timeline
                                            worktimes={manualAddingTimeSlots}
                                            newTimeSlotArray={newTimeSlotArray}
                                            keyVal={2}
                                            overlap={isTimeOverlap}
                                            isDateFuture={isDateFuture}
                                        />

                                        <div className="text-center"> <p className="text-red-500 text-xs italic">{formSubmitResponce}</p></div>

                                        <div className={"flex flex-col mt- mx-auto mb-1 w-full"}>
                                            <div className="pb-2 border-b border-gray-100 ">
                                                <div className="w-full py-2 mb-3">
                                                    <div className="mt-2">
                                                        <label className="text-gray-600 font-semibold mb-2 text-xs uppercase">Project <span className="required">*</span> </label>
                                                        <Dropdown
                                                            listData={projectList}
                                                            multiselect={false}
                                                            placeholder={'Search for a project'}
                                                            selectedItems={selectedProject}
                                                            setSelectedItems={setSelectedProject}
                                                            descriptionShow={false}
                                                            validation={validation.project}
                                                        />
                                                        {projectValidationMessage !== null ? (
                                                            <p className="text-red-500 text-xs italic mt-1">
                                                                {projectValidationMessage}
                                                            </p>
                                                        ) : null}
                                                    </div>

                                                    <div className="mt-2">
                                                        <label className="text-gray-600 font-semibold mb-2 text-xs uppercase">task </label>
                                                        <Dropdown
                                                            listData={activeTaskList}
                                                            multiselect={false}
                                                            placeholder={'Search for a task'}
                                                            selectedItems={selectedTask}
                                                            setSelectedItems={setSelectedTask}
                                                            descriptionShow={false}
                                                            disabled={activeTaskList.length === 0 ? true : false}
                                                        />
                                                        {selectedTask !== null ? (
                                                            <span className={'cursor-pointer z-50 absolute right-[25px]'} onClick={clearTask}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 project-search-close2">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                </svg>
                                                            </span>
                                                        ) : null}
                                                    </div>

                                                    <div className="mt-2 mb-2">
                                                        <label className="text-gray-600 font-semibold mb-2 text-xs uppercase">Date <span className="required">*</span> </label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                value={manualaddingDate}
                                                                onChange={(newValue) => {

                                                                    setManualaddingDate(newValue);

                                                                    if (newValue === null) {
                                                                        setValidation({
                                                                            ...validation,
                                                                            date: false,
                                                                        });
                                                                    } else if (newValue !== null && newValue?.$d == 'Invalid Date') {
                                                                        setValidation({
                                                                            ...validation,
                                                                            date: false,
                                                                        });
                                                                    } else {
                                                                        setValidation({
                                                                            ...validation,
                                                                            date: true,
                                                                        });
                                                                    }
                                                                    getSelectedDateWorkTimes(newValue);

                                                                }}
                                                                inputFormat="YYYY-MM-DD"
                                                                inputClass={'custom-input'}
                                                                className={`mt-1 w-full cursor-pointer border-1 honeybee-datepicker ${validation.date === false ? 'datepicker-danger' : ''}`}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} />
                                                                )}
                                                                maxDate={new Date()}
                                                            />
                                                        </LocalizationProvider>
                                                        {dateValidationMessage !== null ? (
                                                            <p className="text-red-500 text-xs italic mt-5">
                                                                {dateValidationMessage}
                                                            </p>
                                                        ) : null}
                                                    </div>

                                                    <div className={"mt-5 mb-5 flex"}>
                                                        <div className={"w-6/12 mr-1"}>
                                                            <label className="text-gray-600 font-semibold mb-2 text-xs uppercase">start <span className="required">*</span> </label>
                                                            <TimePicker
                                                                className={
                                                                    "border-none w-full cursor-pointer honeybee-timepicker"
                                                                }
                                                                defaultTime={manualAddingStratTime}
                                                                setNewValue={setManualAddingStratTime}
                                                                field={"start"}
                                                            />
                                                            {startTimeValidationMessage !== null ? (
                                                                <p className="text-red-500 text-xs italic mt-5">
                                                                    {startTimeValidationMessage}
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                        <div className={"w-6/12 ml-1"}>
                                                            <label className="text-gray-600 font-semibold mb-2 text-xs uppercase">End <span className="required">*</span> </label>
                                                            <TimePicker
                                                                className={
                                                                    "border-none w-full cursor-pointer honeybee-timepicker"
                                                                }
                                                                defaultTime={manualAddingEndTime}
                                                                setNewValue={setManualAddingEndTime}
                                                                field={"end"}
                                                            />
                                                            {endTimeValidationMessage !== null ? (
                                                                <p className="text-red-500 text-xs italic mt-5">
                                                                    {endTimeValidationMessage}
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className={"my-2 text-center"}>
                                                        {isTimeOverlap ? (
                                                            <p className="text-red-500 text-xs italic">
                                                                Your time is overlapping with current time
                                                                slots.
                                                            </p>
                                                        ) : null}
                                                        {manualAddingStratTime > manualAddingEndTime ? (
                                                            <p className="text-red-500 text-xs italic">
                                                                The End time must be greater than the Start
                                                                time.
                                                            </p>
                                                        ) : null}

                                                        {isDateFuture ? (
                                                            <p className="text-red-500 text-xs italic my-4">
                                                                Your can not add a future date time.
                                                            </p>
                                                        ) : null}
                                                    </div>

                                                    <div className="mt-2">
                                                        <label className="text-gray-600 font-semibold mb-2 text-xs uppercase">reason <span className="required">*</span> </label>
                                                        <textarea
                                                            name="reason"
                                                            placeholder="Add a reason"
                                                            className={`modal-input ${validation.reason === false ? 'modal-input-danger' : ''}`}
                                                            autoComplete="off"
                                                            onChange={(event) => {
                                                                if (event.target.value.length > 0) {
                                                                    setReason(event.target.value)
                                                                } else {
                                                                    setReason(null)
                                                                }
                                                            }}></textarea>

                                                        {reasonValidationMessage !== null ? (
                                                            <p className="text-red-500 text-xs italic mt-1">
                                                                {reasonValidationMessage}
                                                            </p>
                                                        ) : null}
                                                    </div>



                                                </div>

                                                <div className="mt-4 flex justify-end">
                                                    <button
                                                        type="button"
                                                        className="  px-4 py-2 text-sm text-white hbt-bg-dark border border-transparent rounded duration-300 "
                                                        onClick={closeAddModal}
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className={
                                                            "px-4 py-2 ml-3 text-sm text-black bg-yellow-300 border border-transparent rounded hover:bg-yellow-500 duration-300"
                                                        }
                                                        onClick={() => {
                                                            dataSubmit()
                                                        }}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : null}
        </>
    );

}

function checkTimeOverlaping(timeSlots, newTimeObj) {
    let newTimeSlotStart = moment(newTimeObj.start).format(DATETIMES.time24h);
    let newTimeSlotEnd = moment(newTimeObj.end).format(DATETIMES.time24h);

    let overlap = false;

    for (const timeslot of timeSlots) {
        let timeSlotStart = moment(timeslot.startDateTime).format(
            DATETIMES.time24h
        );
        let timeSlotEnd = moment(timeslot.endDateTime).format(DATETIMES.time24h);
        if (newTimeSlotStart < timeSlotStart && newTimeSlotEnd > timeSlotEnd) {
            overlap = true;
        }
        if (newTimeSlotStart < timeSlotStart && newTimeSlotEnd <= timeSlotEnd) {
            overlap = true;
        }
        if (newTimeSlotStart < timeSlotStart && newTimeSlotEnd >= timeSlotStart) {
            overlap = true;
        }

        if (newTimeSlotStart == timeSlotStart && newTimeSlotEnd > timeSlotEnd) {
            overlap = true;
        }
        if (newTimeSlotStart == timeSlotStart && newTimeSlotEnd == timeSlotEnd) {
            overlap = true;
        }

        if (newTimeSlotStart > timeSlotStart && newTimeSlotEnd <= timeSlotEnd) {
            overlap = true;
        }
        if (newTimeSlotStart > timeSlotStart && newTimeSlotEnd > timeSlotEnd) {
            overlap = true;
        }
        if (newTimeSlotStart <= timeSlotEnd && newTimeSlotEnd > timeSlotEnd) {
            overlap = true;
        }

        if (newTimeSlotStart < timeSlotStart && newTimeSlotEnd < timeSlotStart) {
            overlap = false;
        }
        if (newTimeSlotStart > timeSlotEnd && newTimeSlotEnd > timeSlotEnd) {
            overlap = false;
        }
        if (newTimeSlotStart > timeSlotEnd && newTimeSlotEnd == timeSlotEnd) {
            overlap = false;
        }

        if (overlap == true) {
            return overlap;
        }
    }

    return overlap;
}

export default AddManualTime;