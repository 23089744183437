import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const organizationId = localStorage.getItem('organizationID');

const ListBox = ({ selectedPeople, setSelectedPeople }) => {
    const dispatch = useDispatch();
    const { activeMemberList: activeMembersList } = useSelector((state) => state.members)
    const [inputFocusStatus, setInputFocusStatus] = useState(false)

    const [query, setQuery] = useState('')
    const filteredPeople =
        query === ''
            ? activeMembersList
            : activeMembersList.filter((person) =>
                person.full_name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )
    
    return (
        <>
            <Combobox value={selectedPeople} onChange={(person) => {
                dispatch(setSelectedPeople(person));
            }} multiple>

                <div className='my-0 flex flex-wrap -m-1'>
                    {selectedPeople.map((person) => (
                        <span
                            className="ht-selected-label-orange"
                            key={person.id + Math.random()}
                        >
                            {person.full_name}
                        </span>
                    ))}
                </div>

                <div className="relative mt-1">

                    <Combobox.Button className={inputFocusStatus === true ? 'dropdown-input-focus' : 'dropdown-input'}>
                        <Combobox.Input
                            className="w-full focus:outline-none border-none py-2"
                            displayValue={(person) => person.full_name}
                            onChange={(event) => {
                                setQuery(event.target.value)
                            }}
                            autoComplete='off'
                            placeholder="Search for a person to add"
                        />

                    </Combobox.Button>

                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => {
                            setInputFocusStatus(false)
                            setQuery('')
                        }}
                        afterEnter={() => {
                            setInputFocusStatus(true)
                        }}
                    >

                        <Combobox.Options className="absolute max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg dropdown-list z-50">
                            {filteredPeople.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                </div>
                            ) : (
                                filteredPeople.map((person) => (
                                    <Combobox.Option
                                        key={Math.random()}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-[#FADE4B] text-black' : 'text-gray-900'
                                            }`
                                        }
                                        value={person}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                >
                                                    {person.full_name}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-blue-600'
                                                            }`}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}

                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>

                    </Transition>
                </div>
            </Combobox>
        </>
    );
}

export default ListBox;