import moment from "moment";

export const getWeekDaysFromSingleDate = (incomDate = null) => {
    var currentDate = moment(incomDate);
    var weekStart = currentDate.clone().startOf("week");
    var week = [];
    for (let i = 0; i <= 6; i++) {
        week.push(moment(weekStart).add(i, "days").format("YYYY-MM-DD"));
    }
    return week;
}