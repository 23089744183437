import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import { Fragment, useState } from "react";
import { DateRange, DateRangePicker } from "react-date-range";

import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
} from 'date-fns';

function dateRangeFormat(object) {
    let dateRange = moment(object[0].startDate.toISOString()).format('ddd, MMM DD, YYYY') + ' - ' + moment(object[0].endDate.toISOString()).format('ddd, MMM DD, YYYY')
    return dateRange;
}

function closeModal(params, setCalendarDisplayStatus, setApplyBtnClickStatus) {
    setCalendarDisplayStatus(false);
    setApplyBtnClickStatus(params)
}

export const DateRangeCalendar = ({ datetimeRange, setDatetimeRange, setApplyBtnClickStatus }) => {
    const [calendarDisplayStatus, setCalendarDisplayStatus] = useState(false)
    const [newDattimeRange, setNewDattimeRange] = useState(datetimeRange)

    const defineds = {
        startOfWeek: startOfWeek(new Date()),
        endOfWeek: endOfWeek(new Date()),
        startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
        endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
        startOfToday: startOfDay(new Date()),
        endOfToday: endOfDay(new Date()),
        startOfYesterday: startOfDay(addDays(new Date(), -1)),
        endOfYesterday: endOfDay(addDays(new Date(), -1)),
        startOfMonth: startOfMonth(new Date()),
        endOfMonth: endOfMonth(new Date()),
        startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
        endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    };

    const defaultInputRanges = [
        {
            label: 'days up to today',
            range(value) {
                return {
                    startDate: addDays(defineds.startOfToday, (Math.max(Number(value), 1) - 1) * -1),
                    endDate: defineds.endOfToday,
                };
            },
            getCurrentValue(range) {
                if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
                if (!range.startDate) return '∞';
                return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
            },
        },
    ];

    function datetimeSubmit() {
        setDatetimeRange(newDattimeRange)
    }



    return (
        <>
            <div className={'border-mg w-full cursor-pointer honeybee-datepicker'} onClick={() => { setCalendarDisplayStatus(true) }}>
                <div
                    className="text-sm py-1.5 px-4 bg-white border border-slate-400 placeholder-slate-400 focus:outline-none focus:border-slate-500 focus:ring-slate-500 block w-full rounded"
                >
                    {dateRangeFormat(datetimeRange)}
                    <div className="calendar-icon cursor-pointer right pl-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" strokeWidth="1.5" stroke="currentColor" className="item-right w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                        </svg>
                    </div>
                </div>
            </div>
            {calendarDisplayStatus ? (
                <>
                    <Transition appear show={calendarDisplayStatus} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/60"
                            onClose={() => { closeModal(false, setCalendarDisplayStatus, setApplyBtnClickStatus) }}

                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className=" h-screen  align-top"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="modal-loading h-[420px] inline-block p-1 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded ">
                                        <div className="m-3">
                                            <DateRangePicker
                                                onChange={item => {
                                                    setNewDattimeRange([item.selection])
                                                }}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                ranges={newDattimeRange}
                                                direction="horizontal"
                                                rangeColors={['#FBE77F']}
                                                color={'#fjfghj'}
                                                maxDate={new Date()}
                                                inputRanges={defaultInputRanges}
                                            />
                                            <div style={{ 'position': 'absolute', 'bottom': '19px', }} className={'w-[23%]'}>

                                                <button className={'py-2 px-6 w-full mt-1 font-bold text-sm transition-colors duration-150 bg-yellow-300 rounded focus:outline-none hover:shadow-lg active:bg-yellow-500  mb-1'} onClick={() => { closeModal(true, setCalendarDisplayStatus, setApplyBtnClickStatus); datetimeSubmit() }}>Apply</button>
                                                <button className={'py-2 px-6 w-full mt-1 font-bold text-sm transition-colors duration-150 bg-gray-300 rounded focus:outline-none hover:shadow-lg active:bg-yellow-500  mb-1'} onClick={() => { closeModal(false, setCalendarDisplayStatus, setApplyBtnClickStatus) }}>Cancel</button>
                                            </div>
                                        </div>

                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : <></>}
        </>
    );

}

export const WeekCalendar = ({ datetimeRange, setSelectedDayForWeek, setApplyBtnClickStatus }) => {
    const [calendarDisplayStatus, setCalendarDisplayStatus] = useState(false)

    return (
        <>
            <div className={'border-mg w-full cursor-pointer honeybee-datepicker'} onClick={() => { setCalendarDisplayStatus(true) }}>
                <div
                    className="text-sm py-1.5 px-4 bg-white border border-slate-400 placeholder-slate-400 focus:outline-none focus:border-slate-500 focus:ring-slate-500 block w-full rounded"
                >
                    {dateRangeFormat(datetimeRange)}
                    <div className="calendar-icon cursor-pointer right  pl-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" strokeWidth="1.5" stroke="currentColor" className="item-right w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                        </svg>
                    </div>
                </div>
            </div>
            {calendarDisplayStatus ? (
                <>
                    <Transition appear show={calendarDisplayStatus} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/60"
                            onClose={() => { closeModal(false, setCalendarDisplayStatus, setApplyBtnClickStatus) }}

                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className=" h-screen  align-top"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="modal-loading h-[380px] inline-block p-1 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded ">
                                        <div className="m-3">
                                            <DateRange
                                                onChange={item => {
                                                    let incomingDate = item.selection.startDate;
                                                    
                                                    setSelectedDayForWeek(incomingDate)
                                                    closeModal(true, setCalendarDisplayStatus, setApplyBtnClickStatus)
                                                }}
                                                ranges={datetimeRange}
                                                rangeColors={['#FBE77F']}
                                                color={'#fjfghj'}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : <></>}
        </>
    );
}

