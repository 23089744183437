import React from 'react';
import moment from 'moment-timezone';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from "../../../../img/logo/Honey bee time tracker logo dark.png";
import icon from "../../../../img/report/Logo with icon.png";

const ExportReport = ({ dateRangeObject, totalDurationToShow }) => {
    function formatDate(dateString) {
        const formattedDate = moment(dateString, 'D-M-YYYY').format('dddd, MMMM D, YYYY');
        return formattedDate;
    }

    // Function to format duration in seconds to hh:mm:ss format
    function formatDuration(durationInSeconds) {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    const downloadPDF = () => {
        var doc = new jsPDF({
            orientation: "landscape"
        });

        const orgName = localStorage.getItem("organizationName");

        const titleFontSize = 16; // Font size for the title
        const contentFontSize = 10; // Font size for the rest of the content

        let title = "Time & Activity Report";
        let titleWidth = doc.getTextWidth(title); // Calculate the width of the title
        let pageWidth = doc.internal.pageSize.getWidth(); // Get the width of the page
        let startX = (pageWidth - titleWidth) / 2;

        doc.setFontSize(8); // Set font size for the title
        doc.text(`${formatDate(dateRangeObject[0].startDate)} - ${formatDate(dateRangeObject[0].endDate)}`, 10, 10);

        doc.setFontSize(titleFontSize);
        doc.text(title, startX, 20);

        doc.setFontSize(12);
        doc.text(orgName, 10, 28)

        doc.setFontSize(10);
        doc.text("Grand Total", 10, 40)

        doc.setFontSize(10);
        doc.autoTable({
            head: [['Time', 'Avg.Activity']],
            body: [
                [`${formatDuration(totalDurationToShow)}`, '-- %']
            ],
            startY: 43,
            theme: 'plain'
        })

        doc.setFontSize(contentFontSize); // Set font size for the content
        doc.autoTable({
            theme: 'grid',
            html: '#report_table',
            styles: { halign: 'left' },
            startY: 60,
            columnStyles: {
                // Set the minimum width for all columns
                // '*': { minWidth: 40 },
                // Example for setting specific minimum width for a column by column index
                0: { minWidth: 120 }
            },
            didParseCell: (data) => {
                if (data.cell.section === 'head') {
                    data.cell.styles.fillColor = '#f6921e'; // Set the background color of the header cells
                    data.cell.styles.textColor = '#FFFFFF'; // Set the text color of the header cells
                    data.cell.styles.halign = 'center'
                }
            }
        });

        const footerText = 'Page ';
        const footerLogoWidth = 23; // Width of the logo in the footer
        const totalPages = doc.internal.getNumberOfPages(); // Get the total number of pages
        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i); // Set the current page

            const footerX = doc.internal.pageSize.getWidth() / 2; // X position of the footer
            const footerY = doc.internal.pageSize.getHeight() - 10; // Y position of the footer
            doc.setFontSize(8);
            doc.text(footerText + i, footerX, footerY); // Add the footer text with the page number

            const footerLogoY = doc.internal.pageSize.getHeight() - 10; // Y position of the logo in the footer
            doc.text("Generated with ", 10, (doc.internal.pageSize.getHeight() - 8))
            // doc.addImage(icon, 'PNG', 27, footerLogoY, 3, 0); // Add the logo to the footer
            doc.addImage(logo, 'PNG', 31, footerLogoY, footerLogoWidth, 0); // Add the logo to the footer
        }
        doc.save(`${orgName}_time_and_activity_${dateRangeObject[0].startDate}_${dateRangeObject[0].endDate}`);
    }
    return (
        <>
            <div className='flex flex-row hover:text-gray-600 text-[#f6921e] font-bold cursor-pointer'
                onClick={() => downloadPDF()}
            >
                <div className='my-auto mr-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>

                </div>
                <div className='my-auto'>Export</div>
            </div>
        </>
    )
}

export default ExportReport