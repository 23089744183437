import moment from "moment";

let eventGuid = 0
let todayStr = new Date('2023-04-12').toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today


export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T13:16:00',
    end: todayStr + 'T15:15:00'
  },
  {
    id: createEventId(),
    title: 'Timed event-2',
    start: todayStr + 'T14:30:00',
    end: todayStr + 'T18:30:00',
    color: 'yellow',
    textColor: 'black'
  },
  {
    id: createEventId(),
    title: 'Timed event-3',
    start: todayStr + 'T16:30:00',
    end: todayStr + 'T18:30:00'
  }
]

// "2023-04-12T13:16:00"

export function setData(data) {
  const InitialData = []

  data.forEach(element => {
    let obj = {
      id: element._id,
      title: element.projectName,
      start: moment(element.startDateTime).format('YYYY-MM-DD[T]HH:mm:ss'),
      end: moment(element.endDateTime).format('YYYY-MM-DD[T]HH:mm:ss')
    }
    InitialData.push(obj)
  });

  // console.log(data);
  // console.log(InitialData);

  return InitialData;

}

export function createEventId() {
  return String(eventGuid++)
}
