import React from 'react'
import Navbar from '../dashboard/component/navbar';
import SideBar from '../dashboard/component/sidebar';
import { Loader } from '../../helper/loading';

import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const ReportMenu = () => {
  const [loading, setLoading] = useState(true);

  const checkOrg = () => {
    try {
      let orgCreate = false;
      if (
        localStorage.hasOwnProperty("organizationID") &&
        localStorage.hasOwnProperty("organizationName")
      ) {
        if (
          localStorage.getItem("organizationID") == "null" ||
          localStorage.getItem("organizationID") == "" ||
          localStorage.getItem("organizationName") == "null" ||
          localStorage.getItem("organizationName") == ""
        ) {
          orgCreate = true;
        }
      } else {
        orgCreate = true;
      }

      if (orgCreate) {
        window.location.replace("/createorg");
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkOrg();
    document.title = "HoneybeeTime - Reports";
  }, []);

  return (
    <>
      <div className="flex flex-row ">
        {loading ? (
          <Loader />
        ) : (
          <>
            <SideBar />
            <div className="flex-1 bg-gray-200">
              <Navbar />

              <div className="w-full p-5 custom-body">
                <div className="bg-[#f9f9f9] rounded-lg p-5 min-h-screen">
                  <div className="md:text-2xl text-lg">
                    <h1>Reports</h1>
                  </div>
                  <div className="settings-section mt-6">
                    <div className="flex font-medium text-left my-auto">
                      <div className="flex-row text-indigo-700 font-bold mr-2 my-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 "
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                          />
                        </svg>
                      </div>
                      <div className="flex-1 md:text-xl text-md my-auto">
                        <h2>General</h2>
                      </div>
                    </div>

                    <div>
                      <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-4 mt-2 px-5">
                        <Link to={"/reports/time-and-activities"}>
                          <div className="bg-white rounded-xl border-2 border-solid border-gray-100 hover:shadow-md p-5">
                            <div className="flex text-left font-semibold">
                              <div className="flex-1">
                                <h3 className="md:text-base text-sm">
                                  Time & Activity
                                </h3>
                              </div>
                            </div>
                            <div className="mt-1 p-1 sm:text-sm text-xs">
                              <p>
                                See team members' time worked, activity levels, and amounts earned per project or to-do
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
        }
      </div>
    </>
  )
}

export default ReportMenu