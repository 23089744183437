import moment from "moment";
import axios from "../api/axios";
import { logout } from "../hooks/logout";

let headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`
}

// Get WorkTimes
export const getWorkTimes = async (organizationId, params = null) => {
    try {

        const config = { headers: { ...headers, organization_id: organizationId }, params }
        const response = await axios.get('/time-slots/' + organizationId + '/organization', config)
        return response.data;
    } catch (error) {
        if (error?.response?.status === 401) {
            logout();
        }
        return { data: [] };

    }
};

// Add Manual Time
export const addManualTime = async (organizationId, formData, setTimeError) => {
    try {
        const config = { headers: { ...headers, organization_id: organizationId } }
        const response = await axios.post('/time-slots/add-manual', formData, config)
        return response.data;
    } catch (error) {
        if (error.response.data.error.message === "Can not add a future time for a manual adding..!") {
            setTimeError(error.response.data.error.message);
        }
        if (error.response.status === 401) {
            logout();
        }
        return { data: error.response.data.error.message };
    }
};

// filter WorkTimes
export const filterWorkTimes = async (organizationID, startTime = null, endTime = null, selectedMember = null, selectedOnProject = null, selectedOnTimeType = null) => {

    try {

        const start_date_time = moment.utc(startTime, 'YYYY-MM-DD HH:mm:ss').toISOString();
        const end_date_time = moment.utc(endTime, 'YYYY-MM-DD HH:mm:ss').toISOString();

        let params = { user_id: selectedMember, start_date_time: start_date_time, end_date_time: end_date_time, tracking_type: selectedOnTimeType, project_id: selectedOnProject };

        if (selectedMember !== null && selectedMember !== '') {
            params = { ...params, user_id: selectedMember }
        }
        const config = { headers: { ...headers }, params }
        const response = await axios.get('/time-slots/' + organizationID + '/organization', config);
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };

    }
};