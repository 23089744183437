import { addDays } from "date-fns";
import moment from "moment";
import { UserRole } from "../../constant/userRole";

export const NameProccess = (name, nameLength) => {
    if (name === null) {
        return name;
    } else {
        if (name.length > nameLength) {
            return name.slice(0, nameLength) + '...';
        } else {
            return name;
        }
    }
}

export const secondsToHours = (secondsCount) => {
    const totalMinutes = Math.floor(secondsCount / 60);
    const seconds = secondsCount % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

export const checkDateIsToday = (date) => {

}

export const checkTodayIsInWeek = (date) => {
    let weekDays = getWeekDaysFromSingleDate(date);
    let today = new Date

    var filteredEvents = weekDays.filter(function (data) {
        return data == moment(today).format('YYYY-MM-DD');
    });

    if (filteredEvents.length > 0) {
        return true;
    } else {
        return false;
    }
}

function getWeekDaysFromSingleDate(incomDate = null) {
    var currentDate = moment(incomDate);
    var weekStart = currentDate.clone().startOf('week');
    var week = [];
    for (let i = 0; i <= 6; i++) {
        week.push(moment(weekStart).add(i, 'days').format("YYYY-MM-DD"));
    };
    return week;
}

export const setNewDateRangeForSingleDayCalendar = (date) => {
    return [
        {
            startDate: new Date(date),
            endDate: addDays(new Date(date), 0),
            key: 'selection'
        }
    ]
}

export const getImageUrl = (imageUrl) => {
    return imageUrl;
}

export const setNewDateRangeForCalendar = (startDate, daysDifference) => {
    return [
        {
            startDate: new Date(startDate),
            endDate: addDays(new Date(startDate), daysDifference),
            key: 'selection'
        }
    ]
}

export const arrayUnique = (array) => {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}

export const userRoleTextRender = (data) => {
    let ROLE = Object.keys(UserRole).find(key => UserRole[key] === data);
    let result = ROLE.replace("_", " ").toLowerCase();
    return result;
}

export const addUserBodyCreate = (selectedMembers, role) => {
    let i = 0;
    let body = [];
    selectedMembers.map((member) => {
        body[i] = {
            id: member,
            role: role,
        };
        i++;
    });
    return body;
}

export function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

export function emailValidate(value) {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return true;
    } else {
        return false;
    }
}

export function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
}

export function imageExists(thumbnailImage) {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.src = getImageUrl(thumbnailImage);

        img.onload = function () {
            resolve(true);
        };

        img.onerror = function () {
            resolve(false)
        };
    })
}


// CHECK IF IMAGE EXISTS
export function checkIfImageExists(url, callback) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
        callback(true);
    } else {
        img.onload = () => {
            callback(true);
        };

        img.onerror = () => {
            callback(false);
        };
    }
}