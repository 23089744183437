import React from 'react'

const TermsList = () => {
  return (
    <>
        <div className='terms-list-container px-8 py-4'>
            <ol className='list-decimal text-[16px]'>
                <li className='font-bold mt-2'>
                    Term and Condition One
                </li>
                <div className='term-description list-none text-[14px] mt-2 text-justify'>
                    <p className='mt-1'>
                        Aenean volutpat ante vel lacus varius, non iaculis neque semper. Proin cursus diam vitae enim pulvinar pretium. Pellentesque dignissim diam a placerat pharetra. Mauris pellentesque enim a blandit tincidunt. Nullam ut pulvinar lectus, sit amet interdum ligula. Donec pharetra id ante quis efficitur. Quisque suscipit finibus odio vitae condimentum. Nulla in posuere neque.
                    </p>
                    <p className='mt-1'>
                        Sed quis auctor sapien. Nullam feugiat velit nec nisi ullamcorper, at hendrerit nisl auctor. Donec sit amet placerat nibh. Phasellus metus erat, vulputate ut felis sed, sagittis maximus leo. Pellentesque convallis, ex at laoreet volutpat, nunc dolor ultrices elit, semper venenatis dui neque ac libero. Donec lorem nunc, tristique eu accumsan ac, posuere non ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec ullamcorper malesuada sollicitudin. Quisque tristique, ante efficitur congue aliquam, arcu diam viverra mauris, mattis dignissim felis justo in nibh. Praesent varius eros at ante pulvinar lobortis. Integer feugiat, justo eget varius viverra, arcu ligula rhoncus tellus, sed tempor ante leo sed libero. Integer dignissim iaculis mi, vel placerat leo vulputate eu.
                    </p>
                </div>
                <li className='font-bold mt-2'>
                    Term and Condition Two
                </li>
                <div className='term-description list-none text-[14px] mt-2 text-justify'>
                    <p className='mt-1'>
                        Aenean volutpat ante vel lacus varius, non iaculis neque semper. Proin cursus diam vitae enim pulvinar pretium. Pellentesque dignissim diam a placerat pharetra. Mauris pellentesque enim a blandit tincidunt. Nullam ut pulvinar lectus, sit amet interdum ligula. Donec pharetra id ante quis efficitur. Quisque suscipit finibus odio vitae condimentum. Nulla in posuere neque.
                    </p>
                    <p className='mt-1'>
                        Sed quis auctor sapien. Nullam feugiat velit nec nisi ullamcorper, at hendrerit nisl auctor. Donec sit amet placerat nibh. Phasellus metus erat, vulputate ut felis sed, sagittis maximus leo. Pellentesque convallis, ex at laoreet volutpat, nunc dolor ultrices elit, semper venenatis dui neque ac libero. Donec lorem nunc, tristique eu accumsan ac, posuere non ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec ullamcorper malesuada sollicitudin. Quisque tristique, ante efficitur congue aliquam, arcu diam viverra mauris, mattis dignissim felis justo in nibh. Praesent varius eros at ante pulvinar lobortis. Integer feugiat, justo eget varius viverra, arcu ligula rhoncus tellus, sed tempor ante leo sed libero. Integer dignissim iaculis mi, vel placerat leo vulputate eu.
                    </p>
                </div>
                <li className='font-bold mt-2'>
                    Term and Condition Three
                </li>
                <div className='term-description list-none text-[14px] mt-2 text-justify'>
                    <p className='mt-1'>
                        Aenean volutpat ante vel lacus varius, non iaculis neque semper. Proin cursus diam vitae enim pulvinar pretium. Pellentesque dignissim diam a placerat pharetra. Mauris pellentesque enim a blandit tincidunt. Nullam ut pulvinar lectus, sit amet interdum ligula. Donec pharetra id ante quis efficitur. Quisque suscipit finibus odio vitae condimentum. Nulla in posuere neque.
                    </p>
                    <p className='mt-1'>
                        Sed quis auctor sapien. Nullam feugiat velit nec nisi ullamcorper, at hendrerit nisl auctor. Donec sit amet placerat nibh. Phasellus metus erat, vulputate ut felis sed, sagittis maximus leo. Pellentesque convallis, ex at laoreet volutpat, nunc dolor ultrices elit, semper venenatis dui neque ac libero. Donec lorem nunc, tristique eu accumsan ac, posuere non ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec ullamcorper malesuada sollicitudin. Quisque tristique, ante efficitur congue aliquam, arcu diam viverra mauris, mattis dignissim felis justo in nibh. Praesent varius eros at ante pulvinar lobortis. Integer feugiat, justo eget varius viverra, arcu ligula rhoncus tellus, sed tempor ante leo sed libero. Integer dignissim iaculis mi, vel placerat leo vulputate eu.
                    </p>
                </div>
            </ol>
        </div>
    </>
  )
}

export default TermsList