import moment from "moment";
import { DATETIMES } from "../../../constant/datetimes";
import { TaskStatus } from "../../../constant/status";
import Dropdown from "./dropdown";
import { UserRole } from "../../../constant/userRole";

const authUserId = JSON.parse(localStorage.getItem("userId"));
const authUserRole = JSON.parse(localStorage.getItem('orgUserRole'));

const TableData = ({
  dataList,
  showComplstedTask,
  allowCreateTask,
  modalData,
  setModalData,
  selectedProject
}) =>
  Array.isArray(dataList)
    ? dataList.map((items, index) => {
      let date = new Date(items.created_at);
      let user = "No assignee";
      let taskStatus = items.status;
      let createdBy = items.created_by;

      let authUserAssigned = false;
      let createdByAuthUser = false;

      if (items.users.length > 0) {
        user = items.users[0].full_name;
      }

      if (authUserRole === UserRole.ORGANIZATION_OWNER || authUserRole === UserRole.ORGANIZATION_MANAGER) {
        authUserAssigned = true;
      } else if (checkProjectManagerPermission() === true) {
        authUserAssigned = true;
      } else if (checkUserPermission() === true) {
        authUserAssigned = true;
      } else if(authUserRole === UserRole.VIEWER){
        authUserAssigned = false;
      }

      if (createdBy === authUserId) {
        createdByAuthUser = true;
      }

      function checkProjectManagerPermission() {
        if (authUserRole === UserRole.PROJECT_MANAGER) {
          let users = selectedProject.projectUsers.filter((user) => user.userId === authUserId)
          console.log('users - aaaaaaa - ', users);
          if (users.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }

      function checkUserPermission() {
        if (authUserRole === UserRole.USER) {
          let projectUsers = selectedProject.projectUsers.filter((user) => user.userId === authUserId)
          if (projectUsers.length > 0) {
            let taskUsers = items.users.filter((user) => user.id === authUserId)
            if (taskUsers.length > 0) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      }

      return (
        <tr className="ht-table-tr group" key={index}>
          {showComplstedTask ? (
            <>
              {taskStatus === TaskStatus.COMPLETED ? (
                <>
                  <td className="px-4 py-3  text-[#171923] leading-[17px] font-normal align-top pr-2">
                    <p
                      className=" text-ellipsis overflow-hidden pt-2"
                      title={items.title}
                    >
                      {items.displayName}
                    </p>
                  </td>
                  <td className="py-3 align-top relative">

                    {items.users.length === 0 && (
                      <div className="w-full flex">
                        <span className="text-[#171923] leading-[17px] font-normal text-center py-2.5 px-3">
                          -- No assignee --
                        </span>
                      </div>
                    )}

                    {items.users.map(function (user, i) {
                      return (
                        <div className="w-full flex" key={Math.random()}>
                          <button className="rounded-full bg-gray-500 w-6 h-6 my-2">
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mx-auto my-2"
                            >
                              <path
                                d="M8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 12C10.7 12 13.8 13.29 14 14H2C2.23 13.28 5.31 12 8 12ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
                                fill="currentColor"
                              />
                            </svg>
                          </button>
                          <span className="text-[#171923] leading-[17px] font-normal text-center py-2.5 px-3">
                            {user.full_name}
                          </span>
                        </div>
                      );
                    })}

                  </td>
                  <td className="py-3 align-top relative">
                    <p className="text-[#171923] leading-[17px] font-normal text-left pt-3 px-3 ">
                      {moment(date).format(
                        DATETIMES.date + " " + DATETIMES.time
                      )}
                    </p>
                  </td>
                  <td className="py-3 align-top relative">
                    {allowCreateTask ? (
                      <Dropdown
                        data={items}
                        modalData={modalData}
                        setModalData={setModalData}
                        editAllow={false}
                        markAsCompletedAllow={false}
                        deleteAllow={createdByAuthUser}
                      />
                    ) : null}
                  </td>
                </>
              ) : null}
            </>
          ) : (
            <>
              {taskStatus === TaskStatus.ACTIVE ? (
                <>
                  <td className="px-4 py-3  text-[#171923] leading-[17px] font-normal align-top pr-2">
                    <p
                      className=" text-ellipsis overflow-hidden pt-2"
                      title={items.title}
                    >
                      {items.displayName}
                    </p>
                  </td>
                  <td className="py-3 align-top relative">

                    {items.users.length === 0 && (
                      <div className="w-full flex">
                        <span className="text-[#171923] leading-[17px] font-normal text-center py-2.5 px-3">
                          -- No assignee --
                        </span>
                      </div>
                    )}


                    {items.users.map(function (user, i) {
                      return (
                        <div className="w-full flex" key={Math.random()}>
                          <button className="rounded-full bg-gray-500 w-6 h-6 my-2">
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mx-auto my-2"
                            >
                              <path
                                d="M8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 12C10.7 12 13.8 13.29 14 14H2C2.23 13.28 5.31 12 8 12ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
                                fill="currentColor"
                              />
                            </svg>
                          </button>
                          <span className="text-[#171923] leading-[17px] font-normal text-center py-2.5 px-3">
                            {user.full_name}
                          </span>
                        </div>
                      );
                    })}

                  </td>
                  <td className="py-3 align-top relative">
                    <p className="text-[#171923] leading-[17px] font-normal text-left pt-3 px-3 ">
                      {moment(date).format(
                        DATETIMES.date + " " + DATETIMES.time
                      )}
                    </p>
                  </td>
                  <td className="py-3 align-top relative">
                    {allowCreateTask ? (
                      <Dropdown
                        data={items}
                        modalData={modalData}
                        setModalData={setModalData}
                        editAllow={createdByAuthUser}
                        markAsCompletedAllow={authUserAssigned}
                        deleteAllow={createdByAuthUser}
                      />
                    ) : null}
                  </td>
                </>
              ) : null}
            </>
          )}
        </tr>
      );
    })
    : null;

export default TableData;
