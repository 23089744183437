import axios from "../api/axios";
import { notifyError } from "../helper/notification-helper";
import { logout } from "../hooks/logout";

let headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`
}

// Get Projects
export const getProjects = async (organizationId, statusId) => {
    const config = { headers: { ...headers, organization_id: organizationId } }
    try {
        const response = await axios.get('/projects/?organization_id=' + organizationId + '&status=' + statusId, config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// Get Projects by assigned user and status
export const getProjectByUser = async (organizationId, userId = null) => {
    const config = { headers: { ...headers, organization_id: organizationId } }
    let assigneeId = '';
    if (userId != null) {
        assigneeId = userId;
    }
    else {
        assigneeId = JSON.parse(localStorage.getItem('userId'))
    }
    try {
        const response = await axios.get('/projects/?organization_id=' + organizationId + '&status=1&assignee_id=' + assigneeId, config)
        return response.data;
        
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// Get single project
export const getProjectById = async (organizationId, statusId, projectId) => {
    const config = { headers: { ...headers, organization_id: organizationId, project_id: projectId } }
    try {
        const response = await axios.get('/projects/' + projectId, config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// Add new project
export const addNewProject = async (formData, organizationId) => {
    try {
        const config = { headers: { ...headers } }
        formData.organization_id = +organizationId;
        const response = await axios.post('/projects', formData, config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// Edit Project
export const editProject = async (formData) => {
    try {
        const config = { headers: { ...headers } }
        const response = await axios.patch('/projects/' + formData.id, formData, config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// Archive Project
export const archiveProject = async (projectId, organizationId) => {
    const config = { headers: { ...headers, organization_id: organizationId, project_id: projectId } }
    try {
        let formData = {};
        const response = await axios.patch('/projects/' + projectId + '/archive', formData, config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// Add members to project
export const addMemberToProject = async (formData, projectID) => {
    const config = { headers: { ...headers } }
    try {
        const response = await axios.post('/projects/' + projectID, formData, config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        notifyError(error.response.data.error.message);
        return { data: [] };
    }
};

// Duplicate Project
export const duplicateProjectModalSubmit = async (organizationID, projectID, duplicateProjectObj) => {
    const projectId = projectID;
    const organizationId = organizationID;
    const params = {
        organization_id: organizationId,
        new_project_name: duplicateProjectObj.new_project_name,
        add_all_project_members: duplicateProjectObj.add_all_project_members,
        duplicate_all_tasks: duplicateProjectObj.duplicate_all_tasks,
        add_all_task_assignees: duplicateProjectObj.add_all_task_assignees,
        duplicate_completed_task: duplicateProjectObj.duplicate_completed_task
    }
    const config = { headers: { ...headers , organization_id: organizationId}, params }
    try {
        const response = await axios.post(`/projects/${projectId}/duplicate?`, null, config);
        return response.data;
    } catch (error) {
        return error.response.message;
    }
}