import { Field, Formik, Form } from "formik";
import Navbar from "../dashboard/component/navbar";
import * as Yup from "yup";
import { getAccountData } from "../../services/account";
import { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "../../helper/notification-helper";
import { ToastContainer } from "react-toastify";
import { updateUserDetails } from "../../services/members";
import EmailUpdate from "./components/email/emailUpdate";
import PasswordUpdate from "./components/passwordUpdate";
import ImageUpload from "./components/imageUpload";
import { loadingHide, loadingShow } from "../../helper/loading";
import DeleteImage from "../globle-component/imageUploader/imageDelete";
import logo from "../../img/logo/Honey bee time tracker logo dark.png";

// Social Link Icons
import FacebookIcon from "../../img/icons/socialIcons/facebook-icon.png";
import TwitterIcon from "../../img/icons/socialIcons/twitter-icon.png";
import YoutubeIcon from "../../img/icons/socialIcons/youtube-icon.png";

// Time Zone Selector
import moment from 'moment-timezone';
import Select from "react-select";
import { Link } from "react-router-dom";

const modelFormSchema = Yup.object().shape({
  accountName: Yup.string().required("Name is required"),
  accountEmail: Yup.string().required("Email is required"),
  accountPhoneNumber: Yup.string().required("Phone number is required"),
  accountFacebook: Yup.string(),
  accountTwitter: Yup.string(),
  accountYoutube: Yup.string(),
  accountSocial: Yup.array(),
});

const profileImageHost =
  process.env.REACT_APP_PROFILE_IMAGE_HOST +
  localStorage.getItem("userId") +
  "/";
const profileImageAvatar = process.env.REACT_APP_PROFILE_IMAGE_HOST;

const CustomTextfieldComponent = (props) => (
  <input
    className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
    type="input"
    {...props}
  ></input>
);
const CustomeSocialLinkComponent = (props) => (
  <input
    className="rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    type="input"
    {...props}
  ></input>
);

const formData = {
  accountName: "",
  accountEmail: "",
  accountTimeZone: "",
  accountPhoneNumber: "",
  accountSocial: "",
  accountFacebook: "",
  accountTwitter: "",
  accountYoutube: "",
};

const Profile = () => {
  const [userId, setUserId] = useState("");
  const [dataLoad, setDataLoad] = useState(false);
  const [emailUpdateModalStatus, setemailUpdateModalStatus] = useState(false);
  const [passwordUpdateModalStatus, setpasswordUpdateModalStatus] =
    useState(false);
  const [imageUploadModalStatus, setimageUploadModalStatus] = useState(false);
  const [imageUploadStatus, setImageUploadStatus] = useState(false);
  const [deleteImageModdalStatus, setDeleteImageModdalStatus] = useState(false);
  const [deleteImageStatus, setDeleteImageStatus] = useState(false);
  const [profileImage, setProfileImage] = useState("");

  const [saveBtnState, setSaveBtnState] = useState(true);
  const [imageHash, setImageHash] = useState(new Date());

  const timeZones = moment.tz.names();
  const options = timeZones.map((timezone, index) => ({
      value: timezone,
      label: timezone,
  }));
  const [selectedTimeZone, setSelectedTimeZone] = useState([]);
  const [timeZoneError, setTimeZoneError ] = useState("");
  
  const selectOnChange = (zone) => {
    setSelectedTimeZone(zone ? zone : []);
    localStorage.setItem("systemTimeZone", zone ? zone.value : "");
    setTimeZoneError("")
  };

  function loadData() {
    loadingShow();

    getAccountData().then(({ data }) => {
      setUserId(data.id);
      formData.accountName = data.full_name;
      formData.accountEmail = data.email;
      data.timezone === null
        ? (formData.accountTimeZone = "")
        : (formData.accountTimeZone = data.timezone);
      data.phone_number === null
        ? (formData.accountPhoneNumber = "")
        : (formData.accountPhoneNumber = data.phone_number);
      formData.accountSocial = data.social_media;

      data.timezone === null
        ? (formData.accountTimeZone = "")
        : (formData.accountTimeZone = data.timezone);

      data.timezone === null
      ? (setSelectedTimeZone({label: "Select", value: ""}))
      : (setSelectedTimeZone({label: formData.accountTimeZone, value: formData.accountTimeZone}));

      setDataLoad(true);

      if (data.image_name === null) {
        setProfileImage(profileImageAvatar + "avatar.jpg");
      } else {
        setProfileImage(profileImageHost + data.image_name);
      }
      loadingHide();
    });
  }

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (imageUploadStatus === true) {
      loadingShow(".image-load");
      setDeleteImageStatus(false);
      notifySuccess("Avatar updated successfully!");
      setImageHash(new Date());
      setTimeout(() => {
        window.location.replace("/account");
      }, 5000);
    }
  }, [imageUploadStatus]);

  useEffect(() => {
    if (deleteImageStatus === true) {
      loadingShow(".image-load");
      setImageUploadStatus(false);
      notifySuccess("Avatar deleted successfully!");
      setTimeout(() => {
        window.location.replace("/account");
      }, 5000);
    }
  }, [deleteImageStatus]);

  return (
    <>
      <div className="flex flex-row h-full min-h-screen w-full custom-body overflow-x-hidden">
        {dataLoad ? (
          <>
            <div className="bg-gray-200 w-full h-full min-h-screen flex-1">
              <Link to='/'>
                <span className="absolute"><img
                  src={logo}
                  alt="logo"
                  className=" w-[143px] h-[21.5px] my-[19px] ml-[40px] mr-[86px]"
                /></span>
              </Link>
              <Navbar />

              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />

              <div className="flex flex-row w-full h-full min-h-screen p-5 custom-body">
                <div className="w-full h-full min-h-screen bg-white sm:p-3 p-2 rounded-md">
                  <div className="flex flex-row py-3">
                    <div className="grid md:grid-cols-4 grid-cols-1 gap-4 w-full text-[14px]">
                      <div className="md:p-5 lg:p-10 pt-10 pb-1 px-10">
                        <div className="mb-5 image-load">
                          <img
                            alt="avatar"
                            id="user_avatar"
                            className="avatar md:w-full md:h-full max-md:w-2/3 max-md:h-2/3 mx-auto rounded-full"
                            src={`${profileImage}?${imageHash}`}
                          />
                        </div>
                        <div className="text-center ">
                          <div
                            className="w-full cursor-pointer bg-transparent transition duration-200 hover:bg-yellow-400 text-gray-700 font-semibold hover:text-black py-1.5 px-4 border border-gray-300 hover:border-transparent text-sm sm:text-xs rounded mb-2"
                            onClick={() => {
                              setimageUploadModalStatus(true);
                            }}
                          >
                            Change picture
                          </div>
                          <div
                            className="w-full cursor-pointer bg-transparent transition duration-200 hover:bg-yellow-400 text-gray-700 font-semibold hover:text-black py-1.5 px-4 border border-gray-300 hover:border-transparent text-sm sm:text-xs  rounded mb-2"
                            onClick={() => {
                              setDeleteImageModdalStatus(true);
                            }}
                          >
                            Delete picture
                          </div>
                          <div
                            className="w-full cursor-pointer bg-transparent transition duration-200 hover:bg-yellow-400 text-gray-700 font-semibold hover:text-black py-1.5 px-4 border border-gray-300 hover:border-transparent text-sm sm:text-xs  rounded mb-2"
                            onClick={() => {
                              setemailUpdateModalStatus(true);
                            }}
                          >
                            Change email
                          </div>
                          <div
                            className="w-full cursor-pointer bg-transparent transition duration-200 hover:bg-yellow-400 text-gray-700 font-semibold hover:text-black py-1.5 px-4 border border-gray-300 hover:border-transparent text-sm sm:text-xs  rounded mb-2"
                            onClick={() => {
                              setpasswordUpdateModalStatus(true);
                            }}
                          >
                            Change password
                          </div>
                          <div className="w-full cursor-pointer bg-[#d9534f] transition duration-200 hover:bg-red-600 text-white font-semibold py-1.5 px-4 border border-red-500 hover:border-transparent text-sm sm:text-xs rounded mb-2">
                            Delete account
                          </div>
                        </div>
                      </div>
                      <div className="md:col-span-3 lg:my-10 md:my-5 my-1 md:p-10 lg:pt-2 pt-1 sm:px-10 px-6 pb-10">
                        <div className="w-96 order-first">
                          <h1 className="text-gray-800 lg:text-2xl md:text-xl text-lg">
                            Edit account
                          </h1>
                        </div>
                        <div>
                          <Formik
                            initialValues={{
                              accountName: formData.accountName,
                              accountEmail: formData.accountEmail,
                              accountTimeZone: formData.accountTimeZone,
                              accountPhoneNumber: formData.accountPhoneNumber,
                              accountFacebook: formData.accountSocial?.Facebook,
                              accountTwitter: formData.accountSocial?.Twitter,
                              accountYoutube: formData.accountSocial?.Youtube,
                            }}
                            validationSchema={modelFormSchema}
                            onSubmit={(values) => {
                              let obj= {}
                              if (selectedTimeZone.value === "") {
                                setTimeZoneError("Timezone is required !");
                              } else {
                                setTimeZoneError("");
                                obj = {
                                  full_name: values.accountName,
                                  phone_number: values.accountPhoneNumber,
                                  timezone: selectedTimeZone.value,
                                  social_media: JSON.stringify({
                                  Facebook: values.accountFacebook,
                                  Twitter: values.accountTwitter,
                                  Youtube: values.accountYoutube,
                                  }),
                                }
                                localStorage.setItem("systemTimeZone", selectedTimeZone.value)

                                updateUserDetails(userId, obj).then((response) => {
                                  console.log(response.data[0].error);
                                  if (response.code === 200) {
                                    setSaveBtnState(false);
  
                                    setTimeout(() => {
                                      setSaveBtnState(true);
                                    }, 7000);
  
                                    notifySuccess(
                                      "Profile updated successfully!"
                                    );
                                  } else {
                                    setSaveBtnState(false);
  
                                    setTimeout(() => {
                                      setSaveBtnState(true);
                                    }, 7000);
  
                                    notifyError("Profile update fail!");
                                  }
                                });
                              }

                              
                            }}
                          >
                            {({ errors, touched, setFieldValu }) => (
                              <Form>
                                <div className="my-4">
                                  <label className="text-gray-600 font-semibold mb-2 text-xs uppercase">
                                    name*
                                  </label>
                                  <div className="relative">
                                    <Field
                                      name="accountName"
                                      as={CustomTextfieldComponent}
                                      placeholder="Name"
                                    />
                                    {errors.accountName &&
                                      touched.accountName ? (
                                      <p className="text-red-500 text-xs italic my-4">
                                        {errors.accountName}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="my-4">
                                  <label className="text-gray-600 font-semibold mb-2 text-xs uppercase">
                                    email*
                                  </label>
                                  <div className="relative">
                                    <Field
                                      name="accountEmail"
                                      as={CustomTextfieldComponent}
                                      placeholder="Email"
                                    />

                                    <EmailUpdate
                                      CustomTextfieldComponent={
                                        CustomTextfieldComponent
                                      }
                                      notifySuccess={notifySuccess}
                                      notifyError={notifyError}
                                      emailUpdateModalStatus={
                                        emailUpdateModalStatus
                                      }
                                      setemailUpdateModalStatus={
                                        setemailUpdateModalStatus
                                      }
                                    />
                                    {errors.accountEmail &&
                                      touched.accountEmail ? (
                                      <p className="text-red-500 text-xs italic my-4">
                                        {errors.accountEmail}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="my-4">
                                  <label className="text-gray-600 font-semibold mb-2 text-xs uppercase">
                                    time zone
                                  </label>
                                  <div className="relative">
                                    <Select
                                      name="accountTimeZone"
                                      options={options}
                                      onChange={selectOnChange}
                                      value={selectedTimeZone}
                                      menuPosition="fixed"
                                      menuPlacement="auto"
                                      className="mt-1 bg-white shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" 
                                    />
                                    {timeZoneError != "" ? 
                                    (<p className="text-red-500 text-xs italic my-4">
                                        {timeZoneError}
                                      </p>)
                                      : null 
                                    }
                                  </div>
                                </div>
                                <div className="my-4">
                                  <label className="text-gray-600 font-semibold mb-2 text-xs uppercase">
                                    phone
                                  </label>
                                  <div className="relative">
                                    <Field
                                      name="accountPhoneNumber"
                                      as={CustomTextfieldComponent}
                                      placeholder="Phone"
                                    />
                                    {errors.accountPhoneNumber &&
                                      touched.accountPhoneNumber ? (
                                      <p className="text-red-500 text-xs italic my-4">
                                        {errors.accountPhoneNumber}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="my-4">
                                  <label className="text-gray-600 font-semibold mb-2 text-xs uppercase">
                                    Social Links
                                  </label>
                                  <div className="flex">
                                    <div className="inline-flex items-center  bg-gray-200 border border-r-0 border-gray-300 rounded-l-md w-8">
                                      <img
                                        src={FacebookIcon}
                                        alt="Logo of Facebook"
                                        className="w-full p-2"
                                      />
                                    </div>

                                    <Field
                                      name="accountFacebook"
                                      as={CustomeSocialLinkComponent}
                                      placeholder="https://facebook.com/..."
                                    />
                                  </div>
                                  <div className="flex mt-2">
                                    <div className="inline-flex items-center  bg-gray-200 border border-r-0 border-gray-300 rounded-l-md w-8">
                                      <img
                                        src={TwitterIcon}
                                        alt="Logo of Twitter"
                                        className="w-full p-2"
                                      />
                                    </div>
                                    <Field
                                      name="accountTwitter"
                                      as={CustomeSocialLinkComponent}
                                      placeholder="https://twitter.com/..."
                                    />
                                  </div>
                                  <div className="flex mt-2">
                                    <div className="inline-flex items-center  bg-gray-200 border border-r-0 border-gray-300 rounded-l-md w-8">
                                      <img
                                        src={YoutubeIcon}
                                        alt="Logo of Youtube"
                                        className="w-full p-2"
                                      />
                                    </div>
                                    <Field
                                      name="accountYoutube"
                                      as={CustomeSocialLinkComponent}
                                      placeholder="https://youtube.com/..."
                                    />
                                  </div>
                                </div>
                                <div className="my-4 flex justify-end">
                                  <button
                                    type="button"
                                    className="  px-4 py-2 text-sm text-white hbt-bg-dark border border-transparent rounded duration-300 "
                                  >
                                    Close
                                  </button>
                                  {saveBtnState === false ? (
                                    <button
                                      type="submit"
                                      className={
                                        "px-4 py-2 mx-3 text-sm text-black bg-yellow-300 border border-transparent rounded hover:bg-yellow-500 duration-300"
                                      }
                                      disabled
                                    >
                                      Save
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className={
                                        "px-4 py-2 mx-3 text-sm text-black bg-yellow-300 border border-transparent rounded hover:bg-yellow-500 duration-300"
                                      }
                                    >
                                      Save
                                    </button>
                                  )}
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ImageUpload
                  imageUploadModalStatus={imageUploadModalStatus}
                  setimageUploadModalStatus={setimageUploadModalStatus}
                  CustomTextfieldComponent={CustomTextfieldComponent}
                  originImg={profileImage}
                  setImageUploadStatus={setImageUploadStatus}
                />

                <DeleteImage
                  deleteImageModdalStatus={deleteImageModdalStatus}
                  setDeleteImageModdalStatus={setDeleteImageModdalStatus}
                  deleteImageStatus={deleteImageStatus}
                  setDeleteImageStatus={setDeleteImageStatus}
                />
              </div>

              <PasswordUpdate
                passwordUpdateModalStatus={passwordUpdateModalStatus}
                setpasswordUpdateModalStatus={setpasswordUpdateModalStatus}
                CustomTextfieldComponent={CustomTextfieldComponent}
                notifySuccess={notifySuccess}
                notifyError={notifyError}
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default Profile;
