import { useDispatch, useSelector } from "react-redux";
import { fetchActiveMembers, fetchInvitedMembers, setSelectedTab } from "../../store/slices/members";
import { Fragment, useEffect, useState } from "react";
import SideBar from "../dashboard/component/sidebar";
import Navbar from "../dashboard/component/navbar";
import { UserRole } from "../../constant/userRole";
import TableData from "./components/tableData";
import { Dialog, Transition } from "@headlessui/react";
import Modal from "./components/modal";
import InviteModal from "./components/inviteModal";
import MemberSearch from "./components/search";
import { cancelInvitation } from "../../services/members";
import { loadingShow, pageLoading } from "../../helper/loading";
import { getActiveProjects } from "../../store/slices/projects";
import { notifyError, notifySuccess } from "../../helper/notification-helper";
import { ToastContainer } from "react-toastify";

const organizationId = localStorage.getItem('organizationID');
const authUserRoll = JSON.parse(localStorage.getItem('orgUserRole'));
const modalObject = {
    formData: {
        orgName: '',
        currentUserRole: '',
        userID: '',
        userName: '',
    },
    modals: {
        type: '',
        show: false,
    }
};

const Members = () => {
    const dispatch = useDispatch();
    const {
        activeMemberList,
        filteredActiveMemberList,
        invitedMemberList,
        filteredInvitedMemberList,
        meta
    } = useSelector((state) => state.members)

    let [modalData, setModalData] = useState(modalObject);
    const [inviteModalDisplay, setInviteModalDisplay] = useState(false)
    const [addNeweInvite, setAddNeweInvite] = useState(false);
    const [cancelInvitationModal, setCancelInvitationModal] = useState(false)
    const [cancelingUserId, setCancelingUserId] = useState(null)
    const [inviteModalNotification, setInviteModalNotification] = useState(null)

    const {
        activeProjectList,
    } = useSelector((state) => state.projects)

    useEffect(() => {
        if (activeMemberList.length === 0) {
            dispatch(fetchActiveMembers(organizationId))
        }
        if (authUserRoll === UserRole.ORGANIZATION_OWNER || authUserRoll === UserRole.ORGANIZATION_MANAGER) {
            if (invitedMemberList.length === 0) {
                dispatch(fetchInvitedMembers(organizationId))
            }
            if (activeProjectList.length === 0) {
                dispatch(getActiveProjects(organizationId))
            }
        }

    }, [])

    useEffect(() => {
        if (addNeweInvite) {
            dispatch(fetchInvitedMembers(organizationId))
        }
    }, [addNeweInvite])

    function closeModal() {
        setModalData(modalObject)
    }


    useEffect(() => {
        if (inviteModalNotification !== null) {
            if (inviteModalNotification.type === 'success') {
                notifySuccess(inviteModalNotification.message);
            } else if (inviteModalNotification.type === 'error') {
                notifyError(inviteModalNotification.message);
            }
        }
    }, [inviteModalNotification])

    return (
        <>
            {cancelInvitationModal ? (
                <>
                    <>
                        <Transition appear show={cancelInvitationModal} as={Fragment}>
                            <Dialog
                                as="div"
                                className="fixed inset-0 z-50 overflow-y-auto bg-gray-600/60 "
                                onClose={closeModal}
                            >
                                <div className="min-h-screen px-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Dialog.Overlay className="fixed inset-0" />
                                    </Transition.Child>

                                    <span
                                        className="inline-block h-screen align-top"
                                        aria-hidden="true"
                                    >
                                        &#8203;
                                    </span>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <div className="modal-loading inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                            <Dialog.Title
                                                as="h3"
                                                className="ht-modal-header"
                                            >
                                                Canceling Invitation!
                                            </Dialog.Title>

                                            <div className="mt-2">
                                                <div className="relative flex-auto">
                                                    <div className="flex flex-wrap mb-6 text-gray-500 ">
                                                        <div>
                                                            Are you sure you want to cancel the invitation?
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex items-center justify-end border-t border-solid border-slate-200 pt-6">

                                                <button
                                                    className="ht-btn-yellow"
                                                    type="submit"
                                                    onClick={() => {
                                                        loadingShow('.modal-loading');
                                                        cancelInvitation(organizationId, cancelingUserId)
                                                            .then(() => {
                                                                dispatch(fetchInvitedMembers(organizationId))
                                                                setCancelInvitationModal(false)
                                                            })
                                                            .catch((error) => {
                                                            });
                                                    }}
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    className="ht-btn-black"
                                                    type="button"
                                                    onClick={() => {
                                                        closeModal()
                                                        setCancelInvitationModal(false)
                                                    }}
                                                >
                                                    No
                                                </button>
                                            </div>
                                        </div>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition>
                    </>
                </>
            ) : null}
            <div className="flex flex-row h-full w-full overflow-x-hidden">
                <SideBar />

                <div className="bg-gray-200 w-full h-full min-h-screen flex-1">
                    <Navbar />
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                    <div className="flex flex-row w-full p-5 custom-body">
                        <div className="w-full bg-white sm:p-3 p-2 rounded-md">


                            <div className="mx-4 order-first">
                                <h1 className="ht-page-header">
                                    Members
                                </h1>
                            </div>

                            <div className="mt-1">
                                <div className="relative">


                                    <div className="grid grid-cols-2 gap-4  mx-5">
                                        <div>
                                            <ul
                                                className="nav nav-tabs flex flex-row flex-wrap list-none border-b-0 pl-0 mb-4 "
                                                id="tabs-tab"
                                                role="tablist"
                                            >
                                                <li
                                                    className="nav-item"
                                                    role="presentation"
                                                >
                                                    <a
                                                        href="#members"
                                                        className={
                                                            "nav-link block font-bold leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent active"
                                                        }
                                                        id="members-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#members"
                                                        role="tab"
                                                        aria-controls="members"
                                                        aria-selected="true"
                                                        onClick={() => {
                                                            dispatch(setSelectedTab(0));
                                                        }}
                                                    >
                                                        Members  ( {activeMemberList.length} )
                                                    </a>
                                                </li>
                                                {authUserRoll === UserRole.ORGANIZATION_OWNER || authUserRoll === UserRole.ORGANIZATION_MANAGER ? (

                                                    <li className="nav-item" role="presentation">
                                                        <a
                                                            href="#invites"
                                                            className={
                                                                "nav-link block font-bold leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent"
                                                            }
                                                            id="invites-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#invites"
                                                            role="tab"
                                                            aria-controls="invites"
                                                            aria-selected="false"
                                                            onClick={() => {
                                                                dispatch(setSelectedTab(1));
                                                            }}
                                                        >
                                                            Invites  ( {invitedMemberList.length} )
                                                        </a>
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>
                                        <div>
                                            <div className="flex sm:flex-row flex-col right">
                                                <div className="sm:w-18 w-auto mx-3">
                                                    <MemberSearch />
                                                </div>

                                                {authUserRoll == UserRole.ORGANIZATION_OWNER || authUserRoll == UserRole.ORGANIZATION_MANAGER ? (
                                                    <InviteModal
                                                        setInviteModalNotification={setInviteModalNotification}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="flex flex-wrap mb-6">
                                        <div className="tab-content w-full  px-5" id="tabs-tabContent">


                                            <div
                                                className={
                                                    "tab-pane fade show active"
                                                }
                                                id="members"
                                                role="tabpanel"
                                                aria-labelledby="members-tab"
                                            >

                                                <div className="flex flex-col max-sm:!h-full">
                                                    <div className="max-sm:overflow-x-auto sm:-mx-6 lg:-mx-8 max-sm:!h-full">
                                                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                                            {Object.keys(filteredActiveMemberList).length > 0 ? (
                                                                <div className="sm:relative max-sm:overflow-x-auto">
                                                                    <table className="min-w-full h-full relative overflow-x-auto">
                                                                        <thead className="border-b">
                                                                            <tr>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="font-medium text-[#171923] my-4 text-left"
                                                                                >
                                                                                    Name
                                                                                </th>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="font-medium text-[#171923] px-6 py-4 text-center"
                                                                                >
                                                                                    About
                                                                                </th>

                                                                                <th
                                                                                    scope="col"
                                                                                    className="font-medium text-[#171923] px-6 py-4 text-center"
                                                                                >
                                                                                    Account Status
                                                                                </th>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="font-medium text-[#171923] px-6 py-4 text-center"
                                                                                >
                                                                                    Role
                                                                                </th>
                                                                                {authUserRoll === UserRole.ORGANIZATION_OWNER || authUserRoll === UserRole.ORGANIZATION_MANAGER ? (
                                                                                    <th
                                                                                        scope="col"
                                                                                        className="font-medium text-[#171923] px-6 w-[300px] py-4 text-center"
                                                                                    >
                                                                                        Screenshot Count (with 10 minutes)
                                                                                    </th>
                                                                                ) : null}
                                                                                <th
                                                                                    scope="col"
                                                                                    className="font-medium text-[#171923] px-6 py-4 text-center"
                                                                                ></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <TableData
                                                                                tab={'members'}
                                                                                modalData={modalData}
                                                                                setModalData={setModalData}
                                                                            />
                                                                        </tbody>
                                                                    </table>
                                                                    {meta.activeMembers.pageLoading &&
                                                                        <div className="flex justify-center w-full p-3">
                                                                            {pageLoading()}
                                                                        </div>
                                                                    }

                                                                </div>
                                                            ) : filteredActiveMemberList.length === 0 ? (
                                                                <div className="grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto bg-gray-100 text-center text-gray-500  sm overflow-x-auto">
                                                                    Nothing found.
                                                                </div>
                                                            ) : activeMemberList.length === 0 ? (
                                                                <div className="grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto bg-gray-100 text-center text-gray-500  sm overflow-x-auto">
                                                                    No active members.
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>




                                            </div>

                                            <div
                                                className={"tab-pane fade"}
                                                id="invites"
                                                role="tabpanel"
                                                aria-labelledby="invites-tab"
                                            >

                                                <div className="flex flex-col max-sm:!h-full">
                                                    <div className="max-sm:overflow-x-auto sm:-mx-6 lg:-mx-8 max-sm:!h-full">
                                                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                                            {Object.keys(filteredInvitedMemberList).length > 0 ? (
                                                                <div className="sm:relative max-sm:overflow-x-auto">
                                                                    <table className="min-w-full h-full max-h-screen relative overflow-x-auto">
                                                                        <thead className="border-b">
                                                                            <tr>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="font-medium text-gray-900 px-6 py-4 text-left w-fill"
                                                                                >
                                                                                    Name
                                                                                </th>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="font-medium text-gray-900 px-6 py-4 text-center"
                                                                                >
                                                                                    Email
                                                                                </th>

                                                                                <th
                                                                                    scope="col"
                                                                                    className="font-medium text-gray-900 px-6 py-4 text-center"
                                                                                >
                                                                                    Account Status
                                                                                </th>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="font-medium text-gray-900 px-6 py-4 text-center"
                                                                                >
                                                                                    Role
                                                                                </th>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="font-medium text-gray-900 px-6 py-4 text-center"
                                                                                ></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <TableData
                                                                                tab={'invited'}
                                                                                modalData={modalData}
                                                                                setModalData={setModalData}
                                                                                setCancelInvitationModal={setCancelInvitationModal}
                                                                                setCancelingUserId={setCancelingUserId}
                                                                            />
                                                                        </tbody>
                                                                    </table>
                                                                    {meta.invitedMembers.pageLoading &&
                                                                        <div className="flex justify-center w-full p-3">
                                                                            {pageLoading()}
                                                                        </div>
                                                                    }

                                                                </div>
                                                            ) : filteredInvitedMemberList.length === 0 ? (
                                                                <div className="grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto bg-gray-100 text-center text-gray-500  sm overflow-x-auto">
                                                                    Nothing found.
                                                                </div>
                                                            ) : invitedMemberList.length === 0 ? (
                                                                <div className="grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto bg-gray-100 text-center text-gray-500  sm overflow-x-auto">
                                                                    No invited members.
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="overflow-x-auto">

                                                </div>


                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div >

            {/* Modal Start */}
            {modalData.modals.show &&
                (modalData.modals.type === "insert" ||
                    modalData.modals.type === "update" ||
                    modalData.modals.type === "delete") && (
                    <Transition appear show={modalData.modals.show} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/80"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                <span className="inline-block h-screen " aria-hidden="true">
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="inline-block z-[1000] w-full max-w-md p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded-2xl">
                                        <Dialog.Title
                                            as="h3"
                                            className="ht-modal-header"
                                        >
                                            {modalData.modals.type == "insert" ? (
                                                <>Invite a member</>
                                            ) : modalData.modals.type == "update" ? (
                                                <>Update membership of {modalData.formData.userName}</>
                                            ) : modalData.modals.type == "delete" ? (
                                                <>Remove a membership</>
                                            ) : null}
                                        </Dialog.Title>
                                        <Modal setModalData={setModalData} modalData={modalData} />
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                )}

            {/* Modal End */}
        </>

    );

}

export default Members;