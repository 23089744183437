import React from "react";
import Navbar from "../../../dashboard/component/navbar";
import Sidebar from "../../../dashboard/component/sidebar";
import { Loader } from "../../../../helper/loading";

import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { checkOrgPermissions } from "../../../../services/organizations";

function Index() {
  const [loading, setLoading] = useState(true);

  const checkOrg = () => {
    try {
      let orgCreate = false;
      if (
        localStorage.hasOwnProperty("organizationID") &&
        localStorage.hasOwnProperty("organizationName")
      ) {
        if (
          localStorage.getItem("organizationID") == "null" ||
          localStorage.getItem("organizationID") == "" ||
          localStorage.getItem("organizationName") == "null" ||
          localStorage.getItem("organizationName") == ""
        ) {
          orgCreate = true;
        }
      } else {
        orgCreate = true;
      }

      if (orgCreate) {
        window.location.replace("/createorg");
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkOrg();
    document.title = "HoneybeeTime-Settings & Policies";
  }, []);

  return (
    <>
      {checkOrgPermissions() ? (
        <div className="flex flex-row ">
          {loading ? (
            <Loader />
          ) : (
            <>
              <Sidebar />
              <div className="flex-1 bg-gray-200">
                <Navbar />

                <div className="w-full p-5 custom-body">
                  <div className="bg-[#f9f9f9] rounded-lg p-5 min-h-screen">
                    <div className="md:text-2xl">
                      <h1>Settings & Policies</h1>
                    </div>
                    <div className="settings-section mt-6">
                      <div className="flex font-medium text-left">
                        <div className="flex-none text-indigo-700 font-bold mr-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 "
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                            />
                          </svg>
                        </div>
                        <div className="flex-1 md:text-xl">
                          <h2>General</h2>
                        </div>
                      </div>

                      <div>
                        <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1 gap-4 mt-2">
                          <Link to={"/settings/general"}>
                            <div className="bg-white rounded-xl border-2 border-solid border-gray-100 hover:shadow-md p-5">
                              <div className="flex text-left font-semibold">
                                <div className="flex-none text-red-500 mr-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                                    />
                                  </svg>
                                </div>
                                <div className="flex-1">
                                  <h3 className="md:text-base">
                                    Organization
                                  </h3>
                                </div>
                              </div>
                              <div className="mt-1 p-1">
                                <p>
                                  Edit your company's name, address, time zone,
                                  and more...
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : null}
    </>
  );
}

export default Index;
