import { Dialog, Transition } from "@headlessui/react";
import { Field, Formik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { Form, Link } from "react-router-dom";
import * as Yup from "yup";
import { Dropdown } from "../../globle-component/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifySuccess } from "../../../helper/notification-helper";
import { addNewTask } from "../../../services/tasks";
import { loadingHide, loadingShow } from "../../../helper/loading";
import { logout } from "../../../hooks/logout";
import { AddMembers } from "./members";
import { getTaskData } from "../../../store/slices/tasks";

const formValidation = {
    taskName: {
        required: 'Required',
        over: 'Must be less than 255 characters',
    },
    assignee: {
        required: 'Required'
    }
}
const organizationId = JSON.parse(localStorage.getItem('organizationID'));

const AddTaskModal = ({ assigneeList }) => {

    const { selectedProject } = useSelector((state) => state.tasks)
    const dispatch = useDispatch();

    const [addTaskModalDisplyStatus, setAddTaskModalDisplyStatus] = useState(false)
    const [taskName, setTaskName] = useState(null)
    const [selectedAssignees, setSelectedAssignees] = useState([])
    const [validations, setValidations] = useState(null)
    const [initialStatus, setInitialStatus] = useState(true)

    function closeModal() {
        setAddTaskModalDisplyStatus(false)
        setSelectedAssignees([])
        setTaskName(null)
        setValidations(null);
        setInitialStatus(true)
    }

    function submitNewTask() {

        if (checkValidation()) {
            let taskObject = {
                title: taskName,
                status: 1,
                projectId: JSON.parse(selectedProject.id)
            };

            onAddModelSubmit(taskObject)

        } 
    }

    function checkValidation() {
        let validation = {};

        if (taskName === null || taskName === '') {
            validation.taskName = formValidation.taskName.required;
        } else if (taskName.length > 255) {
            validation.taskName = formValidation.taskName.over;
        }

        setValidations(validation)

        if (Object.keys(validation).length === 0) {
            return true;
        } else {
            return false;
        }


    }

    useEffect(() => {
        if (!initialStatus) {
            checkValidation()
        }
    }, [taskName])

    useEffect(() => {
        if (!initialStatus) {
            checkValidation()
        } else {
            if (selectedAssignees.length > 0) {
                setInitialStatus(false)
            }
        }

    }, [selectedAssignees])


    // Add Task model data submit function
    const onAddModelSubmit = async (data) => {
        loadingShow('.modal-loading');
        try {
            loadingShow();
            addNewTask(organizationId, data.projectId, data).then((response) => {
                let taskId = response.data.id;
                {
                    response.code == 201
                        ? (notifySuccess("Task Added Successfully"))
                        : (notifyError("Error in Task Adding !"))
                }

                if (selectedAssignees.length > 0) {
                        AddMembers(data.projectId, taskId, selectedAssignees)
                }

                closeModal();
                loadingHide('.modal-loading');
                setTimeout(() => {
                    dispatch(getTaskData(organizationId))
                    loadingHide();

                }, 1000);

            });
        } catch (error) {
            //logout when unauthorized
            if (error.response.status === 401) {
                logout();
            }
            notifyError(error.response.data.message);
        }
    };

    return (
        <>
            <div className="order-last flex flex-row mt-1 lg:w-auto w-full">
                <button
                    className={`bg-[#FBE77F] rounded  h-[37px] px-3  mt-5`}
                    // className={`${allowCreateTask ? 'bg-[#FBE77F]' : 'bg-[#E6E7E7]'} rounded  h-[37px] px-3`}
                    onClick={() => {
                        setAddTaskModalDisplyStatus(true)
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 left mr-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                    </svg>

                    <span className="text-[171923] leading-[17px] font-bold"
                    >
                        Add Task
                    </span>
                </button>
            </div>

            {addTaskModalDisplyStatus === true ? (
                <>
                    <Transition appear show={true} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/80"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                <span
                                    className="inline-block h-screen "
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="modal-loading inline-block z-[1000] w-full max-w-lg p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded-2xl">
                                    {assigneeList.length > 0 ? (
                                        <Dialog.Title
                                            as="h3"
                                            className="ht-modal-header"
                                        >
                                            Add a new task
                                        </Dialog.Title>
                                    ):null}
                                        <div className={"flex flex-col mt- mx-auto mb-1 w-full"}>
                                            {assigneeList.length === 0 ? (
                                                <div className="pb-2 border-b border-gray-100 text-center mb-3">
                                                    <div className="p-1 text-red-600 font-bold my-1">Please assign some members to the project before you create tasks</div>
                                                    <div className="p-1 my-1">Please <Link className={'text-yellow-600 hover:underline font-bold'} to={'/projects'}>click here</Link> to go to Project Page</div>
                                                </div>
                                            ) :
                                                <>
                                                    <div className="pb-2 border-b border-gray-100 mb-3">
                                                        <div className="w-full py-2" >
                                                            <div>
                                                                <label className="uppercase font-bold">Task name <span className="required">*</span></label>
                                                                <input
                                                                    name="email"
                                                                    placeholder="Task name"
                                                                    className={`modal-input ${validations !== null && validations.taskName !== undefined ? 'modal-input-danger' : ''}`}
                                                                    autoComplete="off"
                                                                    onChange={(event) => {
                                                                        setTaskName(event.target.value)
                                                                        setInitialStatus(false)
                                                                    }}
                                                                />
                                                                {validations !== null && validations.taskName !== undefined ? (
                                                                    <div className="p-1 text-red-600 font-bold my-1">{validations.taskName}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                        <div className="w-full py-2 ">
                                                            <div>
                                                                <label>
                                                                    <span className="uppercase font-bold">Assignee</span>
                                                                </label>
                                                                <Dropdown
                                                                    listData={assigneeList}
                                                                    multiselect={true}
                                                                    placeholder={'Search for a person'}
                                                                    selectedItems={selectedAssignees}
                                                                    setSelectedItems={setSelectedAssignees}
                                                                    descriptionShow={false}
                                                                    validation={validations !== null && validations.assignee !== undefined ? false : true}
                                                                    disabled={false}
                                                                    selectedItemsDisplaySeparately={true}
                                                                />
                                                                {validations !== null && validations.assignee !== undefined ? (
                                                                    <div className="p-1 text-red-600 font-bold my-1">{validations.assignee}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="pb-2 border-b border-gray-100 ">
                                                        <div className=" flex justify-end">

                                                            <button
                                                                className="ht-btn-black"
                                                                type="button"
                                                                onClick={closeModal}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                className="ht-btn-yellow"
                                                                type="submit"
                                                                onClick={() => {
                                                                    checkValidation()
                                                                    submitNewTask()
                                                                }}
                                                            >
                                                                Send
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            }


                                        </div>

                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : null}


        </>
    );
}

export default AddTaskModal;