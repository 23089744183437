import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getAllOrganizations } from "../../../services/organizations";
import { NameProccess, userRoleTextRender } from "../../../component/globle-component/globle";

const initialState = {
    organizationsList: [],
    filteredOrganizationsList: [],
    meta: {
        organizations: {
            pageCount: 11,
            pageNumber: 1,
            pageLoading: false
        }
    },
    nameCharacterLength : 30
}

export const fetchOrganizations = createAsyncThunk('organizations/getOrganizations', async (_, thunkApi) => {
    try {
        const { data } = await getAllOrganizations();
        organizationNameShorten(data)
        return data;
    } catch (error) {
        console.log('error -', error);
    }
})

export const setOrganizationsPageNumber = createAsyncThunk('organizations/setOrganizationsPageNumber', async (pageNumber, thunkApi) => {
    const currentState = thunkApi.getState();
    let totalCount = (currentState.organizations.meta.organizations.pageNumber * currentState.organizations.meta.organizations.pageCount);
    if (totalCount <= currentState.organizations.organizationsList.length) {
        thunkApi.dispatch(setOrganizationsPageLoading(true))

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                thunkApi.dispatch(setOrganizationsPageLoading(false))
                resolve(currentState.organizations.meta.organizations.pageNumber + 1)
            }, 1500);
        })
    }
    return null
})

export const organizationsAppendList = createAsyncThunk('organizations/organizationsAppendList', async (_, thunkApi) => {
    const currentState = thunkApi.getState()
    thunkApi.dispatch(setOrganizationsPageNumber())
})

function organizationNameShorten(list) {
    for (const item of list) {
        item.organization.displayName = NameProccess(item.organization.name, initialState.nameCharacterLength);
    }
}

export const organizationsSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        filteredOrganizations: (state, action) => {
            state.filteredOrganizationsList = state.organizationsList.filter((obj) => String(obj.organization.name + obj.organization.industry + userRoleTextRender(obj.role))
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(action.payload.toLowerCase().replace(/\s+/g, "")))
        },

        setOrganizationsList: (state, action) => {
            state.organizationsList = action.payload
        },

        setOrganizationsPageLoading: (state, action) => {
            state.meta.organizations.pageLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        // Organizations List
        builder.addCase(fetchOrganizations.pending, (state, action) => {
            state.organizationsList = []
            state.filteredOrganizationsList = []
        });

        builder.addCase(fetchOrganizations.fulfilled, (state, action) => {
            let data = action?.payload;
            if (data !== undefined && data.length > 0) {
                state.organizationsList = data;
                state.filteredOrganizationsList = state.organizationsList.slice((state.meta.organizations.pageNumber - 1), (state.meta.organizations.pageNumber * state.meta.organizations.pageCount));
            }
        });

        builder.addCase(fetchOrganizations.rejected, (state, action) => {
            state.meta.organizations.pageNumber = 1;
        });

        builder.addCase(setOrganizationsPageNumber.fulfilled, (state, action) => {
            if (action.payload != null) {
                let pageN = action.payload
                state.meta.organizations.pageNumber = pageN
                state.filteredOrganizationsList = state.organizationsList.slice(0, (pageN * state.meta.organizations.pageCount));
            }
        });

    }
})

// Action creators are generated for each case reducer function
export const { setOrganizationsList, filteredOrganizations, setOrganizationsPageLoading } = organizationsSlice.actions

export default organizationsSlice.reducer