function CreateFirstProject() {
    return (
        <>
            <div className="container w-96 mt-48 flex flex-col mx-auto">
                <h1 className="text-3xl font-semibold text-gray-700 leading-tight ">Create your first project</h1>
                <p className=" mt-5 text-gray-400 text-base font-semibold text-justify font-sans tracking-normal">A project is a category of work within an organization. Here are some examples that you can edit, and you can always add more later.</p>

                <div className="container flex flex-col mt-8">
                    <h3 className="text-gray-500 font-bold uppercase text-base">Project Name</h3>

                    <div className="flex flex-row mt-4">
                        <div className="contaienr border-2 rounded-lg border-4 border-indigo-200 border-l-indigo-500 w-11/12">
                            <h2 className="text-gray-700 font-bold text-lg py-3 px-6">Software</h2>
                        </div>
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="gray" className="w-9 h-9 my-auto mx-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                    </div>

                    <div className="flex flex-row mt-4">
                        <div className="contaienr border-2 rounded-lg border-4 border-green-200 border-l-green-500 w-11/12">
                            <h2 className="text-gray-700 font-bold text-lg py-3 px-6">Marketing</h2>
                        </div>
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="gray" className="w-9 h-9 my-auto mx-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                    </div>
                    <button className="bg-blue-600 rounded-full w-10 h-10 my-5">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="white" className="w-6 h-6 mx-auto my-auto">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </button>

                    <button className="bg-green-600 rounded-md p-2 my-8 text-white text-base font-semibold w-11/12 mx-auto">
                        Continue
                    </button>

                </div>

            </div>
        </>
    );
}

export default CreateFirstProject;