import { Field } from "formik";

export const InputComponent = ({ props, fieldName, placeholder }) => {
    return (
        <input
            className="ht-input"
            type="text"
            name={fieldName}
            placeholder={placeholder}
            {...props}
        />
    );
}

export const TextareaComponent = ({ props, fieldName, placeholder }) => {
    return (
        <textarea
            className="ht-input"
            type="text"
            name={fieldName}
            id={fieldName}
            placeholder={placeholder}
            {...props}
        />
    );
}
