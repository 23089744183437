import { useDispatch, useSelector } from "react-redux"
import ColorSelector from "../../../helper/colorSelectorOrganization"
import Dropdown from "./dropdown";
import { notifyError, notifySuccess } from "../../../helper/notification-helper";
import { userRoleTextRender } from "../../globle-component/globle";
import { useEffect } from "react";
import { organizationsAppendList } from "../../../store/slices/organizations";

const TableData = ({ setModalData }) => {
  const dispatch = useDispatch();
  const {
    filteredOrganizationsList,
  } = useSelector((state) => state.organizations)

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
      return;
    }
    dispatch(organizationsAppendList())
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])
  return [...filteredOrganizationsList].map((data, organizationIndex) => (
    <tr className="border-b group" key={organizationIndex}>
      <td
        className="py-3 px-4 relative w-96 h-16 flex flex-row font-medium "
        title={data.organization.name}
      >
        <button
          className={"rounded-full w-10 h-10 bg-green-700 "}
          style={{ backgroundColor: ColorSelector(data.organizationId) }}
        >
          <p className="text-white font-bold">
            {data.organization.displayName[0].toUpperCase()}
          </p>
        </button>
        <div>
          <h5 className="px-4 text-gray-800 font-normal py-2 leading-[17px]">
            {data.organization.displayName}
          </h5>
        </div>
      </td>
      <td className="text-[#171923] font-medium text-center px-6 py-4 whitespace-nowrap">
        <p>{data.organization.industry}</p>
      </td>
      <td className="text-[#171923] font-medium text-center px-6 py-4 whitespace-nowrap capitalize">
        <p>{userRoleTextRender(data.role)}</p>
      </td>

      <td className="text-gray-900 font-light  px-6 py-1 whitespace-nowrap mr-5">
        <Dropdown
          data={data}
          setModalData={setModalData}
          notifyError={notifyError}
          notifySuccess={notifySuccess}
        />
      </td>
    </tr>
  ));
}

export default TableData