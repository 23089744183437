import React, { useState, useCallback, Fragment, useEffect } from 'react'
import { Dialog, Transition } from "@headlessui/react";
import Cropper from "react-easy-crop";
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import { getCroppedFile, getCroppedImg } from './canvasUtils';
import { orgLogoImageUpload, profileImageUpload } from '../../../services/images';
import { loadingHide, loadingShow } from '../../../helper/loading';

let uploadBtn = '';

const ImageUploader = ({ setimageUploadModalStatus, setImageUploadStatus, type}) => {

    const [newImageSelectStatus, setNewImageSelectStatus] = useState(false)
    const [originImageSrc, setOriginImageSrc] = React.useState(null)
    const [originFile, setOriginFile] = React.useState(null)

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const [croppedFile, setCroppedFile] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [uploadButtonLabel, setUploadButtonLabel] = useState('')


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    function clear() {
        setNewImageSelectStatus(false)
        setOriginImageSrc(null);
        setOriginFile(null);
        setCroppedFile(null);
        setCroppedImage(null);
    }

    const showCroppedImage = useCallback(async () => {
        loadingShow('.modal-loading');

        try {
            const croppedImage = await getCroppedImg(
                originImageSrc,
                croppedAreaPixels)


            const croppedFile = await getCroppedFile(
                originFile,
                croppedAreaPixels
            )

            setTimeout(() => {
                setCroppedImage(croppedImage)
                setCroppedFile(croppedFile);
                loadingHide('.modal-loading');

            }, 500);

        } catch (e) {
            console.error(e)
        }

    }, [originImageSrc, croppedAreaPixels])


    function uploadImage(params) {
        loadingShow('.modal-loading');

        try {
            if (type == 'profile') {

                profileImageUpload(croppedFile).then(async (response) => {
                    if (response.code == 200) {
                        clear()
                        setimageUploadModalStatus(false)
                        setTimeout(() => {
                            loadingHide('.modal-loading');
                            setImageUploadStatus(true);
                        }, 1000);
                    }
                })
            } else if (type == 'orgLogo') {
                orgLogoImageUpload(croppedFile, localStorage.getItem('organizationID')).then(async (response) => {
                    if (response.code == 200) {
                        clear()
                        setimageUploadModalStatus(false)
                        setTimeout(() => {
                            setImageUploadStatus(true);
                        }, 1000);
                    }
                })
            }
        } catch (e) {
            console.error(e)
        }

    }

    useEffect(() => {
        if(type === 'profile'){
            setUploadButtonLabel("Select a profile image")
        } else if(type === 'orgLogo'){
            setUploadButtonLabel("Select a logo for organization")
        }
    }, [])
    
    return (
        <>
        <div className="custom-file-input w-full mb-5" onClick={(event) => {
                document.querySelector('#file').click()
            }}>{uploadButtonLabel}</div>

            <input className=" hidden w-full mb-5" id="file" name="file" type="file" onChange={(event) => {
                let newFile = URL.createObjectURL(event.target.files[0]);
                setNewImageSelectStatus(true);
                setOriginImageSrc(newFile)
                setOriginFile(event.target.files[0])
            }} />

            {newImageSelectStatus ? (
                <>
                    <Transition appear show={newImageSelectStatus} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/60"
                            onClose={() => {
                                clear()
                                setNewImageSelectStatus(false)
                            }}

                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>
                                <span
                                    className=" h-screen  align-top"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className={`modal-loading inline-block w-full ${croppedImage == null ? 'max-w-lg' : 'max-w-[800px]'}  p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded `}>
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900 pb-2 border-b border-solid border-slate-200"
                                        >
                                            Image Editor

                                            <span className=' right cursor-pointer'
                                                onClick={() => {
                                                    clear()
                                                    setNewImageSelectStatus(false)
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </span>

                                        </Dialog.Title>
                                        <div className={`grid ${croppedImage == null ? 'grid-cols-1' : 'grid-cols-2'} gap-4`}>
                                            <div>
                                                <div className={'custom_cropper'}>
                                                    <Cropper
                                                        image={originImageSrc}
                                                        crop={crop}
                                                        zoom={zoom}
                                                        aspect={4 / 4}
                                                        onCropChange={setCrop}
                                                        onCropComplete={onCropComplete}
                                                        onZoomChange={setZoom}
                                                    />
                                                </div>
                                                <div >
                                                    <div >
                                                        <Typography
                                                            variant="overline"
                                                        >
                                                            Zoom
                                                        </Typography>
                                                        <Slider
                                                            value={zoom}
                                                            min={1}
                                                            max={3}
                                                            step={0.1}
                                                            aria-labelledby="Zoom"
                                                            onChange={(e, zoom) => setZoom(zoom)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {croppedImage != null ? (
                                                <div>
                                                    <img src={croppedImage} />
                                                </div>
                                            ) : null}
                                            <span
                                                className="imageUploader-btn-hbt cursor-pointer w-fit"
                                                onClick={showCroppedImage}
                                            >
                                                Show Result
                                            </span>
                                            {croppedImage != null ? (
                                                <span className='right'>

                                                    <span
                                                        className="imageUploader-btn-hbt right cursor-pointer mx-2 w-fit"
                                                        onClick={uploadImage}
                                                    >
                                                        Save
                                                    </span>
                                                    <span
                                                        className="px-4 py-2 right cursor-pointer text-sm text-white hbt-bg-dark border border-transparent rounded duration-300"
                                                        onClick={() => {
                                                            clear()
                                                            setNewImageSelectStatus(false)
                                                        }}
                                                    >
                                                        Cancel
                                                    </span>
                                                </span>
                                            ) : null}
                                        </div>


                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : null}

        </>
    );
}

export default ImageUploader;