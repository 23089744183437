import axios from "../../../api/axios";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Select from "react-select";
import { notifyError, notifySuccess } from "../../../helper/notification-helper";


const logo = 'http://sanmark.lk/logo';

const formSchema = Yup.object().shape({
    orgName: Yup.string().required('Organization name is required'),
    teamSize: Yup.number().required('Team size is required. Please enter a team size').min('1', 'Team size must be greator than or equal to 1').truncate(),
    industry: Yup.string().required('Industry is required'),
    inviteEmail: Yup.string().email('Please enter a valid email'),
    startDate: Yup.date(),
    orgTimeZone: Yup.array().required('Timezone is required'),
});

const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`
}


function CreateOrgForm({ setModalData, modalData, closeModal }) {

    const [submitBtnStatus, setSubmitBtnStatus] = useState(true)
    const timeZones = moment.tz.names();
    const options = timeZones.map((timezone, index) => ({
        value: timezone,
        label: timezone,
    }));
    const [selectedTimeZone, setSelectedTimeZone] = useState([]);
    const [timeZoneError, setTimeZoneError ] = useState("");
    
    const selectOnChange = (zone) => {
        setSelectedTimeZone(zone ? zone : []);
        setTimeZoneError("")
    };


    useEffect(() => {
        document.title = "Create Organization";
    }, []);

    // const notifyError = (msg) => {
    //     toast.error(msg, {
    //         position: toast.POSITION.TOP_LEFT
    //     });
    // };

    const CreateOrgRequest = async (data) => {
        try {
            const response = await axios.post('/organizations/', data, { headers })
            const organizationId = response.data?.data?.id;
            const organizationName = response.data?.data?.name;
            localStorage.setItem('organizationID', organizationId);
            localStorage.setItem('organizationName', organizationName);
            notifySuccess('Organization Created Successfully!');
            setTimeout(() => {
                window.location.replace(process.env.REACT_APP_FRONTEND_HOST + '/organizations');
            }, 7000);
        } catch (error) {
            notifyError('Something went wrong. Please try again later');
        }
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className={"flex flex-col mt-1 mx-auto mb-2 w-full"}>
                <p className="text-gray-400 text-base mt-1 text-left text-[11.8px]">We just need some basic info to get you started</p>
                <Formik
                    initialValues={{
                        orgName: '',
                        teamSize: 1,
                        industry: '',
                        inviteEmail: '',
                        startDate: '',
                        orgTimeZone: [],
                    }}
                    validationSchema={formSchema}
                    onSubmit={data => {                       
                        if (submitBtnStatus) {
                            if (selectedTimeZone.value === undefined || selectedTimeZone.value === "") {
                                setTimeZoneError("TimeZone is required");
                              } else {
                                  setSubmitBtnStatus(false)
                                  const formData = {
                                      name: data.orgName,
                                      industry: data.industry,
                                      team_size: data.teamSize,
                                                                      status: 1,
                                      logo_image_link: logo,
                                      start_date: new Date().toISOString().split('T')[0],
                                      timezone: selectedTimeZone.value,
                                  };
                                  CreateOrgRequest(formData);
                                  setTimeout(() => {
                                      setSubmitBtnStatus(true)
                                  }, 4000)
                                  setTimeout(() => {
                                      closeModal();
                                  }, 500)
                              }
                        }
                    }}
                >

                    {({ errors, touched }) => (
                        <Form className=" container mx-auto mt-2 align-center" >
                                <div className="py-1 w-full">
                                    <div>
                                        <label className="uppercase font-bold text-gray-500">
                                            Organization Name*
                                        </label>
                                        <Field
                                            className="mt-1 px-2 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#FFC400] focus:ring-[#FFC400] block w-full rounded-md focus:ring-1"
                                            placeholder="Enter a organization name"
                                            name='orgName'

                                        />
                                        {errors.orgName && touched.orgName ? (
                                            <p className="text-red-500 italic mt-1">{errors.orgName}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="py-1 w-full">
                                    <div>
                                        <label className="uppercase font-bold text-gray-500">
                                            Team Size*
                                        </label>
                                        <Field
                                            className="mt-1 px-2 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#FFC400] focus:ring-[#FFC400] block w-full rounded-md focus:ring-1"
                                            placeholder="Select team size"
                                            name='teamSize'
                                            type='number'
                                            min='1'
                                        />
                                        {errors.teamSize && touched.teamSize ? (
                                            <p className="text-red-500 italic mt-1">{errors.teamSize}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="py-1 w-full">
                                    <div>
                                        <label className="uppercase font-bold text-gray-500">
                                            Industry*
                                        </label>
                                        <Field
                                            className="mt-1 px-2 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#FFC400] focus:ring-[#FFC400] block w-full rounded-md focus:ring-1"
                                            placeholder="Enter an industry name"
                                            name='industry' />
                                        {errors.industry && touched.industry ? (
                                            <p className="text-red-500 italic mt-1">{errors.industry}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="py-1 w-full">
                                    <div>
                                        <label className="uppercase font-bold text-gray-500">
                                            Organization Timezone
                                        </label>.
                                        <Select
                                            name="orgTimeZone"
                                            options={options}
                                            onChange={selectOnChange}
                                            value={selectedTimeZone}
                                            // menuPosition="fixed"
                                            // menuPlacement="auto"
                                            className="mt-1 bg-white shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1" 
                                        />
                                        {/* {errors.orgTimeZone && touched.orgTimeZone ? (
                                            <p className="text-red-500 italic mt-1">{errors.orgTimeZone}</p>
                                        ) : null} */}

                                        {timeZoneError !== "" ? 
                                            (<p className="text-red-500 italic mt-1">
                                                {timeZoneError}
                                            </p>)
                                        : null 
                                        }
                                      
                                        {/* {selectedTimeZone ?
                                            (
                                                <p className="text-red-500 italic mt-1">Time Zone Required !</p>
                                            ) : null
                                        } */}                                        
                                    </div>
                                </div>
                            <div className="flex items-center justify-end pt-6">
                                <button
                                    className="ht-btn-black"
                                    type="button"
                                    onClick={closeModal}
                                    // onClick={() => setModalData({ ...modalData, modals: { type: 'insert', show: false } })}
                                >
                                    Cancel
                                </button>
                                {/* <button
                                    className={`${submitBtnStatus === false ? 'ht-btn-yellow-disabled':'ht-btn-yellow'}`}
                                    type="submit"
                                    onClick={() => {
                                    }}
                                >
                                    Save
                                </button> */}
                                {submitBtnStatus == false ? (
                                    <button type="button" className="ht-btn-yellow" disabled>
                                     Save
                                    </button>
                                ): (
                                    <button
                                    type="submit"
                                    className="ht-btn-yellow"
                                    onClick={() => {
                                        if (selectedTimeZone.value === undefined) {
                                            setTimeZoneError("TimeZone is required");
                                        }
                                    }}
                                    >
                                    Save
                                    </button>
                                )}
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}

export default CreateOrgForm;