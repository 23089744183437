import axios from "../api/axios";
import { logout } from "../hooks/logout";

let headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`
}

// Get All Members List
export const getActiveMembers = async (organizationId) => {
    const config = { headers: { ...headers, organization_id: organizationId } }
    try {
        const response = await axios.get('organizations/' + organizationId + '/members', config);
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// Get Members for projects
export const getMembersForProjects = async (organizationId) => {
    const config = { headers: { ...headers, organization_id: organizationId } }
    try {
        const response = await axios.get('/users?organization_id=' + organizationId, config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// Get All Invited Members List
export const getInvitedMembers = async (organizationId) => {
    const config = { headers: { ...headers, organization_id: organizationId } }
    try {
        const response = await axios.get('/verify-add-user-to-organization/' + organizationId + '/pending', config);
        return response.data.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// Send invitation
export const sendInvitation = async (organizationId, formData) => {
    const config = { headers: { ...headers, organization_id: organizationId } }
    try {
        const response = await axios.post('/organizations/' + organizationId, formData, config)
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// Cancel invitation
export const cancelInvitation = async (organizationId, userId) => {

    const config = { headers: { ...headers, organization_id: organizationId } };
    try {
        const response = await axios.delete('/verify-add-user-to-organization/' + organizationId + '/cancel/' + userId, config);
        return response.data;
       
    } catch (error) {
        // Logout when unauthorized
        if (error.response.status === 401) {
          logout();
           
        }
        return { data: [] };
    }
};

// Get user details
export const getUserDetails = async (userId) => {
    const config = { headers: { ...headers } }
    try {
        const response = await axios.get('/users/' + userId, config);
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// Update user details
export const updateUserDetails = async (userId, bodyData) => {
    const config = { headers: headers }
    try {
        const response = await axios.patch('/users/' + userId, bodyData, config);
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [error.response.data] };
    }
};

// Get self user data
export const getMyData = async (tag) => {
    let headers = {
        Authorization: `Bearer ${tag}`
    }
    const config = { headers: { ...headers} }
    try {
        const response = await axios.get('/authentication/me', config);
        return response;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// // Get public invitation URL.
// export const getPublicInvitationUrl = async () => {
//     const config = { headers: { ...headers} }
//     let body = {
//         'organizationPublicInviteToken': localStorage.getItem('organizationPublicInviteToken')
//     }
//     try {
//         const response = await axios.get('/organizations/public-invite', body, config);
//         return response;
//     } catch (error) {
//         // //logout when unauthorized
//         // if (error.response.status === 401) {
//         //     logout();
//         // }
//         // return { data: [] };
//     }
// }