import axios from "../api/axios";
import { logout } from "../hooks/logout";

let headers = {
  Authorization: `Bearer ${JSON.parse(localStorage.getItem("tag"))}`,
  organization_id: localStorage.getItem("organizationID"),
};

// Add Organization
export const getAllOrganizations = async () => {
  try {
    const response = await axios.get("/organizations", { headers });
    return response.data;
  } catch (error) {
    //logout when unauthorized
    if (error.response.status === 401) {
      logout();
    }
    return { data: [] };
  }
};

// Find an Organization
export const getOrganization = async (organizationId) => {
  try {
    const response = await axios.get("/organizations/" + organizationId, { headers });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      logout();
    }
    return { data: [] };
  }
};

// Add Organization
export const addNewOrganization = async (formData) => {
  try {
    const response = await axios.post("/organizations", formData, { headers });
    return response.data;
  } catch (error) {
    //logout when unauthorized
    if (error.response.status === 401) {
      logout();
    }
    return { data: [] };
  }
};

// Edit Organization
export const editOrganization = async (orgId, formData) => {
  try {
    const config = {
      headers: { ...headers },
    };
    const response = await axios.patch(
      "/organizations/" + orgId,
      formData,
      config
    );
    return response;
  } catch (error) {
    // logout when unauthorized
    if (error.response.status === 401) {
      logout();
    }
    return { data: [] };
  }
};

// Leave Organization
export const leaveOrganization = async (orgId, userId) => {
  try {
    const config = {
      headers: { ...headers },
    };
    const response = await axios.delete(
      "/organizations/" + orgId + "/leave/?user_id=" + userId,
      config
    );
    let res = {
      message: "success",
      code: response.data.code,
      data: response.data.data
    }
    return res;
  } catch (error) {
    // logout when unauthorized
    if (error.response.status === 401) {
      logout();
    }
    return error.response.data.error;
  }
};

// Archive Organization
export const archiveOrganization = async (orgId, formData) => {
  try {
    const config = {
      headers: { ...headers },
    };
    const response = await axios.patch(
      "/organizations/" + orgId + "/archive",
      formData,
      config
    );
    let res = {
      message: "success",
      code: response.data.code,
      data: response.data.data
    }
    return res;
  } catch (error) {
    // logout when unauthorized
    if (error.response.status === 401) {
      logout();
    }
    return error.response.data.error;
  }
};

// Update Organization settings
export const updateOrganizationSettings = async (orgId, memberId = null, formData) => {
  try {
    const config = {
      headers: { ...headers },
    };

    let request = "/organizations/" + orgId + "/settings"

    if (memberId) {
      request = "/organizations/" + orgId + "/users/" + memberId + "/settings"
    }

    const response = await axios.patch(
      request,
      formData,
      config
    );
    return response;
  } catch (error) {
    // logout when unauthorized
    if (error.response.status === 401) {
      logout();
    }
    return { data: [] };
  }
};

// Check Permissions 
export const checkOrgPermissions = () => {
  console.log('orgUserRole - ', localStorage.getItem('orgUserRole'));
  if (localStorage.getItem('orgUserRole') != 1) {
    window.location.replace("/");
  } else {
    return true;
  }
}

export const publicInvitationRequest = async (tokan) => {
  let formData = {
    organizationPublicInviteToken: tokan
  }

  try {
    const response = await axios.post("/organizations/public-invite", formData, { headers });
    return response.data;
  } catch (error) {
    //logout when unauthorized
    if (error.response.status === 401) {
      logout();
    }
    return { data: [] };
  }
}
