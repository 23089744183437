import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
    filteredMember: null,
    filteredProject: null,
    filteredTimeType: null,
    filteredSource: null,
    filterParams: null,
    filterData:{
        members:[],
        projects:[],
        sources:[],
        trackingTypes:[],
        activityLevels:[]
    }
}

export const filterSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFilteredMember: (state, action) => {
            state.filteredMember = action.payload

            if (state.filteredMember != null) {
                state.filterParams = { ...state.filterParams, user_id: state.filteredMember.id };
            }
        },
        setFilteredProject: (state, action) => {
            state.filteredProject = action.payload

            if (state.filteredProject != null) {
                state.filterParams = { ...state.filterParams, project_id: state.filteredProject.id };
            }
        },
        setFilteredTimeType: (state, action) => {
            state.filteredTimeType = action.payload

            if (state.filteredTimeType != null) {
                state.filterParams = { ...state.filterParams, tracking_type: action.payload.value };
            }

        },
        setFilteredSource: (state, action) => {
            state.filteredSource = action.payload

            if (state.filteredSource != null) {
                state.filterParams = { ...state.filterParams, source: state.filteredSource.value };
            }

        },
        
        setFilterParams: (state, action) => {
            state.filterParams = { ...state.filterParams, ...action.payload }
        },
        setFilterData: (state, action) => {
            state.filterData = { ...state.filterData, ...action.payload }
        },
        clearFilter: (state, action) => {
            state.filteredMember = null;
            state.filteredProject = null;
            state.filteredTimeType = null; 
            state.filteredSource = null;
            state.filterParams = { ...state.filterParams, project_id: null, tracking_type: null, source: null };
        },
    },
    extraReducers: (builder) => {

    }
})

// Action creators are generated for each case reducer function
export const { setFilteredMember, setFilteredProject, setFilteredTimeType, setFilteredSource, setFilterParams, setFilterData, clearFilter } = filterSlice.actions

export default filterSlice.reducer


