import FsLightbox from "fslightbox-react";
import { useEffect, useState } from "react";
import { imageExists } from "../../globle-component/globle";

export const ImageLightBox = ({ screens }) => {
    const [toggler, setToggler] = useState(false);
    const [lightBoxArray, setLightBoxArray] = useState([])

    useEffect(() => {
        setUrlArray(screens)
    }, [])

    function setUrlArray(screens) {
        let imageArray = [];

        screens.forEach(element => {
            imageArray.push(element.originalUrl)
        });
        setLightBoxArray(imageArray);
    }

    return (
        <>
            <div
                onClick={() => setToggler(!toggler)}
                className="absolute w-full py-2.5 bottom-0 inset-x-0 bg-yellow-400 text-xs text-center leading-4 screenshout-count cursor-pointer"><b>{lightBoxArray.length}</b> screenshots</div>
            <FsLightbox
                toggler={toggler}
                type={'image'}
                sources={lightBoxArray}
            />
        </>
    );
}

export const ImageLightboxForSingle = ({ screen , visibility}) => {

    const [toggler, setToggler] = useState(false);

    return (
        <>
        {visibility === true ? (
            <div
                onClick={() => {
                    setToggler(!toggler)
                }}
                className="absolute w-full py-2.5 bottom-0 inset-x-0 bg-yellow-400 text-xs text-center leading-4 screenshout-count cursor-pointer">View full Image</div>
        ):null}
            <>
                <FsLightbox
                    toggler={toggler}
                    type={'image'}
                    sources={[screen.originalUrl]}
                />
            </>
        </>
    );
}
