import React from 'react'
import { Loader } from '../../helper/loading';
import { Link } from "react-router-dom";
import TermsList from './components/terms_list';
import { useState } from "react";
import { useEffect } from "react";
import logo from "../../img/logo/Honey bee time tracker logo dark.png";

const TermsConditions = () => {
    const [loading, setLoading] = useState(true);

    const checkLoad = () => {
        {
            document.readyState === 'loading' 
            ? (setLoading(true)) 
            : (setLoading(false))
        }
    }
    useEffect(() => {
        checkLoad();
        document.title = "HoneybeeTime-Terms & Conditions";
      }, []);
    return (
        <>
        {
            loading 
            ? (<Loader />) 
            : (
                <>
                    <div className='custom-body w-full bg-gray-200 h-full min-h-screen flex'>
                        <div className='bg-white mx-auto w-3/4 m-10 h-full rounded-lg shadow-lg'>
                            <div className='logo-container mt-[70px] mb-2 w-full'>
                                <img 
                                    src={logo}
                                    alt="Logo of HoneybeeTime"
                                    className='w-1/2 mx-auto'
                                />
                            </div>
                            <div className='title-container items-center'>
                                <h1 className='text-center'>
                                    <span className='text-[#3d3d3d] text-[25px] font-medium'>Terms & Conditions</span>
                                </h1>
                            </div>

                            <hr className='w-3/4 h-1 mx-auto my-4 bg-gray-100 border-0 rounded'/>

                            <div className='terms-container px-8 py-4 text-[14px]'>
                                <p className='text-justify'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse quis suscipit est. Suspendisse lacinia, nibh eu blandit lobortis, ante mauris placerat felis, a tristique justo eros sit amet nibh. Maecenas id nisl id quam commodo blandit vitae eu purus. Vivamus sem tellus, euismod at sagittis tempor, pretium sit amet ex. Integer sit amet blandit elit, et imperdiet lacus. Integer sed tortor convallis, sollicitudin turpis eget, ultricies quam.
                                </p>
                                <div className='py-2'>
                                    <TermsList />
                                </div>
                            </div>

                            <hr className='w-3/4 h-1 mx-auto my-4 bg-gray-100 border-0 rounded'/>

                            <div className='continue-to-sign-up-container w-full flex justify-center my-5'>
                                <Link to={"/signup"} className='w-auto'>
                                    <button className='my-3 mx-auto w-full text-white bg-[#F6921E] hover:bg-[#EE8000] font-medium rounded-lg shadow-sm px-4 py-2 text-[14px]'>
                                        Back to Sign Up
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default TermsConditions