import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack, TextField } from '@mui/material';
import React from 'react';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const TimePicker = ({ className, defaultTime, setNewValue, field = null, setFieldValue = null }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DesktopTimePicker
                    className={className}
                    value={defaultTime}
                    onChange={(newValue) => {
                        setNewValue(newValue);

                        // if (newValue === null || newValue?.$d.toString() === "Invalid Date") {
                        //     // console.log('Invalid Date');
                        //     setNewValue(null)
                        // } else {
                        //     // setNewValue(moment(newValue.$d.toISOString()).format('HH:mm:ss'))
                        // }

                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Stack>
        </LocalizationProvider>
    );
}

export default TimePicker;