import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredProject, setFilteredTimeType, setFilteredSource, clearFilter } from '../../store/slices/filters';
import { UserRole } from "../../constant/userRole";
import { Dropdown } from "./dropdown";

const timeType = [
  { id: 1, name: "Systematic", displayName: "Systematic", value: "systematic" },
  { id: 2, name: "Manual", displayName: "Manual", value: "manual" },
];

const sourceType = [
  { id: 1, name: "Desktop", displayName: "Desktop", value: "desktop" },
  { id: 2, name: "Mobile", displayName: "Mobile", value: "mobile" },
  { id: 3, name: "Browser", displayName: "Browser", value: "browser" },
  { id: 4, name: "Web timer", displayName: "Web timer", value: "web-timer" },
];

let organizationUserRole = JSON.parse(localStorage.getItem("orgUserRole"));

const Filters = ({
  selectedUser,
  setSelectedUser,
  searchBoxVisible
}) => {
  const dispatch = useDispatch();
  const { filterData } = useSelector((state) => state.filters)

  const [fillterDisplayStatus, setFillterDisplayStatus] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null)
  const [selectedType, setSelectedType] = useState(null)
  const [selectSource, setSelectSource] = useState(null)

  const sidebarClosed = () => {
    setFillterDisplayStatus(false);
  };

  const clearFilters = () => {
    setSelectedProject(null);
    setSelectedType(null);
    setSelectSource(null);


    dispatch(setFilteredProject(null));
    dispatch(setFilteredTimeType(null));
    dispatch(setFilteredSource(null));
    
    dispatch(clearFilter());
  };


  useEffect(() => {
    if (selectedProject !== null) {
      dispatch(setFilteredProject(selectedProject))
    }
  }, [selectedProject])

  useEffect(() => {
    if (selectedType !== null) {
      dispatch(setFilteredTimeType(selectedType))
    }
  }, [selectedType])

  useEffect(() => {
    if (selectSource !== null) {
      dispatch(setFilteredSource(selectSource))
    }
  }, [selectSource])


  return (
    <>
      <button
        className="py-1.5 w-full font-bold border-2 sm:text-sm text-xs transition-all duration-300 ease-in-out border-yellow-300 rounded focus:outline-none hover:shadow-lg hover:bg-yellow-300 mb-2"
        type="button"
        onClick={() => {
          setFillterDisplayStatus(true);
        }}
      >
        <span className={"mx-auto"}>Filter</span>
      </button>

      <div
        className={
          "fixed w-[100%] h-screen top-0 z-10 left-0 " +
          (fillterDisplayStatus === true ? "filter-panel" : "hidden")
        }
        onClick={() => {
          setFillterDisplayStatus(false);
        }}
      ></div>

      {fillterDisplayStatus ? (
        <>
          <div className="flex fixed inset-y-0 -right-[10px] shadow-lg h-screen w-[300px] bg-white  slidebarOpen filter filter-panel-show">
            <div className="">
              <div className="flex flex-row justify-between">
                <div className="order-last">
                  <button
                    className="mx-5 mt-5 hover:bg-gray-200 rounded"
                    onClick={() => sidebarClosed()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#64748b"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="mt-10 mx-3 px-5 border-b-4 border-[#FFC400] w-20">
                  <span className="text-[#171923] font-bold mx-auto">
                    Filter
                  </span>
                </div>
              </div>

              {searchBoxVisible ? (
                <div className="mx-5 mt-5">
                  <label className="text-gray-500 text-sm font-bold">
                    Members
                  </label>
                  <div className="mt-2 w-[250px]">
                    <Dropdown
                      listData={filterData.members}
                      multiselect={false}
                      placeholder={'Search members'}
                      selectedItems={selectedUser}
                      setSelectedItems={setSelectedUser}
                      descriptionShow={true}
                    />
                  </div>
                </div>
              ) : null}

              <div className="mx-5 mt-5">
                <label className="text-gray-500 text-sm font-bold">
                  Project
                </label>
                <div className="mt-2 w-[250px]">
                  <Dropdown
                    listData={filterData.projects}
                    multiselect={false}
                    placeholder={'Search project'}
                    selectedItems={selectedProject}
                    setSelectedItems={setSelectedProject}
                    descriptionShow={false}
                  />
                </div>
              </div>
            
              <div className="mx-5 mt-5">
                <label className="text-gray-500 text-sm font-bold">
                  Time-type
                </label>
                <div className="mt-2 w-[250px]">
                  <Dropdown
                    listData={timeType}
                    multiselect={false}
                    placeholder={'Search type'}
                    selectedItems={selectedType}
                    setSelectedItems={setSelectedType}
                    descriptionShow={false}
                  />
                </div>
              </div>
             
              <div className="mx-5 mt-5">
                <label className="text-gray-500 text-sm font-bold">
                  Source
                </label>

                <div className="mt-2 w-[250px]">
                  <Dropdown
                    listData={sourceType}
                    multiselect={false}
                    placeholder={'Search source'}
                    selectedItems={selectSource}
                    setSelectedItems={setSelectSource}
                    descriptionShow={false}
                  />
                </div>
              </div>

              {/* <div className="mx-5 mt-5">
                <label className="text-gray-500 text-sm font-bold">
                  Activity level
                </label>
                <div className="mt-2 w-[250px]">
                  <Dropdown
                    listData={sourceType}
                    multiselect={false}
                    placeholder={'Search level'}
                    selectedItems={selectSource}
                    setSelectedItems={setSelectSource}
                    descriptionShow={false}
                  />
                </div>
              </div> */}

              <div className="mx-5 mt-5">
                <button
                  className="mt-2 w-[250px] border bg-[#FBE77F] border-[#FBE77F] rounded-md py-1.5 hover:bg-[#FADE4B]"
                  onClick={() => {
                    clearFilters()
                    // dispatch(clearFilter())
                  }}
                >
                  <span className="font-bold ">Clear Filters</span>
                </button>
              </div>


            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Filters;
