import axios from "../../../api/axios";
import {
  GoogleLogin,
  useGoogleLogin,
  GsiButtonConfiguration,
} from "@react-oauth/google";
import { ReactComponent as GoogleSvg } from "../../../img/logo/google.svg";

const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;

function Login({ logginDataSet, clientId }) {
  const onSuccess = (res) => {
    GoogleLoginSubmit(res.credential);
    console.log("LOGIN SUCCESS");
  };

  const onFailure = (res) => {
    console.log("LOGIN FAILED! res: ", res);
  };

  const GoogleLoginSubmit = async (token) => {
    const formData = { id_token: token };
    if (localStorage.hasOwnProperty("invited-user-token")) {
      formData.verifyToken = localStorage.getItem("invited-user-token");
    }

    if((localStorage.hasOwnProperty('public-verification-tokan')) && (localStorage.getItem('public-verification-tokan') !== '' && localStorage.getItem('public-verification-tokan') !== 'null')){
      formData.organizationPublicInviteToken = localStorage.getItem('public-verification-tokan')
    }

    try {
      const response = await axios.post("/authentication/google", formData);
      logginDataSet(response.data);
      return response.data;
    } catch (error) {
      return { data: [] };
    }
  };

  const login = useGoogleLogin({
    onSuccess: (credentialResponse) =>
      onSuccess({
        clientId,
        credential: credentialResponse["access_token"],
        select_by: "btn",
      }),
    onError: () => console.log("Login Failed"),
  });

  // import { useGoogleLogin } from '@react-oauth/google';

  // const login = useGoogleLogin({
  //   onSuccess: tokenResponse => console.log(tokenResponse),
  // });

  // <MyCustomButton onClick={() => login()}>
  //   Sign in with Google 🚀{' '}
  // </MyCustomButton>;

  return (
    <div
      id="googleSignInButton"
      className="sm:w-[380px] w-[250px] flex justify-center text-center"
    >
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          console.log(credentialResponse);
          onSuccess(credentialResponse);
        }}
        onError={() => {
          console.log("Login Failed");
        }}
        width="380px"
      />
    </div>
  );
}

export default Login;
