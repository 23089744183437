import { Triangle } from "react-loader-spinner";

export const TableLoadingTriangle = ({thCount}) => {
    return (
        <tr className="bg-white border-b text-center">
            <td colSpan={thCount} className="px-6 py-4 font-medium text-gray-400 whitespace-nowrap">
                <div className="flex flex-row justify-center">
                    <Triangle
                        height="40"
                        width="40"
                        color="#ffb30f"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{ 'margin': '47px' }}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            </td>
        </tr>
    )
}
