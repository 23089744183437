import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { loadingHide, loadingShow } from "../../../../helper/loading";
import { newEmailUpdateVerify } from "../../../../services/account";
import { getUserDetails } from "../../../../services/members";

const EmailUpdateVerify = () => {
    let params = useParams()
    const [verify, setVerify] = useState(false)
    const [tokenExpire, setTokenExpire] = useState(false)
    const [loginStatus, setLoginStatus] = useState(false)

    useEffect(() => {
        loadingShow()
        if ((localStorage.hasOwnProperty('tag')) && (localStorage.getItem('tag') !== '' && localStorage.getItem('tag') !== 'null')) {
            setLoginStatus(true);
        }
        if (params.token !== null || params.token !== undefined) {
            let formObject = {
                "verifyToken": params.token,
            }

            if ((localStorage.hasOwnProperty('email-update-verification-token') && localStorage.getItem('email-update-verification-token') !== 'verified') || !localStorage.hasOwnProperty('email-update-verification-token')) {
                newEmailUpdateVerify(formObject).then(function (response) {
                    if (response.code === 201) {
                        setTimeout(() => {
                            localStorage.setItem('email-update-verification-token', 'verified');
                            if (localStorage.hasOwnProperty('userId')) {
                                getUserDetails(localStorage.getItem('userId')).then(({ data }) => {
                                    let userObj = JSON.parse(localStorage.getItem('user'))
                                    userObj.email = data.email;
                                    localStorage.setItem('user',JSON.stringify(userObj))
                                })
                            }
                            setTokenExpire(false);
                            setVerify(true);
                            loadingHide()
                        }, 2000);
                    } else if (response.data.response.status === 404) {
                        setTimeout(() => {
                            localStorage.setItem('email-update-verification-token', 'not-verified');
                            setTokenExpire(true);
                            setVerify(false);
                            loadingHide()
                        }, 2000);
                    }
                })
            } else if (localStorage.hasOwnProperty('email-update-verification-token') && localStorage.getItem('email-update-verification-token') === 'verified') {
                setTokenExpire(true);
                setVerify(false);
                loadingHide()
            }
        }
    }, [params.token])

    return (
        <>
            <div className="relative flex flex-row h-screen justify-center items-center w-full custom-body">
                {verify ? (
                    <>
                        <div className='w-full'>
                            <div className='px-32 py-44 mx-auto w-2/6'>
                                <div className="flex flex-col">
                                    <div className={'justify-items-center text-center'}>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 p-2 rounded-full mx-auto bg-green-600 text-white my-3">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                            </svg>
                                        </div>
                                        <div className={'text-xl'}>Email address verified!</div>
                                        {loginStatus ? (<>
                                            <div><Link className={'text-sm text-blue-600'} to={'/'}>Click here to go to the Dashboard</Link></div>
                                        </>) : <>
                                            <div><Link className={'text-sm text-blue-600'} to={'/login'}>Click here to go to the Login page</Link></div>
                                        </>}

                                    </div>

                                </div>
                            </div>
                            <div className=' w-full  justify-bottom items-center text-center'>HoneybeeTime.com</div>
                        </div>
                    </>
                ) : null}

                {tokenExpire ? (
                    <>
                        <div className='w-full'>
                            <div className='px-32 py-44 mx-auto w-2/6'>
                                <div className="flex flex-col">
                                    <div className={'justify-items-center text-center'}>
                                        <div>

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 p-2 rounded-full mx-auto bg-red-600 text-white my-3">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>

                                        </div>
                                        <div className={'text-xl'}>Token Expired!</div>
                                        {loginStatus ? (<>
                                            <div><Link className={'text-sm text-blue-600'} to={'/'}>Click here to go to the Dashboard</Link></div>
                                        </>) : <>
                                            <div><Link className={'text-sm text-blue-600'} to={'/login'}>Click here to go to the Login page</Link></div>
                                        </>}

                                    </div>

                                </div>
                            </div>
                            <div className=' w-full  justify-bottom items-center text-center'>HoneybeeTime.com</div>
                        </div>
                    </>
                ) : null}

            </div>
        </>
    );
}

export default EmailUpdateVerify;