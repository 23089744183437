import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";
import Select from 'react-select';
import Switch from '@mui/material/Switch';

const Filters = ({
    value,
    selectedUser,
    setSelectedUser,
    membersList,
    projectList,
    selectedProjectList,
    setSelectedProjectList,
    filteredProject,
    setFilteredProject,
    filteredReload,
    setFilteredReload,
    tasksList,
    selectedTasksList,
    setSelectedTasksList
}) => {
    const [fillterDisplayStatus, setFillterDisplayStatus] = useState(false);
    const sumDateRangesLabel = { inputProps: { 'aria-label': 'Sum date ranges' } };
    const includeArchivedProjectsLabel = { inputProps: { 'aria-label': 'Include archived projects' } };
    const [sumDateRanges, setSumDateRanges] = useState(true);
    const [includeArchivedProjects, setIncludeArchivedProjects] = useState(true);

    useEffect(() => {
        // console.log('sum date-->', sumDateRanges);
    }, [sumDateRanges])

    const memberListForDropdown = membersList.map((person) => ({
        "value": person.user.id,
        "label": person.user.full_name
    }));
    const projectsListForDropdown = projectList.map((project) => ({
        "value": project.id,
        "label": project.name
    }));
    const tasksListForDropdown = tasksList.map((task) => ({
        "value": task.id,
        "label": task.title
    }));

    const sidebarClosed = () => {
        setFillterDisplayStatus(false);
    };

    const clearFilters = () => {
        setSelectedProjectList([]);
        setSelectedTasksList([]);
        setSelectedUser([]);
    };

    useEffect(() => {
        setFilteredProject(null);
    }, [selectedUser]);

    return (
        <>
            <button
                className="py-1.5 font-bold border-2 sm:text-sm text-xs transition-all duration-300 ease-in-out border-yellow-300 rounded focus:outline-none hover:shadow-lg hover:bg-yellow-300 mb-2 w-[100px]"
                type="button"
                onClick={() => {
                    setFillterDisplayStatus(true);
                }}
            >
                <span className={"mx-auto"}>Filter</span>
            </button>

            <div
                className={
                    "fixed w-[100%] h-screen top-0 z-10 left-0 " +
                    (fillterDisplayStatus === true ? "filter-panel" : "hidden")
                }
                onClick={() => {
                    setFillterDisplayStatus(false);
                }}
            ></div>

            {fillterDisplayStatus ? (
                <>
                    <div className="flex fixed inset-y-0 -right-[10px] shadow-lg h-screen w-[300px] bg-white  slidebarOpen filter filter-panel-show">
                        <div className="">
                            <div className="flex flex-row justify-between">
                                <div className="order-last">
                                    <button
                                        className="mx-5 mt-5 hover:bg-gray-200 rounded"
                                        onClick={() => sidebarClosed()}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="#64748b"
                                            className="w-4 h-4"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="mt-10 mx-3 px-5 border-b-4 border-[#FFC400] w-20">
                                    <span className="text-[#171923] font-bold mx-auto">
                                        Filter
                                    </span>
                                </div>
                            </div>

                            <div className="mx-5 mt-5">
                                <label className="text-gray-500 text-sm font-bold">
                                    Project
                                </label>
                                <div className="mt-2 w-[250px]">
                                    <Select
                                        isMulti
                                        name="projects"
                                        options={projectsListForDropdown}
                                        onChange={
                                            (project) => {
                                                setSelectedProjectList(project);
                                            }
                                        }
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={selectedProjectList}
                                    />
                                </div>
                            </div>

                            {value === 1
                                ? (
                                    <div className="mx-5 mt-5">
                                        <label className="text-gray-500 text-sm font-bold">
                                            Members
                                        </label>
                                        <div className="mt-2 w-[250px]">
                                            <Select
                                                isMulti
                                                name="members"
                                                options={memberListForDropdown}
                                                onChange={
                                                    (person) => {
                                                        setSelectedUser(person);
                                                    }
                                                }
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                value={selectedUser}
                                            />
                                        </div>
                                    </div>
                                ) : null}

                            <div className="mx-5 mt-5">
                                <label className="text-gray-500 text-sm font-bold">
                                    Tasks
                                </label>
                                <div className="mt-2 w-[250px]">
                                    <Select
                                        isMulti
                                        name="tasks"
                                        options={tasksListForDropdown}
                                        onChange={
                                            (task) => {
                                                setSelectedTasksList(task);
                                            }
                                        }
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={selectedTasksList}
                                    />
                                </div>
                            </div>

                            <div className="mx-5 mt-5">
                                <div className="flex flex-row my-auto">
                                    <div className="w-auto h-auto">
                                        <Switch {...sumDateRangesLabel} onChange={(data) => { setSumDateRanges(data.target.checked); }} value={sumDateRanges} disabled />
                                    </div>
                                    <div className="text-base text-left my-auto">Sum Date Ranges</div>
                                </div>
                            </div>

                            <div className="mx-5 mt-1">
                                <div className="flex flex-row my-auto">
                                    <div className="w-auto h-auto">
                                        <Switch {...includeArchivedProjectsLabel} onChange={(data) => { setIncludeArchivedProjects(data.target.checked); }} value={includeArchivedProjects} disabled />
                                    </div>
                                    <div className="text-base text-left my-auto">Include Archived Projects</div>
                                </div>
                            </div>

                            <div div className="mx-5 mt-5">
                                <button
                                    className="mt-2 w-[250px] border bg-[#FBE77F] border-[#FBE77F] rounded-md py-1.5 hover:bg-[#FADE4B]"
                                    onClick={() => clearFilters()}
                                >
                                    <span className="text-[] font-bold ">Clear Filers</span>
                                </button>
                            </div>

                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default Filters