import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
    notifyError,
    notifySuccess,
} from "../../../helper/notification-helper";
import { loadingHide, loadingShow } from "../../../helper/loading";
import { useDispatch } from "react-redux";
import { getTaskData } from "../../../store/slices/tasks";
import { markAsCompleted } from "../../../services/tasks";
import { NameProccess } from "../../globle-component/globle";

const organizationId = JSON.parse(localStorage.getItem("organizationID"));

const initModal = {
    formData: {
        taskId: "",
        taskName: "",
        taskAssignee: "",
        taskCreatedDate: "",
        taskStatus: "",
    },
    modals: {
        type: "",
        show: false,
    },
};

const MarkAsCompleteModal = ({ modalData, setModalData }) => {
    const [markAsCompleteBtnState, setMarkAsCompleteBtnState] = useState(true);
    const dispatch = useDispatch();

    function closeModal() {
        setModalData(initModal);
    }

    const markAsCompleteModalSubmit = async (taskID) => {
        loadingShow(".modal-loading");
        try {
            markAsCompleted(taskID).then((response) => {
                if (response.code === 200) {
                    notifySuccess("Task marked as complete Successfully !");
                    setTimeout(() => {
                        loadingHide(".modal-loading");
                        closeModal();
                        dispatch(getTaskData(organizationId))
                    }, 1000);
                } else {
                    notifyError("Task marking as complete ERROR !");
                }
            });
        } catch (error) {
            notifyError(error);
        }
    };

    return (
        <>
            {modalData.modals.type === "markAsComplete" && modalData.modals.show === true ? (
                <>
                    <Transition appear show={true} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/60"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span className=" h-screen  align-top" aria-hidden="true">
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="modal-loading inline-block w-full max-w-md p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded">
                                        <Dialog.Title as="h3" className="ht-modal-header">
                                            Mark as Complete {NameProccess(modalData.formData.taskName, 15)} task?
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p>Are you sure you want to mark this task as complete?</p>
                                        </div>
                                        <div className="mt-4 flex justify-end">
                                            <button
                                                type="button"
                                                className="ht-btn-black"
                                                onClick={closeModal}
                                            >
                                                Close
                                            </button>

                                            {markAsCompleteBtnState == false ? (
                                                <button type="button" className="ht-btn-yellow-disabled" disabled>
                                                    Mark as Complete
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="ht-btn-yellow"
                                                    onClick={() => {
                                                        markAsCompleteModalSubmit(modalData.formData.taskId);
                                                    }}
                                                >
                                                    Mark as Complete
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : null}
        </>
    )
}

export default MarkAsCompleteModal