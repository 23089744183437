import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { setData } from "./fullCalendar/event-utils";
import moment from "moment";
import { checkTodayIsInWeek } from "../../../component/globle-component/globle";

const CalendarWeekView = ({
  worktimeDataForCalendar,
  setSelectedDayForWeek,
  setAddTimeToCalendar,
  setAddTimeFromCalendar,
  addTimeFromCalendar,
}) => {
  const [state, setState] = useState({
    weekendsVisible: true,
    currentEvents: [],
  });
  const today = new Date();
  const [buttonStatus, setButtonStatus] = useState(false);

  const [calendarData, setCalendarData] = useState([]);
  const [currentDate, setCurrentDate] = useState(today);
  const calendarRef = useRef();

  const handleDateSelect = (selectInfo) => {
    setAddTimeToCalendar({
      start: new Date(selectInfo.startStr),
      end: new Date(selectInfo.endStr),
    });
    setAddTimeFromCalendar(true);
  };

  const handleEvents = (events) => {
    if (events.length > 0) {
      setCurrentDate(events[0]._context.dateProfile.currentDate);
    }

    if (checkTodayIsInWeek(currentDate)) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }

    setState({
      ...state,
      currentEvents: events,
    });
  };

  useEffect(() => {
    setSelectedDayForWeek(currentDate);
  }, [currentDate]);

  useEffect(() => {
    if (worktimeDataForCalendar.length > 0) {
      setCalendarData(setData(worktimeDataForCalendar));
    }
  }, [worktimeDataForCalendar]);

  function setNewWeek(date, type) {
    if (type === "today") {
      setButtonStatus(false);
      setCurrentDate(today);
    } else {
      let oldDate = new Date(date);
      let newDate = null;
      if (type === "prev") {
        newDate = oldDate.setDate(oldDate.getDate() - 7);
        setButtonStatus(true);
        setCurrentDate(new Date(newDate));
      } else if (type === "next") {
        newDate = oldDate.setDate(oldDate.getDate() + 7);
        if (checkTodayIsInWeek(newDate)) {
          setButtonStatus(false);
          setCurrentDate(today);
        } else {
          setButtonStatus(true);
          setCurrentDate(new Date(newDate));
        }
      }
    }
  }

  return (
    <>
      <div className="">
        <div className="relative -top-[118px] lg:-top-[52px] left-[175px] lg:float-left flex lg:flex-row sm:-top-[120px] xs:-top-[108px]">
          <button
            className="mr-2 px-2 py-2 md:text-sm text-xs transition-colors duration-150 hbt-bg-dark text-white rounded focus:outline-none hover:shadow-lg active:bg-gray-500 mb-2"
            onClick={() => {
              let calendarApi = calendarRef.current.getApi();
              calendarApi.prev();
              setNewWeek(currentDate, "prev");
            }}
          >
            Prev
          </button>
          {buttonStatus ? (
            <>
              <button
                className="mr-2 px-2 py-2 md:text-sm text-xs transition-colors duration-150 hbt-bg-dark text-white rounded focus:outline-none hover:shadow-lg active:bg-gray-500 mb-2"
                onClick={() => {
                  let calendarApi = calendarRef.current.getApi();
                  calendarApi.next();
                  setNewWeek(currentDate, "next");
                }}
              >
                Next
              </button>
            </>
          ) : (
            <>
              <button className="mr-2 px-2 py-2 md:text-sm text-xs transition-colors duration-150 hbt-bg-dark text-white rounded focus:outline-none hover:shadow-lg active:bg-gray-500 mb-2 disabled">
                Next
              </button>
            </>
          )}
          {buttonStatus ? (
            <>
              <button
                className="lg:mr-2 mr-0 px-2 py-2 md:text-sm text-xs transition-colors duration-150 hbt-bg-dark text-white rounded focus:outline-none hover:shadow-lg active:bg-gray-500 mb-2"
                onClick={() => {
                  let calendarApi = calendarRef.current.getApi();
                  calendarApi.today();
                  setNewWeek(today, "today");
                }}
              >
                Today
              </button>
            </>
          ) : (
            <>
              <button className="lg:mr-2 mr-0 px-2 py-2 md:text-sm text-xs transition-colors duration-150 hbt-bg-dark text-white rounded focus:outline-none hover:shadow-lg active:bg-gray-500 mb-2 disabled">
                Today
              </button>
            </>
          )}
        </div>
        <div className="demo-app relative h-full">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "",
              center: "",
              right: "",
            }}
            dayHeaderContent={(args) => {
              let element = (
                <div className="grid grid-cols-2 lg:gap-1 gap-0">
                  <div className="xl:text-[36px] lg:text-[28px] md:text-[22px] text-[14px] font-medium text-right">
                    {moment(args.date).format("DD")}
                  </div>
                  <div className="text-left lg:py-2 py-1 px-2 lg:px-0">
                    <div className="xl:text-[12px] lg:text-[10px] md:text-[8px] text-[6px] font-semibold">
                      {moment(args.date).format("MM")}
                    </div>
                    <div className="xl:text-[11px] lg:text-[9px] md:text-[7px] text-[5px] font-light ">
                      {moment(args.date).format("MMM")}
                    </div>
                  </div>
                </div>
              );
              return element;
            }}
            initialView="timeGridWeek"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            allDaySlot={false}
            weekends={state.weekendsVisible}
            events={calendarData} // alternatively, use the `events` setting to fetch from a feed
            select={handleDateSelect}
            eventsSet={handleEvents}
          />
        </div>
      </div>
    </>
  );
};

export default CalendarWeekView;
