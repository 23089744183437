import React from 'react';

function TeamWorkSelect() {

    React.useEffect(() => {
        document.title = "Team Work Selection";
    }, []);

    const forwardLink = () => {
        let origin = window.location.origin;
        window.location.replace(origin + '/createorg');
    }

    return (
        <div className="container mx-auto w-1/2 flex flex-col py-48 ">
            <h2 className="text-2xl font-bold text-gray-800 leading-tight mx-auto">Where does your team work?</h2>
            <p className="mx-auto mt-5 text-gray-400 font-semibold">This will help us customize your onboarding experience</p>
            <div className="flex flex-col mx-auto py-5 w-1/2">

                <div className="flex flex-row m-5 p-2 justify-between hover:bg-gray-300 rounded-md " onClick={forwardLink}>
                    <div className="bg-green-600 w-12 h-12 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="white" className="w-8 h-8 mx-auto mt-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                        </svg>
                    </div>
                    <div className="px-5 flex flex-col justify-center w-3/4" >
                        <span className="text-lg text-gray-700 font-bold">In an Office</span>
                        <span className="text-gray-400 ">We work on computers</span>
                    </div>
                    <div className="order-last">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="gray" className="w-8 h-8 mt-3">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>

                    </div>


                </div>
                <div className="flex flex-row m-5 p-2 justify-between hover:bg-gray-300 rounded-md " onClick={forwardLink}>
                    <div className="bg-blue-600 w-12 h-12 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-8 h-8 mx-auto mt-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>

                    </div>
                    <div className="px-5 flex flex-col justify-center w-3/4" >
                        <span className="text-lg text-gray-700 font-bold">In the feild</span>
                        <span className="text-gray-400">We do service work at job sites</span>
                    </div>
                    <div className="order-last">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="gray" className="w-8 h-8 mt-3">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>

                    </div>

                </div>
                <div className="flex flex-row m-5 p-2 justify-between hover:bg-gray-300 rounded-md" onClick={forwardLink}>
                    <div className="bg-purple-700 w-12 h-12 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-8 h-8  mx-auto mt-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>

                    </div>
                    <div className="px-5 flex flex-col justify-center w-3/4" >
                        <span className="text-lg text-gray-700 font-bold">Office & feild</span>
                        <span className="text-gray-400 ">some of both</span>
                    </div>
                    <div className="order-last">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="gray" className="w-8 h-8 mt-3">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>

                    </div>

                </div>




            </div>

        </div >
    );
}

export default TeamWorkSelect;