import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../api/axios";
import { loadingHide, loadingShow } from "../../../helper/loading";
import { logout } from "../../../hooks/logout";

const headers = {}

const EmailVerify = () => {
    let params = useParams()
    const [verify, setVerify] = useState(false)
    const [tokenExpire, setTokenExpire] = useState(false)

    useEffect(() => {
        loadingShow()
        if (params.token !== null || params.token !== undefined) {
            let verifyToken = params.token;

            if ((localStorage.hasOwnProperty('verification-token') && localStorage.getItem('verification-token') != 'verified') || !localStorage.hasOwnProperty('verification-token')) {
                VerifyEmail(verifyToken).then(function (response) {

                    if (response.code == 201) {
                        setTimeout(() => {
                            localStorage.setItem('verification-token', 'verified');
                            setTokenExpire(false);
                            setVerify(true);
                            loadingHide()
                        }, 2000);
                    } else if (response.code == 404) {
                        setTimeout(() => {
                            localStorage.setItem('verification-token', 'not-verified');
                            setTokenExpire(true);
                            setVerify(false);
                            loadingHide()
                        }, 2000);
                    }
                });
            } else if (localStorage.hasOwnProperty('verification-token') && localStorage.getItem('verification-token') == 'verified') {
                setTokenExpire(true);
                setVerify(false);
                loadingHide()
            }
        }
    }, [])

    const VerifyEmail = async (token) => {
        try {
            const body = { verifyToken: token };
            const response = await axios.post('/email-verify/verify', body, { headers })
            return response.data;
        } catch (error) {
            console.log(error);
            //logout when unauthorized
            return error.response.data.error;
        }
    };

    return (
        <>
            <div className="relative flex flex-row h-screen justify-center items-center w-full custom-body">
                {verify ? (
                    <>
                        <div className='w-full'>
                            <div className='px-32 py-44 mx-auto w-2/6'>
                                <div className="flex flex-col">
                                    <div className={'justify-items-center text-center'}>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 p-2 rounded-full mx-auto bg-green-600 text-white my-3">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                            </svg>
                                        </div>
                                        <div className={'text-xl'}>Email address verified!</div>
                                        <div><Link className={'text-sm text-blue-600'} to={'/login'}>Click here to go to the Login page</Link></div>

                                    </div>

                                </div>
                            </div>
                            <div className=' w-full  justify-bottom items-center text-center'>HoneybeeTime.com</div>
                        </div>
                    </>
                ) : null}

                {tokenExpire ? (
                    <>
                        <div className='w-full'>
                            <div className='px-32 py-44 mx-auto w-2/6'>
                                <div className="flex flex-col">
                                    <div className={'justify-items-center text-center'}>
                                        <div>

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 p-2 rounded-full mx-auto bg-red-600 text-white my-3">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>

                                        </div>
                                        <div className={'text-xl'}>Token Expired!</div>
                                        <div><Link className={'text-sm text-blue-600'} to={'/login'}>Click here to go to the Login page</Link></div>

                                    </div>

                                </div>
                            </div>
                            <div className=' w-full  justify-bottom items-center text-center'>HoneybeeTime.com</div>
                        </div>
                    </>
                ) : null}

            </div>
        </>
    );
}

export default EmailVerify;