import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { loadingHide, loadingShow } from "../../../helper/loading";
import { publicInvitationRequest } from "../../../services/organizations";

const PublicInvitationVerify = () => {
    let params = useParams()
    const [tokanVerifyStatus, setTokanVerifyStatus] = useState(false)

    useEffect(() => {
        if (params.token !== null || params.token !== undefined) {
            let verifyToken = params.token;
            if ((localStorage.hasOwnProperty('tag')) && (localStorage.getItem('tag') !== '' && localStorage.getItem('tag') !== 'null')) {
                loadingShow()
                publicInvitationRequest(verifyToken).then((response)=>{
                    loadingHide()
                });
            } else {
                console.log('Without login');
                localStorage.setItem('public-verification-tokan', verifyToken)
                window.location.replace("/login");
            }
        }

    }, [])


    return (
        <>
            <div className='custom-body w-full'>
                <div className='px-32 py-44 mx-auto w-2/6'>
                    <div className="flex flex-col">
                        <div className={'justify-items-center text-center'}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 p-2 rounded-full mx-auto bg-green-600 text-white my-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                            </div>
                            <div className={'text-xl'}>Public Invitation Accepted!</div>
                            <div><Link className={'text-sm text-blue-600'} to={'/login'}>Click here to go to the Login page</Link></div>
                        </div>
                    </div>
                </div>
                <div className=' w-full  justify-bottom items-center text-center'>HoneybeeTime.com</div>
            </div>
        </>
    );

}

export default PublicInvitationVerify;