export const ArchiveReasons = [
    { value: "not_needed_anymore", label: "Not needed anymore" },
    { value: "using_a_different_time_tracking_tool", label: "Using a different time tracking tool" },
    { value: "confusing_or_complicated_to_use", label: "Confusing or complicated to use" },
    { value: "desired_features_missing", label: "Desired features missing" },
    { value: "unhappy_with_honeybee_time", label: "Unhappy with HoneybeeTime" },
    { value: "not_ready_to_use_honeybee_time_yet", label: "Not ready to use HoneybeeTime yet" },
    { value: "technical_issues", label: "Technical issues" },
    { value: "price", label: "Price" },
    { value: "other", label: "Other" },
    { value: "no_reason_given", label: "No reason given" }
]