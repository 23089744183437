import { Route, Routes } from "react-router-dom";

import Signup from "./component/signup/signup.view";
import TeamWorkSelect from "./component/signup/team_work.view";
import CreateOrgForm from "./component/signup/create_org";
import CreateFirstProject from "./component/signup/create_first_project.view";
import Dashboard from "./component/dashboard";
import Login from "./component/login/login";
import Task from "./component/task/index.js";
import RequireAuth from "./component/RequireAuth";
import WorkTimes from "./component/worktime/index";
import Organizations from "./component/organization/index";
import Activities from "./component/activities/";
import Projects from "./component/project/index";
import EmailVerification from "./component/signup/verifications/verification";
import ForgotPassword from "./component/login/forgot_password/forgot_password";
import ResetPassword from "./component/login/forgot_password/reset_password";
import Members from "./component/members/index";
import Settings from "./component/organization/components/settings/index";
import GeneralSettings from "./component/organization/components/settings/settingsGroups/general";
import TermsConditions from "./component/terms-conditions/index"
import ReportMenu from "./component/report";
import TimeAndActivity from "./component/report/components/time_and_activity";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import OrgVerification from "./component/signup/verifications/org_invitation";
import Profile from "./component/account/profile";
import EmailUpdateVerify from "./component/account/components/email/emailUpdateVerify";
import PublicInvitationVerify from "./component/members/components/publicInvitationVerify";

function App() {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/verification/:token" element={<EmailVerification />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password/:token" element={<ResetPassword />} />
          <Route path="/org/invitation/:token" element={<OrgVerification />} />
          <Route path="/organizations/invite/:token" element={<PublicInvitationVerify />} />
          <Route element={<RequireAuth />}>
            <Route path="/teamselect" element={<TeamWorkSelect />} />
            <Route path="/createorg" element={<CreateOrgForm />} />
            <Route
              path="/createfirstproject"
              element={<CreateFirstProject />}
            />
            <Route path="/" element={<Dashboard />} />
            <Route path="/Projects" element={<Projects numberOfItems={8} />} />
            <Route
              path="/Organizations"
              element={<Organizations numberOfItems={7} />}
            />
            <Route
              path="/WorkTimes"
              element={<WorkTimes numberOfItems={7} />}
            />
            <Route path="/Activities" element={<Activities />} />
            <Route path="/task" element={<Task numberOfItems={12} />} />
            <Route path="/members" element={<Members numberOfItems={8} />} />
            <Route path="/account" element={<Profile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/settings/general" element={<GeneralSettings />} />
            <Route path="/reports" element={<ReportMenu />} />
            <Route path="/reports/time-and-activities" element={<TimeAndActivity />} />
            <Route path="/emailupdate/:token" element={<EmailUpdateVerify />} />
          </Route>
        </Routes>
      </LocalizationProvider>
    </>
  );
}

export default App;
