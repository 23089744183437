import { Fragment, useEffect } from "react";
import { NameProccess } from "../../../../../globle-component/globle";
import moment from 'moment-timezone';

const GroupByProjects = ({
    fetchReportData,
    setTotalDurationToShow,
    tabValue,
    totalDurationOfPeriod,
    content,
    thCount,
    formatDuration,
    formatDate
}) => {

    useEffect(() => {
        setTotalDurationToShow(totalDurationOfPeriod);
    }, [totalDurationOfPeriod])

    const systemTimeZone = localStorage.getItem("systemTimeZone");
    let datesArray = [];
    let memberIds = [];
    let isHaveDate;
    let isHaveMember;
    totalDurationOfPeriod = 0;

    if (tabValue == 1) {
        fetchReportData.map((row) => {
            totalDurationOfPeriod = totalDurationOfPeriod + row.totalDuration;
        })
        content = fetchReportData.map((row, rowIndex) => {
            return (
                <Fragment key={Math.random() + rowIndex}>
                    <tr className="border-b bg-gray-200 mt-5">
                        <th colSpan={thCount} className="px-6 py-3 font-bold text-gray-900 whitespace-nowrap"
                        title={row.projectName}
                        >
                            <h6 className="px-4 text-gray-600 font-bold capitalize">
                                {NameProccess(row.projectName,125)}
                            </h6>
                        </th>
                    </tr>

                    <tr className="bg-white border-b font-bold hover:bg-gray-50">
                        <th colSpan='4' className="px-6 py-3 text-gray-900 font-bold whitespace-nowrap">
                            Total
                        </th>
                        <th className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
                            {formatDuration(row.totalDuration)}
                        </th>
                        <th className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
                            -
                        </th>
                        <th className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
                            {" "}
                        </th>
                    </tr>

                    {
                        row.dates.map((date, dateIndex) => {
                            datesArray = [];
                            memberIds = [];
                            return (
                                <Fragment key={Math.random() + dateIndex}>
                                    {
                                        date.users.map((user, userIndex) => {
                                            return (
                                                <Fragment key={Math.random() + userIndex}>
                                                    {
                                                        user.tasks.map((task, userTask) => {
                                                            isHaveDate = datesArray.includes(date.date);
                                                            if (isHaveDate === false) {
                                                                datesArray.push(date.date);
                                                            }

                                                            isHaveMember = memberIds.includes(user.userId);
                                                            if (isHaveMember === false) {
                                                                memberIds.push(user.userId);
                                                            }

                                                            return (
                                                                <Fragment key={Math.random() + userTask}>
                                                                    <tr className="bg-white border-b font-bold hover:bg-gray-50 text-[12px] custom-body" >
                                                                        {
                                                                            isHaveDate === false
                                                                                ? (
                                                                                    <th className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                                        <h5 className="px-4 text-gray-600 font-bold my-auto">
                                                                                            {formatDate(date?.date)}
                                                                                        </h5>
                                                                                    </th>
                                                                                )
                                                                                : (
                                                                                    <th className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                                        <h5 className="px-4 text-gray-600 font-bold my-auto">
                                                                                            {" "}
                                                                                        </h5>
                                                                                    </th>
                                                                                )
                                                                        }

                                                                        {
                                                                            isHaveMember === false
                                                                                ? (
                                                                                    <td className="px-3 py-3 text-gray-600 whitespace-nowrap text-left">
                                                                                        <div className="flex flex-row ml-1">
                                                                                            <button className="sm:w-10 sm:h-10 w-[10px] h-[10px]">
                                                                                                <svg
                                                                                                    width="12"
                                                                                                    height="12"
                                                                                                    viewBox="0 0 16 16"
                                                                                                    fill="none"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    className="mx-auto my-2"
                                                                                                >
                                                                                                    <path
                                                                                                        d="M8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 12C10.7 12 13.8 13.29 14 14H2C2.23 13.28 5.31 12 8 12ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
                                                                                                        fill="currentColor"
                                                                                                    />
                                                                                                </svg>
                                                                                            </button>
                                                                                            <div className="w-auto my-auto">
                                                                                                <h6 className="px-2 text-gray-600 font-medium" title={user.fullName}>
                                                                                                    {NameProccess(user.fullName, 20)}
                                                                                                </h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                )
                                                                                : (
                                                                                    <td className="px-3 py-3 text-gray-600 whitespace-nowrap text-left">
                                                                                        {" "}
                                                                                    </td>
                                                                                )
                                                                        }

                                                                        <td className="px-6 py-3 text-gray-600 whitespace-nowrap font-medium text-xs pr-2">
                                                                            <div title='user email | timezone | date crated'>
                                                                                {user.info.email}<br />
                                                                                {user.info.timeZone}<br />
                                                                                {moment.utc(user.info.createdAt).tz(systemTimeZone).format('YYYY-MM-DD')}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 text-gray-600 whitespace-nowrap">
                                                                            <h5 className="text-gray-600 font-medium my-auto" title={task?.taskName}>
                                                                                {
                                                                                    NameProccess(task?.taskName, 35)
                                                                                }
                                                                            </h5>
                                                                        </td>
                                                                        <td className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                            <h5 className="px-4 text-gray-600 font-bold my-auto text-center">
                                                                                {formatDuration(task?.duration)}
                                                                            </h5>
                                                                        </td>
                                                                        <td className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                            <h5 className="px-4 text-gray-600 font-bold text-center my-auto">
                                                                                -
                                                                            </h5>
                                                                        </td>
                                                                        <td className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                            <h5 className={"text-gray-600 font-bold my-auto" + (task?.reason === "" && " text-center")}>
                                                                                {
                                                                                    task?.reason === ""
                                                                                        ? (
                                                                                            "-"
                                                                                        )
                                                                                        : (
                                                                                            NameProccess(task?.reason, 30)
                                                                                        )
                                                                                }
                                                                            </h5>
                                                                        </td>

                                                                    </tr>

                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </Fragment>
                            )
                        })
                    }
                </Fragment>
            );
        });
    } else {
        fetchReportData.map((row) => {
            totalDurationOfPeriod = totalDurationOfPeriod + row.totalDuration;
        })
        content = fetchReportData.map((row, rowIndex) => {
            return (
                <Fragment key={Math.random() + rowIndex}>
                    <tr key={row.projectId} className="border-b bg-gray-200 mt-5">
                        <th colSpan={thCount} className="px-6 py-3 font-bold text-gray-900 whitespace-nowrap">
                            <h6 className="px-4 text-gray-600 font-bold capitalize">
                                {row.projectName}
                            </h6>
                        </th>
                    </tr>

                    <tr key={row.totalDuration} className="bg-white border-b font-bold hover:bg-gray-50">
                        <th colSpan='2' className="px-6 py-3 text-gray-900 font-bold whitespace-nowrap">
                            Total
                        </th>
                        <th className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
                            {formatDuration(row.totalDuration)}
                        </th>
                        <th className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
                            -
                        </th>
                        <th className="px-6 py-3 text-gray-900 whitespace-nowrap text-center">
                            {" "}
                        </th>
                    </tr>

                    {
                        row.dates.map((date, dateIndex) => {
                            datesArray = [];
                            return (
                                <Fragment key={Math.random() + dateIndex}>
                                    {
                                        date.users.map((user, userIndex) => {
                                            return (
                                                <Fragment key={Math.random() + userIndex}>
                                                    {
                                                        user.tasks.map((task, taskIndex) => {
                                                            isHaveDate = datesArray.includes(date.date);
                                                            if (isHaveDate === false) {
                                                                datesArray.push(date.date);
                                                            }

                                                            return (
                                                                <Fragment key={Math.random() + taskIndex}>
                                                                    <tr className="bg-white border-b font-bold hover:bg-gray-50 text-[12px] custom-body" >
                                                                        {
                                                                            isHaveDate === false
                                                                                ? (
                                                                                    <th className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                                        <h5 className="px-4 text-gray-600 font-bold my-auto">
                                                                                            {formatDate(date?.date)}
                                                                                        </h5>
                                                                                    </th>
                                                                                )
                                                                                : (
                                                                                    <th className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                                        <h5 className="px-4 text-gray-600 font-bold my-auto">
                                                                                            {" "}
                                                                                        </h5>
                                                                                    </th>
                                                                                )
                                                                        }

                                                                        <td className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                            <h5 className="px-4 text-gray-600 font-medium my-auto" title={task?.taskName}>
                                                                                {
                                                                                    NameProccess(task?.taskName, 35)
                                                                                }
                                                                            </h5>
                                                                        </td>
                                                                        <td className="px-6 py-3 text-gray-600 whitespace-nowrap">
                                                                            <h5 className="px-4 text-gray-600 font-bold my-auto text-center">
                                                                                {formatDuration(task?.duration)}
                                                                            </h5>
                                                                        </td>
                                                                        <td className="px-6 py-3 text-gray-600 whitespace-nowrap text-center">
                                                                            <h5 className="px-4 text-gray-600 font-bold my-auto">
                                                                                -
                                                                            </h5>
                                                                        </td>
                                                                        <td className="px-6 py-3 text-gray-600 whitespace-nowrap text-center">
                                                                            <h5 className={"px-4 text-gray-600 font-bold my-auto" + (task?.reason === "" && " text-center")} title={task?.reason}>
                                                                                {
                                                                                    task?.reason === ""
                                                                                        ? (
                                                                                            "-"
                                                                                        )
                                                                                        : (
                                                                                            NameProccess(task?.reason, 30)
                                                                                        )
                                                                                }
                                                                            </h5>
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </Fragment>
                            )
                        })
                    }

                </Fragment>
            );
        });
    }


    return (
        <>
            {content}
        </>
    )
}

export default GroupByProjects