import axios from "../api/axios";
import { logout } from "../hooks/logout";

let headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`
}


// Get Activities
export const getActivityData = async (organizationId, params = null) => {
    try {
        const config = { headers: { ...headers, organization_id: organizationId }, params }
        const response = await axios.get('/time-slots/' + organizationId + '/activities/screenshots', config)
        return response.data;
    } catch (error) {
        if (error?.response?.status === 401) {
            logout();
        }
        return { data: null };

    }
};
