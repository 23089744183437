import { ToastContainer } from "react-toastify";
import Navbar from "../dashboard/component/navbar";
import SideBar from "../dashboard/component/sidebar";

import { useDispatch, useSelector } from 'react-redux';
import { getActiveProjects, getArchivedProjects, setSelectedTab } from "../../store/slices/projects";
import { useEffect } from "react";
import TableData from "./components/tableData";
import ProjectSearch from "./components/search";
import AddNewProjectModal from "./components/addProject";
import { InputComponent, TextareaComponent } from "../globle-component/inputs";
import EditProjectModal from "./components/editModal";
import { fetchActiveMembers } from "../../store/slices/members";
import ArchiveProjectModal from "./components/archiveProject";
import { pageLoading } from "../../helper/loading";

const organizationId = localStorage.getItem('organizationID');
function Projects() {

    const dispatch = useDispatch();
    const {
        isLoading: projectsLoading,
        activeProjectList,
        filteredActiveProjectList,
        archivedProjectList,
        filteredArchivedProjectList,
        meta
    } = useSelector((state) => state.projects)
    const { activeMemberList: activeMembersList } = useSelector((state) => state.members)


    const CustomInputComponent = (props) => (
        <InputComponent
            props={props}
        />
    );

    const CustomTextareaComponent = (props) => (
        <TextareaComponent
            props={props}
        />
    );

    useEffect(() => {
        if (activeProjectList.length === 0) {
            dispatch(getActiveProjects(organizationId))
        }
        if (archivedProjectList.length === 0) {
            dispatch(getArchivedProjects(organizationId))
        }
        if (activeMembersList.length === 0) {
            dispatch(fetchActiveMembers(organizationId))
        }
    }, [])


    return (
        <div className="flex flex-row h-full w-full overflow-x-hidden">
            <SideBar />

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            <div className="bg-gray-200 w-full h-full min-h-screen flex-1">
                <Navbar />
                <div className="flex flex-row w-full p-5 custom-body">
                    <div className="w-full h-full bg-white p-3 rounded-md">


                        <div className="mx-4 order-first">
                            <h1 className="ht-page-header">
                                Project
                            </h1>
                        </div>


                        <div className="mt-1">
                            <div className="relative">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <div className="grid grid-cols-2 gap-4 mx-5">
                                            <ul
                                                className="nav nav-tabs flex md:flex-row list-none border-b-0 pl-0 sm:mb-4 mb-1"
                                                id="tabs-tab"
                                                role="tablist"
                                            >
                                                {
                                                    (projectsLoading === false)
                                                        ? (
                                                            <>
                                                                <li className="nav-item" role="presentation">
                                                                    <a
                                                                        href="#active-projects"
                                                                        className={
                                                                            "nav-link block sm: leading-tight  border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent active"
                                                                        }
                                                                        id="active-projects-tab"
                                                                        data-bs-toggle="pill"
                                                                        data-bs-target="#active-projects"
                                                                        role="tab"
                                                                        aria-controls="active-projects"
                                                                        aria-selected="true"
                                                                        onClick={() => {
                                                                            dispatch(setSelectedTab(0));
                                                                        }}
                                                                    >
                                                                        {
                                                                            `Active (${activeProjectList?.length})`
                                                                        }
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item" role="presentation">
                                                                    <a
                                                                        href="#archived-projects"
                                                                        className={
                                                                            "nav-link block sm: leading-tight  border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent "
                                                                        }
                                                                        id="archived-projects-tab"
                                                                        data-bs-toggle="pill"
                                                                        data-bs-target="#archived-projects"
                                                                        role="tab"
                                                                        aria-controls="archived-projects"
                                                                        aria-selected="false"
                                                                        onClick={() => {
                                                                            dispatch(setSelectedTab(1));
                                                                        }}
                                                                    >
                                                                        {`Archived (${archivedProjectList?.length})`}
                                                                    </a>
                                                                </li>
                                                            </>
                                                        )
                                                        : null
                                                }


                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex sm:flex-row flex-col w-auto right">
                                            <div className=" mx-3">
                                                <ProjectSearch />
                                            </div>
                                            <AddNewProjectModal
                                                CustomInputComponent={CustomInputComponent}
                                                CustomTextareaComponent={CustomTextareaComponent}
                                            />

                                            <EditProjectModal
                                                CustomInputComponent={CustomInputComponent}
                                                CustomTextareaComponent={CustomTextareaComponent}
                                            />

                                            <ArchiveProjectModal
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="tab-content w-full  px-5" id="tabs-tabContent">



                                    <div
                                        className={"tab-pane fade show active"}
                                        id="active-projects"
                                        role="tabpanel"
                                        aria-labelledby="active-projects-tab"
                                    >

                                        <div className="flex flex-col max-sm:!h-full">
                                            <div className="max-sm:overflow-x-auto sm:-mx-6 lg:-mx-8 max-sm:!h-full">
                                                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                                    {Object.keys(filteredActiveProjectList).length > 0 ? (
                                                        <div className="sm:relative max-sm:overflow-x-auto">
                                                            <table className="w-full">
                                                                <thead className="border-b">
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            className="flex flex-row  font-medium text-[#171923] my-4 text-left"
                                                                        >
                                                                            Name
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" font-medium text-[#171923] my-4 text-left"
                                                                        >
                                                                            Members
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" font-medium text-center text-[#171923] my-4"
                                                                        >
                                                                            Member count
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" font-medium text-center text-[#171923] my-4"
                                                                        >
                                                                            Task
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <TableData
                                                                        projectList={filteredActiveProjectList}
                                                                        actionDisplay={true}
                                                                    />
                                                                </tbody>
                                                            </table>
                                                            {meta.activeProjects.pageLoading &&
                                                                <div className="flex justify-center w-full p-3">
                                                                    {pageLoading()}
                                                                </div>
                                                            }

                                                        </div>
                                                    ) : filteredActiveProjectList.length === 0 ? (
                                                        <div className="grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto bg-gray-100 text-center text-gray-500  sm overflow-x-auto">
                                                            Nothing found.
                                                        </div>
                                                    ) : activeProjectList.length === 0 ? (
                                                        <div className="grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto bg-gray-100 text-center text-gray-500  sm overflow-x-auto">
                                                            No active projects.
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                    <div
                                        className={"tab-pane fade"}
                                        id="archived-projects"
                                        role="tabpanel"
                                        aria-labelledby="archived-projects-tab"
                                    >
                                        <div className="flex flex-col">
                                            <div className="overflow-x-hidder sm:-mx-6 lg:-mx-8">
                                                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                                    {Object.keys(filteredArchivedProjectList).length > 0 ? (
                                                        <div className="overflow-x-hidder">
                                                            <table className="min-w-full">
                                                                <thead className="border-b">
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            className="flex flex-row  font-medium text-[#171923] my-4 text-left"
                                                                        >
                                                                            Name
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" font-medium text-[#171923] my-4 text-left"
                                                                        >
                                                                            Members
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" font-medium text-center text-[#171923] my-4"
                                                                        >
                                                                            Member count
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" font-medium text-center text-[#171923] my-4"
                                                                        >
                                                                            Task
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <TableData
                                                                        projectList={filteredArchivedProjectList}
                                                                        actionDisplay={false}
                                                                    />
                                                                </tbody>
                                                            </table>
                                                            {meta.archivedProjects.pageLoading &&
                                                                <div className="flex justify-center w-full p-3">
                                                                    {pageLoading()}
                                                                </div>
                                                            }
                                                        </div>
                                                    ) : filteredArchivedProjectList.length === 0 ? (
                                                        <div className="grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto bg-gray-100 text-center text-gray-500 inline-block">
                                                            Nothing found.
                                                        </div>
                                                    ) : archivedProjectList.length === 0 ? (
                                                        <div className="grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto bg-gray-100 text-center text-gray-500 inline-block">
                                                            No archived projects.
                                                        </div>
                                                    ) : null}

                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Projects;