import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { loadingHide, loadingShow } from "../../../helper/loading";
import { notifySuccess } from "../../../helper/notification-helper";
import { archiveProject } from "../../../services/projects";
import { getActiveProjects, getArchivedProjects, setEditModal } from "../../../store/slices/projects";

const organizationId = localStorage.getItem('organizationID');

const ArchiveProjectModal = () => {

    const dispatch = useDispatch();
    const {
        editModal,
        initialModal
    } = useSelector((state) => state.projects)

    function closeModal() {
        dispatch(setEditModal(initialModal))
    }

    function clearModalData() {
        dispatch(setEditModal(initialModal));
    }

    return (
        <>
            {editModal.modals.show && editModal.modals.type === 'archive' ? (
                <Transition appear show={editModal.modals.show} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed inset-0 z-50 overflow-y-auto bg-gray-600/60"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            <span
                                className="inline-block h-screen align-top"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="modal-loading inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="ht-modal-header"
                                    >
                                        Archive this project?
                                    </Dialog.Title>

                                    <div className="mt-2">
                                        <div className="relative flex-auto">
                                            <div className="flex flex-wrap mb-6 text-gray-500 ">
                                                <div>
                                                    Archiving prevents any more time from being logged.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-end border-t border-solid border-slate-200 pt-6">
                                        <button
                                            className="ht-btn-black"
                                            type="button"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="ht-btn-red"
                                            type="submit"
                                            onClick={() => {
                                                loadingShow('.modal-loading');
                                                archiveProject(editModal.formData.id,organizationId).then((response)=>{
                                                    notifySuccess("Project archived successfully");
                                                    dispatch(getActiveProjects(organizationId))
                                                    dispatch(getArchivedProjects(organizationId))
                                                    loadingHide('.modal-loading');
                                                    clearModalData();
                                                    // window.location.reload();
                                                });
                                            }}
                                        >
                                            Archive
                                        </button>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            ) : null}

        </>
    );
}

export default ArchiveProjectModal;