import moment from "moment";
import TableData from "../components/tableData";
import Timeline from "../components/timeline";

const DailyView = ({ timeSlots, loading, setLoading }) => {
  return (
    <>
      {Object.keys(timeSlots).length > 0 ? (
        <>
          {timeSlots.map((timeSlotObj, timeSlotObjIdx) => (
            <div key={timeSlotObjIdx}>
              <div className="flex px-2 py-2 mx-1 my-4">
                <h3>
                  {timeSlotObj?.date && timeSlotObj?.date === new Date() ? (
                    <>
                      Today :{" "}
                      <span className={"font-bold"}>
                        {timeSlotObj?.totalDuration}
                      </span>
                    </>
                  ) : (
                    <>
                      {moment(timeSlotObj?.date).format("ddd, MMM Do, YYYY")} -{" "}
                      <span className={"font-bold mx-1"}>
                        {" "}
                        {timeSlotObj?.totalDuration}
                      </span>
                    </>
                  )}
                </h3>
              </div>
              <Timeline
                worktimes={timeSlotObj.timeslots}
                newTimeSlotArray={null}
                keyVal={1}
              />
              {Object.keys(timeSlotObj.timeslots).length > 0 ? (
                <>
                  <div className="flex flex-col">
                    <div className="overflow-x-auto lg:px-10 px-5">
                      <div className="overflow-x-auto py-2 inline-block min-w-full">
                        <div className="overflow-x-auto">
                          <table className="min-w-full relative overflow-x-auto">
                            <thead className="border-b">
                              <tr>
                                <th
                                  scope="col"
                                  style={{ width: "25%" }}
                                  className="md:text-sm text-xs font-medium text-gray-900 px-6 py-4 text-left"
                                >
                                  Project
                                </th>
                                <th
                                  scope="col"
                                  style={{ width: "25%" }}
                                  className="md:text-sm text-xs font-medium text-gray-900 px-6 py-4 text-center"
                                ></th>
                                <th
                                  scope="col"
                                  style={{ width: "5%" }}
                                  className="md:text-sm text-xs font-medium text-gray-900 px-6 py-4 text-center"
                                >
                                  Activity
                                </th>
                                <th
                                  scope="col"
                                  style={{ width: "5%" }}
                                  className="md:text-sm text-xs font-medium text-gray-900 px-6 py-4 text-center"
                                >
                                  Idle
                                </th>
                                <th
                                  scope="col"
                                  style={{ width: "5%" }}
                                  className="md:text-sm text-xs font-medium text-gray-900 px-6 py-4 text-center"
                                >
                                  Manual
                                </th>
                                <th
                                  scope="col"
                                  style={{ width: "5%" }}
                                  className="md:text-sm text-xs font-medium text-gray-900 px-6 py-4 text-center"
                                >
                                  Duration
                                </th>
                                <th
                                  scope="col"
                                  style={{ width: "10%" }}
                                  className="md:text-sm text-xs font-medium text-gray-900 px-6 py-4 text-center"
                                >
                                  Date
                                </th>
                                <th
                                  scope="col"
                                  style={{ width: "20%" }}
                                  className="md:text-sm text-xs font-medium text-gray-900 px-6 py-4 text-center"
                                >
                                  Time
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <TableData
                                worktimes={timeSlotObj.timeslots}
                                loading={loading}
                              />
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          ))}
        </>
      ) : (
        <>
          <div className="grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto bg-gray-100 text-center text-gray-500 inline-block">
            No work times recorded.
          </div>
        </>
      )}
    </>
  );
};

export default DailyView;
