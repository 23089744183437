import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Dropdown } from "../../globle-component/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { notifyError, notifySuccess } from "../../../helper/notification-helper";
import { editTask } from "../../../services/tasks";
import { loadingHide, loadingShow } from "../../../helper/loading";
import { logout } from "../../../hooks/logout";
import { EditMembers } from "./members";
import { getTaskData } from "../../../store/slices/tasks";

const formValidation = {
    taskName: {
        required: 'Required',
        over: 'Must be less than 255 characters',
    },
    assignee: {
        required: 'Required'
    }
}
const organizationId = JSON.parse(localStorage.getItem('organizationID'));

const initModal = {
    formData: {
        taskId: "",
        taskName: "",
        taskAssignee: "",
        taskCreatedDate: "",
        taskStatus: "",
    },
    modals: {
        type: "",
        show: false,
    },
};

const EditTaskModal = ({ assigneeList, modalData, setModalData }) => {

    const { selectedProject } = useSelector((state) => state.tasks)
    const dispatch = useDispatch();

    const [taskName, setTaskName] = useState(modalData.formData.taskName)
    const [selectedAssignees, setSelectedAssignees] = useState([])
    const [validations, setValidations] = useState(null)
    const [initialStatus, setInitialStatus] = useState(true)

    useEffect(() => {
        let taskUsers = [];
        for (const taskUser of modalData.formData.taskUsers) {
            let user = assigneeList.filter((user) => user.id === taskUser.id)
            if(user.length > 0){
                taskUsers.push(user[0])
            }
        }

        setSelectedAssignees(taskUsers)
    }, [])

    function closeModal() {
        setModalData(initModal)
    }

    function submitEditTask() {

        if (checkValidation()) {
            let taskObject = {
                title: taskName,
                status: 1,
                projectId: JSON.parse(selectedProject.id)
            };

            editSubmit(taskObject)

        }

    }

    function checkValidation() {
        let validation = {};

        if (taskName === null || taskName === '') {
            validation.taskName = formValidation.taskName.required;
        } else if (taskName.length > 255) {
            validation.taskName = formValidation.taskName.over;
        }

        setValidations(validation)

        if (Object.keys(validation).length === 0) {
            return true;
        } else {
            return false;
        }


    }

    useEffect(() => {
        if (!initialStatus) {
            checkValidation()
        }
    }, [taskName])

    useEffect(() => {
        if (!initialStatus) {
            checkValidation()
        } else {
            if (selectedAssignees.length > 0) {
                setInitialStatus(false)
            }
        }

    }, [selectedAssignees])


    // Add Task model data submit function
    const editSubmit = async (data) => {
        loadingShow('.modal-loading');
        try {
            loadingShow();
            editTask(organizationId, modalData.formData.taskId, data).then((response) => {
                let taskId = response.data.id;
                {
                    response.code == 200
                        ? (notifySuccess("Task edited Successfully"))
                        : (notifyError("Error in Task edit !"))
                }

                // if (selectedAssignees.length > 0) {
                    EditMembers(data.projectId, taskId, selectedAssignees)
                // }

                closeModal();
                loadingHide('.modal-loading');
                setTimeout(() => {
                    dispatch(getTaskData(organizationId))
                    loadingHide();

                }, 1000);

            });
        } catch (error) {
            //logout when unauthorized
            if (error.response.status === 401) {
                logout();
            }
            notifyError(error.response.data.message);
        }
    };

    return (
        <>
            {modalData.modals.show === true && modalData.modals.type === 'edit' ? (
                <>
                    <Transition appear show={true} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/80"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                <span
                                    className="inline-block h-screen "
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="modal-loading inline-block z-[1000] w-full max-w-lg p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded-2xl">
                                        <Dialog.Title
                                            as="h3"
                                            className="ht-modal-header"
                                        >
                                            Edit task
                                        </Dialog.Title>
                                        <div className={"flex flex-col mt- mx-auto mb-1 w-full"}>

                                            <>
                                                <div className="pb-2 border-b border-gray-100 mb-3">
                                                    <div className="w-full py-2" >
                                                        <div>
                                                            <label className="uppercase font-bold">Task name <span className="required">*</span></label>
                                                            <input
                                                                name="email"
                                                                placeholder="Task name"
                                                                className={`modal-input ${validations !== null && validations.taskName !== undefined ? 'modal-input-danger' : ''}`}
                                                                autoComplete="off"
                                                                defaultValue={taskName}
                                                                onChange={(event) => {
                                                                    setTaskName(event.target.value)
                                                                    setInitialStatus(false)
                                                                }}
                                                            />
                                                            {validations !== null && validations.taskName !== undefined ? (
                                                                <div className="p-1 text-red-600 font-bold my-1">{validations.taskName}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="w-full py-2 ">
                                                        <div>
                                                            <label>
                                                                <span className="uppercase font-bold">Assignee</span>
                                                            </label>
                                                            <Dropdown
                                                                listData={assigneeList}
                                                                multiselect={true}
                                                                placeholder={'Search for a person'}
                                                                selectedItems={selectedAssignees}
                                                                setSelectedItems={setSelectedAssignees}
                                                                descriptionShow={false}
                                                                validation={validations !== null && validations.assignee !== undefined ? false : true}
                                                                disabled={false}
                                                                selectedItemsDisplaySeparately={true}
                                                            />
                                                            {validations !== null && validations.assignee !== undefined ? (
                                                                <div className="p-1 text-red-600 font-bold my-1">{validations.assignee}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="pb-2 border-b border-gray-100 ">
                                                    <div className=" flex justify-end">

                                                        <button
                                                            className="ht-btn-black"
                                                            type="button"
                                                            onClick={closeModal}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            className="ht-btn-yellow"
                                                            type="submit"
                                                            onClick={() => {
                                                                checkValidation()
                                                                submitEditTask()
                                                            }}
                                                        >
                                                            Send
                                                        </button>
                                                    </div>
                                                </div>
                                            </>


                                        </div>

                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : null}


        </>
    );
}

export default EditTaskModal;