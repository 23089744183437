import axios from '../../../api/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { NameProccess, userRoleTextRender } from '../../../component/globle-component/globle';
import { userStatus } from '../../../constant/userState';

const initialState = {
    activeMemberList: [],
    filteredActiveMemberList: [],
    activeMembersSearchInputValue: null,
    invitedMemberList: [],
    filteredInvitedMemberList: [],
    invitedMembersSearchInputValue: null,
    selectedUser: null,
    isLoading: false,
    selectedTab: 0,
    meta: {
        activeMembers: {
            pageCount: 11,
            pageNumber: 1,
            pageLoading: false
        },
        invitedMembers: {
            pageCount: 11,
            pageNumber: 1,
            pageLoading: false
        }
    },
    nameCharacterLength : 30
}

export const fetchActiveMembers = createAsyncThunk('members/getActiveMembers', async (organizationId, thunkApi) => {

    try {
        const config = { headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}` } }
        const { data } = await axios.get('organizations/' + organizationId + '/members', config);

        if (Array.isArray(data.data) && data.data.length > 0) {
            // thunkApi.dispatch(setSelectedUser(data.data[0]))
            // thunkApi.dispatch(setFilterParams({ user_id: data.data[0].id }))
        }

        memberNameShorten(data.data)

        return data
    } catch (error) {
        console.log('error - ', error);
    }
})

export const fetchInvitedMembers = createAsyncThunk('members/getInvitedMembers', async (organizationId, thunkApi) => {

    try {
        const config = { headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}` } }
        const { data } = await axios.get('/verify-add-user-to-organization/' + organizationId + '/pending', config);

        return data
    } catch (error) {
        console.log('error - ', error);
    }
})

export const setActiveMembersPageNumber = createAsyncThunk('members/setActiveMembersPageNumber', async (pageNumber, thunkApi) => {
    const currentState = thunkApi.getState()
    let totalCount = (currentState.members.meta.activeMembers.pageNumber * currentState.members.meta.activeMembers.pageCount)
    if (totalCount <= currentState.members.activeMemberList.length) {
        thunkApi.dispatch(setActiveMemberPageLoading(true))

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                thunkApi.dispatch(setActiveMemberPageLoading(false))
                resolve(currentState.members.meta.activeMembers.pageNumber + 1)
            }, 1500);
        })
    }
    return null
})

export const setInvitedMembersPageNumber = createAsyncThunk('members/setInvitedMembersPageNumber', async (pageNumber, thunkApi) => {
    const currentState = thunkApi.getState()
    let totalCount = (currentState.members.meta.invitedMembers.pageNumber * currentState.members.meta.invitedMembers.pageCount)
    if (totalCount <= currentState.members.invitedMemberList.length) {
        thunkApi.dispatch(setInvitedMemberPageLoading(true))

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                thunkApi.dispatch(setInvitedMemberPageLoading(false))
                resolve(currentState.members.meta.invitedMembers.pageNumber + 1)
            }, 1500);
        })
    }
    return null
})

export const membersAppendList = createAsyncThunk('members/membersAppendList', async (_, thunkApi) => {
    const currentState = thunkApi.getState()
    if (currentState.members.selectedTab === 0) {
        thunkApi.dispatch(setActiveMembersPageNumber())
    } else if (currentState.members.selectedTab === 1) {
        thunkApi.dispatch(setInvitedMembersPageNumber())
    }
})

function memberNameShorten(list) {
    for (const item of list) {
        item.displayName = item.full_name ? (NameProccess(item.full_name, 15)) : (NameProccess("Name Unkown")) ;
    }
}


export const membersSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        filteredActiveMembers: (state, action) => {
            state.filteredActiveMemberList = state.activeMemberList.filter((obj) => String(obj.full_name + obj.email + userStatus[obj.status] + userRoleTextRender(obj.role))
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(action.payload.toLowerCase().replace(/\s+/g, "")));
        },
        filteredInvitedMembers: (state, action) => {
            state.filteredInvitedMemberList = state.invitedMemberList.filter((obj) => String(obj.full_name + obj.email + userStatus[obj.status] + userRoleTextRender(obj.role))
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(action.payload.toLowerCase().replace(/\s+/g, ""))
            )
        },

        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload
        },

        setActiveMemberPageLoading: (state, action) => {
            state.meta.activeMembers.pageLoading = action.payload
        },
        setInvitedMemberPageLoading: (state, action) => {
            state.meta.invitedMembers.pageLoading = action.payload
        },
        setActiveMembersSearchInputValue: (state, action) => {
            state.activeMembersSearchInputValue = action.payload
        },
        setInvitedMembersSearchInputValue: (state, action) => {
            state.invitedMembersSearchInputValue = action.payload
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
    },
    extraReducers: (builder) => {
        // Active Members
        builder.addCase(fetchActiveMembers.pending, (state, action) => {
            state.isLoading = true;
            state.activeMemberList = []
            state.filteredActiveMemberList = []
        });

        builder.addCase(fetchActiveMembers.fulfilled, (state, action) => {
            state.isLoading = false;
            let data = action?.payload?.data;
            if (data !== undefined && data.length > 0) {
                state.activeMemberList = data;
                state.filteredActiveMemberList = state.activeMemberList.slice((state.meta.activeMembers.pageNumber - 1), (state.meta.activeMembers.pageNumber * state.meta.activeMembers.pageCount));
            }
        });

        builder.addCase(fetchActiveMembers.rejected, (state, action) => {
            state.isLoading = false;
            state.meta.activeMembers.pageNumber = 1;
        });

        builder.addCase(setActiveMembersPageNumber.fulfilled, (state, action) => {
            if (action.payload != null) {
                let pageN = action.payload
                state.meta.activeMembers.pageNumber = pageN
                state.filteredActiveMemberList = state.activeMemberList.slice(0, (pageN * state.meta.activeMembers.pageCount));
            }
        });


        // Invited Members
        builder.addCase(fetchInvitedMembers.pending, (state, action) => {
            state.isLoading = true;
            state.invitedMemberList = []
            state.filteredInvitedMemberList = []
        });

        builder.addCase(fetchInvitedMembers.fulfilled, (state, action) => {
            state.isLoading = false;
            let data = action?.payload?.data;
            if (data !== undefined && data.length > 0) {
                state.invitedMemberList = data;
                state.filteredInvitedMemberList = state.invitedMemberList.slice((state.meta.invitedMembers.pageNumber - 1), (state.meta.invitedMembers.pageNumber * state.meta.invitedMembers.pageCount));
            }
        });

        builder.addCase(fetchInvitedMembers.rejected, (state, action) => {
            state.isLoading = false;
            state.meta.invitedMembers.pageNumber = 1;
        });

        builder.addCase(setInvitedMembersPageNumber.fulfilled, (state, action) => {
            if (action.payload != null) {
                let pageN = action.payload
                state.meta.invitedMembers.pageNumber = pageN
                state.filteredInvitedMemberList = state.invitedMemberList.slice(0, (pageN * state.meta.invitedMembers.pageCount));
            }
        });
    }
})

// Action creators are generated for each case reducer function
export const { filteredActiveMembers, filteredInvitedMembers, setSelectedUser, setActiveMemberPageLoading, setInvitedMemberPageLoading, setSelectedTab, setActiveMembersSearchInputValue, setInvitedMembersSearchInputValue } = membersSlice.actions

export default membersSlice.reducer