import { Dialog, Transition } from "@headlessui/react";
import { Field, Form, Formik } from "formik";
import { Fragment, useState } from "react";
import * as Yup from 'yup';
import { newEmailUpdate } from "../../../../services/account";

const emailModelFormSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Required'),
    newEmail: Yup.string().required('Required').email('Please enter a valid email')
});

const EmailUpdate = ({ CustomTextfieldComponent, notifySuccess, notifyError, emailUpdateModalStatus, setemailUpdateModalStatus }) => {
    const [passwordDisplayStatus, setPasswordDisplayStatus] = useState(false)

    function updateEmail(data) {

        let formObject = {
            "new_email": data.newEmail,
            "password": data.password
        }

        newEmailUpdate(formObject).then(({ data }) => {
            if (data === 'Succeed') {
                localStorage.removeItem('email-update-verification-token')
                setemailUpdateModalStatus(false);
                notifySuccess('Email change request sent successfully!')
            } else if ((data.response.data.error.code === 400) && (data.response.data.error.message === "Password does not match")) {
                notifyError(data.response.data.error.message)
            }
        })

    }

    return (
        <>
            <div
                className="uppercase bg-gray-100 transition duration-200 hover:border-yellow-300 hover:bg-white text-gray-800 py-2 px-4 rounded-r right absolute bottom-0 right-0 cursor-pointer border border-slate-300"
                onClick={() => {
                    setemailUpdateModalStatus(true);
                }}
            >change</div>

            {emailUpdateModalStatus ? (
                <>
                    <Transition appear show={emailUpdateModalStatus} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/60"
                            onClose={() => { }}

                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>
                                <span
                                    className=" h-screen  align-top"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="modal-loading inline-block w-full max-w-md max-w-md p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded ">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900 pb-2 border-b border-solid border-slate-200"
                                        >
                                            Email change
                                        </Dialog.Title>
                                        <Formik
                                            initialValues={{
                                                oldPassword: '',
                                                newEmail: '',
                                            }}
                                            validationSchema={emailModelFormSchema}
                                            onSubmit={values => {
                                                updateEmail({ newEmail: values.newEmail, password: values.oldPassword })
                                            }}
                                        >
                                            {({ errors, touched, setFieldValu }) => (

                                                <Form>
                                                    <div className="my-4">
                                                        <label
                                                            className="text-gray-600 font-semibold mb-2 text-xs uppercase">
                                                            password
                                                        </label>
                                                        <div className="relative">
                                                            <Field name="oldPassword" type={passwordDisplayStatus ? 'text': 'password'} as={CustomTextfieldComponent} placeholder="Password" />
                                                            <div
                                                                className="uppercase bg-gray-100 transition duration-200 hover:border-yellow-300 hover:bg-white text-gray-800 py-1.5 px-4 rounded-r right absolute bottom-0 right-0 cursor-pointer border border-slate-300"
                                                                onClick={() => {
                                                                    if(passwordDisplayStatus){setPasswordDisplayStatus(false);}
                                                                    else{setPasswordDisplayStatus(true);}
                                                                }}
                                                            >
                                                                {passwordDisplayStatus ? (
                                                                    <>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                                                        </svg>
                                                                    </>
                                                                ) :
                                                                    <>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                        </svg>
                                                                    </>
                                                                }


                                                            </div>
                                                            {errors.oldPassword && touched.oldPassword ? (
                                                                <p className="text-red-500 text-xs italic mt-1">{errors.oldPassword}</p>
                                                            ) : null}
                                                        </div>

                                                    </div>
                                                    <div className="my-4">
                                                        <label
                                                            className="text-gray-600 font-semibold mb-2 text-xs uppercase">
                                                            new email
                                                        </label>
                                                        <div className="relative">
                                                            <Field name="newEmail" as={CustomTextfieldComponent} placeholder="Email" />
                                                            {errors.newEmail && touched.newEmail ? (
                                                                <p className="text-red-500 text-xs italic mt-1">{errors.newEmail}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="my-4 flex justify-end">
                                                        <button
                                                            type="button"
                                                            className="  px-4 py-2 text-sm text-white hbt-bg-dark border border-transparent rounded duration-300 "
                                                            onClick={() => {
                                                                setemailUpdateModalStatus(false);
                                                            }}
                                                        >
                                                            Close
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className={'px-4 py-2 mx-3 text-sm text-black bg-yellow-300 border border-transparent rounded hover:bg-yellow-500 duration-300'}

                                                        >
                                                            Save
                                                        </button>

                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : null}


        </>
    );
}

export default EmailUpdate;
