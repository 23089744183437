import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const TableHead = ({ groupByValue, tabValue, loadingState }) => {
    let value = groupByValue;
    let headerValues = [];

    if (value == "date") {
        {
            tabValue == '1'
                ? (headerValues = ["Project", "Member", "About", "Task", "Time", "Activity", "Notes"])
                : (headerValues = ["Project", "Task", "Time", "Activity", "Notes"])
        }
    } else if (value == "member") {
        {
            tabValue == '1'
                ? (headerValues = ["Date", "Project", "Task", "Time", "Activity", "Notes"])
                : (headerValues = [])
        }
    } else if (value == "project") {
        {
            tabValue == '1'
                ? (headerValues = ["Date", "Member", "About", "Task", "Time", "Activity", "Notes"])
                : (headerValues = ["Date", "Task", "Time", "Activity", "Notes"])
        }
    } else if (value == "task") {
        {
            tabValue == '1'
                ? (headerValues = ["Date", "Member", "About", "Project", "Time", "Activity", "Notes"])
                : (headerValues = ["Date", "Time", "Activity", "Notes"])
        }
    }

    return (
        <>
            {
                loadingState === false
                && (
                    <tr>
                        {headerValues.map((title, titleIndex) => (
                            <th
                                key={Math.random() + titleIndex}
                                className={
                                    "px-6 py-4" 
                                    + (
                                        (titleIndex === 0)
                                            ? " text-left "
                                            : " text-center "
                                    )
                                }
                                scope="col"
                            >
                                {title}
                            </th>
                        ))}
                    </tr>
                )
            }

        </>
    )
}

export default TableHead