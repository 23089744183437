import { useDispatch, useSelector } from "react-redux";
import Navbar from "../dashboard/component/navbar";
import SideBar from "../dashboard/component/sidebar";
import { setScreenType, setSelectedDate, setSelectedUser } from "../../store/slices/activites";
import { useEffect, useState } from "react";
import moment from "moment";
import { addDays } from "date-fns";
import { DateCalendar } from "./components/calendarComponent";
import { Dropdown } from "../globle-component/dropdown";
import { fetchActiveMembers } from "../../store/slices/members";
import Filters from "../globle-component/filters";
import { getActiveProjects } from "../../store/slices/projects";
import { getActivityData } from "../../services/activities";
import { setFilterData, setFilteredMember } from "../../store/slices/filters";
import { loadingHide, loadingShow } from "../../helper/loading";
import { NameProccess, secondsToHours } from "../globle-component/globle";
import CardsByHoursAndMinutes from "./components/cardsByHoursAndMinutes";
import SingleCard from "./components/card";
import { DATETIMES } from "../../constant/datetimes";
import { UserRole } from "../../constant/userRole";

const organizationId = JSON.parse(localStorage.getItem('organizationID'));
const authUserId = JSON.parse(localStorage.getItem('userId'));
const systemTimeZone = localStorage.getItem('systemTimeZone');
const organizationUserRole = JSON.parse(localStorage.getItem("orgUserRole"));

const today = new Date()

const initDateRangeObj = [
  {
    startDate: today,
    endDate: addDays(today, 0),
    key: "selection",
  },
];

const initialActivityDataObject = {
  hourly: [],
  all: []
}


const Activities = () => {
  const dispatch = useDispatch();

  const { activeMemberList } = useSelector((state) => state.members);
  const { screenType, selectedDate, selectedUser } = useSelector((state) => state.activities);
  const { activeProjectList } = useSelector((state) => state.projects);
  const { filteredMember, filterParams, filterData } = useSelector((state) => state.filters);

  const [dateRangeObject, setDateRangeObject] = useState(initDateRangeObj);
  const [applyDateRange, setApplyDateRange] = useState(false);
  const [filterUser, setFilterUser] = useState(null)
  const [todayTotalTime, setTodayTotalTime] = useState(0)
  const [activityData, setActivityData] = useState(initialActivityDataObject)
  const [searchBoxVisible, setSearchBoxVisible] = useState(false)

  const [membersList, setMembersList] = useState([])

  useEffect(() => {
    if (activeMemberList.length === 0) {
      dispatch(fetchActiveMembers(organizationId))
    }

    if (activeProjectList.length === 0) {
      dispatch(getActiveProjects(organizationId))
    }

    checkSearchBoxVisibility()

  }, [])

  function checkSearchBoxVisibility() {
    if (organizationUserRole === UserRole.ORGANIZATION_OWNER || organizationUserRole === UserRole.ORGANIZATION_MANAGER) {
      setSearchBoxVisible(true);
    } else{
      setSearchBoxVisible(false);
    }
  }

  function setAuthUser() {
    let user = null;
    if (activeMemberList.length > 0) {
      const currentUser = activeMemberList.filter(item => item.id === authUserId);
      if (currentUser.length > 0) {
        user = currentUser[0]
      }
    }

    setFilterUser(user)
    dispatch(setFilteredMember(user))
    dispatch(setSelectedUser(user))

    // console.log('set user- ', user);
  }

  function changeUser(user) {
    if (selectedUser !== user) {
      dispatch(setFilteredMember(user))
      dispatch(setSelectedUser(user))

      // console.log('change user- ', user);
    }
  }

  function getData() {

    // console.log('filterParams - ', filterParams);

    const params = {
      'time-zone': systemTimeZone,
    }

    if (selectedDate !== null) {
      params['start-date'] = selectedDate
    }

    if (filterParams.hasOwnProperty("user_id")) {
      params['members'] = filterParams.user_id
    }

    if (filterParams.hasOwnProperty("project_id")) {
      params['projects'] = filterParams.project_id
    }

    if (filterParams.hasOwnProperty("tracking_type")) {
      params['tracking-types'] = filterParams.tracking_type
    }

    if (filterParams.hasOwnProperty("source")) {
      params['source'] = filterParams.source
    }

    // console.log('params - ', params);

    getActivityData(organizationId, params).then(({ data }) => {
      if (data !== null) {

        nameShorten(data.filters.membersFilter, 'full_name')
        nameShorten(data.filters.projectsFilter, 'name')
        setTodayTotalTime(data.benchmarks.worked.todayCalculated);

        setActivityData({
          hourly: data.hours,
          all: setAllActivityData(data.hours)
        })

        dispatch(setFilterData({
          members: data.filters.membersFilter,
          projects: data.filters.projectsFilter,
          sources: data.filters.sourceFilter,
          trackingTypes: data.filters.trackingTypesFilter,
          activityLevels: data.filters.activeSlotsFilter
        }))
        setUserList()
        // setProjectsList(data.filters.projectsFilter)
      }
      loadingHide();

    });
  }

  function setAllActivityData(data) {
    let allScreenshots = []
    if (data.length > 0) {
      for (const hour of data) {
        if (hour.minuteSlots.length > 0) {
          for (const minute of hour.minuteSlots) {
            if (minute.screens.length > 0) {
              for (const screen of minute.screens) {
                allScreenshots.push(screen)
              }
            }
          }
        }
      }
    }
    return allScreenshots
  }

  function nameShorten(list, fieldName) {
    for (const item of list) {
      const name = item[fieldName];
      item.displayName = NameProccess(name, 15);
    }
  }

  function setData(date) {
    dispatch(setSelectedDate(moment(date).startOf('day').utc().toISOString()));
  }

  function setDateRange(date) {
    setDateRangeObject([
      {
        startDate: date,
        endDate: addDays(date, 0),
        key: "selection",
      },
    ])
  }

  function setUserList() {
    if (filterData.members.length > 0) {
      setMembersList(filterData.members)
    } else {
      setMembersList(activeMemberList)
    }
  }

  useEffect(() => {
    if (activeMemberList.length > 0) {

      if (selectedUser === null && filteredMember === null) {
        setAuthUser()
      } else if (filterUser !== null) {
        changeUser(filterUser)
      }
    }
  }, [activeMemberList, filterUser])

  useEffect(() => {
    if (selectedDate === null) {
      setData(today.toISOString());
    }

    loadingShow();
    if (selectedDate !== null && selectedUser !== null && filterParams !== null) {
      // console.log('all set');
      getData()
    }
  }, [selectedDate, selectedUser, filterParams])

  useEffect(() => {
    if (initDateRangeObj !== dateRangeObject) {
      let dateRange = moment(dateRangeObject[0].startDate)
      setData(dateRange._d.toISOString());
    }
  }, [dateRangeObject])


  useEffect(() => {
    // console.log('filterData - ', filterData);
  }, [filterData])

  useEffect(() => {
    // console.log('activityData - ', activityData);
  }, [activityData])




  return (
    <div className="flex flex-row ">
      <SideBar />
      <div className="flex-1 bg-gray-100 h-full min-h-screen">
        <Navbar />
        <div className="flex flex-row w-full p-5 custom-body">
          <div className="w-full bg-white p-2 rounded-md relative">
            <div className="flex flex-row py-3">
              <div className="flex w-full mx-4 order-first">
                <h1 className=" font-bold page-title absolute">Activity</h1>
                <div
                  className="m-auto py-1.5 rounded-full text-sm timeframe"
                  style={{ background: "#E6E7E7" }}
                >
                  <span
                    className={`px-5 rounded-full py-2 cursor-pointer ${screenType === 0 ? "active" : ""
                      }`}
                    onClick={() => {
                      dispatch(setScreenType(0));
                    }}
                  >
                    Every 10 minutes
                  </span>
                  <span
                    className={`px-5 rounded-full py-2 cursor-pointer ${screenType === 1 ? "active" : ""
                      }`}
                    onClick={() => {
                      dispatch(setScreenType(1));
                    }}
                  >
                    All screenshots
                  </span>
                </div>
              </div>
            </div>

            <div className="flex px-3 pb-2 w-full justify-between item-center">
              <div className="flex">
                <div className="w-1/24 block mb-2 text-sm font-medium dark:text-white cursor-pointer">
                  <button
                    className={
                      "border border-slate-400 p-2 mr-1 py-1 hover:bg-yellow-300 hover:border-yellow-300 rounded-md"
                    }
                    onClick={() => {
                      let newDate = moment(selectedDate).subtract(1, "day");
                      setData(newDate._d.toISOString());
                      setDateRange(newDate._d)
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                      />
                    </svg>
                  </button>
                  {moment(selectedDate).isSame(new Date(), "date") ? (
                    <button
                      className={
                        "border border-slate-400 p-2 mr-1 py-1 rounded-md disabled"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 fill-gray-100"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </button>
                  ) : (
                    <button
                      className={
                        "border border-slate-400 p-2 mr-1 py-1  hover:bg-yellow-300 hover:border-yellow-300 rounded-md"
                      }
                      onClick={() => {
                        let newDate = moment(selectedDate).add(1, "day");
                        setData(newDate._d.toISOString());
                        setDateRange(newDate._d)

                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 fill-gray-100"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </button>
                  )}
                </div>
                <div className="w-4/24 ml-2">
                  <DateCalendar
                    dateRangeObject={dateRangeObject}
                    setDateRangeObject={setDateRangeObject}
                  // setApplyDateRange={setApplyDateRange}
                  //   setSelectedDayForWeek={setSelectedDayForWeek}
                  />
                </div>
              </div>
              <div className={`flex justify-end`}>

                {searchBoxVisible ? (
                  <div style={{ maxWidth: '200px', width: '200px' }}>
                    <Dropdown
                      listData={membersList}
                      multiselect={false}
                      placeholder={'Search members'}
                      selectedItems={filterUser}
                      setSelectedItems={setFilterUser}
                      descriptionShow={true}
                    />
                  </div>
                ) : null}
                <div className="ml-2"  style={{ maxWidth: '150px', width: '150px' }}>
                  <Filters
                    selectedUser={filterUser}
                    setSelectedUser={setFilterUser}
                    searchBoxVisible={searchBoxVisible}
                  />
                </div>


              </div>
            </div>

            <div className="flex-row px-3 pb-2 mb-3">
              <div className="w-full py-2">
                <div className="font-bold text-base ">How activity works</div>
              </div>

              <div className="flex w-full border p-1 rounded-lg border-gray-300 text-[#7F8290]">
                <div className="w-4/12 border-r-2 p-3">
                  <div className="uppercase text-xs font-bold">time</div>
                  <div className="flex">
                    <div className="flex-row w-full">
                      <div className="w-6/12 text-3xl pt-3 font-extralight text-[#1D212C]">
                        {todayTotalTime === null ? <> - </> : secondsToHours(todayTotalTime)}
                      </div>
                      <div className="w-6/12 text-xs uppercase font-extralight">
                        Total worked
                      </div>
                    </div>
                    <div className="flex-row w-full">
                      <div className="w-6/12 text-3xl pt-3 font-extralight text-[#1D212C]">
                        =
                      </div>
                      <div className="w-6/12 text-xs uppercase font-extralight">
                        to prev day
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-4/12 border-r-2 p-3">
                  <div className="uppercase text-xs font-bold">activity</div>
                  <div className="flex">
                    <div className="flex-row w-full">
                      <div className="w-6/12 text-3xl pt-3 font-extralight text-[#1D212C]">
                        -
                      </div>
                      <div className="w-6/12 text-xs uppercase font-extralight">
                        average
                      </div>
                    </div>
                    <div className="flex-row w-full">
                      <div className="w-6/12 text-3xl pt-3 font-extralight text-[#1D212C]">
                        -
                      </div>
                      <div className="w-6/12 text-xs uppercase font-extralight">
                        to prev day
                      </div>
                    </div>
                    <div className="flex-row w-full">
                      <div className="w-6/12 text-3xl pt-3 font-extralight text-[#1D212C]">
                        -
                      </div>
                      <div className="w-6/12 text-xs uppercase font-extralight">
                        to org avg
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-4/12 p-3">
                  <div className="uppercase text-xs font-bold">tasks</div>
                  <div className="flex">
                    <div className="flex-row w-full">
                      <div className="w-6/12 text-3xl pt-3 font-extralight text-[#1D212C]">
                        0:00
                      </div>
                      <div className="w-6/12 text-xs uppercase font-extralight">
                        completed
                      </div>
                    </div>
                    <div className="flex-row w-full">
                      <div className="w-6/12 text-3xl pt-3 font-extralight text-[#1D212C]">
                        -
                      </div>
                      <div className="w-6/12 text-xs uppercase font-extralight">
                        to prev day
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-row px-3 py-2 pb-2">
              {screenType === 0 ? (
                <>
                  {activityData.hourly.length > 0 ? (
                    <>
                      {activityData.hourly.map((hourSlot, hourSlotIndex) => (
                        <div key={Math.random() + hourSlotIndex}>
                          <CardsByHoursAndMinutes
                            hourSlot={hourSlot} />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto  text-center bg-gray-300 inline-block my-3 rounded-lg">
                        No screenshots has being taken
                      </div>
                    </>
                  )}
                </>
              ) : screenType === 1 ? (
                <>
                  {activityData.all.length > 0 ? (
                    <>
                      <div className="mb-5">
                        {(Object.keys(activityData).length > 0) ? (
                          <>
                            <div className="mt-3 ml-4 mr-4 grid grid-cols-6 gap-4">
                              {activityData.all.map((screen, screenIndex) => (
                                <div key={Math.random() + screenIndex}>
                                  <SingleCard
                                    screen={screen}
                                  />
                                </div>
                              ))}
                            </div>
                          </>
                        ) :
                          <>
                            <div className="mt-3">
                              <div className="w-full grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto  text-center bg-gray-300 inline-block my-3 rounded-lg">
                                No screenshots has being taken
                              </div>
                            </div>
                          </>
                        }
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <div className="grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto  text-center bg-gray-300 inline-block my-3 rounded-lg">
                    No screenshots has being taken
                  </div>
                </>
              )}
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default Activities;
