import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { deleteProfileImage } from "../../../services/account";

const DeleteImage = ({ deleteImageModdalStatus, setDeleteImageModdalStatus, deleteImageStatus, setDeleteImageStatus }) => {
    return (
        <>
            {deleteImageModdalStatus ? (
                <>
                    <Transition appear show={deleteImageModdalStatus} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/60"
                            onClose={() => {
                            }}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>
                                <span
                                    className=" h-screen  align-top"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="modal-loading inline-block w-full max-w-lg p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded ">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900 pb-2 border-b border-solid border-slate-200"
                                        >
                                            Delete Image
                                            <span className=' right cursor-pointer'
                                            onClick={()=>{
                                                setDeleteImageModdalStatus(false);
                                            }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </span>
                                        </Dialog.Title>
                                        <div className="">
                                            <div className={'mt-3'}>
                                                Are you sure you want to delete the Profile Image?
                                            </div>
                                        </div>
                                        <div className="my-5">
                                            <span
                                                className="imageUploader-btn-hbt right cursor-pointer mx-2 w-fit"
                                                onClick={()=>{
                                                    deleteProfileImage({}).then((response)=>{
                                                        if(response.code == 200){
                                                            setDeleteImageModdalStatus(false);
                                                            setDeleteImageStatus(true);
                                                        }
                                                    })
                                                }}
                                            >
                                                Delete
                                            </span>
                                            <span
                                                className="px-4 py-2 right cursor-pointer text-sm text-white hbt-bg-dark border border-transparent rounded duration-300"
                                                onClick={() => {
                                                    setDeleteImageModdalStatus(false);
                                                }}
                                            >
                                                Cancel
                                            </span>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : null}
        </>
    );
}

export default DeleteImage;