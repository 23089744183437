import React from 'react'
import { Loader } from '../../../helper/loading';
import { Link } from 'react-router-dom';
import { useState } from "react";
import { useEffect } from "react";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import * as yup from "yup";
import axios from '../../../api/axios';
import logo from "../../../img/logo/Honey bee time tracker logo dark.png";
import { notifySuccess, notifyError } from '../../../helper/notification-helper';
import { ToastContainer } from 'react-toastify';

const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
  });

const ForgotPassword = () => {
    const [loading, setLoading] = useState(true);
    const [saveBtnState, setSaveBtnState] = useState(true);
    const [error, setError] = useState({});

    const checkLoad = () => {
        {
            document.readyState === 'loading' 
            ? (setLoading(true)) 
            : (setLoading(false))
        }
    }
    useEffect(() => {
        checkLoad();
        document.title = "HoneybeeTime Account";
        }, []);

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const data = {
            email: values.email,
            };
            onSubmit(data);
        },
    });

    const onSubmit = async (data) => {
        try {
          const responce = await axios.post("/forgot-password", data);
          if (responce.data.code === 201) {
            notifySuccess("Instructions sent successfully !");
          }
        } catch (error) {
          if (error.response.status === 400) {
            setSaveBtnState(false)
            notifyError("Account not found !")
            setTimeout(() => {
                setSaveBtnState(true);
            }, 6500);
          }
        }
    };

    const EmailIcon = () => {
        return (
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.29408 18.4651H13.7647V16.6418H9.29408C5.41361 16.6418 2.14114 13.3053 2.14114 9.34882C2.14114 5.39236 5.41361 2.0558 9.29408 2.0558C13.1746 2.0558 16.447 5.39236 16.447 9.34882V10.6525C16.447 11.3726 15.8122 12.0837 15.1058 12.0837C14.3995 12.0837 13.7647 11.3726 13.7647 10.6525V9.34882C13.7647 6.83273 11.7618 4.79068 9.29408 4.79068C6.82632 4.79068 4.82349 6.83273 4.82349 9.34882C4.82349 11.8649 6.82632 13.907 9.29408 13.907C10.528 13.907 11.6546 13.3965 12.4593 12.5669C13.0404 13.3782 14.0418 13.907 15.1058 13.907C16.8673 13.907 18.2353 12.4484 18.2353 10.6525V9.34882C18.2353 4.31664 14.2296 0.232544 9.29408 0.232544C4.35855 0.232544 0.352905 4.31664 0.352905 9.34882C0.352905 14.381 4.35855 18.4651 9.29408 18.4651ZM9.29408 12.0837C7.80985 12.0837 6.61173 10.8621 6.61173 9.34882C6.61173 7.83552 7.80985 6.61394 9.29408 6.61394C10.7783 6.61394 11.9764 7.83552 11.9764 9.34882C11.9764 10.8621 10.7783 12.0837 9.29408 12.0837Z"
              fill="#1C2130"
            />
          </svg>
        );
    };

    return (
        <>
            {loading 
                ? (<Loader />) 
                : (
                    <>
                    <div className='bg-gray-200 h-full w-full min-h-screen custom-body flex relative'>
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                        />
                        <div className='bg-white w-[650px] p-[60px] mx-auto rounded-[25px] shadow-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                            <div className='logo-container my-2 w-full'>
                                <img 
                                    src={logo}
                                    alt="Logo of HoneybeeTime"
                                    className='w-2/3 mx-auto'
                                />
                            </div>
                            <div className='title-container items-center mt-2'>
                                <h1 className='text-[#3d3d3d] text-[40px] font-bold text-center'>
                                    Forgot your password?
                                </h1>
                            </div>
                            <div className='w-full mx-auto text-center mt-4'>
                                <p>
                                    <span className='text-[16px]'>
                                        Please enter the email that you registered with HoneybeeTime. We will send the necessary instructions to reset your password.
                                    </span>
                                </p>
                            </div>
                            <div>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="flex flex-col">
                                        <div className="mt-5 w-full mx-auto !h-[50px]">
                                            <TextField
                                                name="email"
                                                className="text-[18px] !placeholder:text-[16px] mt-1 w-full mx-auto !h-[50px] px-3 py-2 bg-white border-slate-300 placeholder-slate-400 block rounded-md"
                                                label="Work Email"
                                                placeholder="Please enter your email"
                                                variant="outlined"
                                                onChange={formik.handleChange}
                                                error={
                                                (formik.touched.email && Boolean(formik.errors.email)) ||
                                                error.show
                                                }
                                                helperText={formik.errors.email}
                                                InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                    <EmailIcon />
                                                    </InputAdornment>
                                                ),
                                                sx: { height: 50, marginTop: "2px" },
                                                }}
                                                InputLabelProps={{
                                                style: { color: "#000000", fontSize: "16px" },
                                                }}
                                                sx={{
                                                "& placeholder": {
                                                    fontSize: "16",
                                                },
                                                "& label": {
                                                    // marginTop: "5",
                                                    fontSize: "16",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "1px solid #1c2130",
                                                    borderRadius: "7px",
                                                },
                                                "& .MuiOutlinedInput-root.Mui-focused": {
                                                    "& > fieldset": {
                                                    borderColor: "#1C2130",
                                                    },
                                                },
                                                "& .MuiOutlinedInput-root:hover": {
                                                    "& > fieldset": {
                                                    border: "2px solid #1c2130",
                                                    },
                                                },
                                                }}
                                            />
                                        </div>
                                        <div className="flex flex-col w-full mt-[25x] mr-[25px]">
                                            {error.show && error.type === "custom" ? (
                                                <p className="mt-6 text-red-600 text-[12px] mx-14 ">
                                                {error.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                    {saveBtnState === false ? (
                                        <button
                                        className="mt-8 mx-auto w-full text-white bg-[#F6921E] hover:bg-[#EE8000] focus:ring-4 focus:ring-[EE8000] font-medium rounded-lg focus:outline-none h-[50px]"
                                        type="submit"
                                        disabled
                                        >
                                            <span className="font-semibold text-[18px]">
                                                Send Instructions
                                            </span>
                                        </button>
                                    ) : (
                                        <button
                                            className="mt-8 mx-auto w-full text-white bg-[#F6921E] hover:bg-[#EE8000] focus:ring-4 focus:ring-[EE8000] font-medium rounded-lg focus:outline-none h-[50px]"
                                            type="submit"
                                        >
                                            <span className="font-semibold text-[18px]">
                                                Send Instructions
                                            </span>
                                        </button>
                                    )}
                                    
                                </form>
                            </div>
                            <div className='w-full mx-auto text-center mt-[35px]'>
                                <Link to={"/login"}>
                                    <span className='text-[14px] underline'>
                                        Back to Sign in
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </>
                )
            }
        </>
    )
}

export default ForgotPassword