import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../../../api/axios";
import { startTimer } from "../../../helper/globle";
import { loadingHide, loadingShow, timer } from "../../../helper/loading";

const headers = {}


const OrgVerification = () => {
    let params = useParams()

    const [verify, setVerify] = useState(false)
    const [tokenExpire, setTokenExpire] = useState(false)

    const [timerCount, setTimerCount] = useState(null)
    const [inviteResponse, setInviteResponse] = useState([])
    const [verifiedUserSignup, setVerifiedUserSignup] = useState(false)

    useEffect(() => {
        if (timerCount != null) {
            setTimeout(() => {
                if (timerCount > 0) {
                    setTimerCount(timerCount - 1)
                } else {
                    localStorage.setItem('invited-user-email', inviteResponse.data.email)
                    localStorage.setItem('invited-user-token', inviteResponse.data.verifyToken)
                    window.location.replace("/signup");
                }
            }, 1000);
        }

    }, [timerCount])

    function str_pad_left(string, pad, length) {
        return (new Array(length + 1).join(pad) + string).slice(-length);
    }


    useEffect(() => {
        loadingShow()
        if (params.token !== null || params.token !== undefined) {
            let verifyToken = params.token;

            VerifyOrg(verifyToken).then(function (response) {
                setInviteResponse(response);
                if (response.code == 201) {
                    if (response.data == 'Succeed') {
                        setTimeout(() => {
                            localStorage.setItem('org-verification-token', 'verified');
                            setTokenExpire(false);
                            setVerify(false);
                            setVerifiedUserSignup(true)
                            loadingHide()
                        }, 2000);
                    } else {
                        setTimeout(() => {
                            localStorage.setItem('org-verification-token', 'verified');
                            setTokenExpire(false);
                            setVerify(true);
                            loadingHide()
                            setTimeout(() => {
                                setTimerCount(5)

                            }, 100);
                        }, 2000);
                    }


                } else if (response.code == 404) {
                    setTimeout(() => {
                        localStorage.setItem('org-verification-token', 'not-verified');
                        setTokenExpire(true);
                        setVerify(false);
                        loadingHide()
                    }, 2000);
                }


            });

        }

    }, [])

    const VerifyOrg = async (token) => {
        try {
            const body = { verifyToken: token };
            const response = await axios.post('/verify-add-user-to-organization/verify', body, { headers })
            return response.data;
        } catch (error) {
            console.log(error);
            //logout when unauthorized
            return error.response.data.error;
        }
    };

    return (
        <>
            <div className="relative flex flex-row h-screen justify-center items-center w-full custom-body">
                {verifiedUserSignup ? (
                    <>
                        <div className="relative flex flex-row h-screen justify-center items-center w-full custom-body">
                            <div className='w-full'>
                                <div className='px-32 py-44 mx-auto w-2/6'>
                                    <div className="flex flex-col">
                                        <div className={'justify-items-center text-center'}>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 p-2 rounded-full mx-auto bg-green-600 text-white my-3">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                </svg>
                                            </div>
                                            <div className={'text-xl'}>We've successfully registered you</div>
                                            <div><Link className={'text-sm text-blue-600'} to={'/login'}>Click here to go to the Login page</Link></div>

                                        </div>

                                    </div>
                                </div>
                                <div className=' w-full  justify-bottom items-center text-center'>HoneybeeTime.com</div>
                            </div>
                        </div>
                    </>
                ) : null}
                {verify ? (
                    <>
                        <div className='w-full'>
                            <div className='px-32 py-44 mx-auto w-2/6'>
                                <div className="flex flex-col">
                                    <div className={'justify-items-center text-center'}>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 p-2 rounded-full mx-auto bg-green-600 text-white my-3">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                            </svg>
                                        </div>
                                        <div className={'text-xl'}>Invitation Accepted!</div>
                                        <div id="timer" className={'timer'}></div>
                                        {timerCount != null ? (<div>{str_pad_left('0', '0', 2) + ':' + str_pad_left(timerCount, '0', 2)}</div>) : null}
                                        <div><Link className={'text-sm text-blue-600'} to={'/login'}>Click here to go to the Login page</Link></div>

                                    </div>

                                </div>
                            </div>
                            <div className=' w-full  justify-bottom items-center text-center'>HoneybeeTime.com</div>
                        </div>
                    </>
                ) : null}

                {tokenExpire ? (
                    <>
                        <div className='w-full'>
                            <div className='px-32 py-44 mx-auto w-2/6'>
                                <div className="flex flex-col">
                                    <div className={'justify-items-center text-center'}>
                                        <div>

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-14 h-14 p-2 rounded-full mx-auto bg-red-600 text-white my-3">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>

                                        </div>
                                        <div className={'text-xl'}>Invitation Expired!</div>
                                        <div><Link className={'text-sm text-blue-600'} to={'/login'}>Click here to go to the Login page</Link></div>

                                    </div>

                                </div>
                            </div>
                            <div className=' w-full  justify-bottom items-center text-center'>HoneybeeTime.com</div>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
}

export default OrgVerification;