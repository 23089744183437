import moment from "moment";
import ColorSelector from "../../../helper/colorSelector";
import { secondsToHours } from "../../globle-component/globle";
import CardForHourRow from "./cardForHourRow";

function timeFormat(value) {
    return moment(value, "hh:mm:ss").format('hh:mm a');
}

function minuteRangeBreak(string) {
    var res = string.split(" - ");
    let obj = {
        start: timeFormat(res[0]),
        end: timeFormat(res[1])
    }
    return obj;
}

const CardsByHoursAndMinutes = ({ hourSlot }) => {

    // console.log('hourSlot - ', hourSlot);
    // console.log('hour - ',moment(hourSlot.hourSlotStart,"hh:mm:ss").hours());
    // console.log('hour - ',moment(hourSlot.hourSlotStart,"hh:mm:ss").format('hh:mm:ss a'));
    // console.log('hour proccess - ',new Date(hourSlot.hourSlotStart));

    let projectColor = ColorSelector(Math.floor(Math.random() * (20 - 0 + 1)) + 0);
    return (
        <>
            <div className="mb-5">
                <div className="flex text-[14px] text-[#1D212C]">
                    <div className=" projectColorCircle mr-2" style={{ background: `${projectColor}` }}></div>
                    <div className="mr-5">
                        <div className=" font-bold"> {timeFormat(hourSlot.hourSlotStart)} - {timeFormat(hourSlot.hourSlotEnd)}</div>
                    </div>
                    <div className="">
                        <div className="text-[#7F8290]">
                            total time worked -
                            <span className="font-bold text-[#1D212C]">{secondsToHours(hourSlot.duration)}</span>
                        </div>
                    </div>
                </div>
                {hourSlot.minuteSlots.length > 0 ? (
                    <>
                        <div className="mt-3 ml-4 mr-4 grid grid-cols-6 gap-4">
                            {hourSlot.minuteSlots.map((minuteSlot, minuteIndex) => {
                                const minuteRange = minuteRangeBreak(minuteSlot.minuteSlot)
                                return (
                                    <CardForHourRow
                                        minuteSlot={minuteSlot}
                                        minuteRange={minuteRange}
                                        key={minuteIndex}
                                    />
                                );
                            })}
                        </div>
                    </>
                ) : null}

            </div>
        </>
    );
}

export default CardsByHoursAndMinutes;