import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getTasksByProject } from "../../../services/tasks"
import { NameProccess } from "../../../component/globle-component/globle"
import { loadingHide } from "../../../helper/loading"

const initialState = {
    completedTaskDisplayStatus:false,
    activeTaskList:[],
    selectedProject: null,
    selectedProjectMembers: [],
    selectedAssignee: null,
    meta: {
        activeTasks: {
            pageCount: 11,
            pageNumber: 1,
            pageLoading: false
        }
    },
    nameCharacterLength : 30
}

export const getActiveTaskByProject = createAsyncThunk('tasks/getActiveTaskByProject', async (object, thunkApi) => {
    try {
        const { data } = await getTasksByProject(object.organizationId, object.projectID,object.assigneeId,object.status)
        nameShorten(data)
        loadingHide()
        return data
    } catch (error) {
        console.log('error - ', error);
    }
})

export const getTaskData = createAsyncThunk('tasks/getTaskData', async (organizationId, thunkApi)=>{
    let taskState = thunkApi.getState().tasks;

    let filterObject = {
        organizationId: organizationId,
        projectID: null,
        assigneeId: null,
        status:1
    }
    if(taskState.selectedProject !== null){
        filterObject.projectID = JSON.parse(taskState.selectedProject.id)
    }

    if(taskState.selectedAssignee !== null){
        filterObject.assigneeId = JSON.parse(taskState.selectedAssignee.id)
    }

    thunkApi.dispatch(getActiveTaskByProject(filterObject))
})

function nameShorten(list) {
    for (const item of list) {
        item.displayName = NameProccess(item.title, initialState.nameCharacterLength);
    }
}

export const taskSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setCompletedTaskDisplayStatus: (state, action) => {
            state.completedTaskDisplayStatus = action.payload
        },
        setSelectedProject: (state, action) => {
            state.selectedProject = action.payload
        },
        setSelectedAssignee: (state, action) => {
            state.selectedAssignee = action.payload
        },
        setSelectedProjectMembers: (state, action) => {
            state.selectedProjectMembers = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getActiveTaskByProject.pending, (state, action) => {
            state.isLoading = true;
            state.activeTaskList = []
            state.meta.activeTasks.pageNumber = 1;
        });

        builder.addCase(getActiveTaskByProject.fulfilled, (state, action) => {
            state.isLoading = false;
            state.activeTaskList = action.payload;
        });

        builder.addCase(getActiveTaskByProject.rejected, (state, action) => {
            state.isLoading = false;
            state.meta.activeTasks.pageNumber = 1;
        });

    }
})

// Action creators are generated for each case reducer function
export const { setCompletedTaskDisplayStatus, setSelectedProject, setSelectedAssignee } = taskSlice.actions

export default taskSlice.reducer