import Navbar from "../dashboard/component/navbar";
import Sidebar from "../dashboard/component/sidebar";
import React from "react";
import { Fragment, useState, useEffect } from "react";
import { Transition, Dialog } from "@headlessui/react";
import "tw-elements";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Paginations from "../globle-component/paginations";

import TableData from "./components/tableData";
import CreateOrgForm from "./components/createOrganization";
import { notifyError, notifySuccess } from "../../helper/notification-helper";

import { useDispatch, useSelector } from "react-redux";
import { fetchOrganizations } from "../../store/slices/organizations";
import { loadingHide, loadingShow, pageLoading } from "../../helper/loading";

import OrganizationSearch from "./components/search";

import {
    addNewOrganization,
    editOrganization,
    getAllOrganizations,
} from "../../services/organizations";
import { NameProccess } from "../globle-component/globle";
import { ToastContainer } from "react-toastify";

const modalObject = {
    formData: {
        id: "",
        name: "",
        industry: "",
        team_size: "",
    },
    modals: {
        type: "",
        show: false,
    },
};

const formSchema = Yup.object().shape({
    orgName: Yup.string().required("Required"),
    orgIndustry: Yup.string().required("Required"),
    orgTeamSize: Yup.number()
        .required("Team size is required. Please enter a team size")
        .min("1", "Team size must be greator than or equal to 1")
        .integer("Team size must be a Integer"),
});

let organizationObj = {
    id: localStorage.getItem("organizationID"),
    name: localStorage.getItem("organizationName"),
    authRole: localStorage.getItem("authUserRoll"),
};

const Organizations = () => {
    function closeModal() {
        setModalData(modalObject);
    }

    const CustomInputComponent = (props) => (
        <input
            className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm: focus:ring-1"
            type="text"
            {...props}
        />
    );

    const CustomInputComponentNumber = (props) => (
        <input
            className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm: focus:ring-1"
            type="number"
            {...props}
        />
    );

    const dispatch = useDispatch();
    const { organizationsList, filteredOrganizationsList, meta } = useSelector(
        (state) => state.organizations
    );

    let [loading, setLoading] = useState(true);
    const [organization, setOrganization] = useState(organizationObj);
    let [modalData, setModalData] = useState(modalObject);

    useEffect(() => {
        if (organizationsList.length === 0) {
            dispatch(fetchOrganizations());
        }
    }, []);

    //save organization data on load and default organization changed
    useEffect(() => {
        organizationObj = organization;
    }, [organization]);

    // Edit Organization
    const EditFormSubmit = async (formData) => {
        editOrganization(formData.id, formData).then(({ response }) => {
            if (JSON.parse(organizationObj.id) === response?.data?.data?.id) {
                localStorage.setItem("organizationName", response?.data?.data?.name);
                localStorage.setItem("organizationID", response?.data?.data?.id);
            }
            ReloadOrganizationMethod();
        });
    };

    function ReloadOrganizationMethod() {
        dispatch(fetchOrganizations());
        setLoading(false);
        loadingHide();
    }

    return (
        <>
            <div className="flex flex-row h-full w-full overflow-x-hidden">
                <Sidebar />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <div className="bg-gray-200 w-full h-full min-h-screen flex-1">
                    <Navbar />

                    <div className="flex flex-row w-full p-5 custom-body">
                        <div className="flex flex-col w-full h-full bg-white p-2 rounded-md">
                            <div className="flex flex-row py-3">
                                <div className="w-96 mx-4 order-first">
                                    <h1 className="ht-page-header">Manage Organization</h1>
                                </div>
                            </div>

                            <div className="flex sm:flex-row flex-col justify-between">
                                <button
                                    className=" order-first inline-flex items-center h-10 px-[15px] mx-[15px] md:mt-[30px] mt-2 transition-colors duration-150 bg-[#FBE77F] rounded focus:outline-none hover:shadow-lg active:chronos-blue mb-2 w-auto"
                                    type="button"
                                    onClick={() => {
                                        setModalData({
                                            ...modalData,
                                            modals: { type: "insert", show: true },
                                        });
                                    }}
                                >
                                    <svg
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.88889 4.94444H7.11111V7.61111H4.44445V9.38889H7.11111V12.0556H8.88889V9.38889H11.5556V7.61111H8.88889V4.94444ZM8 0.5C3.59111 0.5 0 4.09111 0 8.5C0 12.9089 3.59111 16.5 8 16.5C12.4089 16.5 16 12.9089 16 8.5C16 4.09111 12.4089 0.5 8 0.5ZM8 14.7222C4.56889 14.7222 1.77778 11.9311 1.77778 8.5C1.77778 5.06889 4.56889 2.27778 8 2.27778C11.4311 2.27778 14.2222 5.06889 14.2222 8.5C14.2222 11.9311 11.4311 14.7222 8 14.7222Z"
                                            fill="#171923"
                                        />
                                    </svg>
                                    <span className="py-[10.5px] pl-[15px] leading-[17px] font-bold">
                                        Create Organization
                                    </span>
                                </button>

                                <div className="sm:w-auto w-full md:mt-[30px] mt-2 sm:order-last px-4 ">
                                    <OrganizationSearch />
                                </div>
                            </div>

                            {Object.keys(filteredOrganizationsList).length > 0 ? (
                                <div className="sm:relative max-sm:overflow-x-auto">
                                    <table className="w-full">
                                        <thead className="border-b">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className=" font-medium text-[#171923] px-6 py-4 text-left w-10"
                                                >
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className=" font-medium text-[#171923] px-6 py-4 text-center w-auto"
                                                >
                                                    Industry
                                                </th>

                                                <th
                                                    scope="col"
                                                    className=" font-medium text-[#171923] px-6 py-4 text-center"
                                                >
                                                    User role
                                                </th>
                                                <th
                                                    scope="col"
                                                    className=" font-medium text-[#171923] px-6 py-4 text-center"
                                                ></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <TableData
                                                setModalData={setModalData}
                                            />
                                        </tbody>
                                    </table>
                                    {meta.organizations.pageLoading &&
                                        <div className="flex justify-center w-full p-3">
                                            {pageLoading()}
                                        </div>
                                    }

                                </div>
                            ) : (
                                <>
                                    <div className="grid grid-cols-1 grid-flow-col gap-4 p-3 px-auto bg-gray-100 text-center text-gray-500">
                                        Nothing found.
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Start */}
            {modalData.modals.show && modalData.modals.type == "edit" ? (
                <>
                    <Transition appear show={modalData.modals.show} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/60"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                <span className="inline-block h-screen " aria-hidden="true">
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="inline-block  w-full max-w-md p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded">
                                        <Dialog.Title as="h3" className="ht-modal-header">
                                            Edit Organization General Info
                                        </Dialog.Title>

                                        <Formik
                                            initialValues={{
                                                orgName: modalData.formData.name,
                                                orgIndustry: modalData.formData.industry,
                                                orgTeamSize: modalData.formData.team_size,
                                            }}
                                            validationSchema={formSchema}
                                            onSubmit={(values) => {
                                                setLoading(true);
                                                loadingShow();
                                                const submitObject = {
                                                    name: values.orgName,
                                                    industry: values.orgIndustry,
                                                    team_size:
                                                        values.orgTeamSize == null ||
                                                            values.orgTeamSize == ""
                                                            ? 1
                                                            : values.orgTeamSize,
                                                    start_date: new Date().toISOString().split("T")[0],
                                                    status: 1,
                                                };

                                                if (modalData.modals.type == "insert") {
                                                    addNewOrganization(submitObject).then(function (
                                                        response
                                                    ) {
                                                        submitObject.id = response.data.id;
                                                        let membersArray = submitObject.member_id;
                                                        membersArray.push(response.data.users[0].id);
                                                        submitObject.member_id = membersArray;
                                                        ReloadOrganizationMethod();
                                                    });
                                                } else if (modalData.modals.type == "edit") {
                                                    submitObject.id = modalData.formData.id;
                                                    EditFormSubmit(submitObject);
                                                }
                                                setTimeout(() => {
                                                    setLoading(false);
                                                    loadingHide();
                                                }, 2500);
                                                setModalData(modalObject);
                                            }}
                                        >
                                            {({ errors, touched }) => (
                                                <Form>
                                                    <div className="flex flex-wrap relative my-3">
                                                        <div className="w-full">
                                                            <div className="">
                                                                <div className="mb-3">
                                                                    <label className="block uppercase tracking-wide text-gray-700  font-bold mb-2">
                                                                        Name{" "}
                                                                        <label className="text-red-700 font-bold mb-2">
                                                                            *
                                                                        </label>
                                                                    </label>
                                                                    <Field
                                                                        name="orgName"
                                                                        as={CustomInputComponent}
                                                                        placeholder="Organization Name"
                                                                        className="mt-1 px-2 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#FFC400] focus:ring-[#FFC400] block w-full rounded-md sm: focus:ring-1"
                                                                    />
                                                                    {errors.orgName && touched.orgName ? (
                                                                        <p className="text-red-500 italic mb-3">
                                                                            {errors.orgName}
                                                                        </p>
                                                                    ) : null}
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="block uppercase tracking-wide text-gray-700  font-bold mb-2">
                                                                        Industry{" "}
                                                                        <label className="text-red-700 font-bold mb-2">
                                                                            *
                                                                        </label>
                                                                    </label>
                                                                    <Field
                                                                        name="orgIndustry"
                                                                        as={CustomInputComponent}
                                                                        placeholder="Organization Industry"
                                                                        className="mt-1 px-2 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#FFC400] focus:ring-[#FFC400] block w-full rounded-md sm: focus:ring-1"
                                                                    />
                                                                    {errors.orgIndustry && touched.orgIndustry ? (
                                                                        <p className="text-red-500  italic mb-3">
                                                                            {errors.orgIndustry}
                                                                        </p>
                                                                    ) : null}
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="block uppercase tracking-wide text-gray-700  font-bold mb-2">
                                                                        Team Size
                                                                    </label>
                                                                    <Field
                                                                        name="orgTeamSize"
                                                                        as={CustomInputComponentNumber}
                                                                        placeholder="Team Size"
                                                                        type="number"
                                                                        min="1"
                                                                        className="mt-1 px-2 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#FFC400] focus:ring-[#FFC400] block w-full rounded-md sm: focus:ring-1"
                                                                    />
                                                                    {errors.orgTeamSize && touched.orgTeamSize ? (
                                                                        <p className="text-red-500 italic mb-3">
                                                                            {errors.orgTeamSize}
                                                                        </p>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                            <div className="flex items-center justify-end">
                                                                <button
                                                                    className="ht-btn-black"
                                                                    type="button"
                                                                    onClick={closeModal}
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button className="ht-btn-yellow" type="submit">
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : (
                <Transition appear show={modalData.modals.show} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/60"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            <span className="inline-block h-screen " aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title as="h3" className="ht-modal-header">
                                        Create Organization
                                    </Dialog.Title>
                                    <CreateOrgForm
                                        setModalData={setModalData}
                                        modalData={modalData}
                                        closeModal={closeModal}
                                    />
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            )}
            {/* Modal End */}
        </>
    );
};

export default Organizations;
