import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getProjects } from '../../../services/projects';
import { NameProccess } from '../../../component/globle-component/globle';

const modalObject = {
    formData: {
        id: "",
        name: "",
        description: "",
        managers: [],
        users: [],
        viewers: [],
    },
    modals: {
        type: "",
        show: false,
        tab: null,
    },
};

const initialState = {
    activeProjectList: [],
    filteredActiveProjectList: [],
    archivedProjectList: [],
    filteredArchivedProjectList: [],
    selectedTab: 0,
    initialModal: modalObject,
    addModal: modalObject,
    editModal: modalObject,
    selectedManagers: [],
    selectedUsers: [],
    selectedViewers: [],
    meta: {
        activeProjects: {
            pageCount: 11,
            pageNumber: 1,
            pageLoading: false
        },
        archivedProjects: {
            pageCount: 11,
            pageNumber: 1,
            pageLoading: false
        }
    },
    isLoading: false,
    nameCharacterLength : 30

}

export const getActiveProjects = createAsyncThunk('projects/getActiveAllProjects', async (organizationId, thunkApi) => {
    try {
        const { data } = await getProjects(organizationId, 1)
        projectNameShorten(data.projects)
        return data
    } catch (error) {
        console.log('error - ', error);
    }
})

export const getArchivedProjects = createAsyncThunk('projects/getArchivedAllProjects', async (organizationId, thunkApi) => {
    try {
        const { data } = await getProjects(organizationId, 2)

        return data
    } catch (error) {
        console.log('error - ', error);
    }
})

export const setActiveProjectPageNumber = createAsyncThunk('projects/setActiveProjectPageNumber', async (pageNumber, thunkApi) => {
    const currentState = thunkApi.getState()
    let totalCount = (currentState.projects.meta.activeProjects.pageNumber * currentState.projects.meta.activeProjects.pageCount)
    if (totalCount <= currentState.projects.activeProjectList.length) {
        thunkApi.dispatch(setActiveProjectPageLoading(true))

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                thunkApi.dispatch(setActiveProjectPageLoading(false))
                resolve(currentState.projects.meta.activeProjects.pageNumber + 1)
            }, 1500);
        })
    }
    return null
})

export const setArchivedProjectPageNumber = createAsyncThunk('projects/setArchivedProjectPageNumber', async (pageNumber, thunkApi) => {
    const currentState = thunkApi.getState()
    let totalCount = (currentState.projects.meta.archivedProjects.pageNumber * currentState.projects.meta.archivedProjects.pageCount)
    if (totalCount <= currentState.projects.archivedProjectList.length) {
        thunkApi.dispatch(setArchivedProjectPageLoading(true))

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                thunkApi.dispatch(setArchivedProjectPageLoading(false))
                resolve(currentState.projects.meta.archivedProjects.pageNumber + 1)
            }, 1500);
        })
    }
    return null
})

export const projectsAppendList = createAsyncThunk('projects/projectsAppendList', async (_, thunkApi) => {
    const currentState = thunkApi.getState()
    if (currentState.projects.selectedTab === 0) {
        thunkApi.dispatch(setActiveProjectPageNumber())
    } else if (currentState.projects.selectedTab === 1) {
        thunkApi.dispatch(setArchivedProjectPageNumber())
    }
})

function projectNameShorten(list) {
    for (const item of list) {
        item.displayName = NameProccess(item.name, initialState.nameCharacterLength);
    }
}

export const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        filteredActiveProjects: (state, action) => {
            if (action.payload === "") {
                state.filteredActiveProjectList = state.activeProjectList.slice(0, (state.meta.activeProjects.pageNumber * state.meta.activeProjects.pageCount));
            } else {
                state.filteredActiveProjectList = state.activeProjectList.filter((obj) => obj.name
                    .toLowerCase()
                    .replace(/\s+/g, "")
                    .includes(action.payload.toLowerCase().replace(/\s+/g, "")))
            }
        },

        filteredArchivedProjects: (state, action) => {
            if (action.payload === "") {
                state.filteredArchivedProjectList = state.archivedProjectList.slice(0, (state.meta.activeProjects.pageNumber * state.meta.archivedProjects.pageCount));
            } else {
                state.filteredArchivedProjectList = state.archivedProjectList.filter((obj) => obj.name
                    .toLowerCase()
                    .replace(/\s+/g, "")
                    .includes(action.payload.toLowerCase().replace(/\s+/g, "")))
            }
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setAddModal: (state, action) => {
            state.addModal = action.payload
        },
        setEditModal: (state, action) => {
            state.editModal = action.payload
        },
        setSelectedManagers: (state, action) => {
            state.selectedManagers = action.payload
        },
        setSelectedUsers: (state, action) => {
            state.selectedUsers = action.payload
        },
        setSelectedViewers: (state, action) => {
            state.selectedViewers = action.payload
        },
        setActiveProjectPageLoading: (state, action) => {
            state.meta.activeProjects.pageLoading = action.payload
        },
        setArchivedProjectPageLoading: (state, action) => {
            state.meta.archivedProjects.pageLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getActiveProjects.pending, (state, action) => {
            state.isLoading = true;
            state.activeProjectList = []
            state.filteredActiveProjectList = []
            state.meta.activeProjects.pageNumber = 1;
        });

        builder.addCase(getActiveProjects.fulfilled, (state, action) => {
            state.isLoading = false;
            state.activeProjectList = action.payload.projects;
            state.filteredActiveProjectList = state.activeProjectList.slice((state.meta.activeProjects.pageNumber - 1), (state.meta.activeProjects.pageNumber * state.meta.activeProjects.pageCount));
        });

        builder.addCase(getActiveProjects.rejected, (state, action) => {
            state.isLoading = false;
            state.meta.activeProjects.pageNumber = 1;
        });

        builder.addCase(setActiveProjectPageNumber.fulfilled, (state, action) => {
            if (action.payload != null) {
                let pageN = action.payload
                state.meta.activeProjects.pageNumber = pageN
                state.filteredActiveProjectList = state.activeProjectList.slice(0, (pageN * state.meta.activeProjects.pageCount));
            }
        });



        builder.addCase(getArchivedProjects.pending, (state, action) => {
            state.isLoading = true;
            state.archivedProjectList = []
            state.filteredArchivedProjectList = []
            state.meta.archivedProjects.pageNumber = 1;
        });

        builder.addCase(getArchivedProjects.fulfilled, (state, action) => {
            state.isLoading = false;
            state.archivedProjectList = action.payload.projects;
            state.filteredArchivedProjectList = state.archivedProjectList.slice((state.meta.archivedProjects.pageNumber - 1), (state.meta.archivedProjects.pageNumber * state.meta.archivedProjects.pageCount));
        });

        builder.addCase(getArchivedProjects.rejected, (state, action) => {
            state.isLoading = false;
            state.meta.archivedProjects.pageNumber = 1;
        });

        builder.addCase(setArchivedProjectPageNumber.fulfilled, (state, action) => {
            if (action.payload != null) {
                let pageN = action.payload
                state.meta.archivedProjects.pageNumber = pageN
                state.filteredArchivedProjectList = state.archivedProjectList.slice(0, (pageN * state.meta.archivedProjects.pageCount));
            }
        });

    }
})

// Action creators are generated for each case reducer function
export const { filteredActiveProjects, filteredArchivedProjects, setSelectedTab, setAddModal, setEditModal, setSelectedManagers, setSelectedUsers, setSelectedViewers, setActiveProjectPageLoading, setArchivedProjectPageLoading } = projectSlice.actions

export default projectSlice.reducer