import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const ReCAPTCHAComponent = ({ onTokenReceived }) => {
  const onChange = (recaptchaToken) => {
    if (recaptchaToken )
    onTokenReceived(recaptchaToken);
  };

  return (
    <ReCAPTCHA
      sitekey= {process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      onChange={onChange}
    />
  );
}

export default ReCAPTCHAComponent;
