import { ToastContainer } from "react-toastify";
import SideBar from "../dashboard/component/sidebar";
import Navbar from "../dashboard/component/navbar";
import { Dropdown } from "../globle-component/dropdown";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getActiveProjects } from "../../store/slices/projects";
import { Switch, alpha, styled } from "@mui/material";
import { yellow } from "@mui/material/colors";
import { getTaskData, setCompletedTaskDisplayStatus, setSelectedAssignee, setSelectedProject } from "../../store/slices/tasks";
import { Link } from "react-router-dom";
import { loadingHide, loadingShow } from "../../helper/loading";
import TableData from "./components/tableData";
import AddTaskModal from "./components/addTaskModal";
import { UserRole } from "../../constant/userRole";
import EditTaskModal from "./components/editTaskModal";
import DuplicateProject from "./components/duplicateProject";
import DeleteModal from "./components/deleteModal";
import MarkAsCompleteModal from "./components/markAsCompleteModal";

const organizationId = JSON.parse(localStorage.getItem('organizationID'));
const authUserId = JSON.parse(localStorage.getItem('userId'));
const authUserRole = JSON.parse(localStorage.getItem('orgUserRole'));

const YSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: yellow[600],
        '&:hover': {
            backgroundColor: alpha(yellow[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: yellow[600],
    },
}));

const taskModal = {
    formData: {
        taskId: "",
        taskName: "",
        taskAssignee: "",
        taskCreatedDate: "",
        taskStatus: "",
    },
    modals: {
        type: "",
        show: false,
    },
};



const Task = () => {
    const dispatch = useDispatch();

    const { activeProjectList } = useSelector((state) => state.projects)
    const { completedTaskDisplayStatus, activeTaskList, selectedAssignee } = useSelector((state) => state.tasks)

    const selectedProject = useSelector((state) => state.tasks.selectedProject)

    const [assigneeList, setAssigneeList] = useState([])
    // const [selectedAssignee, setSelectedAssignee] = useState(null)
    const [assignee, setAssignee] = useState(null)
    const [assigneePlaceholder, setAssigneePlaceholder] = useState('Select assignee')
    const [allowCreateTask, setAllowCreateTask] = useState(true)
    const [userRoleOnProject, setUserRoleOnProject] = useState(null);

    let [modalData, setModalData] = useState(taskModal);

    useEffect(() => {
        loadingShow()
        if (activeProjectList.length === 0) {
            dispatch(getActiveProjects(organizationId))
            loadingHide()
        }

    }, [])


    useEffect(() => {
        if (activeProjectList.length > 0) {
            getSingleProject(activeProjectList[0])
            loadingHide()
        }
    }, [activeProjectList])


    function showCompletedTask(status) {
        dispatch(setCompletedTaskDisplayStatus(status))
    }

    function getSingleProject(project) {
        setUserRoleOnProject(null)
        loadingShow()
        let users = []
        project.projectUsers.forEach(userObj => {
            let user = { id: userObj.user.id, displayName: userObj.user.full_name }
            users.push(user);
        });

        dispatch(setSelectedProject(project))
        setAssigneeList(users)
        dispatch(getTaskData(organizationId))
        checkPermissions(project)
    }

    useEffect(() => {
        if (assignee !== null) {
            dispatch(setSelectedAssignee(assignee))
            dispatch(getTaskData(organizationId))

        }
    }, [assignee])

    function clearAssignee() {
        dispatch(setSelectedAssignee(null))
        setAssigneePlaceholder('Select assignee')
        dispatch(getTaskData(organizationId))
    }

    const checkPermissions = (project) => {
        const projectUsers = project.projectUsers.filter((user) => user.userId === authUserId);
        const projectUserRole = projectUsers.length > 0 ? projectUsers[0]?.role : null;
        setUserRoleOnProject(projectUserRole);

        if (projectUserRole === null) {
            if (authUserRole === UserRole.ORGANIZATION_OWNER || authUserRole === UserRole.ORGANIZATION_MANAGER) {
                setAllowCreateTask(true);
            } else {
                setAllowCreateTask(false);
            }
        } else if (projectUserRole !== null) {
            if (authUserRole === UserRole.ORGANIZATION_OWNER || authUserRole === UserRole.ORGANIZATION_MANAGER) {
                setAllowCreateTask(true);
            } else if (authUserRole === UserRole.PROJECT_MANAGER || projectUserRole < UserRole.VIEWER) {
                setAllowCreateTask(true);
            } else if (authUserRole === UserRole.PROJECT_MANAGER || projectUserRole === UserRole.VIEWER) {
                setAllowCreateTask(false);
            }
        }
    }

    return (
        <>
            {modalData.modals.show && modalData.modals.type === 'edit' ? (
                <EditTaskModal
                    assigneeList={assigneeList}
                    modalData={modalData}
                    setModalData={setModalData}
                />
            ) : null}
            <div className="flex flex-row h-full w-full overflow-x-hidden">
                <SideBar />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />

                <div className="bg-gray-200 w-full h-full min-h-screen flex-1">
                    <Navbar />
                    <div className="flex flex-row w-full p-5 custom-body">
                        <div className="w-full h-full bg-white sm:p-3 p-2 rounded-md">

                            <div className="mx-4 order-first">
                                <h2 className="ht-page-header">
                                    Tasks
                                </h2>
                            </div>

                            <div className="m-3">
                                <div>

                                    {activeProjectList.length === 0 ? (
                                        <div className="border border-gray-300 flex flex-col grid grid-cols-1 grid-flow-col gap-4 mt-5 p-3 px-auto bg-gray-100 text-center text-gray-500 inline-block element">
                                            <span>No project recorded. Please <Link className={'text-yellow-600 hover:underline font-bold'} to={'/projects'}>Create project</Link> first</span>

                                        </div>
                                    ) : (


                                        <>
                                            <div className="flex lg:flex-row flex-col justify-between">


                                                <div className="flex lg:flex-row flex-col">

                                                    <div className="relative w-[200px] mt-[5px] mx-1">
                                                        <label className="">
                                                            <p className="text-[#7F8290] uppercase px-1">
                                                                Projects
                                                            </p>
                                                        </label>

                                                        <Select
                                                            name="project-select"
                                                            options={activeProjectList}
                                                            onChange={(item) => {
                                                                getSingleProject(item)
                                                            }}
                                                            menuPosition="fixed"
                                                            menuPlacement="auto"
                                                            className="select-input"
                                                            getOptionLabel={option =>
                                                                `${option.displayName}`
                                                            }
                                                            getOptionValue={option => `${option.id}`}
                                                            isSearchable={true}
                                                            placeholder={'Enter Project Name'}
                                                            defaultValue={activeProjectList[0]}
                                                        // menuIsOpen={true}
                                                        />


                                                    </div>

                                                    <div className="relative max-lg:w-full min-w-[210px] mt-1 mx-1">
                                                        <label className="">
                                                            <p className="text-[#7F8290] uppercase px-1 mb-1">
                                                                Assignee
                                                            </p>

                                                        </label>
                                                        {assigneeList.length > 0 ? (
                                                            <>
                                                                <Dropdown
                                                                    listData={assigneeList}
                                                                    multiselect={false}
                                                                    placeholder={assigneePlaceholder}
                                                                    selectedItems={selectedAssignee}
                                                                    setSelectedItems={setAssignee}
                                                                    descriptionShow={true}
                                                                    validation={null}
                                                                    selectedItemsDisplaySeparately={true}
                                                                // disabled={true}
                                                                />
                                                                {selectedAssignee !== null ? (
                                                                    <span className={'cursor-pointer z-50'} onClick={clearAssignee} >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 assignee-search-close">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                        </svg>
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        ) :
                                                            (
                                                                <div className="dropdown-disabled">Select assignee</div>
                                                            )
                                                        }

                                                    </div>

                                                    <div className="relative lg:mt-1 mt-0 mx-1">
                                                        <div className="lg:pt-5 pt-2 uppercase">
                                                            {" "}
                                                            <YSwitch
                                                                checked={completedTaskDisplayStatus}
                                                                onClick={(event) => {
                                                                    showCompletedTask(event.target.checked)
                                                                }}
                                                            />
                                                            {" "}
                                                            <span className="">
                                                                show completed tasks
                                                            </span>{" "}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex lg:flex-row flex-col">
                                                    <div className="relative max-lg:w-full mt-[5px] mx-1">

                                                        <DuplicateProject
                                                            allowCreateTask={allowCreateTask}
                                                            selectedProject={selectedProject}
                                                        />
                                                    </div>

                                                    {allowCreateTask ? (
                                                        <div className="relative max-lg:w-full mt-[5px] mx-1 ml-3.5">
                                                            <AddTaskModal
                                                                assigneeList={assigneeList}
                                                            />
                                                        </div>
                                                    ) : null}

                                                </div>
                                            </div>

                                            {selectedProject !== null ? (
                                                <div>

                                                    <div className="">
                                                        <table className="min-w-full relative table-fixed element mt-5">
                                                            <thead className="border-b">
                                                                <tr className="text-left border-b border-1">
                                                                    <th className="lg:w-25 px-4 py-2 text-[#171923] leading-[16px] font-medium text-[13px] w-30">
                                                                        Task
                                                                    </th>
                                                                    <th className="text-[#171923] leading-[16px] font-medium text-[13px] w-120">
                                                                        Assignee
                                                                    </th>
                                                                    <th className="text-[#171923] leading-[16px] font-medium text-[13px] w-60">
                                                                        Created
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <TableData
                                                                    dataList={activeTaskList}
                                                                    modalData={modalData}
                                                                    setModalData={setModalData}
                                                                    showComplstedTask={completedTaskDisplayStatus}
                                                                    allowCreateTask={allowCreateTask}
                                                                    selectedProject={selectedProject}
                                                                />
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <DeleteModal
                                                        modalData={modalData}
                                                        setModalData={setModalData}
                                                    />
                                                    <MarkAsCompleteModal
                                                        modalData={modalData}
                                                        setModalData={setModalData}
                                                    />
                                                    <div className="flex flex-wrap relative">
                                                        {/* <span className="text-[#7F8290] font-medium leading-4 mt-[20px] mx-[15px]">
                                                        Showing {currentItems.length} of {taskData.length}{" "}
                                                        tasks
                                                    </span> */}
                                                        {/* pagination */}
                                                        {/* <Paginations
                                                        handlePageClick={handlePageClick}
                                                        pageCount={pageCount}
                                                    /> */}
                                                    </div>

                                                </div>
                                            ) : (
                                                <div className="border border-gray-300 flex flex-col grid grid-cols-1 grid-flow-col gap-4 mt-5 p-3 px-auto bg-gray-100 text-center text-gray-500 inline-block element">
                                                    <span>Didn't select any project yet. Please select a project from the dropdown, to see the task list.</span>
                                                </div>
                                            )}
                                        </>
                                    )}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Task;