import moment from "moment";
import { DATETIMES } from "../../../constant/datetimes";
import ColorSelector from "../../../helper/colorSelector";
import { NameProccess } from "../../../component/globle-component/globle";

function setDateValue(val) {
  let x = new Date(val);
  return (
    x.getFullYear() +
    "-" +
    (x.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    x.getDate()
  );
}

const TableData = ({ worktimes, loading }) => {
  if (loading) {
    return (
      <tr>
        <td></td>
      </tr>
    );
  }

  function datetimeCovert(val) {
    return moment(val).format(DATETIMES.time);
  }
  return (
    <>
      {worktimes.map((data, index) => {
        let color = "#ae322e";
        if (data.project.length > 0) {
          color = ColorSelector(data.project[0].id);
        }
        if (data.projectId != null) {
          color = ColorSelector(data.projectId);
        }
        return (
          <tr className="border-b group" key={index}>
            <th
              scope="col"
              className="md:text-sm text-xs font-medium text-gray-900 md:px-6 md:py-4 px-2 py-1 text-left w-78 flex flex-row"
            >
              <button
                className={"rounded-full w-10 h-10 bg-green-700 "}
                style={{ backgroundColor: color }}
              >
                <p className="text-white md:text-sm text-xs font-bold">
                  {data?.projectName[0].toUpperCase()}
                </p>
              </button>
              <div className="w-full">
                <h5 className="px-4 text-gray-800 font-bold static py-2">
                  {NameProccess(data.projectName, 30)}
                </h5>
                {data.project[0]?.tasks ? (
                  <>
                    <div className="px-4 text-gray-600 font-medium static py-1">
                      {NameProccess(data.project[0]?.tasks[0]?.title,35)}
                    </div>
                  </>
                ) : null}
              </div>
            </th>
            <th
              scope="col"
              className="md:text-sm text-xs font-medium text-gray-900 md:px-6 md:py-4 px-2 py-1 text-center"
            >
              <div className="px-4 w-full">
                {data.reason ? (
                  <>
                    {" "}
                    <span className={"font-bold"}>Reason : </span>
                    {data?.reason}
                  </>
                ) : null}
              </div>
            </th>
            <th
              scope="col"
              className="md:text-sm text-xs font-medium text-gray-900 md:px-6 md:py-4 px-2 py-1 text-center"
            >
              {data?.activityLevel?.activePercentage}
            </th>
            <th
              scope="col"
              className="md:text-sm text-xs font-medium text-gray-900 md:px-6 md:py-4 px-2 py-1 text-center"
            >
              {data?.activityLevel?.idlePercentage}
            </th>
            <th
              scope="col"
              className="md:text-sm text-xs font-medium text-gray-900 md:px-6 md:py-4 px-2 py-1 text-center"
            >
              {data?.reason ? <>100%</> : <>0%</>}
            </th>
            <th
              scope="col"
              className="text-sm font-medium text-gray-900 md:px-6 md:py-4 px-2 py-1 text-center"
            >
              {data.durationFormatted}
            </th>
            <th
              scope="col"
              className="text-sm font-medium text-gray-900 md:px-6 md:py-4 px-2 py-1 text-center"
            >
              {setDateValue(data?.startDateTime)}
            </th>
            <th
              scope="col"
              className="text-sm font-medium text-gray-900 md:px-6 md:py-4 px-2 py-1 text-center"
            >
              {datetimeCovert(data?.startDateTime)} -{" "}
              {datetimeCovert(data?.endDateTime)}
            </th>
          </tr>
        );
      })}
    </>
  );
};

export default TableData;
