import axios from "../api/axios";
import { logout } from "../hooks/logout";

let headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`
}

// Get Task List on projects
export const getTasksByProject = async (organizationId, projectID, assigneeId = null, status = null) => {
    let params = {};
    params = { ...params, project_id: projectID, status: 1 }
    if (assigneeId != null) {
        params = { ...params, assignee_id: assigneeId }
    }

    const config = { headers: { ...headers, organization_id: organizationId, project_id: projectID }, params }

    try {
        const response = await axios.get('/tasks/project?', config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
}

// Add new task
export const addNewTask = async (organizationId, projectId, data) => {
    const config = { headers: { ...headers, organization_id: organizationId, project_id: projectId } }
    try {
        const response = await axios.post('/tasks', data, config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
};

// Add members to a task
export const addMembersToTask = async (projectId, taskId, data) => {
    const config = { headers: { ...headers, project_id: projectId } }
    try {
        const response = await axios.post('/tasks/' + taskId, data, config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
}

// Edit task
export const editTask = async (organizationId, taskId, data) => {
    const config = { headers: { ...headers, organization_id: organizationId } }
    try {
        const response = await axios.patch('/tasks/' + taskId, data, config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
}

// Mark task as completed
export const markAsCompleted = async (taskId) => {
    const config = { headers: { ...headers } }
    try {
        const response = await axios.patch('/tasks/' + taskId + "/completed", {}, config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
}

// Delete task
export const deleteTask = async (organizationId, taskId) => {
    const config = { headers: { ...headers, organization_id: organizationId } }
    try {
        const response = await axios.delete('/tasks/' + taskId, config)
        return response.data;
    } catch (error) {
        //logout when unauthorized
        if (error.response.status === 401) {
            logout();
        }
        return { data: [] };
    }
}
