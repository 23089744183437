import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrganizations, filteredOrganizations } from '../../../store/slices/organizations';

const OrganizationSearch = () => {
    const dispatch = useDispatch();
    const {
        filteredOrganizationsList,

    } = useSelector((state) => state.organizations)

    const [searchInputValue, setSearchInputValue] = useState(null)

    function clearSearch() {
        let input = null;
        input = document.getElementById("organization-search");
        setSearchInputValue(null);
        dispatch(filteredOrganizations(''))
        input.value = null;
    }

    return (
        <>
            <label className="project-search-lable sm:w-auto w-full ">
                <input
                    id='organization-search'
                    placeholder="Search organization"
                    type="text"
                    className=" bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-slate-500 focus:ring-slate-500 block sm:w-auto w-full rounded-full py-2 px-10"
                    onChange={(event) =>{
                        setSearchInputValue(event.target.value);
                        dispatch(filteredOrganizations(event.target.value))
                    }
                    }
                />
                {searchInputValue?.length > 0 ? (
                    <span className={'cursor-pointer z-50'} onClick={clearSearch}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 project-search-close">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </span>
                ) : null}
            </label>
        </>
    )
}

export default OrganizationSearch