import axios from "../../api/axios";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect,  useState } from 'react';
import { logout } from "../../hooks/logout";
import moment from 'moment-timezone';
import Select from "react-select";

const logo = 'http://sanmark.lk/logo';

const formSchema = Yup.object().shape({
    orgName: Yup.string().required('Organization name is required'),
    teamSize: Yup.number().required('Team size is required. Please enter a team size').min('1', 'Team size must be greator than or equal to 1').truncate(),
    industry: Yup.string().required('Industry is required'),
    inviteEmail: Yup.string().email('Please enter a valid email'),
    startDate: Yup.date(),
    orgTimeZone: Yup.array().required('Timezone is required'),
});

const headers = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('tag'))}`
}


function CreateOrgForm() {

    const timeZones = moment.tz.names();
    const options = timeZones.map((timezone, index) => ({
        value: timezone,
        label: timezone,
    }));
    const [selectedTimeZone, setSelectedTimeZone] = useState([]);
    
    const selectOnChange = (zone) => {
        setSelectedTimeZone(zone ? zone : []);
    };

    useEffect(() => {
        document.title = "Create Organization";
    }, []);

    const notifyError = (msg) => {
        toast.error(msg, {
            position: toast.POSITION.TOP_LEFT
        });
    };

    const CreateOrgRequest = async (data) => {
        try {
            const response = await axios.post('/organizations/', data, { headers })
            const organizationId = response.data?.data?.id;
            const organizationName = response.data?.data?.name;
            localStorage.setItem('organizationID', organizationId);
            localStorage.setItem('organizationName', organizationName);
            localStorage.setItem('systemTimeZone', selectedTimeZone.value);
            localStorage.setItem('orgUserRole', 1);

            window.location.replace(process.env.REACT_APP_FRONTEND_HOST);
        } catch (error) {
            notifyError('Something went wrong. Please try again later');

        }
    }
    const back = () => {
        logout()
        window.location.replace(process.env.REACT_APP_FRONTEND_HOST);
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className={"flex flex-col  mx-auto mb-5 w-3/6 mt-28"}>
                <h2 className="text-3xl font-semibold text-gray-700 leading-tight mx-16 text-center">Create your organization</h2>
                <p className="text-gray-400 text-base font-medium mt-3 mx-16 text-center">We just need some basic info to get you started</p>
                <Formik
                    initialValues={{
                        orgName: '',
                        teamSize: 1,
                        industry: '',
                        inviteEmail: '',
                        startDate: '',
                        orgTimeZone: []

                    }}
                    validationSchema={formSchema}
                    onSubmit={data => {
                        const formData = {
                            name: data.orgName,
                            industry: data.industry,
                            team_size: data.teamSize,
                            status: 1,
                            logo_image_link: logo,
                            start_date: new Date().toISOString().split('T')[0],
                            timezone: selectedTimeZone.value,
                        };
                        CreateOrgRequest(formData);
                    }}
                >

                    {({ errors, touched }) => (
                        <Form className=" container mx-auto mt-6 w-2/3 align-center" >
                            <div className=" flex flex-row">
                                <div className=" mx-5 py-1 w-1/2">
                                    <div>
                                        <label className="uppercase font-bold text-xs text-gray-500">
                                            Organization Name*
                                        </label>
                                        <Field
                                            className="mt-1 px-2 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                            placeholder="Enter a organization name"
                                            name='orgName'

                                        />
                                        {errors.orgName && touched.orgName ? (
                                            <p className="text-red-500 text-xs italic mt-1">{errors.orgName}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className=" mx-5 py-1 w-1/2">
                                    <div>
                                        <label className="uppercase font-bold text-xs text-gray-500">
                                            Team Size*
                                        </label>
                                        <Field className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                            placeholder="Select team size"
                                            name='teamSize'
                                            type='number'
                                            min='1'
                                        />
                                        {errors.teamSize && touched.teamSize ? (
                                            <p className="text-red-500 text-xs italic mt-1">{errors.teamSize}</p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className=" flex flex-row">
                                <div className=" mx-5 py-1 w-1/2">
                                    <div>
                                        <label className="uppercase font-bold text-xs text-gray-500">
                                            Industry*
                                        </label>
                                        <Field className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                            placeholder="Enter an industry name"
                                            name='industry' />
                                        {errors.industry && touched.industry ? (
                                            <p className="text-red-500 text-xs italic mt-1">{errors.industry}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className=" mx-5 py-1 w-1/2">
                                    <div>
                                        <label className="uppercase font-bold text-xs text-gray-500">
                                            Invite a manager
                                        </label>
                                        <Field className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                            placeholder="Enter an member email"
                                            name='inviteEmail' />
                                        {errors.inviteEmail && touched.inviteEmail ? (
                                            <p className="text-red-500 text-xs italic mt-1">{errors.inviteEmail}</p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className=" flex flex-row">
                                <div className=" mx-5 py-1 w-full">
                                    <div>
                                        <label className="uppercase font-bold text-xs text-gray-500">
                                            Organization Timezone
                                        </label>.
                                        <Select
                                            name="orgTimeZone"
                                            options={options}
                                            onChange={selectOnChange}
                                            value={selectedTimeZone}
                                            menuPosition="fixed"
                                            menuPlacement="auto"
                                            className="mt-1 bg-white shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" 
                                        />

                                        {/* {selectedTimeZone == "" ?
                                        (
                                            <p className="text-red-500 text-xs italic mt-1">Time Zone Required !</p>
                                        ) : null
                                        } */}
                                        
                                        {errors.orgTimeZone && touched.orgTimeZone ? (
                                            <p className="text-red-500 text-xs italic mt-1">{errors.orgTimeZone}</p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            {/* <div className=" mx-9 py-1 ">
                                <div className="mr-3 ">
                                    <label className="uppercase font-bold text-xs text-gray-500">
                                        HOW DID YOU FIND OUT ABOUT CHRONOS?
                                    </label>
                                    <Field
                                        className="mt-1 px-3  py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                                        placeholder="Let us know how you got here"
                                        type='text'
                                        name="about"
                                    />

                                </div>

                            </div> */}

                            {/* <div className="container flex items-center mb-4 container mx-9 py-2">
                                <Field
                                    type="checkbox"
                                    name="about"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-blue-800 focus:ring-4"
                                />
                                <label

                                    className="mx-2 text-sm font-xs text-gray-900">
                                    I currently use a project management tool(Asana. Trello, etc) with my team
                                </label>
                            </div> */}
                            <div className="flex flex-row content-center mt-4 justify-center align-end">
                                <button
                                    className="mx-2 text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none w-36"
                                    type="button"
                                    onClick={() => back()}>
                                    <p className="font-semibold text-bold text-lg  mx-auto">Back</p>
                                </button>
                                <button
                                    className="mx-2 text-white bg-green-500 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none w-36"
                                    type="submit" >
                                    <p className="font-semibold text-bold text-lg  mx-auto">Continue</p>
                                </button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}

export default CreateOrgForm;