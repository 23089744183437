import { Dialog, Transition } from "@headlessui/react";

import { loadingHide, loadingShow } from "../../../helper/loading";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendInvitation } from "../../../services/members";
import { Fragment, useEffect, useState } from "react";
import { emailValidate } from "../../globle-component/globle";
import { Dropdown } from "../../globle-component/dropdown";
import { UserRole } from "../../../constant/userRole";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvitedMembers } from "../../../store/slices/members";

const userRoleArrayConst = [
    {
        id: UserRole.ORGANIZATION_MANAGER,
        displayName: 'Manager',
        description: "Can do everything, except manage financials (depending on permissions) and send payments",
    },
    {
        id: UserRole.ORGANIZATION_USER,
        displayName: 'User',
        description: "Can track and view their time for any project/work order they’re added to",
    },
    // {
    //     id: UserRole.PROJECT_MANAGER,
    //     displayName: 'Project manager',
    //     description: "Can manage, track, and view reports for any projects they’re added to",
    // },
    // {
    //     id: UserRole.USER,
    //     displayName: 'User',
    //     description: "Can track and view their time for any project/work order they’re added to",
    // },
    // {
    //     id: UserRole.VIEWER,
    //     displayName: 'Project viewer',
    //     description: "Can view reports for any project they’re added to (viewers are free)",
    // },
]

const validationMessage = {
    email_required: "This email field cannot be left blank",
    email_invalid: "invalid email address",
    role_required: "This Role field cannot be left blank",
}

const organizationId = JSON.parse(localStorage.getItem('organizationID'));

const InviteModal = ({ setInviteModalNotification }) => {
    const dispatch = useDispatch();
    const {
        activeProjectList,
    } = useSelector((state) => state.projects)

    const [inviteModalDisplay, setInviteModalDisplay] = useState(false)
    const [inviteEmailList, setInviteEmailList] = useState([{ email: null, valid: true }])
    const [selectedUserRole, setSelectedUserRole] = useState([])
    const [selectedProjects, setSelectedProjects] = useState([])
    const [publicInvitationLinkDisplay, setPublicInvitationLinkDisplay] = useState(false)
    const [publicInvitationLink, setPublicInvitationLink] = useState(null)

    const [emailsValidation, setEmailsValidation] = useState(false)
    const [roleValidation, setRoleValidation] = useState(null)
    const [copyLinkButtonClicked, setCopyLinkButtonClicked] = useState(false)

    function closeModal() {
        setInviteModalDisplay(false)
    }


    useEffect(() => {
        let organizationPublicInviteToken = localStorage.getItem('organizationPublicInviteToken');
        if (publicInvitationLink === null) {
            if (organizationPublicInviteToken !== undefined || organizationPublicInviteToken !== '') {
                setPublicInvitationLink(process.env.REACT_APP_FRONTEND_HOST + '/organizations/invite/' + organizationPublicInviteToken)
            }
        }
    }, [publicInvitationLink])



    function addEmail(obj, event) {
        let value = event.target.value;
        let element = document.getElementById(event.target.id)
        console.log('element - ', emailValidate(value));
        if (emailValidate(value)) {
            if (element.classList.contains('modal-input-danger')) {
                element.className -= " modal-input-danger";
                element.className = "modal-input";
                obj.valid = true
            }
        } else {
            if (element.classList.contains('modal-input')) {
                element.className -= "modal-input";
                element.className = " modal-input-danger";
                obj.valid = false
            }
        }

        obj.email = value
        setEmailsValidation(obj.valid)
        let newArray = [...inviteEmailList]
        setInviteEmailList(newArray)
    }

    function addNewInvite() {
        let newArray = [...inviteEmailList, { email: null, valid: true }]
        setInviteEmailList(newArray)
        setEmailsValidation(false)
    }

    function removeObjectFromArrray(object, objectIndex) {
        const newArray = inviteEmailList.filter((object, index) => index !== objectIndex);
        setInviteEmailList(newArray)
    }

    function selectAllProjects() {
        setSelectedProjects(activeProjectList);
    }

    function clearAllProjects() {
        setSelectedProjects([]);
    }

    function checkEmailsForValidation() {
        const newArray = inviteEmailList.filter((object) => object.valid === false);
        if (newArray.length > 0) {
            setEmailsValidation(false)

        } else if (newArray.length === 0) {
            setEmailsValidation(true)
        }
    }

    async function copyContent() {
        try {
            await navigator.clipboard.writeText(publicInvitationLink);
            console.log('Content copied to clipboard');
            /* Resolved - text copied to clipboard successfully */
        } catch (err) {
            console.error('Failed to copy: ', err);
            /* Rejected - text failed to copy to the clipboard */
        }
    }

    function checkRoleValidation() {
        if (Array.isArray(selectedUserRole) && selectedUserRole.length === 0) {
            setRoleValidation(false)
        } else if (!Array.isArray(selectedUserRole) && (selectedUserRole !== null || selectedUserRole !== undefined)) {
            setRoleValidation(true)
        }
    }

    useEffect(() => {
        if (!Array.isArray(selectedUserRole)) {
            checkRoleValidation()
        }
    }, [selectedUserRole])


    function submitInvitationForm() {

        if (emailsValidation === false) {
            inviteEmailList.forEach((emailObj, index) => {
                if ((emailObj.email === null || emailObj.email === '')) {
                    emailObj.valid = false;

                    let element = document.getElementById('add-email-' + index)
                    if (element.classList.contains('modal-input')) {
                        element.className -= "modal-input";
                        element.className = " modal-input-danger";
                    }
                }
            });
        }

        checkRoleValidation()

        if (emailsValidation === true && roleValidation === true) {

            // Emails
            let emailIds = []
            for (const emailObject of inviteEmailList) {
                if (emailObject.valid && emailObject.email !== null) {
                    emailIds.push(emailObject.email)
                }
            }

            // Projects
            let projects = []
            for (const projectObject of selectedProjects) {
                if (projectObject !== null || projectObject !== undefined) {
                    projects.push(projectObject.id)
                }
            }

            let dataObject = {
                email_ids: emailIds,
                role: selectedUserRole.id,
                project_ids: projects,
            }

            loadingShow('.modal-loading');
            sendInvitation(organizationId, dataObject).then((response) => {
                loadingHide('.modal-loading');
                if (response.code === 201) {
                    setInviteModalNotification({
                        type: 'success',
                        message: 'Invitation sent successfully!'
                    })
                    dispatch(fetchInvitedMembers(organizationId))
                    closeModal()
                }
            });


        } else {
            setInviteModalNotification({
                type: 'error',
                message: 'Invitation faild!'
            })
            console.log('Invitation faild!');
        }

    }

    return (
        <>
            <div className="mt-2 sm:mt-0 text-center">
                <button
                    className="ht-btn-yellow-lte"
                    type="button"
                    onClick={() => {
                        setInviteModalDisplay(true);
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 mr-3"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                        />
                    </svg>
                    <span>Invite a member</span>
                </button>

            </div>
            {inviteModalDisplay ? (
                <>
                    <Transition appear show={true} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed inset-0 z-10 overflow-y-auto bg-gray-600/80"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                <span
                                    className="inline-block h-screen "
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="modal-loading inline-block z-[1000] w-full max-w-lg p-6 my-8  text-left align-middle transition-all transform bg-white drop-shadow-2xl rounded-2xl">
                                        <Dialog.Title
                                            as="h3"
                                            className="ht-modal-header"
                                        >
                                            Add members
                                        </Dialog.Title>
                                        <div className={"flex flex-col mt- mx-auto mb-1 w-full"}>
                                            <div className="pb-2 border-b border-gray-100 ">
                                                <div className="ht-modal-title pt-4 pb-2">
                                                    <span>invite via email</span>
                                                </div>
                                                {[...inviteEmailList].map((obj, objIndex) => {
                                                    return (
                                                        <div className="w-full py-2 mb-3" key={objIndex}>
                                                            <div>
                                                                <label className="uppercase font-bold">email<span className="required">*</span></label>
                                                                {objIndex > 0 ? (
                                                                    <span
                                                                        className="email-field-close right z-50  hover:cursor-pointer "
                                                                        onClick={() => {
                                                                            removeObjectFromArrray(obj, objIndex)
                                                                        }}
                                                                    >
                                                                        <div className="absolute">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth="1.5"
                                                                                stroke="currentColor"
                                                                                className="w-4 h-4">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                            </svg>
                                                                        </div>

                                                                    </span>
                                                                ) : null}
                                                                <input
                                                                    name="email"
                                                                    id={`add-email-${objIndex}`}
                                                                    placeholder="Add an email"
                                                                    className={`modal-input`}
                                                                    value={obj.email ?? ''}
                                                                    autoComplete="off"
                                                                    onChange={(event) => {
                                                                        addEmail(obj, event)
                                                                    }}
                                                                />

                                                                {obj.valid === false ? (
                                                                    <>
                                                                        {obj.email === null || obj.email === '' ? (
                                                                            <p className="absolute text-red-500 text-xs italic mt-1 ml-3 capitalize">{validationMessage.email_required}</p>
                                                                        ) : (
                                                                            <p className="absolute text-red-500 text-xs italic mt-1 ml-3 capitalize">{validationMessage.email_invalid}</p>
                                                                        )}
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                <div
                                                    // className="py-2 mb-4 capitalize hover:cursor-pointer inline-flex h-10 px-5 transition-colors duration-150 bg-yellow-300 rounded focus:outline-none hover:shadow-lg w-40 items-center mb-2;"
                                                    className="capitalize mt-3 hover:cursor-pointer inline-flex transition-colors duration-150 w-40 items-center text-[#db9600] hover:text-[#c48602]"
                                                    onClick={addNewInvite}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 28 28"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="w-4 h-4"
                                                    >
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                    </svg>
                                                    <span>invite another</span>
                                                </div>
                                            </div>
                                            <div className="pb-2 border-b border-gray-100 ">
                                                <div className="w-full py-2 mb-3">
                                                    <div>
                                                        <label>
                                                            <span className="uppercase font-bold">role<span className="required">*</span></span>
                                                        </label>
                                                        <Dropdown
                                                            listData={userRoleArrayConst}
                                                            multiselect={false}
                                                            placeholder={'Search for a person to add'}
                                                            selectedItems={selectedUserRole}
                                                            setSelectedItems={setSelectedUserRole}
                                                            descriptionShow={true}
                                                            validation={roleValidation}
                                                            disabled={false}
                                                            selectedItemsDisplaySeparately={true}
                                                        />
                                                        {roleValidation === false ? (
                                                            <p className="absolute text-red-500 text-xs italic mt-1 ml-3 capitalize">{validationMessage.role_required}</p>
                                                        ) : null}


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pb-2 border-b border-gray-100 ">
                                                <div className="w-full py-2 mb-3">
                                                    <div>
                                                        <label>
                                                            <span className="uppercase font-bold">projects</span>
                                                            <div className='right'>
                                                                <span className='cursor-pointer hover:underline px-1' onClick={selectAllProjects}>Select All</span>
                                                                <span className='text-gray-400 text-[10px] px-1'>|</span>
                                                                <span className='cursor-pointer hover:underline px-1' onClick={clearAllProjects}>Clear</span>
                                                            </div>
                                                        </label>
                                                        <Dropdown
                                                            listData={activeProjectList}
                                                            multiselect={true}
                                                            placeholder={'Select projects you want to add team members to'}
                                                            selectedItems={selectedProjects}
                                                            setSelectedItems={setSelectedProjects}
                                                            descriptionShow={false}
                                                            validation={null}
                                                            disabled={false}
                                                            selectedItemsDisplaySeparately={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pb-2 border-b border-gray-100 ">
                                                <div className="w-full py-2 mb-3">
                                                    <div className="grid gap-4 grid-cols-2">
                                                        <div>
                                                            {publicInvitationLinkDisplay === false ? (
                                                                <span
                                                                    className="capitalize my-2 hover:cursor-pointer inline-flex transition-colors duration-150 w-40 items-center text-[#db9600] hover:text-[#c48602] text-[14px]"
                                                                    onClick={() => {
                                                                        if (publicInvitationLinkDisplay) {
                                                                            setPublicInvitationLinkDisplay(false)
                                                                        } else {
                                                                            setPublicInvitationLinkDisplay(true)
                                                                        }
                                                                    }}
                                                                >
                                                                    invite via link
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                        <div className="grid gap-4 grid-cols-2">
                                                            <button
                                                                className="ht-btn-black"
                                                                type="button"
                                                                onClick={closeModal}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                className="ht-btn-yellow"
                                                                type="submit"
                                                                onClick={submitInvitationForm}
                                                            >
                                                                Send
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {publicInvitationLinkDisplay ? (
                                                <div className="pb-2 border-b border-gray-100 ">
                                                    <div className="w-full py-2 mb-3">
                                                        <div className="ht-modal-title pt-4">
                                                            <span>Invite via link</span>
                                                        </div>
                                                        <div className="text-xs text-gray-600">Invite your team by sending them the link below</div>
                                                        <div className="inputWithButton">
                                                            <input
                                                                name="email"
                                                                className={`modal-input`}
                                                                style={{ paddingRight: '100px' }}
                                                                defaultValue={publicInvitationLink}
                                                            />
                                                            <button
                                                                className={`${copyLinkButtonClicked ? 'clicked' : ''}`}
                                                                onClick={() => {
                                                                    copyContent()
                                                                    setCopyLinkButtonClicked(true)
                                                                }}
                                                            >
                                                                {copyLinkButtonClicked ? 'copied' : 'copy'}
                                                            </button>
                                                        </div>
                                                        <div className="bg-gray-100 p-1 my-1 text-xsm inline-block">
                                                            <div className="d-flex">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 left">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                                    </svg>
                                                                    <span className="text-muted small via-link-info pl-1">The next step is to add them to projects after they join</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>

                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            ) : null}

        </>
    );
}

export default InviteModal;